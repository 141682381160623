import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Avatar,
  Backdrop,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";

const defaultValues = {
  title: "",
  campaign_type: "prospecting",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter title"),
    campaign_type: yup.string().required("You must select campaign type"),
  })
  .required();

export default function ProfileSummaryDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const openedChat = useSelector((state) => state.messages.openedChat);
  const prospectInfo = useSelector((state) => state.messages.prospectInfo);

  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const skills = [
    "Vulnerability Assessment",
    "IT RIsk Management",
    "Cyber Security Risk",
    "IT Audit",
    "Security Audit",
    "Security Audit",
  ];

  const { errors } = formState;

  function onSubmit(data) {
    handleClose();
    reset(defaultValues);
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark"
                ? "rgba(0, 0, 0, 0.60)"
                : "rgba(0, 0, 0, 0.60)", // Inner background color
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-between cursor-pointer pb-8"
          onClick={handleClose}
        >
          <div
            className={
              themeMode === "dark"
                ? "message-sender-position px-1 flex "
                : "message-sender-position px-1 flex "
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="30"
              viewBox="0 0 29 30"
              fill="none"
            >
              <g filter="url(#filter0_dd_2627_442)">
                <path
                  d="M14.5 21.0129C13.1206 21.0152 11.7544 20.745 10.4796 20.2179C9.20494 19.6908 8.04689 18.9171 7.07197 17.9413C2.97601 13.8453 2.97601 7.18121 7.07197 3.08525C9.05608 1.10113 11.694 0.0078125 14.5 0.0078125C17.306 0.0078125 19.9439 1.10029 21.928 3.08525C26.024 7.18121 26.024 13.8453 21.928 17.9413C20.9531 18.9171 19.7951 19.6908 18.5204 20.2179C17.2456 20.745 15.8794 21.0152 14.5 21.0129ZM8.26025 16.753C11.7007 20.1935 17.2993 20.1935 20.7397 16.753C22.4062 15.0857 23.3239 12.8697 23.3239 10.5133C23.3239 8.15688 22.4062 5.94082 20.7397 4.27353C19.0733 2.60624 16.8572 1.68855 14.5 1.68855C12.1428 1.68855 9.92755 2.60708 8.26025 4.27353C6.59296 5.93998 5.67612 8.15604 5.67612 10.5133C5.67612 12.8705 6.5938 15.0857 8.26025 16.753Z"
                  fill="white"
                />
                <path
                  d="M16.4309 15.2158C16.3205 15.216 16.2112 15.1943 16.1093 15.152C16.0073 15.1097 15.9147 15.0478 15.8368 14.9696L11.9744 11.1073C11.8963 11.0293 11.8343 10.9367 11.7921 10.8347C11.7498 10.7328 11.728 10.6235 11.728 10.5131C11.728 10.4028 11.7498 10.2935 11.7921 10.1915C11.8343 10.0896 11.8963 9.99696 11.9744 9.91899L15.8368 6.05665C15.9148 5.97862 16.0074 5.91673 16.1094 5.87451C16.2113 5.83228 16.3206 5.81055 16.4309 5.81055C16.5412 5.81055 16.6505 5.83228 16.7525 5.87451C16.8544 5.91673 16.947 5.97862 17.025 6.05665C17.1031 6.13467 17.165 6.2273 17.2072 6.32924C17.2494 6.43119 17.2711 6.54045 17.2711 6.65079C17.2711 6.76113 17.2494 6.87039 17.2072 6.97234C17.165 7.07428 17.1031 7.16691 17.025 7.24493L13.7568 10.5131L17.025 13.7813C17.1429 13.8987 17.2233 14.0484 17.256 14.2116C17.2886 14.3747 17.2721 14.5439 17.2084 14.6976C17.1447 14.8513 17.0368 14.9826 16.8984 15.0749C16.76 15.1671 16.5973 15.2162 16.4309 15.2158Z"
                  fill="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_dd_2627_442"
                  x="0"
                  y="0.0078125"
                  width="29"
                  height="29.0049"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2627_442"
                  />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="effect1_dropShadow_2627_442"
                    result="effect2_dropShadow_2627_442"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect2_dropShadow_2627_442"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>{" "}
            Back to Messages
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke="white"
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div className="flex flex-col justify center items-center">
              <Avatar
                sx={{ width: 81, height: 81 }}
                src={openedChat?.prospect?.profile_picture_url}
                alt={"user_dp"}
              />
              <div
                className={
                  themeMode === "dark"
                    ? "message-sender pt-2"
                    : "message-sender-light pt-2"
                }
              >
                {" "}
                {openedChat?.prospect?.name}
              </div>
              <div
                className={
                  themeMode === "dark"
                    ? "message-sender-position px-1"
                    : "message-sender-position px-1"
                }
                style={{ fontSize: "13px" }}
              >
                {prospectInfo?.headline}
              </div>
              <div
                className="flex flex-col md:flex-row items-center md:space-x-8 mt-2"
                style={{ fontWeight: "600" }}
              >
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    fill="none"
                  >
                    <path
                      d="M7 4.28571C7.66304 4.28571 8.29893 4.53656 8.76777 4.98308C9.23661 5.42959 9.5 6.0352 9.5 6.66667C9.5 6.97934 9.43534 7.28895 9.3097 7.57782C9.18406 7.86669 8.99991 8.12916 8.76777 8.35025C8.53562 8.57135 8.26002 8.74673 7.95671 8.86638C7.65339 8.98603 7.3283 9.04762 7 9.04762C6.33696 9.04762 5.70107 8.79677 5.23223 8.35025C4.76339 7.90374 4.5 7.29813 4.5 6.66667C4.5 6.0352 4.76339 5.42959 5.23223 4.98308C5.70107 4.53656 6.33696 4.28571 7 4.28571ZM7 0C8.85652 0 10.637 0.702379 11.9497 1.95262C13.2625 3.20286 14 4.89856 14 6.66667C14 11.6667 7 19.0476 7 19.0476C7 19.0476 0 11.6667 0 6.66667C0 4.89856 0.737498 3.20286 2.05025 1.95262C3.36301 0.702379 5.14348 0 7 0ZM7 1.90476C5.67392 1.90476 4.40215 2.40646 3.46447 3.29949C2.52678 4.19252 2 5.40373 2 6.66667C2 7.61905 2 9.52381 7 15.9143C12 9.52381 12 7.61905 12 6.66667C12 5.40373 11.4732 4.19252 10.5355 3.29949C9.59785 2.40646 8.32608 1.90476 7 1.90476Z"
                      fill="white"
                    />
                  </svg>
                  <span> {openedChat?.prospect?.location}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                  >
                    <path
                      d="M8.1 11.2379H6.3C6.3 6.97786 9.927 3.52358 14.4 3.52358V5.23786C10.917 5.23786 8.1 7.92072 8.1 11.2379ZM14.4 8.66643V6.95215C11.916 6.95215 9.9 8.87215 9.9 11.2379H11.7C11.7 9.81501 12.906 8.66643 14.4 8.66643ZM4.5 2.66643C4.5 1.71501 3.699 0.952148 2.7 0.952148C1.701 0.952148 0.9 1.71501 0.9 2.66643C0.9 3.61786 1.701 4.38072 2.7 4.38072C3.699 4.38072 4.5 3.61786 4.5 2.66643ZM8.505 3.09501H6.705C6.489 4.31215 5.4 5.23786 4.05 5.23786H1.35C0.603 5.23786 0 5.81215 0 6.52358V8.66643H5.4V6.72929C7.074 6.22358 8.325 4.80929 8.505 3.09501ZM15.3 13.8093C16.299 13.8093 17.1 13.0464 17.1 12.095C17.1 11.1436 16.299 10.3807 15.3 10.3807C14.301 10.3807 13.5 11.1436 13.5 12.095C13.5 13.0464 14.301 13.8093 15.3 13.8093ZM16.65 14.6664H13.95C12.6 14.6664 11.511 13.7407 11.295 12.5236H9.495C9.675 14.2379 10.926 15.6521 12.6 16.1579V18.095H18V15.9521C18 15.2407 17.397 14.6664 16.65 14.6664Z"
                      fill="white"
                    />
                  </svg>
                  <span>
                    {" "}
                    Connections: {openedChat?.prospect?.connections_count}
                  </span>
                </div>{" "}
              </div>
            </div>
            <div className="flex mt-4 flex-col md:flex-row items-center justify-between md:space-x-4 space-x-0 space-y-4 md:space-y-0">
              <div className="profile-summary-info-outline px-4 py-2 flex items-center space-x-4 w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                >
                  <path
                    d="M18 4C18.58 4 19.05 4.2 19.42 4.59C19.8 5 20 5.45 20 6V17C20 17.55 19.8 18 19.42 18.41C19.05 18.8 18.58 19 18 19H2C1.42 19 0.95 18.8 0.58 18.41C0.2 18 0 17.55 0 17V6C0 5.45 0.2 5 0.58 4.59C0.95 4.2 1.42 4 2 4H6V2C6 1.42 6.2 0.95 6.58 0.58C6.95 0.2 7.42 0 8 0H12C12.58 0 13.05 0.2 13.42 0.58C13.8 0.95 14 1.42 14 2V4H18ZM2 6V17H18V6H2ZM12 4V2H8V4H12Z"
                    fill="white"
                  />
                </svg>{" "}
                <span>{openedChat?.prospect?.current_job_title}</span>
              </div>
              <div className="profile-summary-info-outline px-4 py-2 flex items-center space-x-4 w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                >
                  <path
                    d="M11 0L0 6L4 8.18V14.18L11 18L18 14.18V8.18L20 7.09V14H22V6L11 0ZM17.82 6L11 9.72L4.18 6L11 2.28L17.82 6ZM16 13L11 15.72L6 13V9.27L11 12L16 9.27V13Z"
                    fill="white"
                  />
                </svg>
                <span>{prospectInfo?.education?.[0]?.school}</span>
              </div>{" "}
            </div>
            <div className="flex mt-4 flex-col md:flex-row items-center justify-between md:space-x-4 space-x-0 space-y-4 md:space-y-0">
              <div className="profile-summary-info-outline px-4 py-2 flex items-center space-x-4 w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12M12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.96 15.65 14.43 16.93 12.59 17.56ZM12.34 12H7.66C7.56 11.34 7.5 10.68 7.5 10C7.5 9.32 7.56 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM10 17.96C9.17 16.76 8.5 15.43 8.09 14H11.91C11.5 15.43 10.83 16.76 10 17.96ZM6 6H3.08C4.03 4.34 5.57 3.06 7.4 2.44C6.8 3.55 6.35 4.75 6 6ZM3.08 14H6C6.35 15.25 6.8 16.45 7.4 17.56C5.57 16.93 4.03 15.65 3.08 14ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12M10 2.03C10.83 3.23 11.5 4.57 11.91 6H8.09C8.5 4.57 9.17 3.23 10 2.03ZM16.92 6H13.97C13.65 4.75 13.19 3.55 12.59 2.44C14.43 3.07 15.96 4.34 16.92 6ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z"
                    fill="white"
                  />
                </svg>
                <span>{prospectInfo?.websites?.[0]?.url ?? "N/A"}</span>
              </div>
              <div className="profile-summary-info-outline px-4 py-2 flex items-center space-x-4 w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    d="M7.8325 10.6525C8.2425 11.0425 8.2425 11.6825 7.8325 12.0725C7.4425 12.4625 6.8025 12.4625 6.4125 12.0725C4.4625 10.1225 4.4625 6.9525 6.4125 5.0025L9.9525 1.4625C11.9025 -0.4875 15.0725 -0.4875 17.0225 1.4625C18.9725 3.4125 18.9725 6.5825 17.0225 8.5325L15.5325 10.0225C15.5425 9.2025 15.4125 8.3825 15.1325 7.6025L15.6025 7.1225C16.7825 5.9525 16.7825 4.0525 15.6025 2.8825C14.4325 1.7025 12.5325 1.7025 11.3625 2.8825L7.8325 6.4125C6.6525 7.5825 6.6525 9.4825 7.8325 10.6525ZM10.6525 6.4125C11.0425 6.0225 11.6825 6.0225 12.0725 6.4125C14.0225 8.3625 14.0225 11.5325 12.0725 13.4825L8.5325 17.0225C6.5825 18.9725 3.4125 18.9725 1.4625 17.0225C-0.4875 15.0725 -0.4875 11.9025 1.4625 9.9525L2.9525 8.4625C2.9425 9.2825 3.0725 10.1025 3.3525 10.8925L2.8825 11.3625C1.7025 12.5325 1.7025 14.4325 2.8825 15.6025C4.0525 16.7825 5.9525 16.7825 7.1225 15.6025L10.6525 12.0725C11.8325 10.9025 11.8325 9.0025 10.6525 7.8325C10.2425 7.4425 10.2425 6.8025 10.6525 6.4125Z"
                    fill="white"
                  />
                </svg>
                <span style={{ textWrap: "wrap" }}>
                  {" "}
                  {`${openedChat?.prospect?.profile_url}`}
                </span>
              </div>{" "}
            </div>
            <div className="flex mt-4 flex-col md:flex-row items-center justify-between md:space-x-4 space-x-0 space-y-4 md:space-y-0">
              <div className="profile-summary-info-outline px-4 py-2 flex items-center space-x-4 w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                >
                  <path
                    d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 7L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
                    fill="white"
                  />
                </svg>
                <span>{prospectInfo?.email ?? "N/A"}</span>
              </div>
              <div className="profile-summary-info-outline px-4 py-2 flex items-center space-x-4 w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
                    fill="white"
                  />
                </svg>
                <span>{prospectInfo?.phone ?? "N/A"}</span>
              </div>{" "}
            </div>
            <div className="mt-4 ">
              <span
                className={
                  themeMode === "dark"
                    ? "message-sender "
                    : "message-sender-light "
                }
              >
                {" "}
                About:
              </span>
              <span
                className={
                  themeMode === "dark"
                    ? "message-sender-position ml-2"
                    : "message-sender-position ml-2"
                }
                style={{ fontSize: "13px", textAlign: "start" }}
              >
                {openedChat?.prospect?.headline_bio}
              </span>
            </div>
            <div className="mt-4">
              <span
                className={
                  themeMode === "dark"
                    ? "message-sender "
                    : "message-sender-light "
                }
              >
                {" "}
                Skills:{" "}
              </span>
              <div className="flex flex-col md:flex-row flex-wrap items-center md:space-x-2 space-y-2  mt-2">
                {prospectInfo?.skills?.map((skill, index) => (
                  <div
                    className="profile-summary-info-outline px-4 py-2"
                    key={index}
                  >
                    {skill.name}
                  </div>
                ))}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
