import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import __ from "lodash";

function getBrowserAndOS() {
  const userAgent = navigator.userAgent;
  let browser = "Unknown";
  let os = "Unknown";

  // Detect browser
  if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
    browser = "Chrome";
  } else if (userAgent.includes("Firefox")) {
    browser = "Firefox";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    browser = "Safari";
  } else if (userAgent.includes("Edg")) {
    browser = "Edge";
  }

  // Detect OS
  if (userAgent.includes("Macintosh") || userAgent.includes("Mac OS X")) {
    os = "macOS";
  } else if (userAgent.includes("Windows")) {
    os = "Windows";
  } else if (userAgent.includes("Android")) {
    os = "Android";
  } else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
    os = "iOS";
  }

  return { browser, os };
}

export default function NotificationPermissionDialog({
  open,
  handleClose,
  message,
}) {
  const { browser, os } = getBrowserAndOS();
  let instructions = "To enable notifications, follow these steps:\n";
  if (os === "macOS" && browser === "Safari") {
    instructions +=
      "For Safari on macOS: Go to Safari Preferences > Websites > Notifications.";
  } else if (browser === "Chrome") {
    instructions +=
      "For Chrome: Click the lock icon next to the address bar, then go to Site Settings, and enable Notifications.";
  } else if (browser === "Firefox") {
    instructions +=
      "For Firefox: Click the shield icon next to the address bar, then go to Permissions, and enable Notifications.";
  } else if (browser === "Edge") {
    instructions +=
      "For Edge: Click the lock icon next to the address bar, then go to Site permissions, and enable Notifications.";
  } else {
    instructions +=
      "Please check your browser settings to enable notifications.";
  }
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const deleteMessageId = useSelector(
    (state) => state.messages.deleteMessageId
  );

  function onSubmit(data) {
    handleClose();
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", // Inner background color
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-between cursor-pointer pb-8"
          onClick={handleClose}
        >
          <div />
          <div
            className={
              themeMode === "dark"
                ? "create-campaign-dialog-text "
                : "create-campaign-dialog-text-light "
            }
            style={{ fontSize: "18px", color: "red" }}
          >
            Allow Notifications
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "black"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2 pt-4"
                  : "create-campaign-dialog-text-light pb-2 pt-4"
              }
              style={{ fontWeight: "400" }}
            >
              You've blocked notifications. Please enable them in your browser
              settings to receive notifications.{" "}
              <div className="mt-4">
                {os === "macOS" && browser === "Safari" && (
                  <div>
                    {" "}
                    1. Open Safari and go to the website. <br /> 2. Click Safari
                    in the menu bar and select Preferences. <br /> 3. Go to the
                    Websites tab. <br /> 4. Select Notifications from the
                    sidebar. <br /> 5. Find the website in the list and change
                    the setting to "Allow". <br />
                  </div>
                )}{" "}
                {browser === "Chrome" && (
                  <div>
                    {" "}
                    1. Open Chrome and go to the website. <br /> 2. Click the
                    padlock icon in the address bar (left of the URL). <br /> 3.
                    Select "Site settings". <br /> 4. Scroll down to
                    "Notifications" and change the setting to "Allow". <br /> 5.
                    Refresh the page for the changes to take effect. <br />{" "}
                    <br /> Alternatively: <br /> - Go to Settings -> Privacy and
                    security -> Site settings -> Notifications. - Find the
                    website under the "Not allowed to send notifications"
                    section. - Change it to "Allow".
                  </div>
                )}
                {browser === "Firefox" && (
                  <div>
                    {" "}
                    1. Click the padlock icon next to the website's URL. <br />{" "}
                    2. Select "More Information". <br /> 3. In the pop-up
                    window, click the "Permissions" tab. <br /> 4. Uncheck "Use
                    default" next to "Send Notifications", then check "Allow".{" "}
                    <br /> 5. Refresh the page to apply changes. <br /> <br />{" "}
                    Alternatively: - Go to Menu (three lines) -> Settings ->
                    Privacy & Security -> Permissions > Settings (next to
                    Notifications). - Find the website in the list, select it,
                    and click "Allow".{" "}
                  </div>
                )}
                {browser === "Edge" && (
                  <div>
                    {" "}
                    1. Click the lock icon in the address bar. <br /> 2. Choose
                    "Permissions for this site". <br /> 3. Find "Notifications"
                    and change it to "Allow". <br /> 4. Refresh the page to
                    enable notifications. <br />
                    {"<br /> "}
                    Alternatively: - Go to Settings -> Cookies and site
                    permissions -> Notifications. - Under "Block", find the
                    website and change it to "Allow".
                  </div>
                )}
              </div>
            </div>

            <div className="flex w-full justify-end space-x-4 pt-8">
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-2 px-8"
                    : "create-button-light p-2 px-8"
                }
                variant="contained"
                onClick={handleClose}
              >
                <span className="create-button-text">Go Back</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
