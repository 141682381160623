export const AddFollowupMessageCard = ({ themeMode }) => {
  return (
    <div
      className={
        themeMode === "dark"
          ? "follow-up-card cursor-pointer p-6 h-full"
          : "follow-up-card-light cursor-pointer p-6 h-full"
      }
    >
      <div className="flex items-center w-full h-full justify-center align-middle space-x-4">
        <span
          className={
            themeMode === "dark" ? "follow-up-plus" : "follow-up-plus-light"
          }
        >
          +
        </span>{" "}
        <span
          className={
            themeMode === "dark"
              ? "messaging-card-heading"
              : "messaging-card-heading-light"
          }
        >
          Add Followup Message
        </span>
      </div>
    </div>
  );
};
