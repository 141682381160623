import { Grid, Avatar, Box, Button, styled, TextField } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getOrganization,
  updateOrganization,
} from "../../../store/settingsSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { success } from "../../../store/alertSlice";

const userDp =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ben.png";

const CustomTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiInputBase-input": {
    color: "white",
    fontSize: "13px",
  },

  //   minWidth: "239px",
});

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
};

const schema = yup
  .object()
  .shape({
    first_name: yup.string().required("You must enter first name"),
    last_name: yup.string().required("You must enter last name"),
    email: yup.string().required("You must enter email"),
  })
  .required();

export const AddMembers = () => {
  const organization = useSelector((state) => state.settings.organization);
  const user = useSelector((state) => state.userProfile.userProfile);
  const emailExists = organization?.admins?.some(
    (member) => member.email === user.email
  );

  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const { handleSubmit, register, reset, setValue, control, watch, formState } =
    useForm({
      defaultValues,
      mode: "onSubmit",
      resolver: yupResolver(schema),
    });

  const { errors } = formState;

  function onSubmit(data) {
    console.log("🚀 ~ onSubmit ~ data:", data);
    // dispatch(updateOrganization(data)).then((resp) => {
    //   if (resp.payload.status === 200) {
    //     dispatch(success("Organization updated"));
    //     dispatch(getOrganization());
    //   }
    // });
  }

  const CustomTextField = styled(TextField)({
    "& .MuiInput-underline:before": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInputBase-input": {
      color: themeMode === "dark" ? "white" : "#000",
      fontSize: "13px",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "gray", // Change the text color in disabled state
    },
    //   minWidth: "239px",
  });

  return (
    <div>
      <div
        className={`settings-heading ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Add members to your organization{" "}
      </div>
      <Grid 
      //className="py-6 lg:flex lg:space-x-4" 
      className="py-6"
      sx={{display:"flex",flexDirection:"column"}}>
       
         <Grid 
          className="mb-10 sm:space-x-16"  
          sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column"}}}
        >
          <div
            className={`profile-field-title  ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
            style={{display:"flex",alignItems:"center"}}
          >
            First Name:
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                className=" "
                {...field}
                error={!!errors.first_name}
                helperText={errors?.first_name?.message}
                required
                variant="standard"
                sx={
                  {
                    //   width: { md: "239px", sm: "239px", xs: "150px" },
                    //   marginLeft: { md: "10px" },
                  }
                }
              />
            )}
            name="first_name"
            control={control}
          />
        {/* </div> */}
        </Grid>

       <Grid 
          className="mb-10 sm:space-x-16"  
          sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column"}}}
        >
          <div
            className={`profile-field-title  ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Last Name:
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                className=" "
                {...field}
                error={!!errors.last_name}
                helperText={errors?.last_name?.message}
                required
                variant="standard"
              />
            )}
            name="last_name"
            control={control}
          />
        </Grid>

        <Grid 
          className="mb-10 sm:space-x-24"  
          sx={{display:"flex",flexDirection:{md:"row",sm:"row",xs:"column"}}}
        >
          <div
            className={`profile-field-title  ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Email:{" "}
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                className=" "
                {...field}
                error={!!errors.email}
                helperText={errors?.email?.message}
                required
                variant="standard"
              />
            )}
            name="email"
            control={control}
          />
        </Grid>
      </Grid>
      <div className="mb-8">
        <div
          className={`cursor-pointer text-center py-4 ${
            themeMode === "dark"
              ? "add-member-dashed"
              : "add-member-dashed-light"
          }`}
        >
          <span
            className={
              themeMode === "dark"
                ? "add-member-title "
                : "add-member-title-light "
            }
          >
            + Add More Members{" "}
          </span>
        </div>
      </div>
      <Button
        className={`p-2 px-4 ${
          themeMode === "dark" ? "create-button" : "create-button-light"
        }`}
        variant="contained"
        onClick={handleSubmit(onSubmit)}
      >
        <span className="create-button-text">Send Invitation</span>
      </Button>
    </div>
  );
};
