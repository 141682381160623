export const messagesData = [
  {
    id: 1,
    read: false,
    sender: "Ministry of Magic",
    text: "No worries thank you for letting me know and taking the time to respond. We work in sev...!",
    date: "19 Jul",
  },
  {
    id: 2,
    read: true,
    sender: "Lamborghini Avendator",
    text: "No worries thank you for letting me know and taking the time to respond. We work in sev...",
    date: "19 Jul",
  },
  {
    id: 3,
    read: false,
    sender: "Carolyn Chandler",
    text: "No worries thank you for letting me know and taking the time to respond. We work in sev...",
    date: "19 Jul",
  },
  {
    id: 4,
    read: true,
    sender: "Daffodils Craig Forgulam",
    text: "Hi Benjamin, I haven’t been in the roofing industry for many many years. Have a wonderf...",
    date: "19 Jul",
  },
  {
    id: 5,
    read: true,
    sender: "Lamborghini Avendator",
    text: "No worries thank you for letting me know and taking the time to respond. We work in several..",
    date: "19 Jul",
  },
  {
    id: 6,
    read: true,
    sender: "Lamborghini Avendator",
    text: "Just working on a project.",
    date: "19 Jul",
  },
  {
    id: 7,
    read: false,
    sender: "The Archies",
    text: "Sounds interesting!",
    date: "19 Jul",
  },
  {
    id: 8,
    read: true,
    sender: "The Archies",
    text: "Do you need any help?",
    date: "19 Jul",
  },
  {
    id: 9,
    read: true,
    sender: "The Archies",
    text: "Not right now, but thanks!",
    date: "19 Jul",
  },
  {
    id: 10,
    read: true,
    sender: "The Archies",
    text: "Let us know if you do.",
    date: "19 Jul",
  },
];
