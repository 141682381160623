import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
import api from "../services/api";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  userProfile: {},
};

export const getUserProfile = createAsyncThunk(
  "userProfile/getUserProfile",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/app_settings/show_user_profile_settings`
      );

      return res.data;
    } catch (err) {
      // dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "userProfile/updateUserProfile",
  async (app_settings, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/app_settings/update_user_profile_settings`,
        { app_settings }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const logoutUser = createAsyncThunk(
  "userProfile/logoutUser",
  async (_, { dispatch }) => {
    try {
      const response = await api.delete("/auth/sign_out");
      return response.data;
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const requestCode = createAsyncThunk(
  "userProfile/requestCode",
  async (app_settings, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/app_settings/trigger_email_code`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const changePassword = createAsyncThunk(
  "userProfile/changePassword",
  async (user, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/app_settings/reset_password`,
        { user }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.message));
      console.error(err);
      return null;
    }
  }
);

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload;
        let user = JSON.parse(localStorage.getItem("user"));
        if (user) {
          user.profile = action.payload;
          localStorage.setItem("user", JSON.stringify(user));
        }
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.userProfile = null;
        localStorage.removeItem("client");
        localStorage.removeItem("uid");
        localStorage.removeItem("access-token");
        localStorage.removeItem("user");
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.userProfile = null;
        localStorage.removeItem("client");
        localStorage.removeItem("uid");
        localStorage.removeItem("access-token");
        localStorage.removeItem("user");
      });
  },
});

export const {} = userProfileSlice.actions;

export default userProfileSlice.reducer;
