import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import HowSearchURLWorks from "./HowSearchURLWorks";

const defaultValues = {
  title: "",
  campaign_type: "prospecting",
};

export default function SearchURLDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", // Inner background color
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-8"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            onClick={handleClose}
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "black"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div
              className={
                themeMode === "dark"
                  ? "text-white pb-2 text-center mb-6"
                  : "text-black pb-2 text-center mb-6"
              }
            >
              Manage the search URLs for team members in this campaign{" "}
            </div>
            <div className="mb-8">
              <HowSearchURLWorks themeMode={themeMode} />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
