import { Typography, Button, TextField, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../store/resetSlice";
import ReactCodeInput from "react-code-input";
import { useEffect, useState } from "react";

const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

export const EnterCode = () => {
  const dispatch = useDispatch();
  const chosenMethod = useSelector((state) => state.reset.chosenMethod);
  const [code, setCode] = useState("");
  const [btnDisable, setBtnDisabled] = useState(true);
  const [seconds, setSeconds] = useState(60);

  const handleCodeChange = (value) => {
    setCode(value);
    console.log("Code:", value);
  };

  const CountdownTimer = ({ initialSeconds }) => {
    useEffect(() => {
      if (seconds > 0) {
        const timer = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(timer);
      } else {
        setBtnDisabled(false);
      }
    }, [seconds]);

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    return (
      <span>
        <Typography variant="h7" sx={{ fontWeight: "bold" }}>
          {seconds > 0 ? ` (${formatTime(seconds)})` : "Time's up!"}
        </Typography>
      </span>
    );
  };

  return (
    <div className="px-10">
      <img src={logo} alt="logo" />
      <Typography
        sx={{
          fontSize: { md: "40px", sm: "40px", xs: "30px" },
          fontWeight: "700",
          textWrap: "nowrap",
        }}
      >
        Reset your password{" "}
      </Typography>
      <Typography
        sx={{
          fontSize: { md: "18px", sm: "18px", xs: "14px" },
          fontWeight: "400",
          textAlign: { md: "left", sm: "center", xs: "center" },
        }}
      >
        Enter the code we {chosenMethod === "email" ? "emailed to" : "texted"}{" "}
        you{" "}
      </Typography>
      <Typography
        sx={{
          fontSize: { md: "18px", sm: "18px", xs: "14px" },
          fontWeight: "400",
          textAlign: { md: "left", sm: "center", xs: "center" },
        }}
      >
        Verification codes are valid for 10 minutes{" "}
      </Typography>
      <div className="mt-8">
        <div className="flex w-full justify-center">
          <ReactCodeInput
            type="text"
            fields={6}
            value={code}
            onChange={handleCodeChange}
            inputStyle={{
              margin: "4px",
              padding: "12px",
              width: "40px",
              height: "40px",
              fontSize: "24px",
              borderRadius: "4px",
              border: "1px solid lightgray",
              color: "#B50000",
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Background color with transparency
            }}
          />
        </div>
        <div className="flex w-full justify-center my-8">
          <Button
            variant="contained"
            onClick={() => {
              dispatch(setActiveStep(4));
            }}
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "#FFF",
              borderRadius: "30px",
              color: "#B50000",
              textTransform: "none",
              padding: "8px 20px",
              border: "1px solid transparent",
              minWidth: "170px",
              maxWidth: "170px",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            Submit
          </Button>
        </div>

        <div className="flex flex-col w-full justify-center my-8">
          <div className="flex flex-col justify-center items-center">
            <Typography
              sx={{
                fontSize: { md: "16px", sm: "16px", xs: "12px" },
                fontWeight: "600",
                textAlign: { md: "left", sm: "center", xs: "center" },
              }}
            >
              Use Different Verification Code Method{" "}
            </Typography>
            <Typography
              className="my-2"
              sx={{
                fontSize: { md: "18px", sm: "18px", xs: "14px" },
                fontWeight: "400",
                textAlign: { md: "left", sm: "center", xs: "center" },
              }}
            >
              Didn’t Receive a Code?{" "}
            </Typography>
            <Typography
              sx={{
                fontSize: { md: "14px", sm: "14px", xs: "12px" },
                fontWeight: "400",
                textAlign: { md: "left", sm: "center", xs: "center" },
              }}
            >
              If you haven’t receive a code, after a minute you can request a
              new one.{" "}
            </Typography>

            <Button
              disabled={seconds > 0}
              variant="contained"
              onClick={() => {
                //   dispatch(setActiveStep(4));
              }}
              sx={{
                fontWeight: "700",
                marginY: "10px",
                backgroundColor: "#FFF",
                borderRadius: "30px",
                color: "#B50000",
                textTransform: "none",
                padding: "8px 20px",
                border: "1px solid transparent",
                minWidth: "170px",
                maxWidth: "170px",
                "&:hover": {
                  background: "transparent",
                  color: "#FFF",
                  border: "1px solid #FFF",
                },
              }}
            >
              Resend It {seconds > 0 && <CountdownTimer initialSeconds={60} />}{" "}
            </Button>
          </div>
        </div>

        <Typography
          onClick={() => dispatch(setActiveStep(2))}
          className="mt-8 mb-4 cursor-pointer"
          sx={{
            fontSize: { md: "16px", sm: "16px", xs: "14px" },
            fontWeight: "500",
            textAlign: { md: "center", sm: "center", xs: "center" },
          }}
        >
          BACK
        </Typography>
      </div>
    </div>
  );
};
