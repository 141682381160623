import jsPDF from 'jspdf';
import { toJpeg } from 'html-to-image';


export const generatePdfUsingHtmltoImage = async (contentDiv, pdfName) => {
  try {
    const dataUrl = await toJpeg(contentDiv, { quality: 1, cacheBust: true, useCors: true });
    
    const pdf = new jsPDF('portrait', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Get the actual dimensions of the content
    const img = new Image();
    img.src = dataUrl;
    img.onload = () => {
      const aspectRatio = img.width / img.height;

      // Calculate new dimensions while maintaining aspect ratio
      let imageWidth = pdfWidth;
      let imageHeight = pdfWidth / aspectRatio;

      // If the height is larger than the page height, scale down
      if (imageHeight > pdfHeight) {
        imageHeight = pdfHeight;
        imageWidth = pdfHeight * aspectRatio;
      }

      // Add the image to the PDF, centered if necessary
      const xOffset = (pdfWidth - imageWidth) / 2;
      const yOffset = (pdfHeight - imageHeight) / 2;

      pdf.addImage(dataUrl, 'JPEG', xOffset, yOffset, imageWidth, imageHeight);
      pdf.save(pdfName);
    };
  } catch (error) {
    console.error('Error generating PDF using html-to-image:', error);
  }
};

export const downloadFile = (fileUrl, fileName) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName || 'facoon_invoice';  
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function capitalizeFirstLetter(str) {
  return str?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');           
}

export function capitalizeJustFirstLetter(str) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export function capitalizeString(str) {
  return str?.split(' ')?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(' ');
}