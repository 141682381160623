export const NavItems = [
    // { label: 'Home',link:"/", width: '90px', left: '30px' },
    // { label: 'About',link:"/about", width: '90px', left: '138px' },
    // { label: 'Pricing', link: "/pricing", width: '95px', left: '240px' },
    // { label: 'Contact Us', link: "/contact", width: '120px', left: '338px' },
    // { label: 'FAQ', link: "/faqs", width: '80px', left: '462px' },
    { label: 'Home', link: "/", width: '16%', left: '5%' },
    { label: 'About', link: "/about", width: '15.5%', left: '24%' },
    { label: 'Pricing', link: "/pricing", width: '16.5%', left: '42%' },
    { label: 'Contact Us', link: "/contact", width: '21%', left: '58%' },
    { label: 'FAQ', link: "/faqs", width: '14%', left: '80%' },
];