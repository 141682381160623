import {
    Button,
    InputAdornment,
    OutlinedInput,
    IconButton,
    Grid,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import MembersManagementTable from "./MembersManagementTable";
  import { useDispatch, useSelector } from "react-redux";
  import MemberStatusDialog from "./MemberStatusDialog";
  import DeleteMemberDialog from "./DeleteMemberDialog";
  import AddMemberDialog from "./AddMemberDialog";
  
  export const MembersManagement = () => {
    const dispatch = useDispatch();
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [searchQuery, setSearchQuery] = useState("");
    const [openStatusDialog, setOpenStatusDialog] = useState(null);
    const [openDialog, setOpenDialog] = useState(null);
    const [openAddMemberDialog, setOpenAddMemberDialog] = useState(null);
    const [members, setMembers] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const organizationMembers = useSelector((state) => state.organization.organizationMembers);
    const totalMembers = organizationMembers?.meta?.total_count;
  

    const handleCloseStatusDialog = () => {
      setOpenStatusDialog(false);
      setMembers(null);
      setSelectedMember(null);
      setSelectedMembers([]);
    };

    const handleOpenStatusDialog = (selectedMembers, selectedMember) => {
      setMembers(selectedMembers?.length ? selectedMembers : [selectedMember]);
      setOpenStatusDialog(true);
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
      setMembers(null);
      setSelectedMember(null);
      setSelectedMembers([]);
    };

    const handleOpenDialog = (selectedMembers, selectedMember) => {
      setMembers(selectedMembers?.length  ? selectedMembers : [selectedMember]);
      setOpenDialog(true);
    };

    const handleCloseAddMemberDialog = () => {
      setOpenAddMemberDialog(false);
    };
  
    return (
      <div>
        <div
          className={`settings-heading ${
            themeMode === "dark" ? "text-light" : "text-dark"
          }`}
        >
          Members Management
        </div>
        <div className="py-6">
          <Grid
            className="mt-12 mb-4"
            sx={{display:"flex",width:"100%",flexDirection:{md:"row",sm:"column",xs:"column"},justifyContent:"space-between",gap:"15px"}}
          >
            <div
              className={`total-tags ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              style={{display:"flex",alignItems:"center"}}
            >
              Total Members:{" "}
              {totalMembers}
              <OutlinedInput
                onChange={(e) => setSearchQuery(e.target.value)}
                id="outlined-adornment-password"
                className={
                  themeMode === "dark" ? "dashboard-field mx-2" : "dashboard-field-light mx-2"
                }
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "& .MuiInputBase-input": {
                    color: themeMode === "dark" ? "white" : "black",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" edge="end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M5.2 0C6.57913 0 7.90177 0.547855 8.87696 1.52304C9.85215 2.49823 10.4 3.82087 10.4 5.2C10.4 6.488 9.928 7.672 9.152 8.584L9.368 8.8H10L14 12.8L12.8 14L8.8 10V9.368L8.584 9.152C7.672 9.928 6.488 10.4 5.2 10.4C3.82087 10.4 2.49823 9.85215 1.52304 8.87696C0.547855 7.90177 0 6.57913 0 5.2C0 3.82087 0.547855 2.49823 1.52304 1.52304C2.49823 0.547855 3.82087 0 5.2 0ZM5.2 1.6C3.2 1.6 1.6 3.2 1.6 5.2C1.6 7.2 3.2 8.8 5.2 8.8C7.2 8.8 8.8 7.2 8.8 5.2C8.8 3.2 7.2 1.6 5.2 1.6Z"
                          fill="white"
                        />
                      </svg>{" "}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Search"
              />
            </div>
            <Button
              className={`p-2 px-4 ${
                themeMode === "dark" ? "create-button" : "create-button-light"
              }`}
              variant="contained"
              onClick={() => setOpenAddMemberDialog(true)}
            >
              <span className="create-button-text">+ Add Members</span>
            </Button>
          </Grid>
          <div
            className={`px-8 py-2 my-6 ${
              themeMode === "dark"
                ? "dashboard-table-layout"
                : "dashboard-table-layout-light"
            }`}
          >
            <MembersManagementTable 
              handleOpenStatusDialog={handleOpenStatusDialog} 
              handleOpenDialog={handleOpenDialog} 
              selectedMember={selectedMember} setSelectedMember={setSelectedMember} 
              selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers}
              searchQuery={searchQuery} 
            />
          </div>
        </div>
        <MemberStatusDialog 
          openStatusDialog={openStatusDialog}
          handleCloseStatusDialog={handleCloseStatusDialog}
          members={members}
        />
        <DeleteMemberDialog 
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          members={members}
        />
        <AddMemberDialog
          openDialog={openAddMemberDialog}
          handleCloseAddMemberDialog={handleCloseAddMemberDialog}
        />
      </div>
    );
  };
  