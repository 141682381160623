import { Grid, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const linkedIn =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/LinkedIn.png";
const linkedInDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/LinkedInDark.png";
const connectedPeople =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ConnectedPeople.png";
const connectedPeopleDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Connected%20PeopleDark.png";
const findUserProfile =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/FindUserMale.png";
const findUserProfileDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Find%20User%20MaleDark.png";
const companyProfile =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/CompanyProfile.png";
const companyProfileDark =
  "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/companyProfileDark2.png";
const retrievedPost =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/RetreivedPost.png";
const retrievedPostDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/RetreivedPostDark.png";
const paperPlane =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/PaperPlane.png";
const paperPlaneDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Paper%20PlaneDark.png";
const addComment =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/AddComment.png";
const addCommentDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/AddCommentDark.png";
const search =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/GoogleWebSearch.png";
const searchDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Google%20Web%20SearchDark.png";
const contactSearch =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/SearchContacts.png";
const contactSearchDark =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Search%20ContactsDark.png";
const discussion =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Discussion%20Forum.png";
export const Restrictions = () => {
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <Grid
      sx={{
        height: "fit-content",
        padding: { md: "100px 150px", sm: "130px 100px", xs: "150px 30px" },
        color: themeMode === "dark" ? "#FFF" : "#000",
        position: "relative",
      }}
    >
      <Button
        disableRipple
        onClick={() => navigate("/dashboard/help-center")}
        sx={{
          position: "absolute",
          left: { md: "30px", sm: "30px", xs: "0px" },
          top: { md: "100px", sm: "70px", xs: "80px" },
          color: themeMode === "dark" ? "#FFF" : "#000",
          textTransform: "capitalize",
          "&:hover": { background: "transparent" },
        }}
      >
        <span className="pr-2 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 38 38"
            style={{
              background: themeMode === "dark" ? "#52000099" : "transparent",
              borderRadius: "50%",
              padding: "7px",
            }}
          >
            <path
              d="M18.5055 37.0199C16.0744 37.0239 13.6665 36.5478 11.4199 35.6188C9.17334 34.6899 7.13236 33.3263 5.41413 31.6065C-1.80471 24.3877 -1.80471 12.6426 5.41413 5.42376C8.91099 1.9269 13.5601 0 18.5055 0C23.4509 0 28.1 1.92542 31.5969 5.42376C38.8157 12.6426 38.8157 24.3877 31.5969 31.6065C29.8786 33.3263 27.8377 34.6899 25.5911 35.6188C23.3445 36.5478 20.9366 37.0239 18.5055 37.0199ZM7.50839 29.5122C13.572 35.5758 23.439 35.5758 29.5026 29.5122C32.4396 26.5737 34.057 22.6681 34.057 18.5151C34.057 14.3621 32.4396 10.4565 29.5026 7.51802C26.5656 4.57954 22.66 2.96218 18.5055 2.96218C14.351 2.96218 10.4469 4.58102 7.50839 7.51802C4.56991 10.455 2.95404 14.3607 2.95404 18.5151C2.95404 22.6696 4.57139 26.5737 7.50839 29.5122Z"
              fill={themeMode === "dark" ? "white" : "black"}
            />
            <path
              d="M21.9091 26.8027C21.7146 26.8029 21.5219 26.7647 21.3422 26.6902C21.1625 26.6157 20.9993 26.5065 20.862 26.3688L14.0549 19.5617C13.9172 19.4242 13.808 19.261 13.7335 19.0813C13.659 18.9017 13.6206 18.709 13.6206 18.5145C13.6206 18.32 13.659 18.1274 13.7335 17.9477C13.808 17.768 13.9172 17.6048 14.0549 17.4674L20.862 10.6603C20.9995 10.5228 21.1627 10.4137 21.3424 10.3393C21.5221 10.2649 21.7146 10.2266 21.9091 10.2266C22.1036 10.2266 22.2961 10.2649 22.4758 10.3393C22.6555 10.4137 22.8187 10.5228 22.9562 10.6603C23.0937 10.7978 23.2028 10.9611 23.2772 11.1407C23.3517 11.3204 23.39 11.513 23.39 11.7074C23.39 11.9019 23.3517 12.0945 23.2772 12.2741C23.2028 12.4538 23.0937 12.6171 22.9562 12.7546L17.1963 18.5145L22.9562 24.2745C23.164 24.4813 23.3057 24.7453 23.3632 25.0328C23.4207 25.3203 23.3916 25.6184 23.2794 25.8893C23.1672 26.1602 22.977 26.3916 22.733 26.5543C22.4891 26.7169 22.2023 26.8034 21.9091 26.8027Z"
              fill={themeMode === "dark" ? "white" : "black"}
            />
          </svg>
        </span>
        Back To Help Center
      </Button>

      <Typography
        mt={{ md: 3, sm: 3 }}
        sx={{
          fontSize: { md: "40px", sm: "40px", xs: "35px" },
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        Limitations & Best Practices
      </Typography>

      <Typography
        mt={3}
        sx={{
          fontSize: { md: "30px", sm: "30px", xs: "20px" },
          fontWeight: "700",
        }}
      >
        Provider Limits and Restrictions
      </Typography>
      <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
        Our platform offers APIs to connect with various providers, facilitating
        seamless integration for developers. However, it's crucial to understand
        and manage the limits and restrictions imposed by each provider to
        ensure smooth operation of your applications. Below are the limitations
        and guidelines for interacting with different providers:
      </Typography>

      <Typography
        my={2}
        sx={{
          fontSize: { md: "24px", sm: "24px", xs: "18px" },
          fontWeight: "700",
          display: "flex",
          alignItems: "end",
        }}
      >
        <img
          src={themeMode === "dark" ? linkedIn : linkedInDark}
          alt=""
          style={{ marginRight: "10px" }}
        />
        LinkedIn
      </Typography>
      <Typography sx={{ fontSize: "15px", fontWeight: "400" }}>
        Users should note that these limits are independent of each other and do
        not accumulate.
        <span style={{ fontWeight: "700" }}>
          {" "}
          Exceeding these quotas will prompt an error from our API, ensuring
          immediate notification or LinkedIn can disconnect your account. To
          emulate human behavior and avoid warnings, space out actions randomly
          with intervals of ~1 minute , distributed across multiple launches
          during working hours.
        </span>
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? connectedPeople : connectedPeopleDark}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Connection Requests (Invitations):
        </span>
        A paid and active LinkedIn connected account can send between 80 and 100
        invitations per day and approximately 200 per week (300 characters)
        without being rejected by LinkedIn. For a free account with some
        history, it's about 15 invitations per week (200 characters) or 150
        without a note. For new or inactive accounts, begin with low quantities
        and gradually increase. Some accounts with fewer than ~150 connections
        can't send many invitations, and some invitations might not be
        delivered.
      </Typography>
      
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? findUserProfile : findUserProfileDark}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Retrieve a User Profile (Profile Visits):
        </span>
        Exercise caution when implementing this action. Exceeding these values
        can disconnect your account. For new or inactive accounts, start with
        low quantities and gradually increase.
        <br />
        Free and Premium account: between 80 and 100 profiles per account per
        day
        <br />
        Sales Navigator account: 80 profiles using the classic API or 150
        profiles by utilizing the Sales Navigator API (it's simply a parameter
        to switch in our profile retrieval request) per account per day.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? companyProfile : companyProfileDark}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Retrieve company profile:
        </span>
        80 companies profiles for free account or 150 profiles with premium
        account, per account per day.
      </Typography>
      
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? retrievedPost : retrievedPostDark}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Retrieve Posts:
        </span>
        Between 80 and 100 per account per day.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? paperPlane : paperPlaneDark}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Message Sending:
        </span>{" "}
          Between 100 and 150 per account per day.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? addComment : addCommentDark}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Comment or add reaction to a post:
        </span>
          Between 80 and 100 per account per day.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? search : searchDark}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Search Results:
        </span>
          To retrieve a list of search results we advise handling a maximum of
        1000 profiles per day (or 2500 for a Sales Navigator account).
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? contactSearch : contactSearchDark}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Contacts List: 
        </span>
        You can retrieve them in batches of 500, with a limit on the number of
        calls per hour, for example, a maximum of 4 calls per hour, depending on
        the account. This may result in disconnection for some accounts.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span
          style={{
            fontSize: "15px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={themeMode === "dark" ? discussion : discussion}
            alt=""
            style={{ marginRight: "10px" }}
          />
          Chats Retrieving:{" "}
        </span>
        We manage Messaging inbox sync from our server side, ensuring seamless
        synchronization of chat lists and messages, thereby enabling our users
        to access and fetch their data without limitations (routes Messages,
        Chats & Attendees).
      </Typography>
{/* 
      <Typography
        my={2}
        sx={{
          fontSize: { md: "24px", sm: "24px", xs: "18px" },
          fontWeight: "700",
        }}
      >
        WhatsApp
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span style={{ fontWeight: "700" }}>
          Connect Accounts with Old Activity: 
        </span>
        Avoid using brand new accounts exclusively for software purposes.
        Instead, utilize WhatsApp accounts with historical activity from real
        users to prevent account to be suspended. If you use fresh account they
        can be blocked after 2-3 new chat, they need to be warmup during days
        with one or two chats.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span style={{ fontWeight: "700" }}>Engage Users in Discussion: </span>
        Design messages to initiate interaction and encourage replies. The first
        message should prompt a response to maintain active conversations.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span style={{ fontWeight: "700" }}>
          Respect Delay Between Messages: 
        </span>
        Incorporate realistic delays between messages to mimic human behavior.
        Avoid sending messages with intervals shorter than 10-20 seconds to
        align with natural conversation patterns.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span style={{ fontWeight: "700" }}>
          Number of new chat creations (outreach): 
        </span>
        This is a metric monitored by WhatsApp. If you initiate too many new
        chats without receiving responses or if there are a high number of
        signals of spam/block, it may temporarily restrict your account.
        <br />
        By adhering to these guidelines, developers can optimize their
        software's WhatsApp integration and potentially send several hundred
        messages per day per WhatsApp account without violating policies.
      </Typography> */}

      {/* 
            <Typography my={2} sx={{ fontSize: { md: "24px", sm: "24px", xs: "18px" }, fontWeight: "700" }}>
                WhatsApp
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Connect Accounts with Old Activity: </span>
                Avoid using brand new accounts exclusively for software purposes. Instead, utilize WhatsApp accounts with historical activity from real users to prevent account to be suspended. If you use fresh account they can be blocked after 2-3 new chat, they need to be warmup during days with one or two chats.
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Engage Users in Discussion: </span>
                Design messages to initiate interaction and encourage replies. The first message should prompt a response to maintain active conversations.
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Respect Delay Between Messages: </span>
                Incorporate realistic delays between messages to mimic human behavior. Avoid sending messages with intervals shorter than 10-20 seconds to align with natural conversation patterns.
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Number of new chat creations (outreach): </span>
                This is a metric monitored by WhatsApp. If you initiate too many new chats without receiving responses or if there are a high number of signals of spam/block, it may temporarily restrict your account.<br />
                By adhering to these guidelines, developers can optimize their software's WhatsApp integration and potentially send several hundred messages per day per WhatsApp account without violating policies.
            </Typography>

      <Typography
        my={2}
        sx={{
          fontSize: { md: "24px", sm: "24px", xs: "18px" },
          fontWeight: "700",
        }}
      >
        Gmail
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span style={{ fontWeight: "700" }}>
          Differences Between Gmail Accounts and Google Workspace:
        </span>
         Gmail accounts can send up to 500 emails per day, while Google
        Workspace accounts can send up to 2000 per day officially.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span style={{ fontWeight: "700" }}>Recommended Sending Limits: </span>
        To avoid being flagged or blocked, limit Gmail sending to 50 to 100
        emails per day and Workspace sending to 100 to 150. Depending on sending
        quality, replies, and bounce rate, these limits may be increased up to
        150 for Gmail and 300 for Workspace. For new accounts, start with 20 to
        50 emails per day and gradually increase.
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        <span style={{ fontWeight: "700" }}>Way to increase the limit: </span>
        You can create multiple domains in your Google Workspace and link them
        as aliases to your main email. With our API, you can list aliases and
        use them as the 'from' address. This will allow you to distribute your
        reputation across different domains with only one connected Gmail
        account.
      </Typography>

      <Typography
        my={2}
        sx={{
          fontSize: { md: "24px", sm: "24px", xs: "18px" },
          fontWeight: "700",
        }}
      >
        Outlook
      </Typography>
      <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
        The limits for Microsoft 365 subscribers are:
        <ul style={{ marginLeft: "20px" }}>
          <li>Daily recipients: 5,000</li>
          <li>Maximum recipients per message: 500</li>
          <li>Daily non-relationship recipients: 1,000</li>
        </ul>
        Limits may vary based on usage history and will be lower for
        non-subscribers. A “non-relationship recipient” is someone you've never
        sent email to before. The sending limits of any third party connected
        accounts depend upon the service provider.
        <br />
        Apart from official recommendations, we advise respecting the
        "Recommended Sending Limits" in Gmail part.
      </Typography>


            <Typography my={2} sx={{ fontSize: { md: "24px", sm: "24px", xs: "18px" }, fontWeight: "700" }}>
                Instagram
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Account Requirement: </span>
                Only connect old accounts with months of history to our APIs. Freshly created accounts will be blocked.
            </Typography>





            <Typography my={2} sx={{ fontSize: { md: "24px", sm: "24px", xs: "18px" }, fontWeight: "700" }}>
                Facebook
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                Maximum sending between 10 and 50 requests per day is recommended. It's essential to exclusively send requests to individuals with more than 10 mutual connections to uphold account security. While there is no set limit on friend requests or direct messages (DMs) sent, it's advised not to surpass 50 per day. Following the training guidelines, it's best to start with 15 DMs daily and incrementally increase by 5 each week until reaching the maximum of 50 DMs per day.
            </Typography>




            <Typography my={2} sx={{ fontSize: { md: "24px", sm: "24px", xs: "18px" }, fontWeight: "700" }}>
                Gmail
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Differences Between Gmail Accounts and Google Workspace:</span>
                 Gmail accounts can send up to 500 emails per day, while Google Workspace accounts can send up to 2000 per day officially.
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Recommended Sending Limits: </span>
                To avoid being flagged or blocked, limit Gmail sending to 50 to 100 emails per day and Workspace sending to 100 to 150. Depending on sending quality, replies, and bounce rate, these limits may be increased up to 150 for Gmail and 300 for Workspace. For new accounts, start with 20 to 50 emails per day and gradually increase.
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                <span style={{ fontWeight: "700" }}>Way to increase the limit: </span>
                You can create multiple domains in your Google Workspace and link them as aliases to your main email. With our API, you can list aliases and use them as the 'from' address. This will allow you to distribute your reputation across different domains with only one connected Gmail account.
            </Typography>





            <Typography my={2} sx={{ fontSize: { md: "24px", sm: "24px", xs: "18px" }, fontWeight: "700" }}>
                Outlook
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                The limits for Microsoft 365 subscribers are:
                <ul style={{marginLeft:"20px"}}>
                    <li>Daily recipients: 5,000</li>
                    <li>Maximum recipients per message: 500</li>
                    <li>Daily non-relationship recipients: 1,000</li>
                </ul>
                Limits may vary based on usage history and will be lower for non-subscribers. A “non-relationship recipient” is someone you've never sent email to before. The sending limits of any third party connected accounts depend upon the service provider.<br />
                Apart from official recommendations, we advise respecting the "Recommended Sending Limits" in Gmail part.
            </Typography>





            <Typography my={2} sx={{ fontSize: { md: "24px", sm: "24px", xs: "18px" }, fontWeight: "700" }}>
                Email IMAP
            </Typography>
            <Typography my={1} sx={{ fontSize: "14px", fontWeight: "400" }}>
                Maximum sending between 10 and 50 requests per day is recommended. It's essential to exclusively send requests to individuals with more than 10 mutual connections to uphold account security. While there is no set limit on friend requests or direct messages (DMs) sent, it's advised not to surpass 50 per day. Following the training guidelines, it's best to start with 15 DMs daily and incrementally increase by 5 each week until reaching the maximum of 50 DMs per day.
            </Typography> */}
    </Grid>
  );
};
