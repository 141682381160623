import {Typography, Grid, Button, CircularProgress, LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  disconnectLinkedInAccount,
  getLinkedInConnectedStatus,
  connectLinkedInAccount,
} from "../../../store/settingsSlice";
import ConnectLinkedinDialog from "./ConnectLinkedinDialog";
import { ArkoseCaptchaIntegration } from "./captcha-integration/ArkoseCaptchaIntegration";
import FindCookiesDialog from "./FindCookiesDialog";
import OTPDialog from "./OTPDialog";
import DisconnectConfirmationDialog from "./DisconnectConfirmationDialog";
import CountdownTimer from "./CountDownTimer";

export const LinkedIn = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const linkedin = useSelector(
    (state) => state.settings.linkedInConnectedStatus
  );
  const codeLoading = useSelector((state) => state.settings.codeLoading);
  const showCaptcha = useSelector((state) => state.settings.showCaptcha);
  const [fetchStatus, setFetchStatus] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openOTP, setOpenOTP] = useState(false);

  const handleClickOpenOTP = () => {
    setOpenOTP(true);
  };

  const handleCloseOTP = () => {
    setOpenOTP(false);
  };

  const [openCookie, setOpenCookie] = useState(false);

  const handleClickOpenCookie = () => {
    setOpenCookie(true);
  };

  const handleCloseCookie = () => {
    setOpenCookie(false);
  };

  const [openDisconnect, setOpenDisconnect] = useState(false);

  const handleClickOpenDisconnect = () => {
    setOpenDisconnect(true);
  };

  const handleCloseDisconnect = () => {
    setOpenDisconnect(false);
  };
  useEffect(() => {
    dispatch(getLinkedInConnectedStatus());
    console.log("Linkedin", linkedin);
  }, [fetchStatus]);

  useEffect(() => {
    // dispatch(getLinkedInConnectedStatus());
  }, []);
  return (
    <div>
      <div
        className={`settings-heading ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Connect Your Linkedin
      </div>
      {showCaptcha && (
        <>
          <h1
            className={`text-center pt-6 pb-6 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Please solve captcha
          </h1>
        </>
      )}
      <div id="captcha-frame" className="flex justify-center "></div>

      {codeLoading && (
        <>
          <div className="flex flex-col justify-center items-center my-2">
            Loading...
          </div>
          <LinearProgress />
        </>
      )}

      {!showCaptcha && (
        <div className="py-6">
          <div className="flex items-center mb-10 space-x-8">
            {linkedin?.connected ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="70"
                height="40"
                viewBox="0 0 70 40"
                fill="none"
              >
                <path
                  d="M0 23.3383L16.8301 40L21.0753 35.7675L4.27527 19.1356M65.7247 0L33.871 31.5648L21.3462 19.1356L17.0409 23.3383L33.871 40L70 4.23249M52.9591 4.23249L48.714 0L29.5957 18.927L33.871 23.1297L52.9591 4.23249Z"
                  fill="#24FF00"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="55"
                height="55"
                viewBox="0 0 55 55"
                fill="none"
              >
                <path
                  d="M55 10.0031L44.9969 0L27.5 17.4969L10.0031 0L0 10.0031L17.4969 27.5L0 44.9969L10.0031 55L27.5 37.5031L44.9969 55L55 44.9969L37.5031 27.5L55 10.0031Z"
                  fill="#FF0000"
                />
              </svg>
            )}

            <div>
              <div
                className={`linkedin-main-text ${
                  themeMode === "dark" ? "text-light" : "text-dark"
                }`}
              >
                {/* /No account connected or available. */}
                {linkedin?.connected
                  ? "Your LinkedIn is successfully connected"
                  : linkedin?.message}
              </div>
              <div
                className={`linkedin-secondary-text ${
                  themeMode === "dark" ? "text-light" : "text-dark"
                }`}
              >
                {linkedin?.connected
                  ? " Services are running fine. No action needed."
                  : "Click on connect button to proceed"}
              </div>
            </div>
          </div>
          <div>
            <Button
              className={`p-2 px-4 ${
                themeMode === "dark" ? "create-button" : "create-button-light"
              }`}
              variant="contained"
              onClick={() => {
                if (
                  linkedin?.message === "No account connected or available." ||
                  linkedin?.message === "Account disconnected." ||
                  linkedin?.linkedin_connected_account?.connected === false
                ) {
                  dispatch(disconnectLinkedInAccount()).then(() =>
                    handleClickOpen()
                  );
                } else {
                  handleClickOpenDisconnect();
                  // dispatch(disconnectLinkedInAccount()).then(() =>
                  //   dispatch(getLinkedInConnectedStatus())
                  // );
                }
              }}
            >
              <span className={`create-button-text`}>
                {linkedin?.message === "No account connected or available." ||
                linkedin?.message === "Account disconnected." ||
                linkedin?.linkedin_connected_account?.connected === false
                  ? "Connect"
                  : "Disconnect"}
              </span>
            </Button>
          </div>
        </div>
      )}
      <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className={`settings-heading mt-6 ${
            themeMode === "dark" ? "text-light" : "text-dark"
          }`}
        >
          Linkedin Sales Navigator Status
        </div>
        
      </Grid>
      <div className="py-6">
        <div className="flex items-center mb-10 space-x-8">
          {linkedin?.sales_navigator_connected ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="40"
              viewBox="0 0 70 40"
              fill="none"
            >
              <path
                d="M0 23.3383L16.8301 40L21.0753 35.7675L4.27527 19.1356M65.7247 0L33.871 31.5648L21.3462 19.1356L17.0409 23.3383L33.871 40L70 4.23249M52.9591 4.23249L48.714 0L29.5957 18.927L33.871 23.1297L52.9591 4.23249Z"
                fill="#24FF00"
              />
            </svg>
          ) : !linkedin?.sales_navigator_connected && fetchStatus === true ? (
            <CircularProgress
              sx={{ color: themeMode === "dark" ? "#FFF" : "red" }}
              size={40}
              className="mr-4"
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
            >
              <path
                d="M55 10.0031L44.9969 0L27.5 17.4969L10.0031 0L0 10.0031L17.4969 27.5L0 44.9969L10.0031 55L27.5 37.5031L44.9969 55L55 44.9969L37.5031 27.5L55 10.0031Z"
                fill="#FF0000"
              />
            </svg>
          )}
          <div>
            <div
              className={`linkedin-main-text ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
            >
              {linkedin?.profile_info_fetched
                ? linkedin?.sales_navigator_connected
                  ? "Active"
                  : <>
                    <Typography>Inactive</Typography>
                    <CountdownTimer setFetchStatus={setFetchStatus} />
                  </>
                : <Grid sx={{display:"flex"}}>
                    <Typography sx={{marginRight:"8px"}}>Fetching sales navigator status. . . </Typography>
                    <CountdownTimer setFetchStatus={setFetchStatus} />
                  </Grid>
                }

              {/* {linkedin?.sales_navigator_connected === false ? (
                <CountdownTimer setFetchStatus={setFetchStatus} />
              ) : (
                ""
              )} */}
            </div>
            <div
              className={`linkedin-secondary-text ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
            >
              {linkedin?.profile_info_fetched ? (
                <>
                  Your linkedin sales naviagtor subscription is{" "}
                  {linkedin?.sales_navigator_connected ? "Active" : "Inactive"}{" "}
                  and automation is
                  {linkedin?.sales_navigator_connected
                    ? " running"
                    : " stopped"}
                </>
              ) : (
                "loading. . . "
              )}
            </div>
          </div>
        </div>
      </div>

      <ConnectLinkedinDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        handleClickOpenCookie={handleClickOpenCookie}
        handleClickOpenOTP={handleClickOpenOTP}
      />
      <OTPDialog
        open={openOTP}
        handleClose={handleCloseOTP}
        handleClickOpen={handleClickOpenOTP}
      />
      <FindCookiesDialog
        open={openCookie}
        handleClose={handleCloseCookie}
        handleClickOpen={handleClickOpenCookie}
        handleClickOpenOTP={handleClickOpenOTP}
      />
      <DisconnectConfirmationDialog
        open={openDisconnect}
        handleClose={handleCloseDisconnect}
        handleClickOpen={handleClickOpenDisconnect}
      />
    </div>
  );
};
