import { Typography, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { setActiveStep, setChosenMethod } from "../../store/resetSlice";

const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

export const ResetWelcome = () => {
  const dispatch = useDispatch();
  return (
    <div className="px-10">
      <img src={logo} alt="logo" />
      <Typography
        sx={{
          fontSize: { md: "40px", sm: "40px", xs: "30px" },
          fontWeight: "700",
          textWrap: "nowrap",
        }}
      >
        Reset your password{" "}
      </Typography>
      <Typography
        sx={{
          fontSize: { md: "18px", sm: "18px", xs: "14px" },
          fontWeight: "400",
          textAlign: { md: "left", sm: "center", xs: "center" },
        }}
      >
        How do you want to reset your password?
      </Typography>
      <div className="mt-8">
        <div className="flex space-x-12 items-center mb-4">
          <Button
            onClick={() => {
              dispatch(setChosenMethod("email"));
              dispatch(setActiveStep(2));
            }}
            variant="contained"
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "#FFF",
              borderRadius: "30px",
              color: "#B50000",
              textTransform: "none",
              padding: "8px 20px",
              minWidth: "145px",
              maxWidth: "145px",
              border: "1px solid transparent",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            Send an Email{" "}
          </Button>
          <Typography
            sx={{
              fontSize: { md: "18px", sm: "18px", xs: "14px" },
              fontWeight: "400",
              textAlign: { md: "left", sm: "center", xs: "center" },
              maxWidth: "500px",
            }}
          >
            Receive an email to the address in your profile.{" "}
          </Typography>
        </div>
        <div className="flex space-x-12 items-center mb-4">
          <Button
            variant="contained"
            onClick={() => {
              dispatch(setChosenMethod("phone"));
              dispatch(setActiveStep(2));
            }}
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "#FFF",
              borderRadius: "30px",
              color: "#B50000",
              textTransform: "none",
              padding: "8px 20px",
              border: "1px solid transparent",
              minWidth: "145px",
              maxWidth: "145px",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            Send a Text{" "}
          </Button>
          <Typography
            sx={{
              fontSize: { md: "18px", sm: "18px", xs: "14px" },
              fontWeight: "400",
              textAlign: { md: "left", sm: "center", xs: "center" },
              maxWidth: "500px",
            }}
          >
            Receive a text to the primary number in your profile.{" "}
            <div>
              <Typography
                sx={{
                  fontSize: { md: "14px", sm: "14px", xs: "12px" },
                  fontWeight: "400",
                  textAlign: { md: "left", sm: "center", xs: "center" },
                }}
              >
                By selecting this method you agree to the FacoonLabs terms and
                conditions. Message and data rates may apply.{" "}
              </Typography>
            </div>
          </Typography>
        </div>
        <div className="flex space-x-12 items-center mb-4">
          <Button
            variant="contained"
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "#FFF",
              borderRadius: "30px",
              color: "#B50000",
              textTransform: "none",
              padding: "8px 20px",
              border: "1px solid transparent",
              minWidth: "145px",
              maxWidth: "145px",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            Call Me{" "}
          </Button>
          <Typography
            sx={{
              fontSize: { md: "18px", sm: "18px", xs: "14px" },
              fontWeight: "400",
              textAlign: { md: "left", sm: "center", xs: "center" },
              maxWidth: "500px",
            }}
          >
            Receive a automated call to the primary number in your profile.{" "}
          </Typography>
        </div>
        <Typography
          className="mt-8 mb-4 cursor-pointer"
          sx={{
            fontSize: { md: "16px", sm: "16px", xs: "14px" },
            fontWeight: "500",
            textAlign: { md: "center", sm: "center", xs: "center" },
          }}
        >
          BACK
        </Typography>
      </div>
    </div>
  );
};
