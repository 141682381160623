import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import EmailNotificationsTable from "./EmailNotificationsTable";
import EmailNotificationsAdminTable from "./EmailNotificationsAdminTable";
import { useDispatch, useSelector } from "react-redux";
import { getEmailNotifications } from "../../../store/settingsSlice";
import UpdateEmailNotificationDialog from "./UpdateEmailNotificationDialog";
import { PushNotifications } from "../push-notifications/PushNotifications";

export const EmailNotifications = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const emailNotifications = useSelector(
    (state) => state.settings.emailNotifications
  );

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function transformObject(inputObject) {
    const admin = [];
    const team = [];

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        const isAdmin = key.startsWith("admin");
        const arrayToUpdate = isAdmin ? admin : team;

        const name = key
          .replace(isAdmin ? "admin_" : "common_", "") // Remove 'admin_' or 'common_'
          .replace(/_/g, " ") // Replace underscores with spaces
          .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word

        const members = inputObject[key];

        arrayToUpdate.push({ name: name, members: members, key: key });
      }
    }

    return { admin, team };
  }

  const { admin, team } = transformObject(emailNotifications);

  console.log("Admin:", admin);
  console.log("Team:", team);

  return (
    // <div className="settings-content-layout">
    <div>
      <PushNotifications />
      <div
        className={`settings-heading ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Email Notifications
      </div>
      <div className="py-6">
        <div className="flex w-full justify-between items-center mb-4">
          <div
            className={`total-tags ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Team Members
          </div>
        </div>
        <div
          className={`px-8 py-2 my-6 ${
            themeMode === "dark"
              ? "dashboard-table-layout"
              : "dashboard-table-layout-light"
          }`}
        >
          <EmailNotificationsTable
            handleClickOpen={handleClickOpen}
            team={team}
          />
        </div>
      </div>

      <div className="pb-6">
        <div className="flex w-full justify-between items-center mb-4">
          <div
            className={`total-tags ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Administrators
          </div>
        </div>
        <div
          className={`px-8 py-2 my-6 ${
            themeMode === "dark"
              ? "dashboard-table-layout"
              : "dashboard-table-layout-light"
          }`}
        >
          <EmailNotificationsAdminTable
            handleClickOpen={handleClickOpen}
            admin={admin}
          />
        </div>
      </div>
      <UpdateEmailNotificationDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
    </div>
  );
};
