import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  faqs: [],
  paymentPlans: [],
};

export const getFaqs = createAsyncThunk(
  "home/getFaqs",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(`${REACT_APP_API_URL}/faqs`, {
        params: {
          query: searchQuery ? searchQuery.query : "",
        },
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getDefaultPaymentPlans = createAsyncThunk(
  "home/getDefaultPaymentPlans",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/all_default_payment_plans`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const contactForm = createAsyncThunk(
  "home/contactForm",
  async (contact_form, { dispatch, getState }) => {
    try {
      const res = await axios.post(`${REACT_APP_API_URL}/contact_forms`, {
        contact_form,
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    },
    setQuickResponse: (state, action) => {
      state.quickResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFaqs.fulfilled, (state, action) => {
      state.faqs = action.payload?.data;
    });
    builder.addCase(getDefaultPaymentPlans.fulfilled, (state, action) => {
      state.paymentPlans = action.payload?.data;
    });
  },
});

export const { setTag, setQuickResponse } = homeSlice.actions;

export default homeSlice.reducer;
