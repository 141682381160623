import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMeetings } from "../../store/meetingSlice";
import { useState } from "react";
import { TablePagination } from "@mui/material";
import { getChat } from "../../store/messagesSlice";

function createData(
  booked,
  name,
  jobTitle,
  company,
  replyRate,
  meetingsBooked,
  status
) {
  return {
    booked,
    name,
    jobTitle,
    company,
    replyRate,
    meetingsBooked,
    status,
  };
}

const rows = [
  createData("Yes", "Frederic", "Coordinator", "Alliance Frrancais"),
  createData("Yes", "Virat Kohli", "Cricketer", "Indian Cricket Team"),
];

export default function MeetingTable({ openMessageShow }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const meetings = useSelector((state) => state.meeting.meetings);
  const meetingMeta = useSelector((state) => state.meeting.meetingMeta);

  const [page, setPage] = useState(
    meetingMeta?.current_page ? meetingMeta?.current_page - 1 : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    meetingMeta?.current_page ? meetingMeta?.per_page : 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getMeetings({
        page: newPage + 1,
        per_page: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      getMeetings({
        page: 1,
        per_page: parseInt(event.target.value, 10),
      })
    );
  };

  const navigate = useNavigate();
  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Did you book a meeting?{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Name{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Job Title{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Company{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {meetings?.length > 0 &&
            meetings.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                onClick={() => {
                  dispatch(getChat(row.chat_uuid)).then(() => {
                    openMessageShow(row);
                  });
                }}
                className="cursor-pointer"
              >
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                >
                  Yes
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.full_name}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.job_title}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.company}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={meetingMeta?.total_count ? meetingMeta?.total_count : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
        }}
      />
    </TableContainer>
  );
}
