import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: { messages: [] }, 
  reducers: {
    success: (state, action) => {
      state.messages.push({
        type: "success",
        title: "Successful",
        message: action.payload,
      });
    },
    error: (state, action) => {
      state.messages.push({
        type: "error",
        title: "Failed",
        message: action.payload,
      });
    },
    info: (state, action) => {
      state.messages.push({
        type: "info",
        title: "Info",
        message: action.payload,
      });
    },
    warning: (state, action) => {
      state.messages.push({
        type: "warning",
        title: "Warning",
        message: action.payload,
      });
    },
    removeAlert: (state) => {
      state.messages.shift(); 
    },
    clear: (state) => {
      state.messages = []; 
    },
  },
});

export const { success, error, info, warning, removeAlert, clear } = alertSlice.actions;
export default alertSlice.reducer;