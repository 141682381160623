import { Typography, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../store/resetSlice";

const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

export const EmailAndPhone = () => {
  const dispatch = useDispatch();
  const chosenMethod = useSelector((state) => state.reset.chosenMethod);

  return (
    <div className="px-10">
      <img src={logo} alt="logo" />
      <Typography
        sx={{
          fontSize: { md: "40px", sm: "40px", xs: "30px" },
          fontWeight: "700",
          textWrap: "nowrap",
        }}
      >
        Reset your password{" "}
      </Typography>
      <Typography
        sx={{
          fontSize: { md: "18px", sm: "18px", xs: "14px" },
          fontWeight: "400",
          textAlign: { md: "left", sm: "center", xs: "center" },
        }}
      >
        Verification codes are valid for 10 minutes{" "}
      </Typography>
      <div className="mt-8">
        <Typography
          className="mb-4"
          sx={{
            fontSize: { md: "18px", sm: "18px", xs: "14px" },
            fontWeight: "600",
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          Enter the{" "}
          {chosenMethod === "email" ? "Email address" : " Phone Number"}{" "}
          associated with your account{" "}
        </Typography>

        <TextField
          size="small"
          variant="outlined"
          fullWidth
          placeholder={chosenMethod === "email" ? "Email*" : " Phone# *"}
          InputProps={{
            sx: {
              "& .MuiOutlinedInput-input::placeholder": {
                color: "#B50000",
                opacity: 1,
              },
            },
            inputProps: {
              style: {
                padding: "8px 14px",
              },
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: "rgba(255, 255, 255, 0.5)", // Background color with transparency
              borderRadius: "8px", // Rounded corners
              "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.6)", // Border color
              },
              "&:hover fieldset": {
                borderColor: "white", // Hover state border color
              },
              "&.Mui-focused fieldset": {
                borderColor: "white", // Focused state border color
              },
            },
            "& .MuiInputBase-input": {
              color: "#B50000", // Text color inside the input
              fontWeight: 800,
            },
          }}
        />

        <div className="flex w-full justify-center my-8">
          <Button
            variant="contained"
            onClick={() => {
              dispatch(setActiveStep(3));
            }}
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "#FFF",
              borderRadius: "30px",
              color: "#B50000",
              textTransform: "none",
              padding: "8px 20px",
              border: "1px solid transparent",
              minWidth: "145px",
              maxWidth: "145px",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            Submit
          </Button>
        </div>

        <Typography
          onClick={() => dispatch(setActiveStep(1))}
          className="mt-16 mb-4 cursor-pointer"
          sx={{
            fontSize: { md: "16px", sm: "16px", xs: "14px" },
            fontWeight: "500",
            textAlign: { md: "center", sm: "center", xs: "center" },
          }}
        >
          BACK
        </Typography>
      </div>
    </div>
  );
};
