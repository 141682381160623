export const HowItWorksCard = ({ title, subtitle, heading, content,image  }) => {
  return (
    <div>
      <div
        className="choose-us-card p-4 h-full flex flex-col justify-end items-end text-end"
        style={{ minHeight: "312px" }}
      >
        <div style={{width:"100%",textAlign:"center",display:"flex",justifyContent:"center"}}>
          <img src={image} alt="" style={{width:"191px",height:"191px"}}/>
        </div>
        <div className="how-it-works-title">{title}</div>
        <div className="how-it-works-subtitle">{subtitle}</div>
      </div>
      <div className="py-4">
        <div className="how-it-works-content-heading">{heading}</div>
        <div className="how-it-works-content">{content}</div>
      </div>
    </div>
  );
};
