import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
  connectLinkedInAccount,
  getLinkedInConnectedStatus,
  setShowCaptcha,
  solveCodeCheckpoint,
} from "../../../store/settingsSlice";
import { ArkoseCaptchaIntegration } from "./captcha-integration/ArkoseCaptchaIntegration";
import { success,error } from "../../../store/alertSlice";
import { CookieConnect } from "./CookieConnect";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { InfoOutlined, Visibility, VisibilityOff } from "@mui/icons-material";

const defaultValues = {
  username: "",
  password: "",
};

const schema = yup
  .object()
  .shape({
    username: yup.string().required("You must enter email"),
    password: yup.string().required("You must enter password"),
  })
  .required();

export default function ConnectLinkedinDialog({
  open,
  handleClose,
  handleClickOpenCookie,
  handleClickOpenOTP,
}) {
  const connectAccount = useSelector(
    (state) => state.settings.connectLinkedInAccount
  );
  const connectLinkedInLoading = useSelector(
    (state) => state.settings.connectLinkedInLoading
  );
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [value, setValue] = React.useState("one");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(data) {
    dispatch(connectLinkedInAccount(data)).then((res) => {
      if (res.payload.status === 200 || res.payload.status === 201) {
        dispatch(success(res.payload.data.message));
        dispatch(getLinkedInConnectedStatus());
        handleClose();
        reset(defaultValues);
      } else {
        if (res.payload.status === 202) {
          dispatch(success(res.payload.data.message));

          if (res.payload.data?.checkpoint_details?.type === "CAPTCHA") {
            dispatch(setShowCaptcha(true));
            handleClose();
            reset(defaultValues);
            const publicKey = res.payload.data?.checkpoint_details?.public_key;
            const data = res.payload.data?.checkpoint_details?.data;

            // 1. Instanciate the captcha frame and logic
            const captcha = new ArkoseCaptchaIntegration(publicKey, data);

            captcha.onLoaded = () => {
              console.log(
                "Captcha has been successfully loaded into the DOM !"
              );
            };

            captcha.onSuccess = (token) => {
              console.log("Captcha has been resolved !", { token });
              dispatch(
                solveCodeCheckpoint({
                  code: token,
                })
              ).then((res) => {
                if (res.payload?.status === 201) {
                  dispatch(success(res.payload.data.message));
                  dispatch(getLinkedInConnectedStatus());
                }
                if (res.payload?.status === 202) {
                  if (res.payload.data?.checkpoint_details?.type === "OTP") {
                    handleClose();
                    dispatch(success("Please check your Email for OTP"));
                    handleClickOpenOTP();
                  }
                } else {
                  console.log("OTP Flow aborted!");
                }
              });
              captcha.unload("captcha-frame");
            };

            captcha.onError = () => {
              console.log("Captcha resolution failed !");
            };

            // 3. Load the captcha
            captcha.load("captcha-frame");
          } else {
            reset(defaultValues);
            dispatch(success("OTP Flow"));
          }
        } 
        if (res.payload.status === 422) {
          dispatch(error("Account Not found"));
          //dispatch(getLinkedInConnectedStatus());
          handleClose();
          reset(defaultValues);
        } else {
          handleClose();
          reset(defaultValues);
        }
      }
    });
  }

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color

            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-4"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: themeMode === "dark" ? "white" : "#000" }}
            className="flex flex-col  "
          >
            <div
              className={`create-campaign-dialog text-center py-5 ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              style={{display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center"}}
            >
              Connect Your Linked{" "}
              <LinkedInIcon
                sx={{
                  fontSize: { md: "50px", sm: "50px", xs: "40px" },
                  filter:
                    "drop-shadow(0 0 10px rgba(255, 255, 255, 0.6)) drop-shadow(0 0 20px rgba(255, 255, 255, 0.5))",
                }}
              />
            </div>
            <div className="flex justify-center pb-4">
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="secondary tabs example"
                sx={{
                  "& .MuiTab-root": {
                    color: themeMode === "dark" ? "#FFF" : "#000",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    textTransform: "none",
                  },
                  "& .MuiTab-root.Mui-selected": {
                    color: themeMode === "dark" ? "#FFF" : "#000",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor:
                      themeMode === "dark" ? "whitesmoke" : "#000",
                  },
                }}
              >
                <Tab value="one" label="Credentials" />
                <Tab
                  value="two"
                  label={
                    <span className="flex items-center">
                      <span
                        style={{
                          color: themeMode === "dark" ? "whitesmoke" : "#000",
                        }}
                      >
                        Cookies
                      </span>
                      <Tooltip title="You can use Linkedin Cookies to connect!">
                        {" "}
                        <IconButton>
                          {" "}
                          <InfoOutlined
                            style={{
                              color: themeMode === "dark" ? "white" : "black",
                              fontSize: "17px",
                            }}
                          />{" "}
                        </IconButton>
                      </Tooltip>
                    </span>
                  }
                  disabled
                />
              </Tabs>
            </div>

            {value === "one" && (
              <div>
                <div
                  className={`create-campaign-dialog pb-2 ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                  }`}
                >
                  LinkedIn Email
                </div>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.username}
                      helperText={errors?.username?.message}
                      type="email"
                      required
                      className={`w-full ${
                        themeMode === "dark"
                          ? "create-campaign-dialog-field"
                          : "create-campaign-dialog-field-light"
                      }`}
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          color: themeMode === "dark" ? "#FFF" : "#000",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            color: themeMode === "dark" ? "#FFF" : "#000",
                          },
                        },
                      }}
                    />
                  )}
                  name="username"
                  control={control}
                />

                <div
                  className={`create-campaign-dialog py-2 pt-4 ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                  }`}
                >
                  LinkedIn Password
                </div>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.password}
                      type={showPassword ? "text" : "password"}
                      helperText={errors?.password?.message}
                      required
                      className={`w-full ${
                        themeMode === "dark"
                          ? "create-campaign-dialog-field"
                          : "create-campaign-dialog-field-light"
                      }`}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                              style={{ color: "#FFF" }}
                            >
                              {showPassword ? (
                                <VisibilityOff
                                  style={{
                                    color:
                                      themeMode === "dark"
                                        ? "whitesmoke"
                                        : "#000",
                                  }}
                                />
                              ) : (
                                <Visibility
                                  style={{
                                    color:
                                      themeMode === "dark"
                                        ? "whitesmoke"
                                        : "#000",
                                  }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiInputBase-input": {
                          color: themeMode === "dark" ? "#FFF" : "#000",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            color: themeMode === "dark" ? "#FFF" : "#000",
                          },
                        },
                      }}
                    />
                  )}
                  name="password"
                  control={control}
                />

                <div className="flex w-full justify-center pt-8">
                  <Button
                    className="create-button p-2 px-4"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                  >
                    <span className="create-button-text flex items-center  ">
                      {" "}
                      {connectLinkedInLoading && (
                        <CircularProgress
                          sx={{ color: "white" }}
                          size={20}
                          className="mr-4"
                        />
                      )}
                      Connect
                    </span>
                  </Button>
                </div>
              </div>
            )}

            {value === "two" && (
              <CookieConnect handleClickOpenCookie={handleClickOpenCookie} />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
