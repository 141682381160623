import { Grid, Button } from "@mui/material";
import { AutomatedMessagingCard } from "./AutomatedMessagingCard";
import { AddFollowupMessageCard } from "./AddFollowupMessageCard";
import FollowUpSendTime from "./FollowUpSendTime";
import { useState } from "react";
import CreateFollowUpMessageDialog from "./CreateFollowUpMessageDialog";
import EditFollowUpMessageDialog from "./EditFollowUpMessageDialog";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

export const AutomatedMessaging = () => {
  const navigate = useNavigate();
  const campaign = useSelector((state) => state.campaign.campaign);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  return (
    <div>
      <div
        className={
          themeMode === "dark"
            ? "p-8 my-8 dashboard-table-layout"
            : "p-8 my-8 dashboard-table-layout-light"
        }
      >
        <div
          className={
            themeMode === "dark"
              ? "dashboard-subtitle"
              : "dashboard-subtitle-light"
          }
        >
          Automated messages are what your prospects see when you reach out to
          them on LinkedIn. Follow up messages are used to gently nudge a
          prospect to reply.
        </div>

        <div
          className={
            themeMode === "dark"
              ? "search-header-title py-4"
              : "search-header-title-light py-4"
          }
        >
          Edit your follow up messages{" "}
        </div>
        <Grid container spacing={2}>
          {campaign?.automated_messages?.map((automatedMessage, index) => (
            <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
              <AutomatedMessagingCard
                title={"Connection Request Message"}
                content={automatedMessage.message}
                automatedMessage={automatedMessage}
                handleClose={handleCloseEdit}
                handleClickOpen={handleClickOpenEdit}
                themeMode={themeMode}
                index={index}
              />{" "}
            </Grid>
          ))}

          <Grid item lg={4} md={6} sm={12} xs={12} onClick={handleClickOpen}>
            <AddFollowupMessageCard
              handleClose={handleCloseEdit}
              handleClickOpen={handleClickOpenEdit}
              themeMode={themeMode}
            />{" "}
          </Grid>
        </Grid>


        <Grid my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            className={
              themeMode === "dark"
                ? "create-button p-2 px-8 mt-4 py-3"
                : "create-button-light p-2 px-8 mt-4 py-3"
            }
            variant="contained"
            onClick={() => navigate(`message-flow`)}
            sx={{ backgroundColor: "#730101", "&:hover": { background: "#b80000" } }}
          >
            <span className="create-button-text" style={{ color: "#FFF" }}>
              See how these messages work!
            </span>
          </Button>
        </Grid>
      </div>
      <CreateFollowUpMessageDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
      <EditFollowUpMessageDialog
        open={openEdit}
        handleClose={handleCloseEdit}
        handleClickOpen={handleClickOpenEdit}
      />
    </div>
  );
};