import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button, Box, Skeleton } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OnboardingNav from "./OnboardingNav";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  payLater,
  processPayment,
  setPassword,
} from "../store/onboardingSlice";
import { error as errorMsg, success } from "../store/alertSlice";
import { useDispatch } from "react-redux";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
  CardElement,
} from "@stripe/react-stripe-js";
import OnboardingErrorDialog from "./OnboardingErrorDialog";
const bkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeGif.gif";
const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

const defaultValues = {
  name: "",
  cvc: "",
  expiry: "",
  cardNumber: "",
};

const cardBrandLogos = {
  visa: "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/visa.png",
  mastercard:
    "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/mastercard.png",
  amex: "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/amex.png",
  default:
    "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/defCard.png",
};

const StripePaymentForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full name is required"),
    cvc: Yup.string().required("CVC is required"),
    cardNumber: Yup.string().required("Card Number is required"),
    expiry: Yup.string().required("Expiry is required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const [cardBrand, setCardBrand] = useState("");
  const handleCardNumberChange = (event) => {
    setCardBrand(event.brand);
  };

  const formData = watch();

  const onSubmit = async (data) => {
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
    try {
      const cardElement = elements?.getElement(CardNumberElement);

      const { token, error } = await stripe.createToken(cardElement);
      if (!token) {
        dispatch(errorMsg("Invalid card details"));
        if (error && error?.message) {
          console.error(error.message);
          dispatch(errorMsg(error.message));
        }
        setLoading(false);
        return;
      }
      dispatch(processPayment({ currency: "usd", source: token.id })).then(
        (resp) => {
          setLoading(false);
          if (resp.payload?.status === 200) {
            dispatch(success(`Payment processed`));
            //navigate("/connect-linkedIn");
            handlePageLeave();
          } else {
            dispatch(errorMsg("Error occured"));
            handleClickOpen();
          }
        }
      );
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      handleClickOpen();
    }
  };

  const [animationClass, setAnimationClass] = useState("page-enter");

  useEffect(() => {
    setAnimationClass("page-enter");
    return () => {};
  }, []);

  const handlePageLeave = () => {
    setAnimationClass("page-exit");
    setTimeout(() => {
      navigate("/connect-linkedIn");
    }, 500);
  };

  return (
    <Grid
      className="welcome"
      sx={{
        //backgroundImage: `url(${bkg})`,
        backgroundImage:
          "linear-gradient(to right, #b03302 0%, #FF3001 20%, #FF3001 80%, #b03302 100%)",
        height: {
          md: "100vh !important",
          sm: "160vh !important",
          xs: "160vh !important",
        },
        backgroundSize: "cover !important",
      }}
    >
      <OnboardingNav selected={5} />
      <Grid
        className={animationClass}
        mt={{ md: 16, sm: 18, xs: 18 }}
        mb={{ md: "", sm: 8, xs: 10 }}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { md: "flex-start", sm: "center", xs: "center" },
          width: { md: "53%", sm: "53%", xs: "90%" },
          transform: { md: "scale(1)", sm: "scale(0.8)", xs: "scale(1)" },
        }}
      >
        <img src={logo} alt="Logo" />
        <Typography
          sx={{
            fontSize: { md: "40px", sm: "40px", xs: "30px" },
            fontWeight: "700",
          }}
        >
          Pay with Stripe
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "18px", sm: "18px", xs: "14px" },
            fontWeight: "400",
          }}
        >
          Your transactions are secured and end-to-end encrypted.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: {
              xs: "90%",
              sm: "90%",
              md: "90%",
              lg: "90%",
              xl: "90%",
            },
            margin: "20px 0px",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <div className="lg:flex items-center lg:space-x-4 w-full">
              <div className="w-full">
                <label style={{ color: "white" }} className="pt-4">
                  Full Name
                </label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      className="w-full"
                      FormHelperTextProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      {...field}
                      placeholder="Enter Full Name*"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      required
                      InputProps={{
                        style: {
                          color: "#FFF",
                          borderRadius: "15px",
                          backgroundColor: "transparent",
                          border: "1px solid #FFFFFF",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#FFF",
                        },
                        shrink: true,
                      }}
                      sx={{
                        marginY: "10px",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "transparent",
                          },
                          "&:hover fieldset": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent",
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>

              <div className="w-full">
                <label
                  style={{ color: "white" }}
                  className="  flex justify-between"
                >
                  <span>Card Number*</span>{" "}
                  {cardBrand && (
                    <span>
                      <img
                        src={
                          cardBrandLogos[cardBrand] || cardBrandLogos["default"]
                        }
                        alt={cardBrand || "default"}
                        style={{ width: "30px", height: "30px" }}
                      />
                    </span>
                  )}
                </label>
                <div className="cardInputWrapper mt-2 w-full">
                  {(!stripe || !elements) ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={42}
                      sx={{ borderRadius: "15px" }}
                    />
                  ) : (
                    <CardNumberElement
                      onChange={handleCardNumberChange}
                      options={{
                        showIcon: true,
                        style: {
                          base: {
                            color: "#FFF",
                            borderRadius: "15px",
                            backgroundColor: "transparent",
                            fontSize: "18px",
                            border: "1px solid #FFFFFF",
                            "::placeholder": {
                              color: "rgba(0, 0, 0, 0.54)",
                            },
                          },
                          border: "1px solid #FFFFFF",

                          invalid: {
                            color: "#FFFF00", 
                            iconColor: "#FFFF00",
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="lg:flex items-center lg:space-x-4 w-full">
              <div className="w-full">
                <label style={{ color: "white" }} className="pt-4">
                  Expiry*
                </label>
                <div className="cardInputWrapper mt-2 w-full">
                  {(!stripe || !elements) ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={42}
                      sx={{ borderRadius: "15px" }}
                    />
                  ) : (
                    <CardExpiryElement
                      options={{
                        style: {
                          base: {
                            color: "#FFF",
                            borderRadius: "15px",
                            backgroundColor: "transparent",
                            fontSize: "18px",
                            border: "1px solid #FFFFFF",
                            "::placeholder": {
                              color: "rgba(0, 0, 0, 0.54)",
                            },
                          },
                          border: "1px solid #FFFFFF",

                          invalid: {
                            color: "#FFFF00", 
                            iconColor: "#FFFF00",
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="w-full">
                <label style={{ color: "white" }} className="pt-4">
                  CVC*
                </label>
                <div className="cardInputWrapper mt-2 w-full">
                  {(!stripe || !elements) ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={42}
                      sx={{ borderRadius: "15px" }}
                    />
                  ) : (
                    <CardCvcElement
                      options={{
                        style: {
                          base: {
                            color: "#FFF",
                            borderRadius: "15px",
                            backgroundColor: "transparent",
                            fontSize: "18px",
                            border: "1px solid #FFFFFF",
                            "::placeholder": {
                              color: "rgba(0, 0, 0, 0.54)",
                            },
                          },
                          border: "1px solid #FFFFFF",

                          invalid: {
                            color: "#FFFF00", 
                            iconColor: "#FFFF00",
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Box>
        <div className="flex space-x-4">
          <Button
            type="submit"
            variant="contained"
            disabled={!stripe || !elements || loading}
            onClick={() => {
              handleSubmit(onSubmit());
            }}
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "#FFF",
              borderRadius: "30px",
              color: "#B50000",
              textTransform: "capitalize",
              padding: "8px 20px",
              border: "1px solid transparent",
              "&:hover": {
                background: "transparent",
                color: "#FFF",
                border: "1px solid #FFF",
              },
            }}
          >
            {loading ? "Loading..." : "Pay Now"}
          </Button>{" "}
          <Button
            type="submit"
            variant="contained"
            disabled={!stripe}
            onClick={() => {
              dispatch(payLater()).then(() => {
                handlePageLeave();
              });
            }}
            sx={{
              fontWeight: "700",
              marginY: "10px",
              backgroundColor: "transparent",
              borderRadius: "30px",
              color: "white",
              textTransform: "capitalize",
              padding: "8px 20px",
              border: "1px solid white",
              "&:hover": {
                background: "white",
                color: "#B50000",
                border: "1px solid #FFF",
              },
            }}
          >
            Skip
          </Button>
        </div>
      </Grid>
      <OnboardingErrorDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
    </Grid>
  );
};
export default StripePaymentForm;
