import { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { getOverviewMembers } from "../../../../store/overviewSlice";
import AIMessageRequestDialog from "./AIMessageRequestDialog";
import { textTransform } from "@mui/system";
import {
  deleteAutomatedMessages,
  getCampaignDetail,
  setAutomatedMessage,
} from "../../../../store/campaignSlice";
import { error, success } from "../../../../store/alertSlice";
const tick =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/doubleTick.png";
const edit =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/edit.png";

function createData(index, message, actions, remove) {
  return {
    index,
    message,
    actions,
    remove,
  };
}

export default function ConnectionMessageRequestTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);
  const { id } = useParams();
  const automatedMessage = useSelector(
    (state) => state.campaign.automatedMessage
  );

  // const members = useSelector((state) => state.overview.overviewMembers);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);

  // useEffect(() => {
  //   dispatch(getOverviewMembers({ page: page + 1, per_page: rowsPerPage }));
  // }, [page, rowsPerPage, dispatch]);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    dispatch(getCampaignDetail(id));
  }, []);

  const ordinals = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
  ];
  function getOrdinal(number) {
    return ordinals[number];
  }

  const requestMessages = ["1"];

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={`table-col-title ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              align="left"
            >
              No.
            </TableCell>
            <TableCell
              className={`table-col-title ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              align="left"
            >
              Message{" "}
            </TableCell>
            <TableCell
              className={`table-col-title ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              align="left"
            >
              Action{" "}
            </TableCell>
            <TableCell
              className={`table-col-title ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              align="left"
            >
              {" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaign?.automated_messages?.length === 0 ? (
            <TableRow className="cursor-pointer">
              <TableCell
                colSpan={5}
                align="center"
                className={`table-row-content ${
                  themeMode === "dark" ? "text-light" : "text-dark"
                }`}
              >
                No Records
              </TableCell>
            </TableRow>
          ) : (
            requestMessages?.map((row, index) => (
              <TableRow
                key={row.uuid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                // onClick={() =>
                //   navigate(`/dashboard/campaigns/${row?.first_name} ${row?.last_name}/${index}`)
                // }
                className="cursor-pointer"
              >
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content-noBorder-dark"
                      : "table-row-content-noBorder-light"
                  }`}
                  align="left"
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content-noBorder-dark"
                      : "table-row-content-noBorder-light"
                  }`}
                  align="left"
                >
                  {automatedMessage?.message}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content-noBorder-dark"
                      : "table-row-content-noBorder-light"
                  }`}
                  align="left"
                >
                  <Button
                    disableRipple
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textTransform: "none",
                      color: "#FFF",
                      "&:hover": { background: "transparent" },
                    }}
                    onClick={() => {
                      dispatch(
                        setAutomatedMessage({
                          ...automatedMessage,
                          title:
                            automatedMessage?.message_type ===
                            "connection_message"
                              ? "Connection Request Message"
                              : `${getOrdinal(
                                  automatedMessage?.position
                                )} Message`,
                        })
                      );
                      handleClickOpenEdit();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      className="cursor-pointer"
                    >
                      <path
                        d="M14.7563 3.36828C15.0812 3.04333 15.0812 2.50174 14.7563 2.19345L12.8066 0.243716C12.4983 -0.0812387 11.9567 -0.0812387 11.6317 0.243716L10.0986 1.7685L13.2232 4.89307M0 11.8754V15H3.12457L12.34 5.77628L9.21539 2.65171L0 11.8754Z"
                        fill={themeMode === "dark" ? "white" : "#000"}
                      />
                    </svg>
                    <Typography mx={2} sx={{ fontSize: "14px" }}>
                      Edit
                    </Typography>
                  </Button>
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content-noBorder-dark"
                      : "table-row-content-noBorder-light"
                  }`}
                  align="left"
                >
                  {automatedMessage.position > 1 && (
                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                        className="cursor-pointer"
                        onClick={() => {
                          dispatch(
                            deleteAutomatedMessages({
                              messageId: automatedMessage.uuid,
                              campaignId: id,
                            })
                          ).then((resp) => {
                            if (resp.payload?.status === 200) {
                              dispatch(getCampaignDetail(id));
                              dispatch(success("Automated message deleted"));
                            } else {
                              dispatch(error(resp.payload.statusText));
                            }
                          });
                        }}
                      >
                        <path
                          d="M12 0.888889H9L8.14286 0H3.85714L3 0.888889H0V2.66667H12M0.857143 14.2222C0.857143 14.6937 1.03775 15.1459 1.35925 15.4793C1.68074 15.8127 2.11677 16 2.57143 16H9.42857C9.88323 16 10.3193 15.8127 10.6408 15.4793C10.9622 15.1459 11.1429 14.6937 11.1429 14.2222V3.55556H0.857143V14.2222Z"
                          fill="#FF0000"
                        />
                      </svg>
                      <Typography mx={2} sx={{ fontSize: "14px" }}>
                        Delete
                      </Typography>
                    </Grid>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {/* <TablePagination
        component="div"
        count={members?.meta?.total_count || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
        }}
      /> */}
      <AIMessageRequestDialog
        open={openEdit}
        handleClose={handleCloseEdit}
        handleClickOpen={handleClickOpenEdit}
      />
    </TableContainer>
  );
}
