import React from 'react';
import { useState } from "react";
import { Box, Typography, Button,ButtonGroup } from '@mui/material';
import { useSelector } from "react-redux";
import AutomatedMessages from "./AutomatedMessages.js";
import InMailMessages from "./InMailMessages.js";
import TimeZoneOptimization from "./TimeZoneOptimization.js";

const Parameters = ({ setSelectedTab }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [selectedSubTab, setSelectedSubTab] = useState(1);

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: 900,
                    padding: '20px',
                    color: '#fff',
                    marginTop: '20px',
                }}
            >
                <Typography variant="h4" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black'}}>
                    Review AI-Generated Details
                </Typography>

                <Typography variant="body1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: '50px'}}>
                    Please review the AI suggested parameters before proceeding and do changes if necessary.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        marginBottom: '30px',
                        borderRadius: { md: "30px 0px 0px 30px", sm: "30px 0px 0px 30px", xs: "30px 30px " }
                    }}
                >
                    <ButtonGroup size="large" aria-label="Large button group" 
                        sx={{ 
                            display: "flex", 
                            flexDirection: { md: "row", sm: "row", xs: "column", width: "100%", 
                            borderRadius: { md: "30px 0px 0px 30px", sm: "30px 0px 0px 30px", xs: "30px 30px " } },
                            }}>

                    <Button
                        variant="contained"
                            className={
                                selectedSubTab === 1
                                    ? themeMode === "dark"
                                        ? "follow-up-time-btn-selected px-6"
                                        : "follow-up-time-btn-selected-light px-6"
                                    : themeMode === "dark"
                                        ? "follow-up-time-btn-outline px-6"
                                        : "follow-up-time-btn-outline-light px-6"
                            }
                            sx={{
                                borderRadius: { md: "30px 0px 0px 30px !important", sm: "30px 0px 0px 30px !important", xs: "30px 30px 0px 0px !important" }
                            }}
                        onClick={() => setSelectedSubTab(1)}
                    >
                        <span
                                className={
                                    selectedSubTab === 1
                                        ? themeMode === "dark"
                                            ? "table-filter-btn-text-light"
                                            : "table-filter-btn-text"
                                        : themeMode === "dark"
                                            ? "table-filter-btn-text"
                                            : "table-filter-btn-text-light"
                                }>
                        Automated Messages
                        </span>
                    </Button>
                    <Button
                        variant="contained"
                            className={
                                selectedSubTab === 2
                                    ? themeMode === "dark"
                                        ? "follow-up-time-btn-selected px-6"
                                        : "follow-up-time-btn-selected-light px-6"
                                    : themeMode === "dark"
                                        ? "follow-up-time-btn-outline px-6"
                                        : "follow-up-time-btn-outline-light px-6"
                            }
                            sx={{
                                borderRadius: { md: "0px !important", sm: " 0px !important", xs: "0px !important" },
                                border:themeMode==="dark"?"1px solid #fff":"1px solid #000"

                            }}
                        onClick={() => setSelectedSubTab(2)}
                    >
                            <span
                                className={
                                    selectedSubTab === 2
                                        ? themeMode === "dark"
                                            ? "table-filter-btn-text-light"
                                            : "table-filter-btn-text"
                                        : themeMode === "dark"
                                            ? "table-filter-btn-text"
                                            : "table-filter-btn-text-light"
                                }>
                        In-Mail Messages
                        </span>
                    </Button>
                    <Button
                        variant="contained"
                            className={
                                selectedSubTab === 3
                                    ? themeMode === "dark"
                                        ? "follow-up-time-btn-selected px-6"
                                        : "follow-up-time-btn-selected-light px-6"
                                    : themeMode === "dark"
                                        ? "follow-up-time-btn-outline px-6"
                                        : "follow-up-time-btn-outline-light px-6"
                            }
                            sx={{
                                borderRadius: { md: "0px 30px 30px 0px !important", sm: "0px 30px 30px 0px !important", xs: "0px 0px 30px 30px !important" }
                            }}
                        onClick={() => setSelectedSubTab(3)}
                    >
                            <span
                                className={
                                    selectedSubTab === 3
                                        ? themeMode === "dark"
                                            ? "table-filter-btn-text-light"
                                            : "table-filter-btn-text"
                                        : themeMode === "dark"
                                            ? "table-filter-btn-text"
                                            : "table-filter-btn-text-light"
                                }>
                                Time Zone</span>
                    </Button>
                    </ButtonGroup>
                </Box>
                {selectedSubTab === 1 && <AutomatedMessages setSelectedSubTab={setSelectedSubTab} />}
                {selectedSubTab === 2 && <InMailMessages setSelectedSubTab={setSelectedSubTab} />}
                {selectedSubTab === 3 && <TimeZoneOptimization setSelectedTab={setSelectedTab} />}
            </Box>
        </>
    );
};
export default Parameters;