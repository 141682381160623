import { React, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop, MenuItem, Select, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function OnboardingErrorDialog({ open, handleClose }) {
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const organization = useSelector((state) => state.settings.organization);
  const user = useSelector((state) => state.userProfile.userProfile);
  const emailExists = organization?.admins?.some(
    (member) => member.email === user.email
  );

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "14px",
            paddingRight: "14px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-3"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: themeMode === "dark" ? "white" : "#000",
              textAlign: "center",
              overflowX: "hidden",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                fontWeight: "700",
                flexWrap: "wrap",
              }}
            >
              Error occured!
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "12px",
                fontWeight: "400",
                flexWrap: "wrap",
              }}
            >
              It seems like some issue has occured during onboarding process.
            </Typography>
            <Grid
              item
              my={3}
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "14px",
                  fontWeight: "400",
                  flexWrap: "wrap",
                }}
              >
                We are redirecting you to our Contact Us form—please let us know
                the issue you are experiencing.{" "}
              </Typography>
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={() => navigate("/contact")}
                sx={{
                  color: themeMode === "dark" ? "#FFF" : "black",
                  border:
                    themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  padding: "8px 25px",
                  background: "transparent",
                  "&:hover": {
                    background: themeMode === "dark" ? "#FF000099" : "#d10202",
                  },
                }}
              >
                Contact Us
              </Button>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
