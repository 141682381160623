import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
  disconnectLinkedInAccount,
  getLinkedInConnectedStatus,
} from "../../../store/settingsSlice";
import { success } from "../../../store/alertSlice";

export default function DisconnectConfirmationDialog({
  open,
  handleClose,
  message,
}) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const deleteMessageId = useSelector(
    (state) => state.messages.deleteMessageId
  );

  function onSubmit(data) {
    // dispatch(disconnectLinkedInAccount()).then(() =>
    //   dispatch(getLinkedInConnectedStatus())
    // );
    dispatch(disconnectLinkedInAccount()).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(getLinkedInConnectedStatus());
        dispatch(success("Account disconnected"));
      }
    });
    handleClose();
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", // Inner background color
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-between cursor-pointer pb-8"
          onClick={handleClose}
        >
          <div />
          <div
            className={
              themeMode === "dark"
                ? "create-campaign-dialog-text "
                : "create-campaign-dialog-text-light "
            }
            style={{ fontSize: "18px", color: "red" }}
          >
            Disconnect LinkedIn
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "black"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2 pt-4"
                  : "create-campaign-dialog-text-light pb-2 pt-4"
              }
            >
              Are you sure you want to disconnect your LinkedIn account?
            </div>

            <div className="flex w-full justify-end space-x-4 pt-8">
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-2 px-8"
                    : "create-button-light p-2 px-8"
                }
                variant="contained"
                onClick={() => {
                  onSubmit();
                }}
              >
                <span className="create-button-text">Disconnect</span>
              </Button>
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-2 px-8"
                    : "create-button-light p-2 px-8"
                }
                style={{ backgroundColor: "transparent" }}
                variant="contained"
                onClick={handleClose}
              >
                <span
                  className="create-button-text"
                  style={{
                    color: themeMode === "dark" ? "white" : "black",
                  }}
                >
                  Close
                </span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
