import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { setEmailNotification } from "../../../store/settingsSlice";

function createData(name, sendTo) {
  return {
    name,
    sendTo,
  };
}

const styles = {
  row: {
    border: "none",
  },
};

export default function EmailNotificationsTable({ handleClickOpen, team }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" sx={styles.row}>
              Name{" "}
            </TableCell>
            <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" sx={styles.row}>
              Send to{" "}
            </TableCell>
            <TableCell className={`table-col-title ${themeMode === "dark" ? "text-light" : "text-dark"}`} align="left" sx={styles.row}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {team.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className="cursor-pointer"
            >
              <TableCell className={` ${themeMode === "dark" ? "table-row-content-noBorder-dark" : "table-row-content-noBorder-light"}`} 
                sx={styles.row}>
                {row.name}
              </TableCell>
              <TableCell className={` ${themeMode === "dark" ? "table-row-content-noBorder-dark" : "table-row-content-noBorder-light"}`} 
                align="left"
                sx={styles.row}
              >
                {row.members?.length} Member(s)
              </TableCell>
              <TableCell
                className={`table-row-stats-noBorder ${themeMode === "dark" ? "text-light" : "text-dark"}`} 
                align="left"
                sx={styles.row}
                onClick={(ev) => {
                  ev.stopPropagation();
                  dispatch(setEmailNotification(row));
                  handleClickOpen();
                }}
              >
                <span className={`cursor-pointer email-notifs-add ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
                  {" "}
                  Add / Remove Members
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
