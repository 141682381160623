/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import {
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getTokens, loginUser } from "../store/loginSlice";
import { Controller, useForm } from "react-hook-form";
import { getUserProfile } from "../store/userProfileSlice";
import Navbar from "../website/navbar/Navbar";
import Footer from "../website/footer/Footer";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { error, success } from "../store/alertSlice";
import { getLinkedInConnectedStatus } from "../store/settingsSlice";
import { requestForToken } from "../../firebase";
import { forgotPassword } from "../store/resetSlice";

const gradientAnimation = keyframes`
  0% {
    background-position: 50% 50%;
    background-image: conic-gradient(#37069D 10%, #0f012b 15%, #37069D 18%, #0f012b 22%, #37069D 33%, #0f012b 42%, #37069D 61%, #0f012b 93%,#37069D 99%);
  }
  50% {
    background-position: 100% 50%;
    background-image: conic-gradient(#0f012b 10%, #37069D 15%, #0f012b 18%, #37069D 22%, #0f012b 33%,#37069D 42%, #0f012b 61%,#37069D 93%,#37069D 99%);
  }
  100% {
    background-position: 50% 50%;
    background-image: conic-gradient(#37069D 10%, #0f012b 15%, #37069D 18%, #0f012b 22%, #37069D 33%, #0f012b 42%, #37069D 61%, #0f012b 93%,#37069D 99%);
  }
`;

const defaultValues = {
  email: "",
};

const schema = yup
  .object()
  .shape({
    email: yup.string().required("You must type email"),
  })
  .required();

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(false);

  const isCustomRange = useMediaQuery(
    "(min-width:1024px) and (max-width:1336px)"
  );

  const logeddUser = useSelector((state) => state.login.login);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(data) {
    dispatch(forgotPassword(data)).then((resp) => {
      if (resp?.payload?.status === 200) {
        setShowInfoMessage(true);
      } else dispatch(error("provided email doesn't exists"));
    });
  }
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div
        className="lg:flex w-full mt-24"
        css={css`
          background-color: #37069d;

          background-size: 240% 240%;
          animation: ${gradientAnimation} 20s ease infinite;
        `}
      >
        <Grid
          className="w-full flex flex-col justify-center he-full"
          sx={{
            backgroundColor: "#EFEFEF",
            borderRadius: "0px 30px 30px 0px",
            // height: { md: "100vh", sm: "100%", xs: "100%" },
            backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/loginBg2.png)`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom left",
          }}
        >
          <div className="p-8 px-16">
            {" "}
            <div className="login-heading">Forgot Password?</div>
            <div className="login-content pt-6">
              Enter your email and reset your password to continue exploring
              innovative solutions and automating your workflows. We're here to
              help you achieve your goals with ease.
            </div>
          </div>
          <Grid
            sx={{
              width: {
                md: isCustomRange ? "100%" : "66%",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            <Grid sx={{ width: "100%" }} className="px-16">
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="auth-form-input"
                    placeholder="Email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    required
                    sx={{
                      width: { md: "66%", sm: "66%", xs: "100%" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                )}
                name="email"
                control={control}
              />

              <div className="pt-10">
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: "#3633A7",
                    color: "white",
                    borderRadius: "30px",
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  <span className="sign-button">Submit</span>
                </Button>
                {showInfoMessage && (
                  <div
                    className="select-plan-btn-text-reg-det pb-2 pt-2"
                    style={{ color: "green" }}
                  >
                    If this email is associated with an account, you'll receive
                    an email with further instructions. Please check your inbox.{" "}
                  </div>
                )}
                <div className="text-black pt-6 mb-8">
                  Don’t have an account?
                  <span className="font-bold text-[#2200F2]">
                    <Link to={"/register"}> Sign up</Link>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <div className="w-full lg:w-1/2 px-16">
            <div className="flex justify-end mt-10">
              <img
                src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoIcon.png"
                alt="logo-icon"
                className=""
              />
            </div>
            <div className="login-container">
              <div className="login-text">Welcome back to</div>
              <div className="login-text2">FacoonLabs</div>
            </div>
          </div>
        </Hidden>
      </div>
      <Footer />
    </div>
  );
};
