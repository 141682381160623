import { Grid, Typography, Button } from "@mui/material";
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
const bkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/completeBkg.png";
const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png";
const linkedin =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/linkedInLg.png";
const campaign =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/campaignLg.png";
const prospects =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/prospectsLg.png	";

const CompleteOnboarding = () => {
  const navigate = useNavigate();

  const [animationClass, setAnimationClass] = useState("page-enter-welcome");
  const [animationClassPage, setAnimationClassPage] = useState("");

  useEffect(() => {
    setAnimationClass("page-enter-welcome");
    return () => {
    };
  }, []);

  const handlePageLeave = () => {
    setAnimationClassPage("page-exit-up");
    setTimeout(() => {
      
    }, 500);
    navigate("/dashboard");
  };

  return (
    <Grid
      className="onboardingBkg"
      sx={{
        backgroundImage: `url(${bkg})`,
      }}
    >
      <Grid className={`welcome-content ${animationClassPage}`}>
        <img src={logo} alt="" />
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: { md: "36px", sm: "36px", xs: "20px" },
          }}
        >
          Onboarding Completed
        </Typography>
      </Grid>

      {/* <div className="flex justify-start items-center  space-x-8">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="70"
          height="40"
          viewBox="0 0 70 40"
          fill="none"
        >
          <path
            d="M0 23.3383L16.8301 40L21.0753 35.7675L4.27527 19.1356M65.7247 0L33.871 31.5648L21.3462 19.1356L17.0409 23.3383L33.871 40L70 4.23249M52.9591 4.23249L48.714 0L29.5957 18.927L33.871 23.1297L52.9591 4.23249Z"
            fill="#24FF00"
          />
        </svg>
        <div>
          <div className={`linkedin-main-text`}>Completed</div>
        </div>
      </div> */}

      <Grid my={2} sx={{ display: "flex", flexDirection: "row" }}>
        <Grid
          className={animationClass}
          item
          xs={4}
          mx={{ md: 2, sm: 2, xs: 0 }}
          p={{ md: 4, sm: 3, xs: 1 }}
        >
          <img src={linkedin} alt="" />
        </Grid>
        <Grid
          className={animationClass}
          item
          xs={4}
          mx={{ md: 2, sm: 2, xs: 0 }}
          p={{ md: 4, sm: 3, xs: 1 }}
        >
          <img src={campaign} alt="" />
        </Grid>
        <Grid
          className={animationClass}
          item
          xs={4}
          mx={{ md: 2, sm: 2, xs: 0 }}
          p={{ md: 4, sm: 3, xs: 1 }}
        >
          <img src={prospects} alt="" />
        </Grid>
      </Grid>
      <Button
        my={3}
        type="submit"
        variant="contained"
        onClick={handlePageLeave}
        sx={{
          fontWeight: "700",
          marginY: "10px",
          backgroundColor: "#FFF",
          borderRadius: "30px",
          color: "#B50000",
          textTransform: "capitalize",
          padding: "8px 30px",
          border: "1px solid transparent",
          "&:hover": {
            background: "transparent",
            color: "#FFF",
            border: "1px solid #FFF",
          },
        }}
      >
        Let's Go
      </Button>
    </Grid>
  );
};
export default CompleteOnboarding;
