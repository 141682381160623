import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { contactForm } from "../../store/homeSlice";
import { success } from "../../store/alertSlice";

export const ContactUsForm = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const handleSubmit = (event) => {
    console.log("name", name);
    console.log("email", email);
    console.log("phone", phone);
    console.log("category", category);
    console.log("message", message);
    dispatch(
      contactForm({
        full_name: name,
        email: email,
        phone_number: phone,
        issue_category_type: category,
        inquiry_details: message,
      })
    ).then((resp) => {
      if (resp?.payload?.status === 201) {
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setCategory("");
        dispatch(
          success(
            "Contact Form Request saved successfully. They will reach out to you soon."
          )
        );
      }
    });
  };
  return (
    <div>
      <div>
        <div className="input-label pb-2">Full name</div>
        <TextField
          id="name"
          variant="outlined"
          size="small"
          className="w-full"
          style={{ border: "1px solid white", borderRadius: "10px" }}
          inputProps={{ style: { color: "white" } }}
          InputProps={{
            sx: {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent", borderRadius: "10px"
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
                borderRadius: "10px",
              },
            },
          }}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="mt-6">
        <div className="input-label pb-2">Email</div>
        <TextField
          id="Email"
          variant="outlined"
          size="small"
          className="w-full"
          sx={{borderRadius:"10px"}}
          style={{ border: "1px solid white", borderRadius: "10px" }}
          inputProps={{ style: { color: "white" } }}
          InputProps={{
            sx: {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent", borderRadius: "10px"
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
                borderRadius: "10px",
              },
            },
          }}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mt-6">
        <div className="input-label pb-2">Phone number</div>
        <TextField
          id="Phone"
          variant="outlined"
          size="small"
          className="w-full"
          style={{ border: "1px solid white", borderRadius: "10px" }}
          inputProps={{ style: { color: "white" } }}
          InputProps={{
            sx: {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent", borderRadius:"10px"
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
                borderRadius: "10px", 
              },
            },
          }}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="mt-6">
        <div className="input-label pb-2">Issue Category</div>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            label="category"
            onChange={handleChange}
            size="small"
            sx={{
              ".MuiSelect-icon": {
                color: "white",
              },
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              boxShadow: "none",
            }}
            style={{ border: "1px solid white", color: "white",borderRadius:"10px" }}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: "#333", 
                  "& .MuiMenuItem-root": {
                    color: "#FFF", 
                    "&.Mui-selected": {
                      background: "#260000", // Background color for the selected item
                      color: "#FFF",      // Text color for the selected item
                    },
                    "&.Mui-selected:hover": {
                      background: "#900101", // Background color when selected item is hovered
                    },

                    "&:hover": {
                      bgcolor: "#700101", 
                      color: "#FFF",
                    },
                  },
                },
              },
            }}
          >
            <MenuItem sx={{"&:hover":{background :"#700101",color:"#FFF"}}} value={"onboarding"}>Onboarding</MenuItem>
            <MenuItem sx={{"&:hover":{background :"#700101",color:"#FFF"}}} value={"dashboard"}>Dashboard</MenuItem>
            <MenuItem sx={{"&:hover":{background :"#700101",color:"#FFF"}}} value={"payment"}>Payment</MenuItem>
            <MenuItem sx={{"&:hover":{background :"#700101",color:"#FFF"}}} value={"general"}>General</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="mt-6">
        <div className="input-label pb-2">How can we help?</div>
        <TextField
          id="help"
          variant="outlined"
          multiline
          rows={4}
          size="small"
          className="w-full"
          style={{ border: "1px solid white", borderRadius: "10px" }}
          inputProps={{ style: { color: "white" } }}
          InputProps={{
            sx: {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent", borderRadius: "10px"
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
                borderRadius: "10px",
              },
            },
          }}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="mt-6">
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: "#fff",
                "&.Mui-checked": {
                  color: "#AD0000",
                },
              }}
            />
          }
          label="I’ve read and accept privacy policy"
        />
      </div>
      <div className="mt-6">
        <Button
          variant="contained"
          className="primary-button"
          onClick={() => handleSubmit()}
        >
          Send message
        </Button>
      </div>
    </div>
  );
};
