import { Grid,Typography,useMediaQuery } from "@mui/material";

export const WhyChooseUsCard = ({ title, subtitle, backgroundGif }) => {
  const isCustomRange = useMediaQuery('(min-width:900px) and (max-width:1200px)');
  const isCustomSmallerRange = useMediaQuery('(min-width:360px) and (max-width:600px)');
  return (
    <Grid
      item
      lg={4}
      md={isCustomRange?4:6}
      sm={4}
      xs={12}
      px={{md:2,sm:2,xs:0}}
      pb={12}
      sx={{
        overflow:"visible",
        backgroundImage: `url(${backgroundGif})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: { md: "80%", sm: "150%", xs: isCustomSmallerRange?"100%":"200%" },
        backgroundPosition: {
          md: "220% 120%",
          sm: "50% 100%",
          xs: isCustomSmallerRange?"center center":"top center",
        },
      }}
    >
      <div className="choose-us-card p-8 h-full">
        <Typography className="choose-us-card-title" sx={{fontSize:{md:"24px",sm:"20px",xs:"24px"}}}>{title}</Typography>
        <Typography className="choose-us-card-subtitle py-8" sx={{fontSize:{md:"18px",sm:"14px",xs:"18px"}}}>{subtitle}</Typography>
      </div>
    </Grid>
  );
};
