import { useEffect, useState } from "react";
import { Grid, Button, useMediaQuery, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import PaymentTable from "./PaymentTable";
import ChangePlanDialog from "./ChangePlanDialogBox.js";
import { useDispatch, useSelector } from "react-redux";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import ResumeSubscriptionDialog from "./ResumeSubscriptionDialog";
import { getOrganization } from "../../store/settingsSlice";
import { getPaymentMethods } from "../../store/paymentMethodSlice.js";
import PayNowDialog from "./PayNowDialog";
import { capitalizeFirstLetter } from "../../../utils/globalFunctions.js";
import dayjs from "dayjs";
import DateRangePopover from "../../../utils/DateRangePopover";
import { useNavigate } from "react-router-dom";
import { success, info } from "../../store/alertSlice";

export const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const user = useSelector((state) => state.userProfile.userProfile);
  const payments_invoices = useSelector(
    (state) => state.payments.payments_invoices
  );
  const payment_methods = useSelector(
    (state) => state.paymentMethods.paymentMethods || []
  );
  const [openChangePlan, setOpenChangePlan] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openResume, setOpenResume] = useState(false);
  const [plan, setPlan] = useState(null);
  const isCustomRange = useMediaQuery(
    "(min-width:900px) and (max-width:1056px)"
  );
  const currentDate = dayjs();
  const firstDate = currentDate.subtract(2, "year");
  const lastDate = currentDate;
  const [startDate, setStartDate] = useState(firstDate);
  const [endDate, setEndDate] = useState(lastDate);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickOpenChangePlan = () => {
    setOpenChangePlan(true);
  };

  const handleCloseChangePlan = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpenChangePlan(false);
    }
  };

  const handleCloseResume = () => {
    setOpenResume(false);
  };

  const handleCloseCancel = () => {
    setOpenCancel(false);
  };


  const handleClickOpenPay = (plan) => {
    setPlan(plan);
    setOpenPay(true);
  };

  const handleClosePay = () => {
    setOpenPay(false);
  };

  useEffect(() => {
    dispatch(getOrganization());
    dispatch(getPaymentMethods({ page: 1, per_page: 5 }));
  }, []);

  return (
    <>
      <div>
        <div className="pt-24 pb-6 md:px-16 px-8">
          <div
            className={`dashboard-title ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Payments & Invoices
            <span style={{ paddingLeft: "10px" }}>
              <DateRangePopover
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </span>
          </div>
          <div
            className={`dashboard-subtitle py-4 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            View list of previous generated invoices here. Also you can see your
            subscription plan and payment cycle.{" "}
          </div>
        </div>
        <Grid
          container
          className="md:px-16 px-8"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          <Grid
            item
            md={isCustomRange ? 12 : 6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Grid
              item
              md={6}
              sm={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: { md: "left", sm: "center" },
                  color: themeMode === "dark" ? "#FFF" : "#000",
                }}
              >
                <span>Current Plan</span>
                <Typography variant="body2" component="span" sx={{ marginLeft: "8px" }}>
                  {payments_invoices?.data?.current_plan?.status
                    ? `(${payments_invoices.data.current_plan.status})`
                    : ""}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  color: themeMode === "dark" ? "#BDFF00" : "#608104",
                  fontSize: "22px",
                  fontWeight: "700",
                  justifyContent: { md: "left", sm: "center" },
                }}
              >
                {payments_invoices?.data?.current_plan?.payment_plan?.name
                  ? capitalizeFirstLetter(
                      payments_invoices.data.current_plan.payment_plan.name
                    )
                  : ""}
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: { md: "left", sm: "center" },
                  color: themeMode === "dark" ? "#FFF" : "#000",
                }}
              >
                Next Payment Date
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  color: themeMode === "dark" ? "#BDFF00" : "#608104",
                  fontSize: "22px",
                  fontWeight: "700",
                  justifyContent: { md: "left", sm: "center" },
                }}
              >
                {payments_invoices?.data?.current_plan?.payment_plan?.price
                  ? `$${payments_invoices.data.current_plan.payment_plan.price}`
                  : ""}
                <span
                  style={{
                    color: themeMode === "dark" ? "#FFF" : "#000",
                    marginLeft: "10px",
                  }}
                >
                  {payments_invoices?.data?.current_plan?.next_date
                    ? `on ${dayjs(
                        payments_invoices.data.current_plan.next_date
                      ).format("D MMM YYYY")}`
                    : ""}
                </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            my={{ md: "", sm: 3, xs: 3 }}
            item
            md={isCustomRange ? 12 : 6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { md: "flex-end", sm: "center", xs: "center" },
              alignItems: "center",
              flexWrap: { md: "nowrap", sm: "wrap", xs: "wrap" },
            }}
          >
            <Button
              disableRipple
              onClick={() => navigate("manage-payment-methods")}
              sx={{
                color: themeMode === "dark" ? "#FFF" : "#000",
                border:
                  themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                borderRadius: "30px",
                textTransform: "capitalize",
                padding: "8px 15px",
                height: "fit-content",
                width: "fit-content",
                marginRight: "10px",
                textWrap: "nowrap",
                fontSize: { md: "14px", sm: "14px", xs: "12px" },
                "&:hover":{
                  background: "#BE0000",
                  color: "#FFF"
                }
                
                
              }}
            >
              Manage Payment Methods
            </Button>
            <Tooltip
              arrow
              title={
                user?.organization_role_type !== "admin"
                  ? "Only admins can manage subscription"
                  : ""
              }
            >
              <Button
                onClick={(event) => setAnchorEl(event.currentTarget)}
                disabled={user?.organization_role_type !== "admin"}
                sx={{
                  color: themeMode === "dark" ? "#FFF" : "#000",
                  "&.Mui-disabled": {
                    color: "#525252",  
                  },
                  border:
                    themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  padding: "8px 15px",
                  height: "fit-content",
                  width: "fit-content",
                  marginY: { md: "0px", sm: "0px", xs: "10px" },
                  marginRight: "10px",
                  textWrap: "nowrap",
                  fontSize: { md: "14px", sm: "14px", xs: "12px" },
                  "&:hover":{
                    background: "#BE0000",
                    color: "#FFF"
                  }
                }}
              >
                Manage Subscription
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem 
                  onClick={() => { 
                    setAnchorEl(null);
                    if(payment_methods?.data?.length){ 
                      handleClickOpenChangePlan()
                    }
                    else{
                      dispatch(info("Please add payment method to change subscription plan!"));
                      navigate(`/dashboard/payments/manage-payment-methods/add-payment-method`);
                    }
                  }}
                >
                  Change Plan
                </MenuItem>
                { (payments_invoices?.data?.current_plan?.status !== "active" || (payments_invoices?.data?.current_plan?.payment_plan?.name === "Free" && payments_invoices?.data?.current_plan?.status !== "active")) &&
                  <MenuItem 
                    onClick={() => { 
                      setAnchorEl(null); 
                      if(payment_methods?.data?.length || payments_invoices?.data?.current_plan?.payment_plan?.name === "Free"){
                        setOpenResume(true);
                      }
                      else{
                        dispatch(info("Please add payment method to resume subscription!"));
                        navigate(`/dashboard/payments/manage-payment-methods/add-payment-method`);
                      }
                    }}
                    sx={{
                      color: "#008000",
                    }}
                  >
                    Resume Subscription
                  </MenuItem>
                }
                { (payments_invoices?.data?.current_plan?.status === "active") &&
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenCancel(true);
                    }}
                    sx={{
                      color: "#FF0000",
                    }}
                  >
                    Cancel Subscription
                  </MenuItem>
                }
              </Menu>
            </Tooltip>
            {/* <Button
              onClick={() => setOpenCancel(true)}
              sx={{
                padding: "8px 15px",
                height: "fit-content",
                color: themeMode === "dark" ? "#FFF" : "#FFF",
                border:
                  themeMode === "dark" ? "1px solid #FFF" : "1px solid #000",
                background: themeMode === "dark" ? "#FF0000" : "#BE0000",
                borderRadius: "30px",
                textTransform: "capitalize",
                textWrap: "nowrap",
                width: "fit-content",
                fontSize: { md: "14px", sm: "14px", xs: "12px" },
                "&:hover": {
                  color: "#FFF",
                  background: themeMode === "dark" ? "#BE0000" : "#FF0000",
                },
              }}
            >
              Cancel Subscription{" "}
            </Button> */}
          </Grid>
        </Grid>
        <div className="py-4 pb-12 md:px-16 px-8">
          <div
            className={`p-4 px-8 my-8 ${
              themeMode === "dark"
                ? "dashboard-table-layout"
                : "dashboard-table-layout-light"
            }`}
          >
            <div
              className=""
              style={{ color: themeMode === "dark" ? "white" : "#000" }}
            >
              <PaymentTable startDate={startDate} endDate={endDate} />
            </div>
          </div>
        </div>
        <ChangePlanDialog
          open={openChangePlan}
          handleClose={handleCloseChangePlan}
          handleClickOpenPay={handleClickOpenPay}
          current_plan={payments_invoices?.data?.current_plan}
        />
        <CancelSubscriptionDialog
          open={openCancel}
          handleClose={handleCloseCancel}
          current_plan={payments_invoices?.data?.current_plan}
        />
        <ResumeSubscriptionDialog
          open={openResume}
          handleClose={handleCloseResume}
          current_plan={payments_invoices?.data?.current_plan}
        />
        <PayNowDialog
          open={openPay}
          plan={plan}
          handleClosePay={handleClosePay}
          handleClose={handleCloseChangePlan}
        />
      </div>
    </>
  );
};
