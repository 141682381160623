import { Grid, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const userSheild = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/User%20Shield.png";
const userSheildGrey = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/User%20Shield%20Grey.png";

const Terms = () => {
    const navigate = useNavigate();
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [seeMore, setSeeMore] = useState(false);

    const handleSeeMore = () => {
        setSeeMore(!seeMore);
    }

    return (
        <Grid
            sx={{
                height: "fit-content",
                padding: { md: "100px 150px", sm: "130px 100px", xs: "150px 30px" },
                color: themeMode === "dark" ? "#FFF" : "#000",
                position: "relative",
            }}
        >
            <Button
                disableRipple
                onClick={() => navigate("/")}
                sx={{
                    position: "absolute",
                    left: { md: "30px", sm: "30px", xs: "0px" },
                    top: { md: "100px", sm: "70px", xs: "80px" },
                    color: themeMode === "dark" ? "#FFF" : "#000",
                    textTransform: "capitalize",
                    "&:hover": { background: "transparent" },
                }}
            >
                <span className="pr-2 cursor-pointer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        viewBox="0 0 38 38"
                        style={{
                            background: themeMode === "dark" ? "#52000099" : "transparent",
                            borderRadius: "50%",
                            padding: "7px",
                        }}
                    >
                        <path
                            d="M18.5055 37.0199C16.0744 37.0239 13.6665 36.5478 11.4199 35.6188C9.17334 34.6899 7.13236 33.3263 5.41413 31.6065C-1.80471 24.3877 -1.80471 12.6426 5.41413 5.42376C8.91099 1.9269 13.5601 0 18.5055 0C23.4509 0 28.1 1.92542 31.5969 5.42376C38.8157 12.6426 38.8157 24.3877 31.5969 31.6065C29.8786 33.3263 27.8377 34.6899 25.5911 35.6188C23.3445 36.5478 20.9366 37.0239 18.5055 37.0199ZM7.50839 29.5122C13.572 35.5758 23.439 35.5758 29.5026 29.5122C32.4396 26.5737 34.057 22.6681 34.057 18.5151C34.057 14.3621 32.4396 10.4565 29.5026 7.51802C26.5656 4.57954 22.66 2.96218 18.5055 2.96218C14.351 2.96218 10.4469 4.58102 7.50839 7.51802C4.56991 10.455 2.95404 14.3607 2.95404 18.5151C2.95404 22.6696 4.57139 26.5737 7.50839 29.5122Z"
                            fill={themeMode === "dark" ? "white" : "black"}
                        />
                        <path
                            d="M21.9091 26.8027C21.7146 26.8029 21.5219 26.7647 21.3422 26.6902C21.1625 26.6157 20.9993 26.5065 20.862 26.3688L14.0549 19.5617C13.9172 19.4242 13.808 19.261 13.7335 19.0813C13.659 18.9017 13.6206 18.709 13.6206 18.5145C13.6206 18.32 13.659 18.1274 13.7335 17.9477C13.808 17.768 13.9172 17.6048 14.0549 17.4674L20.862 10.6603C20.9995 10.5228 21.1627 10.4137 21.3424 10.3393C21.5221 10.2649 21.7146 10.2266 21.9091 10.2266C22.1036 10.2266 22.2961 10.2649 22.4758 10.3393C22.6555 10.4137 22.8187 10.5228 22.9562 10.6603C23.0937 10.7978 23.2028 10.9611 23.2772 11.1407C23.3517 11.3204 23.39 11.513 23.39 11.7074C23.39 11.9019 23.3517 12.0945 23.2772 12.2741C23.2028 12.4538 23.0937 12.6171 22.9562 12.7546L17.1963 18.5145L22.9562 24.2745C23.164 24.4813 23.3057 24.7453 23.3632 25.0328C23.4207 25.3203 23.3916 25.6184 23.2794 25.8893C23.1672 26.1602 22.977 26.3916 22.733 26.5543C22.4891 26.7169 22.2023 26.8034 21.9091 26.8027Z"
                            fill={themeMode === "dark" ? "white" : "black"}
                        />
                    </svg>
                </span>
                Back To Home Page
            </Button>

            <Grid sx={{ width: { md: "73%", sm: "75%", xs: "100%" } }}>
                <Typography
                    mt={{ md: 10, sm: 10 }}
                    sx={{
                        fontSize: { md: "16px", sm: "16px", xs: "16px" },
                        fontWeight: "500",
                        textAlign: "left",
                        color: themeMode === "dark" ? "#F9E74D" : "#BE0000",
                        display: "flex"
                    }}
                >
                    <img src={themeMode === "dark" ? userSheild : userSheildGrey} alt="" style={{ marginRight: "10px" }} />
                    Terms & Conditions
                </Typography>

                <Typography my={3}
                    sx={{
                        fontSize: { md: "40px", sm: "40px", xs: "36px" },
                        fontWeight: "600",
                        textAlign: "left",
                        color: themeMode === "dark" ? "#FFF" : "#000",
                        display: "flex"
                    }}>
                    Guidelines
                </Typography>


                <Typography>
                    <span style={{ fontWeight: "700" }}>Welcome to FacoonLabs.info.</span>
                    This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully. <br /><br />
                    <span style={{ fontWeight: "700" }}>1. YOUR AGREEMENT</span> <br />
                    By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site. <br />
                    <span style={{ fontWeight: "700" }}>PLEASE NOTE:</span> We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the


                    {seeMore ?
                        <span>
                            posting of changes constitutes your acceptance of those changes.<br /><br />

                            <span style={{ fontWeight: "700" }}>2. PERMITTED USE</span><br />
                            You may use this Site solely for personal and non-commercial purposes. You agree not to use the Site for any unlawful purpose, including, but not limited to, unauthorized distribution, modification, or exploitation of content without express written permission from FacoonLabs.info.<br /><br />

                            <span style={{ fontWeight: "700" }}>3. USER SUBMISSIONS</span><br />
                            If you submit any content or materials to the Site (including, but not limited to, comments, suggestions, or questions), you grant FacoonLabs.info a non-exclusive, worldwide, royalty-free, perpetual, and irrevocable license to use, reproduce, modify, publish, distribute, or display such content.

                            You agree that any submission of user content complies with all applicable laws and that you will not post any unlawful, harmful, defamatory, or otherwise inappropriate material.<br /><br />

                            <span style={{ fontWeight: "700" }}>4. INTELLECTUAL PROPERTY</span><br />
                            All content, trademarks, logos, graphics, and software on the Site are the property of FacoonLabs.info or third parties. The content is protected by intellectual property laws. You are prohibited from copying, distributing, or otherwise exploiting any content without prior consent from FacoonLabs.info.<br /><br />

                            <span style={{ fontWeight: "700" }}>5. LINKS TO THIRD-PARTY WEBSITES</span><br />
                            This Site may contain links to other websites that are not operated or controlled by FacoonLabs.info. We are not responsible for the content or policies of these third-party websites. The inclusion of links to third-party sites does not imply endorsement by FacoonLabs.info.<br /><br />

                            <span style={{ fontWeight: "700" }}>6. DISCLAIMER OF WARRANTIES</span><br />
                            The Site is provided "as is" and "as available," and we make no representations or warranties of any kind, express
                        </span>
                        :
                        ".........."
                    }
                </Typography>

                <Button onClick={handleSeeMore}
                    sx={{ marginTop: "30px", color: "#FFF", backgroundColor: themeMode === "dark" ? "#390000" : "#BE0000", textTransform: "none", borderRadius: "30px", padding: "10px 20px", "&:hover": { backgroundColor: themeMode === "dark" ? "#390000" : "#BE0000" } }}>
                    {seeMore ? "See Less" : "Learn More"}
                </Button>
            </Grid>
        </Grid>
    )
}
export default Terms;