import React from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";

const AutomatedMessages = ({ setSelectedSubTab }) => {
  const themeMode = useSelector((state) => state.themeMode.mode);

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: themeMode === "dark" ? "white" : "black",
          marginBottom: "20px",
        }}
      >
        Edit your follow up messages
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent:"space-between",
          marginBottom: "20px",
          flexDirection:{md:"row",sm:"row",xs:"column"},
        }}
      >
        <Card
          sx={{
            border: "1px solid #FFF",
            width: {md:"33%",sm:"33%",xs:"100%"},
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.6)" : "white",
            borderRadius: "10px",
            color: "#fff",
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton color="inherit">
                <EditIcon
                  sx={{ color: themeMode === "dark" ? "white" : "black" }}
                />
              </IconButton>
              <IconButton color="inherit">
                <DeleteIcon sx={{ color: "red" }} />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: themeMode === "dark" ? "white" : "black", 
                fontSize: "14px",
                fontWeight: "600" 
              }}
            >
              Connection Request Message
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: themeMode === "dark" ? "white" : "black",
                marginTop: "10px",
                fontSize:"12px",
                fontWeight:"400"
              }}
            >
              Hey [firstname]! I saw you’re in the roofing industry. Would love
              to Connect!
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            border: "1px solid #FFF",
            width: { md: "33%", sm: "33%", xs: "100%" },
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.6)" : "white",
            borderRadius: "10px",
            color: "#fff",
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton color="inherit">
                <EditIcon
                  sx={{ color: themeMode === "dark" ? "white" : "black" }}
                />
              </IconButton>
              <IconButton color="inherit">
                <DeleteIcon sx={{ color: "red" }} />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: themeMode === "dark" ? "white" : "black", fontSize: "14px",
                fontWeight: "600"}}
            >
              Second Message
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: themeMode === "dark" ? "white" : "black",
                marginTop: "10px",
                fontSize: "12px",
                fontWeight: "400"
              }}
            >
              Thanks for connecting [firstname]! The reason I’m reaching out, we
              developed a software specifically...
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            border:"1px solid #FFF",
            width: { md: "33%", sm: "33%", xs: "100%" },
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.6)" : "white",
            borderRadius: "10px",
            color: "#fff",
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton color="inherit">
                <EditIcon
                  sx={{ color: themeMode === "dark" ? "white" : "black" }}
                />
              </IconButton>
              <IconButton color="inherit">
                <DeleteIcon sx={{ color: "red" }} />
              </IconButton>
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: themeMode === "dark" ? "white" : "black", fontSize: "14px",
                fontWeight: "600"}}
            >
              Third Message
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: themeMode === "dark" ? "white" : "black",
                marginTop: "10px",
                fontSize: "12px",
                fontWeight: "400"
              }}
            >
              Hi [firstname]! Just reaching back out. Not sure you’ll saw that
              last message but I’d love to get on...
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Button
        variant="contained"
        sx={{
          textTransform: "none",
          marginY:"10px"
        }}
        
        className={
          themeMode === "dark"
            ? "create-button px-9"
            : "create-button-light px-9"
        }
        onClick={() => setSelectedSubTab(2)}
      >
        Next
      </Button>
    </>
  );
};
export default AutomatedMessages;
