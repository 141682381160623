import { Grid, Typography } from "@mui/material";
import { PricingCard } from "./PricingCard";
import { Partners } from "../homepage/Partners";
import { BeginYourJourney } from "../homepage/BeginYourJourney";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDefaultPaymentPlans } from "../../store/homeSlice";

export const Pricing = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userProfile.userProfile);
  const paymentPlans = useSelector((state) => state.home.paymentPlans);
  const monthlyPlan = paymentPlans?.find((plan) => plan.name === "Monthly");
  const semiAnnualPlan = paymentPlans?.find(
    (plan) => plan.name === "Semi Annual"
  );
  const annualPlan = paymentPlans?.find((plan) => plan.name === "Annual");

  useEffect(() => {
    dispatch(getDefaultPaymentPlans());
  }, []);

  console.log("oo", paymentPlans);
  return (
    <>
      <Navbar />
      <div className="flex flex-col lg:flex md:pt-24 sm:pt-10 xs:pt-8">
        <div className="pricing-bg h-full py-16 sm:px-4">
          <div className="pricing-title"> Pricing Plans</div>
          <Typography
            className="pricing-heading"
            sx={{ fontSize: { md: "70px", sm: "60px", xs: "35px" } }}
          >
            {" "}
            Choose the Plan That
          </Typography>
          <Typography
            className="pricing-heading"
            sx={{ fontSize: { md: "70px", sm: "60px", xs: "35px" } }}
          >
            {" "}
            Fits Your Needs
          </Typography>
          <div class="px-16 py-16 mt-16 mb-16 min-[320px]:px-2">
            <Grid
              container
              spacing={{ md: 9, sm: 9, xs: 0 }}
              style={{
                backgroundImage:
                  "url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/pricingBg.png)",
                backgroundSize: "contain",
              }}
            >
              <Grid item lg={4} md={6} sm={12} xs={12}>
                {monthlyPlan && (
                  <PricingCard
                    title={monthlyPlan?.name}
                    charges={monthlyPlan?.discounted_price}
                    originalPrice={monthlyPlan?.price}
                    duration={"month"}
                    btnTitle={"Choose Monthly Plan"}
                    features={monthlyPlan?.benefits}
                  />
                )}
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                sm={12}
                xs={12}
                my={{ md: 0, sm: 4, xs: 12 }}
              >
                {semiAnnualPlan && (
                  <PricingCard
                    bestDeal
                    title={semiAnnualPlan?.name}
                    charges={semiAnnualPlan?.discounted_price}
                    originalPrice={semiAnnualPlan?.price}
                    duration={"6 months"}
                    btnTitle={"Start 6-Months Journey"}
                    features={semiAnnualPlan?.benefits}
                  />
                )}
              </Grid>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                {annualPlan && (
                  <PricingCard
                    title={annualPlan?.name}
                    charges={annualPlan?.discounted_price}
                    originalPrice={annualPlan?.price}
                    duration={"year"}
                    btnTitle={"Unlock Annual Benefits"}
                    features={annualPlan?.benefits}
                  />
                )}
              </Grid>
            </Grid>
          </div>
          <Partners />
          <div className="my-28">
            <BeginYourJourney />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
