import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop, MenuItem, Select, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { getTags, updateTag } from "../../../store/settingsSlice";
import { error, success } from "../../../store/alertSlice";

const defaultValues = {
  title: "",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter tag title"),
  })
  .required();

export default function EditTagDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const tag = useSelector((state) => state.settings.tag);
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  React.useEffect(() => {
    reset({ ...tag });
  }, [tag, reset]);

  function onSubmit(data) {
    dispatch(updateTag({ ...data, uuid: tag.uuid })).then((resp) => {
      if (resp.payload.status === 200) {
        dispatch(getTags());
        dispatch(success("Tag updated"));
      } else {
        dispatch(error(resp.payload.statusText));
      }
    });
    handleClose();
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D", backdropFilter: "blur(4px)" }}
        PaperProps={{
          sx: {
            backgroundColor: themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", 
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-4"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: themeMode === "dark" ? "white" : "#000" }}
            className="flex flex-col items-center justify-center"
          >
            <div className={`create-campaign-dialog pb-8 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>Tag Name</div>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  required
                  className={`w-full ${themeMode === "dark" ? "create-campaign-dialog-field" : "create-campaign-dialog-field-light"}`}
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "#FFF" : "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "#FFF" : "#000",
                      },
                    },
                  }}
                />
              )}
              name="title"
              control={control}
            />

            <div className="flex w-full justify-center pt-8">
              <Button
                className="create-button p-2 px-4"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="create-button-text">Edit Tag</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
