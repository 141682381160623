import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  body: {
    backgroundColor: "#161616",
  },
 bodyRed:{
   backgroundColor: "#6D1B1D",
 },
 
  flex: {
    display: "flex",
    flexWrap:"nowrap"
  },
  link: {
    textDecoration: "none",
    listStyleType: "none",
    color: "#fff",
    lineHeight: "2",
    
  },
  head: {
    textDecoration: "none",
    color: "#979797",
    lineHeight: "2.75",
    fontSize: "16px",
    fontWeight: "800",
    listStyleType: "none",
    marginRight: "30px",
  },
  headWhite: {
    textDecoration: "none",
    color: "#FFF",
    lineHeight: "2.75",
    fontSize: "16px",
    fontWeight: "800",
    listStyleType: "none",
    marginRight: "30px",
  },
  list: {
    listStyleType: "none",
  },
  
  divider: {
    height: "1px",
    width: "100%",
    orientation: "horizontal",
    
  },
  fitRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  my15: {
    margin: "0px 10px",
  },
});
