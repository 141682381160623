import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  Tabs,
  Tab,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./messageShow.css";

const steps = ["Messaged", "Replied", "Booked"];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00FF19",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00FF19",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#4A4A4A",
  },
}));

function MessageShow({ openMessageShow, closeMessageShow, messageId }) {
  const [messagesTabValue, setMessagesTabValue] = useState(0);
  const [messageText, setMessageText] = useState(
    "Oh it’s alright. Thank you for the response."
  );
  const [conversationSteps, setConversationSteps] = useState(2);
  const themeMode = useSelector((state) => state.themeMode.mode);

  const handleMessagesTabChange = (event, newValue) => {
    setMessagesTabValue(newValue);
  };

  return (
    <Dialog
      open={openMessageShow}
      onClose={closeMessageShow}
      maxWidth={false}
      fullWidth
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.5)" : "#0000004D",
            backdropFilter: "blur(10px)",
          },
        },
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiDialog-paper": {
          background: "none",
          width: "100%",
          margin: 0,
          maxWidth: "100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "20px",
          width: "100%",
        }}
      >
        <Box
          className={themeMode === "dark" ? "left-div" : "left-div-light"}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <div></div>
            <div>
              <IconButton onClick={closeMessageShow}>
                <CloseIcon sx={{ color: "white" }} />
              </IconButton>
            </div>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={messagesTabValue}
              onChange={handleMessagesTabChange}
              aria-label="message tabs"
              className="flex items-center w-full px-8"
              sx={{
                "& .MuiTab-root": {
                  color: themeMode === "dark" ? "#FFF" : "black",
                  fontWeight: "bold",
                  color: themeMode === "dark" ? "#FFF" : "black",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  textTransform: "none",
                },
                "& .MuiTab-root.Mui-selected": {
                  color: themeMode === "dark" ? "#FFF" : "black",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                },
                "& .MuiTabs-indicator": {
                  backgroundColor:
                    themeMode === "dark" ? "whitesmoke" : "#0C0404",
                },
              }}
            >
              <Tab
                label="Conversation"
                disableRipple
                sx={{
                  color: "white",
                  textTransform: "capitalize",
                }}
              />
              <Box sx={{ mx: 2, mt: 2 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="20"
                  viewBox="0 0 2 20"
                  fill="none"
                >
                  <path d="M1 0V40" stroke="white" strokeWidth="1" />
                </svg>
              </Box>
              <Tab
                disableRipple
                label="Schedule Message"
                sx={{ color: "white", textTransform: "capitalize" }}
              />
              <Box sx={{ mx: 2, mt: 2 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="20"
                  viewBox="0 0 2 20"
                  fill="none"
                >
                  <path d="M1 0V40" stroke="white" strokeWidth="1" />
                </svg>
              </Box>
              <Tab
                disableRipple
                label="Notes"
                sx={{ color: "white", textTransform: "capitalize" }}
              />
            </Tabs>
          </Box>
          <Box
            style={{
              borderRadius: "10px",
              borderTop: "1px solid #FFF",
              marginTop: "14px",
              background:
                themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#0000004D",
            }}
          >
            <Box className="conversationBox">
              {messagesTabValue === 0 && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Box className="dateBox">
                      <Typography className="date">
                        Thursday, 21 July 2024
                      </Typography>
                    </Box>
                    <Box className="userConversationBox">
                      <Box className="userNameTimeBox">
                        <Typography className="userName">
                          Ben Metzker (You)
                        </Typography>
                        <Typography className="time">05:23</Typography>
                      </Box>
                      <Typography className="userConversation">
                        Hey Steven! I saw we have some similar connections.
                        Would love to connect!
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Box className="userConversationBox">
                      <Typography className="userName">
                        Ben Metzker (You)
                      </Typography>
                      <Typography className="userConversation">
                        Thanks for connecting Steven! The reason I am reaching
                        out, we developed a software specifically for the flood
                        and fire restoration industry to assist in booking in
                        person inspections. This is cutting edge and completely
                        new to the industry. Any chance you want to jump on a
                        zoom call and take a look at it?
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ p: 2 }}>
                    <Box className="dateBox">
                      <Typography className="date">
                        Saturday, 23 July 2024
                      </Typography>
                    </Box>
                    <Box className="customerConversationBox">
                      <Box className="userNameTimeBox">
                        <span className="userName">Steven Grant</span>
                        <Typography className="time">05:23</Typography>
                      </Box>
                      <Typography className="userConversation">
                        Hey. I’m no longer in restoration. Thanks tho
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
              {messagesTabValue === 2 && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Box className="dateBox">
                      <Typography className="date">
                        Scheduled on Thursday, 21 July 2024
                      </Typography>
                    </Box>
                    <Box className="userConversationSeheduledBox">
                      <Box className="userConversationBox">
                        <Box className="userNameTimeBox">
                          <Typography className="userName">
                            Ben Metzker (You)
                          </Typography>
                          <Typography className="time">05:23</Typography>
                        </Box>
                        <Typography className="userConversation">
                          Hey Steven! I saw we have some similar connections.
                          Would love to connect!
                        </Typography>
                      </Box>
                      <Typography
                        className="reminder-text"
                        sx={{ paddingLeft: "15px" }}
                      >
                        Will be delivered at 8pm, 02 Sept 2024
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
              {messagesTabValue === 4 && (
                <>
                  <Box sx={{ p: 2 }}>
                    <Typography
                      className={themeMode === "dark" ? "notes" : "notes-light"}
                      sx={{ paddingLeft: "15px" }}
                    >
                      No notes are created for this prospect.
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <Box sx={{ p: 2 }}>
              <Box className="actionBox">
                <TextField
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={messageText}
                  onChange={(event) => {
                    setMessageText(event.target.value);
                  }}
                  placeholder="Type your message..."
                  className="custom-textfield"
                  InputProps={{
                    style: {
                      fontSize: "12px", // Change font size here
                    },
                    disableUnderline: true,
                  }}
                />
                <Box className="flex-container">
                  <Box className="flex-left">
                    {messagesTabValue === 0 && (
                      <>
                        <Checkbox className="remind-check-box" />
                        <Typography variant="body2" className="reminder-text">
                          In case of no reply, remind me.
                        </Typography>
                      </>
                    )}
                    {messagesTabValue === 2 && (
                      <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box className="custom-datetime-picker">
                            <DateTimePicker
                              label="Select Time"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Box>
                        </LocalizationProvider>
                      </>
                    )}
                  </Box>
                  <Box className="flex-right">
                    {messagesTabValue === 0 && (
                      <>
                        <Button
                          // disabled
                          variant="outlined"
                          startIcon={<SmartToyIcon />}
                          className="smart-reply-btn"
                        >
                          Smart Reply
                        </Button>
                      </>
                    )}
                    {messagesTabValue === 0 ||
                      (messagesTabValue === 2 && (
                        <>
                          <Button
                            variant="outlined"
                            endIcon={<ArrowDropDownIcon />}
                            className="quick-response-btn"
                          >
                          Quick Reply
                          </Button>
                        </>
                      ))}
                    {messagesTabValue === 0 && (
                      <>
                        <Button variant="contained" className="send-btn">
                          <span style={{ fontSize: "13px" }}>Send</span>
                        </Button>
                      </>
                    )}
                    {messagesTabValue === 2 && (
                      <>
                        <Button variant="contained" className="send-btn">
                          Schedule Message
                        </Button>
                      </>
                    )}
                    {messagesTabValue === 4 && (
                      <>
                        <Button variant="contained" className="send-btn">
                          Send
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={themeMode === "dark" ? "right-div" : "right-div-light"}>
          <Box className="custom-avatar">
            <Avatar
              sx={{
                width: 81,
                height: 81,
                bgcolor: "#350000",
              }}
            ></Avatar>
          </Box>
          <Box
            className={`customerName ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            <span
              className={`customerName ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
            >
              Steven Grant
            </span>
          </Box>
          <Box
            className={`cutomerInfo ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            <Typography
              className={`cutomerInfo ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
            >
              Triad Manager @ Chadwell Supply
            </Typography>
          </Box>
          <Box sx={{ width: "100%", padding: "20px" }}>
            <Stepper
              activeStep={conversationSteps}
              alternativeLabel
              connector={<QontoConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": {
                        color:
                          themeMode === "dark"
                            ? "white !important"
                            : "#000 !important",
                      },
                      "& .MuiStepIcon-root": {
                        //color: index === conversationSteps ? '#00FF19' : '#4A4A4A',
                        color: themeMode === "dark" ? "#00FF19" : "#03660D",
                      },
                      "& .MuiStepIcon-root.Mui-completed": {
                        color: themeMode === "dark" ? "#00FF19" : "#03660D",
                      },
                      "& .MuiStepIcon-root.Mui-active": {
                        color: themeMode === "dark" ? "#00FF19" : "#03660D",
                      },
                      "& .MuiStepIcon-text": {
                        display: "none",
                      },
                    }}
                  >
                    <span style={{ fontSize: "13px" }}> {label}</span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box className="marked-btn-box">
            <Button
              variant="contained"
              className={
                themeMode === "dark" ? "marked-btn" : "marked-btn-light"
              }
            >
              Marked as Meeting Booked
            </Button>
          </Box>
          <Box className="center-box">
            <Button
              className={`set-reminder-txt mt-2 ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
            >
              Set Reminder
            </Button>
          </Box>
          <Box>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: themeMode === "dark" ? "#fff" : "#000",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{ color: themeMode === "dark" ? "#fff" : "#000" }}
                  />
                }
              >
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontSize: "14px",
                  }}
                >
                  Prospect Info
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List
                  sx={{ padding: 0, "& .MuiListItem-root": { paddingY: 0 } }}
                >
                  <ListItem>
                    <ListItemText
                      primary="Position: Triad Manager"
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        },
                      }}
                      className={
                        themeMode === "dark"
                          ? "custom-accordian-item"
                          : "custom-accordian-item-light"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Company: Chadwell Supply"
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        },
                      }}
                      className={
                        themeMode === "dark"
                          ? "custom-accordian-item"
                          : "custom-accordian-item-light"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        },
                      }}
                      primary="Email: Sgrant567@gmail.com"
                      className={
                        themeMode === "dark"
                          ? "custom-accordian-item"
                          : "custom-accordian-item-light"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontFamily: "Poppins",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        },
                      }}
                      primary="Phone: 336 557 889"
                      className={
                        themeMode === "dark"
                          ? "custom-accordian-item"
                          : "custom-accordian-item-light"
                      }
                    />
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box>
            <Accordion
              sx={{
                backgroundColor: "transparent",
                color: themeMode === "dark" ? "#fff" : "#000",
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    sx={{
                      color: themeMode === "dark" ? "#fff" : "#000",
                    }}
                  />
                }
              >
                <Typography
                  sx={{
                    color: themeMode === "dark" ? "#fff" : "#000",
                    fontSize: "14px",
                  }}
                >
                  Tags
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List
                  sx={{ padding: 0, "& .MuiListItem-root": { paddingY: 0.01 } }}
                ></List>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default MessageShow;
