import React, { useEffect, useState } from 'react';
import './iceberg.css';
import { Button, Grid, Typography, CircularProgress } from '@mui/material';
import { useSelector } from "react-redux";
import theme from "../../../Theme/theme";
const iceberg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/iceberg.png"
const blackBkg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/blackBkg.png"
const whiteBkg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/whiteBkg.png"

export const CampaignIceberg = ({ iceberg_stats }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);
    const [loading, setLoading] = useState(true); 


    useEffect(() => {
        if (iceberg_stats) {
            setLoading(false);
        }
    }, [iceberg_stats]);

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '400px' }}>
                <CircularProgress size={80} sx={{ color: themeMode === "dark" ? "#FFF" : "#FF0000" }} />  
            </Grid>
        );
    }

    return (
        <Grid
            sx={{
                backgroundImage: themeMode === "dark" ? `url(${blackBkg})` : `url(${whiteBkg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "left center", 
                width: '100%',
                height: "400px",
                padding: '35px 10px',
                color: themeMode==="dark"?'white':"#000",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center", 
                flexDirection: "row", 
                boxSizing: "border-box",
                position: "relative",
                transform: {md:"scale(1.02)",sm:"scale(1)"},
                borderRadius: { md: "", sm: "", xs: "30px" },
                borderRight: { md: "1px solid transparent", sm: "1px solid transparent", xs: "1px solid #FFF" },
                backgroundSize: { md: "cover", lg: "100% 100%", xl: "100% 100%", xs: "contain", sm: "100% 100%" },
                [theme.breakpoints.down(1200)]: {
                    backgroundSize: "100% 100%", 
                },
                // backgroundSize: "contain",
                // [theme.breakpoints.down(1200)]: {
                //     backgroundSize: "100% 100%",
                // },
                [theme.breakpoints.down(600)]: {
                    backgroundSize: "cover",
                }
            }}>
            <Grid className="iceberg-text" 
            sx={{
                width:"100%",
                transform:{md:"scale(0.9)",sm:"scale(0.9)",xs:"scale(1)"},
                backgroundImage: `url(${iceberg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: {md:"contain",sm:"contain",xs:"60% 70%"},
                backgroundPosition: {md:"100% 100%",sm:"100% 100%",xs:"right bottom"},
        }}>
                <Typography 
                    sx={{ 
                        fontSize: "20px", fontWeight: "700", 
                        width: { md: "100%", sm: "72%", xs: "100%" },
                        paddingBottom: "10px", 
                        marginBottom: { md: "0px", sm: "10px", xs: "20px" }, 
                        borderBottom: themeMode === "dark" ? "0.5px solid #FFFFFF" : "0.5px solid #000", 
                    }}>
                    Prospecting Campaign Iceberg
                </Typography>
                <Grid sx={{ fontSize: { md: "16px", sm: "16px", xs: "10px" }, padding: { md: "5px 0px 15px", sm: "10px 0px 15px", xs: "10px 0px" }, }}>
                    <p>Connection Invitations<br /> Sent: {iceberg_stats?.connection_invitations_sent} </p>
                </Grid>
                <Grid sx={{ fontSize: { md: "16px", sm: "16px", xs: "10px" }, padding: { md: "5px 0px 15px 0px", sm: "10px 0px 15px 0px", xs: "10px 0px" }, borderTop: themeMode === "dark" ? "0.5px solid #FFFFFF" : "0.5px solid #000", }}>
                    <p>New Connections:<br /> {iceberg_stats?.new_connections?.count} {iceberg_stats?.new_connections?.percentage ? `(${iceberg_stats?.new_connections?.percentage}%)` : '' }</p>
                </Grid>
                <Grid sx={{ fontSize: { md: "16px", sm: "16px", xs: "10px" }, padding: "5px 0px 13px 0px", borderTop:themeMode === "dark" ? "0.5px solid #FFFFFF" : "0.5px solid #000",  }}>
                    <p>Replies:<br /> {iceberg_stats?.replies?.count} {iceberg_stats?.replies?.percentage ? `(${iceberg_stats?.replies?.percentage}%)` : '' }</p>
                </Grid>
                <Grid sx={{ fontSize: { md: "16px", sm: "16px", xs: "10px" }, padding: "5px 0px 13px 0px", borderTop:themeMode === "dark" ? "0.5px solid #FFFFFF" : "0.5px solid #000", }}>
                    <p>Meetings Booked:<br /> {iceberg_stats?.meetings_booked?.count} {iceberg_stats?.meetings_booked?.percentage ? `(${iceberg_stats?.meetings_booked?.percentage}%)` : '' }</p>
                </Grid>
            </Grid>
        </Grid>
    );
}


