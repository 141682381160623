import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Dot,
  CartesianGrid,
} from "recharts";

function transformData(input) {
  const result = [];

  // Step 1: Gather all unique days
  const uniqueDays = new Set();
  input?.forEach((metric) => {
    metric.data.forEach((item) => {
      uniqueDays.add(item.day);
    });
  });

  // Step 2: Iterate over each unique day and create the required structure
  uniqueDays?.forEach((day) => {
    const obj = {
      name: day,
      connectionRate: 0,
      replyRate: 0,
      searchListChange: 0,
      messageChange: 0,
    };

    // Step 3: Populate data for each metric on the specific day
    input?.forEach((metric) => {
      const foundData = metric.data.find((item) => item.day === day);

      if (foundData) {
        if (metric.name === "Connection Rate") {
          obj.connectionRate = foundData.percentage;
        } else if (metric.name === "Reply Rate") {
          obj.replyRate = foundData.percentage;
        } else if (metric.name === "Change in Search List") {
          obj.searchListChange = foundData.percentage;
        } else if (metric.name === "Change in Messages") {
          obj.messageChange = foundData.percentage;
        }
      }
    });

    result.push(obj);
  });

  // Sort the result by day if needed (optional, assuming days are in order already)
  return result;
}

const data = [
  {
    name: "Jul 10",
    connectionRate: 30,
    replyRate: 20,
    searchListChange: 0,
    messageChange: 0,
  },
  {
    name: "Jul 15",
    connectionRate: 40,
    replyRate: 0,
    searchListChange: 10,
    messageChange: 0,
  },
  {
    name: "Jul 20",
    connectionRate: 20,
    replyRate: 15,
    searchListChange: 0,
    messageChange: 0,
  },
  {
    name: "Jul 25",
    connectionRate: 45,
    replyRate: 30,
    searchListChange: 20,
    messageChange: 20,
  },
  {
    name: "Aug 05",
    connectionRate: 15,
    replyRate: 20,
    searchListChange: 0,
    messageChange: 30,
  },
  {
    name: "Aug 10",
    connectionRate: 20,
    replyRate: 25,
    searchListChange: 0,
    messageChange: 9,
  },
  {
    name: "Aug 15",
    connectionRate: 40,
    replyRate: 10,
    searchListChange: 15,
    messageChange: 0,
  },
];

const CustomDot = (props) => {
  const { cx, cy, value, stroke } = props;
  if (value !== 0) {
    return <Dot cx={cx} cy={cy} r={6} fill={stroke} />;
  }
  return null;
};

const ImpactChart = ({ campaign }) => {
  const isMobile = window.innerWidth <= 320;
  const themeMode = useSelector((state) => state.themeMode.mode);
  const CustomLegend = ({ campaign }) => (
    <Grid
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
        color: "#FFFFFF",
        flexDirection: { md: "row", sm: "row", xs: "column" },
      }}
    >
      <div
        style={{
          marginRight: "20px",
          display: "flex",
          alignItems: "center",
          fontSize: "10px",
          color: themeMode === "dark" ? "#FFF" : "#000",
        }}
      >
        <span
          style={{
            display: "inline-block",
            width: "23px",
            height: "5px",
            backgroundColor: themeMode === "dark" ? "#00FFFF" : "#00B7D0",
            marginRight: "5px",
          }}
        ></span>
        Connection Rate
      </div>
      <div
        style={{
          marginRight: "20px",
          display: "flex",
          alignItems: "center",
          fontSize: "10px",
          color: themeMode === "dark" ? "#FFF" : "#000",
        }}
      >
        <span
          style={{
            display: "inline-block",
            width: "23px",
            height: "5px",
            backgroundColor: themeMode === "dark" ? "#00FF00" : "#00FF29",
            marginRight: "5px",
          }}
        ></span>
        Reply Rate
      </div>
      <div
        style={{
          marginRight: "20px",
          display: "flex",
          alignItems: "center",
          fontSize: "10px",
          color: themeMode === "dark" ? "#FFF" : "#000",
        }}
      >
        <span
          style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: "#FF0000",
            borderRadius: "50%",
            marginRight: "5px",
          }}
        ></span>
        Change in Search List
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "10px",
          color: themeMode === "dark" ? "#FFF" : "#000",
        }}
      >
        <span
          style={{
            display: "inline-block",
            width: "10px",
            height: "10px",
            backgroundColor: "#0000FF",
            borderRadius: "50%",
            marginRight: "5px",
          }}
        ></span>
        Change in Messages
      </div>
    </Grid>
  );

  return (
    <div
      style={{
        padding: "20px 30px",
        backgroundColor: themeMode === "dark" ? "#00000099" : "#DEDEDE99",
        border: "1px solid #FFF",
        borderRadius: "30px",
        boxShadow: themeMode === "dark" ? "" : "4px 4px 4px 0px #00000014",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { md: "row", sm: "row", xs: "column" },
        }}
      >
        <div>
          <h2
            style={{
              color: themeMode === "dark" ? "#FFF" : "#000",
              fontSize: "20px",
              fontWeight: "700",
            }}
          >
            {campaign.summary_stats?.impact_in_last_month?.chartTitle}
          </h2>
          <p
            style={{
              color: themeMode === "dark" ? "#FFFFFFB2" : "#000",
              marginTop: "25px",
              marginBottom: "20px",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            {campaign?.summary_stats?.impact_in_last_month?.chartSubtitle}
          </p>
        </div>
        <CustomLegend campaign={campaign} />
      </Grid>
      <ResponsiveContainer width="100%" height={isMobile ? 200 : 300}>
        <LineChart
          data={transformData(
            campaign.summary_stats?.impact_in_last_month?.series
          )}
          margin={{ top: 5, right: 10, left: isMobile ? 0 : 20, bottom: 5 }}
          padding={{ left: isMobile ? 0 : "" }}
        >
          <CartesianGrid
            strokeDasharray="0 0"
            horizontal={true}
            vertical={false}
          />
          <XAxis
            dataKey="name"
            stroke={themeMode === "dark" ? "#FFFFFF" : "#000"}
          />
          <YAxis
            stroke={themeMode === "dark" ? "#FFFFFF" : "#000"}
            tickFormatter={(value) => `${value}%`}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: themeMode === "dark" ? "#2D0D0D" : "#FF7074",
              borderRadius: "10px",
              border:
                themeMode === "dark" ? "1px solid #ccc" : "1px solid #000",
              color: themeMode === "dark" ? "#fff" : "#000",
            }}
            itemStyle={{ color: themeMode === "dark" ? "#fff" : "#000" }}
            cursor={{ fill: "rgba(255, 255, 255, 0.1)" }}
          />
          <Line
            type="linear"
            dataKey="connectionRate"
            name="Connection Rate"
            stroke={themeMode === "dark" ? "#00FFFF" : "#00B7D0"}
            strokeWidth={3}
            activeDot={{ r: 8 }}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="replyRate"
            name="Reply Rate"
            stroke={themeMode === "dark" ? "#00FF00" : "#00FF29"}
            strokeWidth={3}
            activeDot={{ r: 8 }}
            dot={false}
          />
          <Line
            type="linear"
            dataKey="searchListChange"
            name="Change in Search List"
            stroke="transparent"
            dot={<CustomDot stroke="#FF0000" />}
          />
          <Line
            type="linear"
            dataKey="messageChange"
            name="Change in Messages"
            stroke="transparent"
            dot={<CustomDot stroke="#0000FF" />}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ImpactChart;
