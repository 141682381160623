import React, { useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ToolbarList } from "./toolbarList";
import { useSelector } from "react-redux";

const Toolbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hovered, setHovered] = useState(false);
  const themeMode = useSelector((state) => state.themeMode.mode);
  return (
    <>
      <Grid className="sideBar" pt={4} sx={{ height: "calc(100vh - 62px)", }}>
        <Grid className="itemList" item sx={{ overflowY: "auto" }}>
          {ToolbarList.map((item, index) => (
            <Button
              disableRipple
              key={item.id}
              className="sideBtn"
              onClick={() => navigate(item.link)}
            >
              <Grid
                item
                md={12}
                className="ball"
                onMouseEnter={() => setHovered(index)}
                onMouseLeave={() => setHovered(null)}
                sx={{
                  boxShadow:
                    themeMode === "dark"
                      ? location.pathname.startsWith(item.link)
                        ? "0px 0px 6px 3px #F9E74D"
                        : "0px 4px 4px 0px #00000040"
                      : location.pathname.startsWith(item.link)
                      ? "0px 0px 6px 3px #BE0000"
                      : "0px 4px 4px 0px #00000040",
                  backgroundColor:
                    themeMode === "dark"
                      ? location.pathname.startsWith(item.link)
                        ? "#F9E74D"
                        : "#52000099"
                      : location.pathname.startsWith(item.link)
                      ? "#BE0000"
                      : "#D4D4D4",
                  "&:hover": {
                    border: themeMode === "dark" ? "0.5px solid #f7e334" : "",
                    background: themeMode === "dark" ? "#f9e74d" : "#BE0000",
                  },
                }}
              >
                <Grid className="toolImg">
                  <div
                    loading="lazy"
                    className="w300">
                    {
                      themeMode === "dark"
                        ? hovered === index ||
                          location.pathname.startsWith(item.link)
                          ? item.imgD
                          : item.img
                        : hovered === index ||
                          location.pathname.startsWith(item.link)
                        ? item.imgW
                        : item.imgD
                    }
                  </div>
                </Grid>
              </Grid>
              <Grid item md={12} className="alignCenter">
                <Typography
                  className="name"
                  sx={{
                    color:
                      themeMode === "dark"
                        ? hovered === index ||
                          location.pathname.startsWith(item.link)
                          ? "#FFF"
                          : "#FFFFFF80"
                        : 
                          hovered === index ||
                          location.pathname.startsWith(item.link)
                          ? "#BE0100"
                          : "#4D4D4D"
                        ,
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
            </Button>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
export default Toolbar;
