import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ArrowUpward } from "@mui/icons-material";
import { useSelector } from "react-redux";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FileUploadButton({ setValue }) {
  const themeMode = useSelector((state) => state.themeMode.mode);

  const getFile = () => {
    const fileInput = document.getElementById("fileInput");

    // Check if any file is selected
    if (fileInput.files.length > 0) {
      // Get the selected file
      const file = fileInput.files[0];

      // Do something with the file
      console.log("Selected file:", file);

      // For example, you can read the file content using FileReader
      const reader = new FileReader();
      reader.onload = function (event) {
        const base64Image = reader.result;
        setValue("image_data_uri", base64Image); 
      };
      reader.readAsDataURL(file); 
    } else {
      console.log("No file selected");
    }
  };

  return (
    <Button
      component="label"
      role={undefined}
      variant="outlined"
      tabIndex={-1}
      className="py-2 px-4"
      startIcon={<ArrowUpward />}
      style={{
        borderRadius: "30px",
        border:themeMode==="dark"? "1px solid white":"1px solid #000",
        color: themeMode === "dark" ? "white" : "#000",
        textTransform: "none",
      }}
    >
      Upload
      <VisuallyHiddenInput
        id="fileInput"
        type="file"
        onChange={(e) => getFile()}
        accept="image/*" 
        name="Image"
      />
    </Button>
  );
}
