import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tabs,
  Tab,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import MeetingTable from "./MeetingTable";
import { useDispatch, useSelector } from "react-redux";
import DatePopover from "./DatePopover";
import { SearchOutlined } from "@mui/icons-material";
import { useEffect } from "react";
import {
  getMeetings,
  setMeetingEndDate,
  setMeetingQuery,
  setMeetingStartDate,
} from "../../store/meetingSlice";
import MessageDialog from "../messages/messageShow/MessageDialog";
import moment from "moment";

export const Meetings = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [inputvalue, setInputValue] = useState("");
  const meetingStartDate = useSelector(
    (state) => state.meeting.meetingStartDate
  );
  const meetingEndDate = useSelector((state) => state.meeting.meetingEndDate);

  const themeMode = useSelector((state) => state.themeMode.mode);
  const handleChange = (event) => {
    setType(event.target.value);
  };

  const [messageId, setMessageId] = useState(null);
  const [openMessageShow, setOpenMessageShow] = useState(null);

  const handleOpenMessage = (message) => {
    setMessageId(message.id);
    setOpenMessageShow(true);
  };

  const handleCloseMessage = () => {
    setMessageId(null);
    setOpenMessageShow(false);
  };

  const [value, setValue] = useState("one");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getMeetings());
  }, []);

  return (
    <div
      className={themeMode === "dark" ? "dashboard-bg" : "dashboard-bg-light"}
    >
      <div className="py-24 md:px-16 px-8">
        <div
          className={
            themeMode === "dark" ? "dashboard-title" : "dashboard-title-light"
          }
        >
          Meetings Booked
        </div>
        <div
          className={
            themeMode === "dark"
              ? "dashboard-subtitle py-4"
              : "dashboard-subtitle-light py-4"
          }
        >
          View and manage your booked meetings with connections in this page.{" "}
        </div>
        <div className="lg:flex justify-between items-center">
          <div className="lg:flex items-center lg:space-x-6 w-full space-y-6 lg:space-y-0">
            <OutlinedInput
              value={inputvalue}
              onChange={(e) => {
                setInputValue(e.target.value);
                dispatch(setMeetingQuery(e.target.value));
                dispatch(
                  getMeetings({
                    query: e.target.value,
                    start_date: meetingStartDate
                      ? moment(meetingStartDate).format(
                          "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        )
                      : null,
                    end_date: meetingEndDate
                      ? moment(meetingEndDate).format(
                          "YYYY-MM-DDTHH:mm:ss.SSSZ"
                        )
                      : null,
                  })
                );
              }}
              id="outlined-adornment-password"
              className={
                themeMode === "dark"
                  ? "dashboard-field"
                  : "dashboard-field-light"
              }
              size="small"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "& .MuiInputBase-input": {
                  color: themeMode === "dark" ? "white" : "black",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <SearchOutlined
                      style={{
                        color: themeMode === "dark" ? "gray" : "darkgray",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Search"
            />
            <DatePopover />
            <Button
              onClick={() => {
                setInputValue("");
                dispatch(setMeetingStartDate(null));
                dispatch(setMeetingEndDate(null));
                dispatch(getMeetings());
              }}
              className={
                themeMode === "dark"
                  ? "dashboard-clear-filters cursor-pointer"
                  : "dashboard-clear-filters-light cursor-pointer"
              }
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: { md: "15px", sm: "15px", xs: "0px" },
                color: "#FFF",
                "&:hover": { background: "transparent" },
              }}
            >
              Clear Filters
            </Button>
          </div>
          <div className="flex w-full lg:w-1/3 justify-end"></div>
        </div>

        <div
          className={
            themeMode === "dark"
              ? "p-4 my-8 dashboard-table-layout"
              : "p-4 my-8 dashboard-table-layout-light"
          }
        >
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="secondary tabs example"
            sx={{
              "& .MuiTab-root": {
                color: "white",
                fontWeight: "bold",
                color: themeMode === "dark" ? "#FFF" : "black",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textTransform: "none",
              },
              "& .MuiTab-root.Mui-selected": {
                color: themeMode === "dark" ? "#FFF" : "black",
                fontWeight: "bold",
                color: themeMode === "dark" ? "#FFF" : "black",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              },
              "& .MuiTabs-indicator": {
                backgroundColor:
                  themeMode === "dark" ? "whitesmoke" : "#0C0404",
              },
            }}
          >
            <Tab value="one" label="Confirmed Meetings" />
            <Tooltip title="Feature will be available soon" arrow>
              {" "}
              <Tab disabled value="two" label="AI Detected" />
            </Tooltip>
          </Tabs>

          <div className="py-8">
            {value === "one" && (
              <MeetingTable openMessageShow={handleOpenMessage} />
            )}
            {value === "two" && (
              <MeetingTable openMessageShow={handleOpenMessage} />
            )}
          </div>
        </div>
      </div>
      <MessageDialog
        openMessageShow={openMessageShow}
        closeMessageShow={handleCloseMessage}
        messageId={messageId}
      />
    </div>
  );
};
