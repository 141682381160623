import {
  Grid,
  Button,
  InputAdornment,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import QuickResponsesTable from "./QuickResponsesTable";
import CreateQuickResponseDialog from "./CreateQuickResponseDialog";
import { useDispatch, useSelector } from "react-redux";
import { getQuickResponses } from "../../../store/settingsSlice";
import EditQuickResponseDialog from "./EditQuickResponseDialog";
import { getCampaigns } from "../../../store/campaignSlice";

export const QuickResponses = () => {
  const dispatch = useDispatch();
  const quickResponses = useSelector((state) => state.settings.quickResponses);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    dispatch(getCampaigns());
  }, []);

  return (
    // <div className="settings-content-layout">
    <div>
      <div
        className={`settings-heading ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Quick Replies
      </div>
      <div className="py-6">
        <div className="flex items-center">
          <div
            className={`linkedin-secondary-text ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Set up response templates for your most common replies! Once set up,
            these "Quick Reply" messages will be saved in your inbox, making
            it easy to click and send your proven responses to prospects in a
            few seconds. You can always edit before sending.
            <br />
            <br /> {"  "}
            <div className="lg:flex lg:w-2/3 justify-between items-center">
              <div>
                <div className="font-semibold" style={{ fontSize: "13px" }}>
                  Best practices:
                </div>
                <div className="pl-6">
                  <ul>
                    <li>Keep templates short and conversational</li>
                    <li>Ask for emails and numbers</li>
                    <li>Always include clear next steps</li>
                    <li>Include your calendar link</li>
                  </ul>
                </div>
              </div>

              <div>
                <div className="font-semibold" style={{ fontSize: "13px" }}>
                  Best use cases:
                </div>
                <div className="pl-6">
                  <ul>
                    <li>Interested later</li>
                    <li>Interested now</li>
                    <li>Not interested</li>
                    <li>Neutral response</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex w-full justify-between items-center mt-12 mb-4"> */}
        <Grid
        //</div>
        //className="flex w-full justify-between items-center mt-12 mb-4"
        className="mt-12 mb-4"
        sx={{display:"flex",width:"100%",flexDirection:{md:"row",sm:"column",xs:"column"},justifyContent:"space-between",gap:"15px"}}
        >
          <div
            className={`total-tags  ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
            style={{display:"flex",alignItems:"center"}}
          >
            Total Quick Reply Messages:{"  "}
            {quickResponses?.length < 10
              ? `0${quickResponses?.length}`
              : quickResponses?.length}
          
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            id="outlined-adornment-password"
            className={
              themeMode === "dark" ? "dashboard-field mx-2" : "dashboard-field-light mx-2"
            }
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .MuiInputBase-input": {
                color: themeMode === "dark" ? "white" : "black",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M5.2 0C6.57913 0 7.90177 0.547855 8.87696 1.52304C9.85215 2.49823 10.4 3.82087 10.4 5.2C10.4 6.488 9.928 7.672 9.152 8.584L9.368 8.8H10L14 12.8L12.8 14L8.8 10V9.368L8.584 9.152C7.672 9.928 6.488 10.4 5.2 10.4C3.82087 10.4 2.49823 9.85215 1.52304 8.87696C0.547855 7.90177 0 6.57913 0 5.2C0 3.82087 0.547855 2.49823 1.52304 1.52304C2.49823 0.547855 3.82087 0 5.2 0ZM5.2 1.6C3.2 1.6 1.6 3.2 1.6 5.2C1.6 7.2 3.2 8.8 5.2 8.8C7.2 8.8 8.8 7.2 8.8 5.2C8.8 3.2 7.2 1.6 5.2 1.6Z"
                      fill="white"
                    />
                  </svg>{" "}
                </IconButton>
              </InputAdornment>
            }
            placeholder="Search"
          />
          </div>
          <Button
            className={`p-2 px-4 ${
              themeMode === "dark" ? "create-button" : "create-button-light"
            }`}
            variant="contained"
            onClick={handleClickOpen}
          >
            <span className="create-button-text">Create a Quick Reply</span>
          </Button>
        {/* </div> */}
        </Grid>
        <div
          className={`px-8 py-2 my-6 ${
            themeMode === "dark"
              ? "dashboard-table-layout"
              : "dashboard-table-layout-light"
          }`}
        >
          <QuickResponsesTable handleClickOpen={handleClickOpenEdit} searchQuery={searchQuery} />
        </div>
      </div>
      <CreateQuickResponseDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
      <EditQuickResponseDialog
        open={openEdit}
        handleClose={handleCloseEdit}
        handleClickOpen={handleClickOpenEdit}
      />
    </div>
  );
};
