import { Typography } from "@mui/material";
import HelpAccordian from "./HelpAccordian";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getFaqs } from "../../store/homeSlice";
import { Link } from "react-router-dom";

export const Faqs = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getFaqs());
  }, []);

  return (
    <div className="lg:flex py-8">
      <div>
        <div className="help-heading">Help Center</div>
        <div className="help-title">Frequently asked questions</div>
        <div className="flex space-x-2 pt-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
          >
            <path
              d="M10 0C4.5 0 0 3.58 0 8C0.05 10.15 1.06 12.17 2.75 13.5C2.75 14.1 2.33 15.67 0 18C2.37 17.89 4.64 17 6.47 15.5C7.61 15.83 8.81 16 10 16C15.5 16 20 12.42 20 8C20 3.58 15.5 0 10 0ZM10 14C5.58 14 2 11.31 2 8C2 4.69 5.58 2 10 2C14.42 2 18 4.69 18 8C18 11.31 14.42 14 10 14Z"
              fill="white"
            />
          </svg>
          <div>
            <div className="help-subtitle">
              Still need help?{" "}
              <Link to="/contact" className="help-contact">
                Contact us
              </Link>{" "}
            </div>
            <div className="help-content">
              Note: Live chat feature. Coming Soon!
            </div>
          </div>
        </div>
      </div>
      <HelpAccordian />
    </div>
  );
};
