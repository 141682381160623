import { Button, Grid, Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OnboardingNav = ({ selected }) => {
  const navigate = useNavigate();
  return (
    <Grid
      sx={{
        display: "flex",
        width: { md: "75%", sm: "85%", xs: "95%" },
        justifyContent: "space-between",
        position: "absolute",
        top: "30px",
        overflowX: { md: "hidden", sm: "auto", xs: "auto" },
      }}
    >
      <Divider
        orientation="horizontal"
        sx={{
          width: "100%",
          height: "1px",
          background: "#FFF",
          position: "absolute",
          top: "20%",
          zIndex: 1,
        }}
      />

      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={() => navigate("/welcome")}
          disableRipple
          sx={{
            minWidth: "0px",
            padding: "0px",
            width: { md: "26px", sm: "26px", xs: "15px" },
            height: { md: "26px", sm: "26px", xs: "15px" },
            background: "#FFF",
            borderRadius: "50%",
            zIndex: 2,
            "&:hover": { background: "#FFF" },
          }}
        />
        <Typography
          mt={1}
          sx={{
            position: "relative",
            right: { md: "0%", sm: "0%", xs: "0%" },
            fontWeight: "700",
            fontSize: { md: "16px", sm: "16px", xs: "8px" },
            textWrap: "nowrap",
          }}
        >
          Welcome
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={() => {
            if (selected > 2) {
              navigate("/confirm-details");
            }
          }}
          disableRipple
          sx={{
            minWidth: "0px",
            padding: "0px",
            width: { md: "26px", sm: "26px", xs: "15px" },
            height: { md: "26px", sm: "26px", xs: "15px" },
            zIndex: 2,
            background: selected >= 2 ? "#FFF" : "#EC9080",
            borderRadius: "50%",
            "&:hover": { background: "#FFF" },
          }}
        />
        <Typography
          mt={1}
          sx={{
            fontWeight: selected >= 2 ? "700" : "",
            fontSize: { md: "16px", sm: "16px", xs: "8px" },
            textWrap: "nowrap",
          }}
        >
          Confirm Details
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button
          //onClick={() => { selected < 3 ? navigate("/set-password") : "" }}
          onClick={() => {
            if (selected > 3) {
              navigate("/set-password");
            }
          }}
          disableRipple
          sx={{
            minWidth: "0px",
            padding: "0px",
            width: { md: "26px", sm: "26px", xs: "15px" },
            height: { md: "26px", sm: "26px", xs: "15px" },
            zIndex: 2,
            background: selected >= 3 ? "#FFF" : "#EC9080",
            borderRadius: "50%",
            "&:hover": { background: "#FFF" },
          }}
        />
        <Typography
          mt={1}
          sx={{
            fontWeight: selected >= 3 ? "700" : "",
            fontSize: { md: "16px", sm: "16px", xs: "8px" },
            textWrap: "nowrap",
          }}
        >
          Set Password
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button
          //onClick={() => navigate("/plan-subscription")}
          //onClick={() => { selected > 4 ? navigate("/plan-subscription") : "" }}
          onClick={() => {
            if (selected > 4) {
              navigate("/plan-subscription");
            }
          }}
          disableRipple
          sx={{
            minWidth: "0px",
            padding: "0px",
            width: { md: "26px", sm: "26px", xs: "15px" },
            height: { md: "26px", sm: "26px", xs: "15px" },
            zIndex: 4,
            background: selected >= 4 ? "#FFF" : "#EC9080",
            borderRadius: "50%",
            "&:hover": { background: "#FFF" },
          }}
        />
        <Typography
          mt={1}
          sx={{
            fontWeight: selected >= 4 ? "700" : "",
            fontSize: { md: "16px", sm: "16px", xs: "8px" },
            textWrap: "nowrap",
          }}
        >
          Subscription
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Button
          //onClick={() => navigate("/connect-linkedIn")}
          //onClick={() => { selected < 5 ? navigate("/connect-linkedIn") : "" }}
          onClick={() => {
            if (selected > 5) {
              navigate("/connect-linkedIn");
            }
          }}
          disableRipple
          //disabled={selected > 5 ? true : false}
          sx={{
            minWidth: "0px",
            padding: "0px",
            width: { md: "26px", sm: "26px", xs: "15px" },
            height: { md: "26px", sm: "26px", xs: "15px" },
            zIndex: 2,
            background: selected === 6 ? "#FFF" : "#EC9080",
            borderRadius: "50%",
            "&:hover": { background: "#FFF" },
          }}
        />
        <Typography
          mt={1}
          sx={{
            position: "relative",
            left: { md: "0%", sm: "0%", xs: "0%" },
            fontWeight: selected === 6 ? "700" : "",
            fontSize: { md: "16px", sm: "16px", xs: "8px" },
            textWrap: "nowrap",
          }}
        >
          Connect LinkedIn
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Button
          //onClick={() => navigate("/connect-linkedIn")}
          //onClick={() => { selected < 5 ? navigate("/connect-linkedIn") : "" }}
          onClick={() => {
            if (selected > 6) {
              navigate("/onboarding-complete");
            }
          }}
          disableRipple
          //disabled={selected > 5 ? true : false}
          sx={{
            minWidth: "0px",
            padding: "0px",
            width: { md: "26px", sm: "26px", xs: "15px" },
            height: { md: "26px", sm: "26px", xs: "15px" },
            zIndex: 2,
            background: selected === 7 ? "#FFF" : "#EC9080",
            borderRadius: "50%",
            "&:hover": { background: "#FFF" },
          }}
        />
        <Typography
          mt={1}
          sx={{
            position: "relative",
            left: { md: "0%", sm: "0%", xs: "0%" },
            fontWeight: selected === 7 ? "700" : "",
            fontSize: { md: "16px", sm: "16px", xs: "8px" },
            textWrap: "nowrap",
          }}
        >
          Finish Onboarding
        </Typography>
      </Grid>
    </Grid>
  );
};
export default OnboardingNav;
