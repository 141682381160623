import {
    Box,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

const PreferenceOptimization = ({ setSelectedTab }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);

    return (
        <>
            <Box
                sx={{
                    height: '100vh',
                    padding: '20px',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 600,
                        padding: '20px',
                        color: '#fff',
                        marginTop: '20px',
                    }}
                >
                    <Typography variant="h5" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black' }}>
                        Initial AI Optimization Setup
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black' }}>
                        Who is the target audience?
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        className={
                            themeMode === "dark"
                                ? "create-campaign-dialog-field w-full"
                                : "create-campaign-dialog-field-light w-full"
                        }
                        sx={{
                            "& .MuiInputBase-input": {
                                color: themeMode === "dark" ? "white" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                    color: themeMode === "dark" ? "white" : "black",
                                },
                            },
                        }}
                    />
                    <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: 2 }}>
                        What are you trying to achieve with the outreach?
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        multiline
                        rows={4}
                        className={
                            themeMode === "dark"
                                ? "create-campaign-dialog-field w-full"
                                : "create-campaign-dialog-field-light w-full"
                        }
                        sx={{
                            "& .MuiInputBase-input": {
                                color: themeMode === "dark" ? "white" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                    color: themeMode === "dark" ? "white" : "black",
                                },
                            },
                        }}
                    />
                    <Box sx={{ marginTop: 3 }}>
                        <Button
                            variant="contained"
                             sx={{
                                 textTransform:"none",
                             }}
                            className={
                                themeMode === "dark"
                                    ? "create-button px-9"
                                    : "create-button-light px-9"
                            }
                            onClick={() => setSelectedTab(8)}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
export default PreferenceOptimization;