import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { success } from "../../../store/alertSlice";
import {
  deleteNote,
  getChat,
  getNotes,
  setNotes,
} from "../../../store/messagesSlice";
import { Delete } from "@mui/icons-material";

export const Notes = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [inputValue, setInputValue] = useState("");
  const openedChat = useSelector((state) => state.messages.openedChat);

  useEffect(() => {
    dispatch(getNotes(openedChat.uuid));
  }, [dispatch]);

  return (
    <Box
      className="  flex flex-col justify-between h-screen"
      sx={{ maxHeight: { lg: "450px", md: "450px", sm: "600px", xs: "600px" } }}
    >
      <div className="" style={{ overflowY: "auto" }}>
        {openedChat?.notes?.length > 0 ? (
          openedChat?.notes?.map((note) => (
            <Box sx={{ p: 1 }}>
              <Box className="userConversationBox flex justify-between w-5/6">
                <Typography className="received-text">
                  {note.content}
                </Typography>
                <Typography className="received-text">
                  <Delete
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(
                        deleteNote({ id: openedChat.uuid, noteId: note.uuid })
                      ).then((resp) => {
                        if (resp?.payload?.status === 200) {
                          dispatch(success("Note deleted"));
                          dispatch(getChat(openedChat?.uuid));
                          dispatch(getNotes(openedChat.uuid));
                        }
                      });
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          ))
        ) : (
          <Box sx={{ p: 2 }}>
            <Typography
              className={themeMode === "dark" ? "notes" : "notes-light"}
              sx={{ paddingLeft: "15px" }}
            >
              No notes are created for this prospect.
            </Typography>
          </Box>
        )}
      </div>
      {/* Second div aligned at the bottom */}
      <Box className="actionBox mx-2 mt-4  ">
        <TextField
          variant="outlined"
          id="message"
          fullWidth
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          multiline
          rows={4}
          placeholder="Type your message..."
          className="custom-textfield"
          InputProps={{
            style: {
              fontSize: "12px",
            },
            disableUnderline: true,
          }}
        />
        <Box className="flex-container">
          <Box className="flex-left">
            <></>
          </Box>
          <Box className="flex-right">
            <></>

            <>
              <Button
                variant="contained"
                style={{ backgroundColor: "#FF000099" }}
                className="smart-reply-btn py-2 m-0"
                disabled={inputValue === ""}
                onClick={(e) => {
                  dispatch(
                    setNotes({
                      id: openedChat.uuid,
                      content: inputValue,
                      // owner_type: "app_user",
                    })
                  ).then((resp) => {
                    if (resp?.payload?.status === 201) {
                      setInputValue("");
                      dispatch(success("Note saved"));
                      dispatch(getNotes(openedChat.uuid));
                      dispatch(getChat(openedChat?.uuid));
                    }
                  });
                }}
              >
                <span style={{ fontSize: "13px", color: "white" }}>Save</span>
              </Button>
            </>
          </Box>
        </Box>
      </Box>{" "}
      {/* 

       */}
    </Box>
  );
};
