import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, IconButton } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import {
  getSearchProspects,
  resumeAutomation,
  stopAutomation,
} from "../../../../store/campaignSlice";
import { success } from "../../../../store/alertSlice";

export default function OpenProfileTable() {
  const dispatch = useDispatch();
  const { id, name } = useParams();

  const searchProspects = useSelector(
    (state) => state.campaign.searchProspects
  );
  const themeMode = useSelector((state) => state.themeMode.mode);
  const prospectUser = useSelector((state) => state.campaign.prospectUser);

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      {searchProspects?.filter(
        (item) =>
          item.network_distance !== "DISTANCE_1" &&
          item.network_distance !== "OUT_OF_NETWORK" &&
          item.is_open_profile == true
      ).length == 0 && (
        <div className="flex w-full items-center justify-center text-center py-8">
          <span
            style={{
              color: themeMode === "dark" ? "white" : "black",
            }}
          >
            There are no records
          </span>
        </div>
      )}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Name{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Current Job Title{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Current Company{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Location{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Actions{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchProspects
            ?.filter(
              (item) =>
                item.network_distance !== "DISTANCE_1" &&
                item.network_distance !== "OUT_OF_NETWORK" &&
                item.is_open_profile == true
            )
            .map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className="cursor-pointer"
              >
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                >
                  <span className="flex items-center space-x-2">
                    <Link to={row.profile_url}>
                    <IconButton                     
                    target="_blank">
                      <LinkedIn
                        sx={{ color: themeMode === "dark" ? "#FFF" : "#000" }}
                      />
                    </IconButton>
                    </Link>
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={row.profile_picture_url}
                    />{" "}
                    <span> {row.name}</span>
                  </span>
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  {row.current_job_title}
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  {row.current_company}
                </TableCell>
                <TableCell
                  className={
                    themeMode === "dark"
                      ? "table-row-content"
                      : "table-row-content-light"
                  }
                  align="left"
                >
                  <span> {row.location}</span>
                </TableCell>
                <TableCell
                  onClick={() =>
                    row.automation_enabled ? (
                      <>
                        {dispatch(stopAutomation(row.uuid)).then((res) => {
                          if (res?.payload?.status === 200) {
                            dispatch(success("Automation Stopped"));
                            dispatch(
                              getSearchProspects({
                                campaign: id,
                                user_uuid: prospectUser,
                              })
                            );
                          }
                        })}
                      </>
                    ) : (
                      <>
                        {dispatch(resumeAutomation(row.uuid)).then((res) => {
                          if (res?.payload?.status === 200) {
                            dispatch(success("Automation Resumed"));
                            dispatch(
                              getSearchProspects({
                                campaign: id,
                                user_uuid: prospectUser,
                              })
                            );
                          }
                        })}
                      </>
                    )
                  }
                  className={
                    themeMode === "dark"
                      ? "table-row-stats"
                      : "table-row-stats-light"
                  }
                  align="left"
                >
                  <span style={{ color: "#FF0000" }} className="font-bold">
                    {row.automation_enabled
                      ? "Stop Automation"
                      : "Resume Automation"}{" "}
                  </span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
