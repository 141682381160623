import React, { useState } from "react";
import {
  Popover,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  styled,
  Popper,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getMeetings,
  setMeetingEndDate,
  setMeetingStartDate,
} from "../../store/meetingSlice";
import moment from "moment";

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    backgroundColor: "red", // Set the desired background color
    color: "white", // Optional: Set text color if needed
  },
});

export default function DatePopover() {
  const dispatch = useDispatch();
  const meetingStartDate = useSelector(
    (state) => state.meeting.meetingStartDate
  );
  const meetingEndDate = useSelector((state) => state.meeting.meetingEndDate);
  const meetingQuery = useSelector((state) => state.meeting.meetingQuery);

  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState(meetingStartDate);
  const [endDate, setEndDate] = useState(meetingEndDate);
  const themeMode = useSelector((state) => state.themeMode.mode);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton onClick={handleClick}>
        <DateRangeIcon
          style={{ color: themeMode === "dark" ? "white" : "black" }}
        />
      </IconButton>

      <Popover
        PaperProps={{
          sx: {
            padding: "0px",
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF",
            color: themeMode === "dark" ? "white" : "black",
            borderRadius: "10px", // Optional: change border radius
            border:
              themeMode === "dark" ? "1px solid white" : "1px solid black",
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className="flex space-x-8 p-6">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              className={
                themeMode === "dark"
                  ? "custom-datetime-picker"
                  : "custom-datetime-picker-light"
              }
            >
              <DatePicker
                placeholder="Start Time"
                value={meetingStartDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                  dispatch(setMeetingStartDate(newValue));
                  dispatch(
                    getMeetings({
                      query: meetingQuery,
                      start_date: moment(newValue).format(
                        "YYYY-MM-DDTHH:mm:ss.SSSZ"
                      ),
                      end_date: meetingEndDate
                        ? moment(meetingEndDate).format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          )
                        : null,
                    })
                  );
                }}
                maxDate={meetingEndDate ? meetingEndDate : null}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    placeholder="Start Date"
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        "& fieldset": {
                          border: "none", // Remove the border here
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          color: themeMode === "dark" ? "white" : "black",
                        },
                      },
                    }}
                    className={
                      themeMode === "dark"
                        ? "meetings-time-field"
                        : "meetings-time-field-light"
                    }
                    {...params}
                  />
                )}
                PopperProps={{
                  component: StyledPopper, // Apply custom styles for the popper
                }}
              />
            </Box>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              className={
                themeMode === "dark"
                  ? "custom-datetime-picker"
                  : "custom-datetime-picker-light"
              }
            >
              <DatePicker
                placeholder="End Time"
                value={meetingEndDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  dispatch(setMeetingEndDate(newValue));
                  dispatch(
                    getMeetings({
                      query: meetingQuery,
                      start_date: meetingStartDate
                        ? moment(meetingStartDate).format(
                            "YYYY-MM-DDTHH:mm:ss.SSSZ"
                          )
                        : null,
                      end_date: moment(newValue).format(
                        "YYYY-MM-DDTHH:mm:ss.SSSZ"
                      ),
                    })
                  );
                }}
                minDate={meetingStartDate ? meetingStartDate : null}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        "& fieldset": {
                          border: "none", // Remove the border here
                        },
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </div>
      </Popover>
    </div>
  );
}
