import AdjustFilters from "./AdjustFilters";
import PreferenceOptimization from './PreferenceOptimization.js';
import Parameters from "./Parameters/Parameters.js";
import SaveChanges from "./SaveChanges.js";

const AICampaignOptimization = ({ selectedTab, setSelectedTab }) => {
    console.log(selectedTab)
    return (
        <>
            {selectedTab === 7 && <PreferenceOptimization setSelectedTab={setSelectedTab} />}
            {selectedTab === 8 && <AdjustFilters setSelectedTab={setSelectedTab}/>}
            {selectedTab === 9 && <Parameters setSelectedTab={setSelectedTab}/>}
            {selectedTab === 10 && <SaveChanges/>}
        </>
    );
};
export default AICampaignOptimization;