import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { useParams } from "react-router-dom";
import {
  createAutomatedMessages,
  getAutomatedMessages,
  getCampaignDetail,
} from "../../../../store/campaignSlice";
import { error, success } from "../../../../store/alertSlice";
import {
  createQuickResponse,
  getQuickResponses,
} from "../../../../store/settingsSlice";
import FollowUpSendTime from "./FollowUpSendTime";
import { useState } from "react";

const defaultValues = {
  message: "",
  saveAsQuickResponse: false,
};

const schema = yup
  .object()
  .shape({
    message: yup.string().required("You must enter message"),
  })
  .required();

export default function CreateFollowUpMessageDialog({ open, handleClose }) {
  const { id, name } = useParams();
  const [followUp, setFollowUp] = useState(false);
  const themeMode = useSelector((state) => state.themeMode.mode);

  const intervalType = useSelector((state) => state.campaign.intervalType);
  const intervalFrequency = useSelector(
    (state) => state.campaign.intervalFrequency
  );

  const dispatch = useDispatch();
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState,
    setValue,
    getValues,
  } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  const formData = watch();

  function onSubmit(data) {
    if (data.saveAsQuickResponse) {
      dispatch(
        createQuickResponse({
          title: data.title,
          message: data.message,
          campaign_uuid: id,
          follow_up_interval_type: intervalType,
          follow_up_interval_frequency: intervalFrequency,
        })
      ).then((resp) => {
        if (resp.payload?.status === 201) {
          dispatch(getQuickResponses());
          dispatch(success("Quick Reply Created"));
        }
      });
    }
    dispatch(
      createAutomatedMessages({
        message: data.message,
        campaign_uuid: id,
        follow_up_interval_type: intervalType,
        follow_up_interval_frequency: intervalFrequency,
      })
    ).then((resp) => {
      if (resp.payload?.status === 201) {
        dispatch(getCampaignDetail(id));
        dispatch(success("Automated message created"));
      }
    });
    handleClose();
    reset(defaultValues);
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: { md: "16px", sm: "16px", xs: "8px" },
            paddingRight: { md: "16px", sm: "16px", xs: "8px" },
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-1"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <div className="text-center pb-8">
            <div className="font-semibold"> Connection Request Message</div>
          </div>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: themeMode === "dark" ? "white" : "#000" }}
          >
            {formData?.saveAsQuickResponse && (
              <>
                <div className="create-campaign-dialog-text pt-4 pb-1">
                  Title
                </div>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id="title"
                      {...field}
                      error={!!errors.title}
                      helperText={errors?.title?.message}
                      required
                      className={
                        themeMode === "dark"
                          ? "create-campaign-dialog-field w-full"
                          : "create-campaign-dialog-field-light w-full"
                      }
                      size="small"
                      sx={{
                        "& .MuiInputBase-input": {
                          color: themeMode === "dark" ? "white" : "#000",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            color: themeMode === "dark" ? "white" : "#000",
                          },
                        },
                      }}
                    />
                  )}
                  name="title"
                  control={control}
                />
              </>
            )}

            <div className="create-campaign-dialog-text pt-4 pb-1">Message</div>
            <Controller
              render={({ field }) => (
                <TextField
                  id="message"
                  {...field}
                  error={!!errors.message}
                  helperText={errors?.message?.message}
                  required
                  className={
                    themeMode === "dark"
                      ? "create-campaign-dialog-field w-full"
                      : "create-campaign-dialog-field-light w-full"
                  }
                  multiline
                  rows={5}
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "white" : "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "white" : "#000",
                      },
                    },
                  }}
                />
              )}
              name="message"
              control={control}
            />

            <div className="create-campaign-dialog-text pt-6 pb-1">
              Personalization Token
            </div>
            <div className="flex items-center space-x-4">
              <Chip
                label={"First Name"}
                variant="outlined"
                className={
                  themeMode === "dark" ? "edit-chip-dark" : "edit-chip-light"
                }
                sx={{
                  color: themeMode === "dark" ? "white" : "#000",
                  fontSize: "12px",
                }}
                onClick={() => {
                  const currentValue = getValues("message") || "";
                  setValue("message", currentValue + "{first_name}", {
                    shouldValidate: true,
                  });
                }}
              />
              <Chip
                label={"Last Name"}
                variant="outlined"
                className={
                  themeMode === "dark" ? "edit-chip-dark" : "edit-chip-light"
                }
                sx={{
                  color: themeMode === "dark" ? "white" : "#000",
                  fontSize: "12px",
                }}
                onClick={() => {
                  const currentValue = getValues("message") || "";
                  setValue("message", currentValue + "{last_name}", {
                    shouldValidate: true,
                  });
                }}
              />
            </div>

            <div className="create-campaign-dialog-text pt-6 pb-1">
              <Controller
                name="saveAsQuickResponse"
                control={control}
                render={({ field: { onChange, value, onBlur, ref } }) => (
                  <FormControl error={!!errors.saveAsQuickResponse}>
                    <FormControlLabel
                      label="Save as Quick Reply"
                      control={
                        <Checkbox
                          sx={{
                            color: themeMode === "dark" ? "#FFFFFF" : "#000",
                            "&.Mui-checked": {
                              color: "#730101",
                            },
                          }}
                          checked={value}
                          onBlur={onBlur}
                          onChange={(ev) => onChange(ev.target.checked)}
                          inputRef={ref}
                        />
                      }
                    />
                    <FormHelperText>
                      {errors?.saveAsQuickResponse?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </div>

            <div>
              <Typography mt={2} sx={{ fontSize: "18px", fontWeight: "600" }}>
                Custom follow up send time
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                Tip: Take at least 60 mins gap to send a follow up message.
              </Typography>
              <FollowUpSendTime followUp={followUp} />{" "}
            </div>

            <div className="flex w-full justify-center pt-8">
              <Button
                className="create-button p-2 px-8"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="create-button-text">Save</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
