import { useSelector } from "react-redux";
import ResetStepper from "./ResetStepper";
import { ResetWelcome } from "./ResetWelcome";
import { EmailAndPhone } from "./EmailAndPhone";
import { EnterCode } from "./EnterCode";
import ChangePassword from "./ChangePassword";
import { SuccessfullyChanged } from "./SuccessfullyChanged";

export const ResetPassword = () => {
  const activeStep = useSelector((state) => state.reset.activeStep);
  return (
    <div
      className="flex flex-col h-full"
      style={{
        backgroundImage: `url(/bgReset.png)`,
        backgroundSize: "cover",
        backgroundPosition: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
        // width: "100vw",
        margin: 0,
      }}
    >
      {/* <div className="flex w-full justify-center mb-32">
        <ResetStepper />
      </div> */}
      <div className="w-full flex justify-center mt-16">
        {activeStep === 1 && <ResetWelcome />}
        {activeStep === 2 && <EmailAndPhone />}
        {activeStep === 3 && <EnterCode />}
        {activeStep === 4 && <ChangePassword />}
        {activeStep === 5 && <SuccessfullyChanged />}
      </div>
    </div>
  );
};
