import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Grid,Typography, Button, CircularProgress } from '@mui/material';
import { Rectangle, ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import theme from "../../../Theme/theme";
const redBkg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/redCurvedBox.png";
const chartArrow = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/chartArrow.png";
const pinkBkg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/pinkCurvedBox.png"

const data = [
    { date: '20/04', line: 210, bar: 90 },
    { date: '21/05', line: 225, bar: 95 },
    { date: '22/05', line: 210, bar: 100 },
    { date: '23/05', line: 135, bar: 105 },
    { date: '24/05', line: 120, bar: 110 },
    { date: '25/05', line: 125, bar: 115 },
    { date: '26/05', line: 180, bar: 10 },
    { date: '27/05', line: 165, bar: 125 },
    { date: '28/05', line: 150, bar: 130 },
    { date: '29/05', line: 195, bar: 35 },
    { date: '30/05', line: 150, bar: 140 },
    { date: '01/06', line: 155, bar: 145 },
    { date: '02/06', line: 240, bar: 60 },
    { date: '03/06', line: 195, bar: 155 },
    { date: '04/06', line: 177, bar: 60 },
    { date: '05/06', line: 175, bar: 165 },
    { date: '06/06', line: 180, bar: 130 },
    { date: '07/06', line: 215, bar: 175 },
    { date: '08/06', line: 210, bar: 130 },
    { date: '09/06', line: 195, bar: 185 },
    { date: '10/06', line: 200, bar: 120 },
];

const CustomTick = ({ x, y, payload }) => {
    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="white" transform="rotate(-90)" style={{ fontSize: '8px' }}>
                {payload.value}
            </text>
        </g>
    );
};

const RoundedBar = (props) => {
    const { x, y, width, height, fill } = props;
    return <Rectangle x={x} y={y} width={width} height={height} radius={[50, 50, 50, 50]} fill={fill} />;
};

export const ConnectionInvitationGraph = ({ overviewData }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);  
    const [graphState, setGraphState] = useState('graph_connection_invitations');
    const [barLabel, setBarLabel] = useState('connection invitations');
    const monthMap = {
        "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04", "May": "05", "Jun": "06",
        "Jul": "07", "Aug": "08", "Sep": "09", "Oct": "10", "Nov": "11", "Dec": "12"
    };
    const [chartData, setChartData] = useState([
        { date: '01/01', line: 0, bar: 0 },
    ]);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(false);    

    useEffect(() => {
        if (overviewData[graphState]?.chart) {
            try {
                const graphData = overviewData[graphState].chart;
                const data = graphData?.x_axis.map((date, index) => {
                    return {
                        date,
                        line: graphData?.line_data[index],
                        bar: graphData?.bar_data[index],
                    };
                });
                setChartData(data);
                setLoading(false);  
            } catch (err) {
                console.error("Error loading data", err);
                setError(true);
                setLoading(false);
            }
        }
    }, [overviewData[graphState]]);

    const handleStateChange = () => {
      const states = ['graph_connection_invitations', 'graph_meetings_booked', 'graph_new_connections', 'graph_replies'];
      const labels = ['connection invitations', 'meetings booked', 'new connections', 'replies'];
      const currentIndex = states?.indexOf(graphState);
      const nextIndex = (currentIndex + 1) % states?.length;
      setGraphState(states[nextIndex]);
      setBarLabel(labels[nextIndex]);
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center" sx={{ height: '400px' }}>
                <CircularProgress size={80} sx={{ color:themeMode==="dark"?"#FFF":"#FF0000"}}/>  
            </Grid>
        );
    }

    if (error || !chartData.length) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '400px' }}>
                <Typography color="error">Failed to load graph data</Typography>
            </Grid>
        );
    }

    return (
        <>
            <Grid sx={{              
                backgroundImage: themeMode === "dark" ? `url(${redBkg})` : `url(${pinkBkg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "left center", 
                width: '100%',
                //minHeight:"500px",
                height: {md:"400px",sm:"400px",xs:"470px"},
                padding: '35px 20px', 
                color: 'white',
                display: "flex",
                alignItems: {md:"flex-end",sm:"center",xs:"flex-end"},
                justifyContent: "space-between", 
                flexDirection: "column", 
                boxSizing: "border-box", 
                position: "relative",
                borderRadius:{md:"",sm:"",xs:"30px"},
                borderRight: { md: "1px solid transparent", sm: "1px solid transparent", xs: "1px solid #FFF" },
                // backgroundSize: "contain",
                // [theme.breakpoints.down(1200)]: {
                //     backgroundSize: "100% 100%",
                // },
                //backgroundSize: { md: "cover", lg: "cover", xl: "100% 100%", xs: "contain", sm: "contain" },
                backgroundSize: { md: "cover", lg: "100% 100%", xl: "100% 100%", xs: "contain", sm: "contain" },
                [theme.breakpoints.down(1200)]: {
                    backgroundSize: "100% 100%", 
                },
                [theme.breakpoints.down(600)]: {
                    backgroundSize: "cover",
                }

            }}>
                <Button disableRipple 
                    onClick={handleStateChange}
                    sx={{ 
                        borderRadius: "50%", 
                        padding: "15px", 
                        background: "#00000099", 
                        border: "1px solid #FFF", 
                        position: "absolute", 
                        width: "60px", 
                        height: "60px", 
                        "&:hover": { background:"#330101",transform:{md:"scale(1.05)",sm:"scale(1.05)",xs:"scale(0.75)"}} ,
                        top: {md:"0%",sm:"5%",xs:"0%"}, 
                        left: {xl:"4% !important", md: "-2% !important", sm:"0% !important",xs:"-3% !important"}, 
                        [theme.breakpoints.down(2000)]: {
                            left:"0% !important"
                        },
                        [theme.breakpoints.down(1600)]: {
                            left: "0% !important"
                        },
                        [theme.breakpoints.down(1500)]: {
                            left: "-2% !important"
                        },

                        [theme.breakpoints.down(1200)]: {
                            top:"0%",
                            left:"3% !important"
                        },
                        [theme.breakpoints.down(860)]: {
                            left: "-2% !important"
                        },
                        [theme.breakpoints.down(680)]: {
                            left: "-3% !important"
                        }
                        
                    }}>
                    <img src={chartArrow} alt="" />
                </Button>
                <Grid sx={{ marginTop:{md:"0px",sm:"0px",xs:"60px"},width: {md:"85%",sm:"70%",xs:"100%"},textAlign:{md:"left",sm:"center",xs:"right"} }}>
                        <Typography sx={{fontSize:"20px",fontWeight:"700",textWrap:"nowrap"}}>
                            {overviewData[graphState]?.title}
                        </Typography>
                    <Grid sx={{ justifyContent: "flex-start",width:"100%", display: "flex", textAlign:{xl:"left",md:"left",sm:"center",xs:"center"}}}>
                        <Typography sx={{fontSize:{md:"14px",sm:"14px",xs:"12px"}, color: "#FFFFFFB2" }}>
                            {overviewData[graphState]?.description}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        width: { md: '100%', sm: '100%',xs:"100%" },
                        height: {md:'70%',sm:"70%",xs:"100%"},
                        padding:{md:"10px",sm:"10px",xs:"0px"},
                    }}
                >
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart data={chartData} >

                        <XAxis dataKey="date" tick={<CustomTick />} interval={0} />
                        <YAxis tick={{ fill: 'white' }} />
                        <Tooltip
                            contentStyle={{ backgroundColor: '#2D0D0D', borderRadius: '10px', border: '1px solid #ccc', color: '#fff' }}
                            itemStyle={{ color: '#fff' }}
                            cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }}
                        />
                        <Legend
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                            wrapperStyle={{
                                paddingTop: '10px',
                                fontSize: '12px',
                                color: 'white',
                            }}
                        />
                        <Bar dataKey="bar" fill="#ccc" barSize={7} shape={<RoundedBar />} name={barLabel} />
                        <Line type="monotone" dataKey="line" stroke="#fff" dot={{ fill: '#fff' }} name="count" />
                    </ComposedChart>
                    </ResponsiveContainer>
                </Grid>
            </Grid>


        </>
    );
}


