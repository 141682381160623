import {React,useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { CircularLoaderProgress } from "../dashboard/LoaderProgress"; 
import { useNavigate } from 'react-router-dom';
//import { error } from "./alertSlice";


const { REACT_APP_API_URL } = process.env;
const logoYellow =
    "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoFace.png";
const logoRed =
    "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoRed.png";

const Impersonate=()=>{
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const themeMode = useSelector((state) => state.themeMode.mode);

    const uid = searchParams.get('uid');
    const client = searchParams.get('client');
    const accessToken = searchParams.get('access-token');
    const tokenType = searchParams.get('token-type');
    const expiry = searchParams.get('expiry');
    const authorization = searchParams.get('Authorization');

    console.log({ uid, client, accessToken, tokenType, expiry, authorization });

    // useEffect(()=>{
    //     if (uid && client && accessToken) {
            
    //         localStorage.setItem("client", client);
    //         localStorage.setItem("uid", uid);
    //         localStorage.setItem("access-token", accessToken);

            
    //         axios.defaults.headers.common['client'] = client;
    //         axios.defaults.headers.common['uid'] = uid;
    //         axios.defaults.headers.common['access-token'] = accessToken;

    //     }
    // }, [uid, client, accessToken, authorization]);

    useEffect(() => {

        if (uid && client && accessToken) {

            localStorage.setItem("client", client);
            localStorage.setItem("uid", uid);
            localStorage.setItem("access-token", accessToken);

            axios.defaults.headers.common['client'] = client;
            axios.defaults.headers.common['uid'] = uid;
            axios.defaults.headers.common['access-token'] = accessToken;
            axios.defaults.headers.common['Authorization'] = authorization; // Optional, if needed


            navigate("/dashboard");
        }
    }, [uid, client, accessToken, authorization, navigate]);

    useEffect(() => {
        const storedClient = localStorage.getItem("client");
        const storedUid = localStorage.getItem("uid");
        const storedAccessToken = localStorage.getItem("access-token");

        if (storedClient && storedUid && storedAccessToken) {
            axios.defaults.headers.common['client'] = storedClient;
            axios.defaults.headers.common['uid'] = storedUid;
            axios.defaults.headers.common['access-token'] = storedAccessToken;
        }
    }, []); 


    return(
        <Grid
            className={
                themeMode === "dark"
                    ? "dashboard-bg h-full"
                    : "dashboard-bg-light h-full"
            }
            sx={{height:"100vh !important",display:"flex",alignItems:"center",justifyContent:"center"}}
        >
            <div
                style={{
                    backgroundImage: themeMode === "dark" ? `url(${logoYellow})` : `url(${logoRed})`,
                    backgroundSize: { md: "cover", sm: "75% 75%" },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center", 
                }}
            >
                <CircularLoaderProgress />
            </div>
        </Grid>

        

    )
}
export default Impersonate;