import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  registers: [],
  registeredUser: {},
  organizations: [],
  organizationDetail: [],
  preselected_payment_plan: null,
};

export const registerUser = createAsyncThunk(
  "register/registerUser",
  async (user_request, { dispatch, getState }) => {
    try {
      const data = await axios.post(
        `${REACT_APP_API_URL}/signup_requests/create`,
        {
          user_request,
        }
      );

      return data;
    } catch (err) {
      dispatch(error(`Email ${err.response.data?.email[0]}`));
      console.error(err);
      return null;
    }
  }
);

export const acceptRequest = createAsyncThunk(
  "register/acceptRequest",
  async (params, { dispatch, getState }) => {
    try {
      const data = await axios.put(
        `${REACT_APP_API_URL}/signup_requests/accept_request/${params}`
      );

      return data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const getOrganizations = createAsyncThunk(
  "register/getOrganizations",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/signup_requests/search_for_organizations`
      );

      return { data: res.data };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);
export const getOrganizationDetail = createAsyncThunk(
  "register/getOrganizationDetail",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/signup_requests/search_for_organizations?query=${params}`
      );

      return { data: res.data };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.registers = action.payload;
    });
    builder.addCase(acceptRequest.fulfilled, (state, action) => {
      state.registeredUser = action.payload;
    });
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      state.organizations = action.payload?.data;
    });
    builder.addCase(getOrganizationDetail.fulfilled, (state, action) => {
      state.organizationDetail =
        action.payload?.data?.[0]?.default_payment_plans;
      state.preselected_payment_plan =
        action.payload?.data?.[0]?.preselected_payment_plan;
    });
  },
});

export const {} = registerSlice.actions;

export default registerSlice.reducer;
