import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PerformanceCard from "./PerformanceCard";
import ImpactChart from "./ImpactChart";
import theme from "../../../../../Theme/theme";
import {
  setautomatedMessaingPerformance,
  setinMailPerformance,
} from "../../../../store/campaignSlice";
import Automation from "./Automation";
import ActivationCampaign from "../ActivationCampaign";
import { useState } from "react";

const Summary = () => {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);
  const dispatch = useDispatch();
  const automatedMessaingPerformance = useSelector(
    (state) => state.campaign.automatedMessaingPerformance
  );
  const inMailPerformance = useSelector(
    (state) => state.campaign.inMailPerformance
  );

  const connectionArrowClick = () => {
    dispatch(setautomatedMessaingPerformance());
  };
  const replyArrowClick = () => {
    dispatch(setinMailPerformance());
  };
  const inMailTitle = (
    <p style={{}}>
      InMa<span style={{ fontSize: "17px" }}>i</span>l Performance
    </p>
  );

  const [openActivation, setOpenActivation] = useState(false);

  const handleClickOpenActivation = () => {
    setOpenActivation(true);
  };

  const handleCloseActivation = () => {
    setOpenActivation(false);
  };

  return (
    <div className="my-8">
      <Grid item md={12}>
        <Automation handleClickOpen={handleClickOpenActivation} />
      </Grid>

      <Grid
        my={{ md: "", sm: 4, xs: 2 }}
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
          [theme.breakpoints.down(1330)]: {
            flexDirection: "column ",
          },
        }}
      >
        <Grid
          px={{ md: 2, sm: 5 }}
          sx={{
            display: "flex",
            width: "50%",
            marginY: "10px",
            [theme.breakpoints.down(1330)]: {
              marginY: "15px",
              width: "100%",
            },
          }}
        >
          <PerformanceCard
            btnBkgColor={"#160202"}
            title="Automated Messaging Performance"
            about="Click the toggle button to see connection rate, Reply rate & interested rate."
            arrowClick={connectionArrowClick}
            showRate={automatedMessaingPerformance}
            value={
              campaign?.summary_stats?.automated_messaging_performance
                ?.connection_rate
            }
            count={
              campaign?.summary_stats?.automated_messaging_performance
                ?.connections_count
            }
            invitations={
              campaign?.summary_stats?.automated_messaging_performance
                ?.invitations_count
            }
            interested={
              campaign?.summary_stats?.automated_messaging_performance
                ?.interested_rate
            }
            conclusion={
              campaign?.summary_stats?.automated_messaging_performance
                ?.conclusion
            }
            max={100}
            rateType={
              automatedMessaingPerformance === 0
                ? "Connection"
                : automatedMessaingPerformance === 1
                ? "Reply"
                : "Interested"
            }
            rateValue={
              automatedMessaingPerformance === 0
                ? campaign?.summary_stats?.automated_messaging_performance
                    ?.connection_rate
                : automatedMessaingPerformance === 1
                ? campaign?.summary_stats?.automated_messaging_performance
                    ?.reply_rate
                : campaign?.summary_stats?.automated_messaging_performance
                    ?.interested_rate
            }
            rateType2={
              automatedMessaingPerformance === 0
                ? "Connection"
                : automatedMessaingPerformance === 1
                ? "Reply"
                : "Interested"
            }
            maxType="invites"
          />
        </Grid>
        {campaign?.campaign_type === "prospecting" && (
          <Grid
            px={{ md: 2, sm: 5 }}
            sx={{
              display: "flex",
              width: "50%",
              marginY: "10px",
              [theme.breakpoints.down(1330)]: {
                marginY: "15px",
                width: "100%",
              },
            }}
          >
            <PerformanceCard
              btnBkgColor={"#1D0505"}
              title={
                <p style={{}}>
                  InMa<span style={{ fontSize: "17px" }}>i</span>l Performance
                </p>
              }
              about="Click the toggle button to see reply rate and interested rate."
              arrowClick={replyArrowClick}
              showRate={inMailPerformance}
              value={
                campaign?.summary_stats?.inmail_messaging_performance
                  ?.reply_rate
              }
              count={
                campaign?.summary_stats?.inmail_messaging_performance
                  ?.replies_count
              }
              invitations={
                campaign?.summary_stats?.inmail_messaging_performance
                  ?.send_message_count
              }
              interested={
                campaign?.summary_stats?.inmail_messaging_performance
                  ?.interested_rate
              }
              conclusion={
                campaign?.summary_stats?.inmail_messaging_performance
                  ?.conclusion
              }
              max={100}
              rateType={inMailPerformance === 0 ? "Reply" : "Interested"}
              rateValue={
                inMailPerformance === 0
                  ? campaign?.summary_stats?.inmail_messaging_performance
                      ?.reply_rate
                  : campaign?.summary_stats?.inmail_messaging_performance
                      ?.interested_rate
              }
              rateType2={inMailPerformance === 0 ? "Replies" : "Interested"}
              maxType="connections"
            />
          </Grid>
        )}
      </Grid>

      <Grid>
        <ImpactChart campaign={campaign} />
      </Grid>
      <ActivationCampaign
        open={openActivation}
        handleClose={handleCloseActivation}
        handleClickOpen={handleClickOpenActivation}
      />
    </div>
  );
};
export default Summary;
