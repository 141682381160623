/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import {
    Button,
    Grid,
    Checkbox,
    FormControlLabel,
    Hidden,
    IconButton,
    InputAdornment,
    TextField,
    Select,
    MenuItem,
    Typography,
    useMediaQuery
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import Navbar from '../website/navbar/Navbar';
import Footer from '../website/footer/Footer';
const proceed = "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Proceed.png"
const rocket = "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/calcRocket.png"
const defaultValues = {
    email: "",
    password: "",
};

const schema = yup
    .object()
    .shape({
        email: yup.string().required("You must type email"),
        password: yup.string().required("You must type your password"),
    })
    .required();


const Calculator = () => {
    const isCustomRange = useMediaQuery('(min-width:1024px) and (max-width:1336px)');
    const navigate = useNavigate();

    const { handleSubmit, register, reset, control, watch, formState } = useForm({
        defaultValues,
        mode: "all",
        resolver: yupResolver(schema),
    });
    return (
        <div>
             <div>
        <Navbar />
      </div>
        
        <div
            className="lg:flex w-full mt-24"
            style={{
                //   backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/loginGif.gif)`,
                //   backgroundSize: "cover",
                background: "#EFEFEF",
                zIndex: "0",
                height:"fit-content"

            }}

        >
            <Grid
                className="w-full"
                sx={{
                    backgroundColor: "#EFEFEF",
                    borderRadius: "0px 50px 50px 0px",
                    height: { md: "100vh", sm: "100%", xs: "100%" },
                    backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bkgBright2.png)`,
                    backgroundSize: {md:"100% 100%",sm:"100% 100%",xs:"cover"},
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    width: "100%",
                    zIndex: "5",
                    height: "fit-content"
                }}
            >
                <Grid 
                    //className="p-4"
                    sx={{
                        py: 4,
                        px: {
                            xs: 4,
                            sm: 8,
                            md: 8,
                            lg: 8,
                            xl: 16,
                        },
                    }}
                >
                    {" "}
                    <div className="flex justify-start mt-10">
                        <img
                            src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoIcon.png"
                            alt="logo-icon"
                            className=""
                        />
                    </div>
                    <Grid className="my-4" sx={{ fontSize: "40px", fontWeight: "700", color: "#FFF" }}>Campaign Calculator</Grid>
                    <Grid sx={{ color: "#FFF", fontSize: "18px", fontWeight: "400", width: {md:"65%",sm:"75%",xs:"100%"} }}>
                        Want to see how much your business can grow with a targeted LinkedIn outreach campaign? Enter your business details below and let us show you the revenue potential.
                    </Grid>
                </Grid>
                <Grid 
                    //className="my-8 px-16"
                    sx={{   my:8,
                            px: {
                                xs: 4, 
                                sm: 8, 
                                md: 8, 
                                lg: 8, 
                                xl: 16,
                            },
                    }}
                    >
                    <Grid
                        sx={{
                            //width: { md: isCustomRange ? "100%" : "66%", sm: "100%", xs: "100%" }
                            width:{md: "75%",sm:"100%",xs:"100%"}, display: "flex",flexDirection:{md:"row",sm:"row",xs:"column"}
                        }}
                        className="my-5"
                    >
                        {/* <Grid sx={{ width: "100%" }} className="px-16">
                        <Controller
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    className="auth-form-input"
                                    placeholder="Email"
                                    // error={!!errors.email}
                                    // helperText={errors?.email?.message}
                                    required
                                    sx={{
                                        width: { md: "66%", sm: "66%", xs: "100%" },
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none",
                                            },
                                        },
                                    }}
                                />
                            )}
                            name="email"
                            control={control}
                        />
                        <div className="pt-8 md:flex space-x-8 w-full items-center">
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="auth-form-input "
                                        placeholder="Passsword"
                                        error={!!errors.password}
                                        helperText={errors?.password?.message}
                                        type={showPassword ? "text" : "password"}
                                        required
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        //onClick={handleClickShowPassword}
                                                        edge="end"
                                                        style={{ color: "#FFF" }}
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff style={{ color: "#2200F2" }} />
                                                        ) : (
                                                            <Visibility style={{ color: "#2200F2" }} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            width: { md: "66%", sm: "66%", xs: "100%" },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                    />
                                )}
                                name="password"
                                control={control}
                            />
                            <div className="pt-4 md:pt-0">
                                <Link
                                    to="/forgot-password"
                                    className="forgot-text cursor-pointer"
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                        </div>
                        <div className="text-black pt-8">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="large"
                                        sx={{
                                            color: "#2200f2",
                                            "&.Mui-checked": {
                                                color: "#2200f2",
                                            },
                                        }}
                                    />
                                }
                                label="Remember me"
                            />
                        </div>
                        <div className="pt-10">
                            <Button
                                variant="contained"
                                size="large"
                                style={{
                                    backgroundColor: "#3633A7",
                                    color: "white",
                                    borderRadius: "30px",
                                }}
                               // onClick={handleSubmit(onSubmit)}
                            >
                                <span className="sign-button">Sign in</span>
                            </Button>
                            <div className="text-black pt-6">
                                Don’t have an account?
                                <span className="font-bold text-[#2200F2]">
                                    <Link to={"/register"}> Sign up</Link>
                                </span>
                            </div>
                        </div>
                    </Grid> */}

                        <Grid item md={4} sx={{ width: {md:"50%",sm:"50%",xs:"100%"}, display: "flex", alignItems: "center" }}>
                            <Typography my={{md:0,sm:0,xs:1}} sx={{ fontSize: "18px", fontWeight: "400" }}>
                                Company Name
                            </Typography>
                        </Grid>
                        <Grid item md={8} sm={8} xs={12} sx={{ width: {md:"75%",sm:"75%",xs:"100%"} }}>
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="calc-form-input"
                                        placeholder="Email"
                                        // error={!!errors.email}
                                        // helperText={errors?.email?.message}
                                        required
                                        sx={{
                                            width: { md: "100%", sm: "100%", xs: "100%" },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                    />
                                )}
                                name="email"
                                control={control}
                            />
                        </Grid>

                    </Grid>


                    <Grid
                        sx={{
                            //width: { md: isCustomRange ? "100%" : "66%", sm: "100%", xs: "100%" }
                                width: { md: "75%", sm: "100%", xs: "100%" }, display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }
                        }}
                        className="my-5"
                    >
                            <Grid item md={4} sx={{ width: { md: "50%", sm: "50%", xs: "100%" }, display: "flex", alignItems: "center" }}>
                                <Typography my={{ md: 0, sm: 0, xs: 1 }} sx={{ fontSize: "18px", fontWeight: "400" }}>
                                Industry:
                            </Typography>
                        </Grid>
                            <Grid item md={8} sx={{ width: { md: "75%", sm: "75%", xs: "100%" } }}>
                            <Controller
                                defaultValue={0}
                                render={({ field }) => (
                                    <Select
                                        select
                                        {...field}
                                        className="calc-form-input"
                                        // error={!!errors.email}
                                        // helperText={errors?.email?.message}
                                        required
                                        sx={{
                                            color: field.value === 0 ? "#A2A2A2" : "#000",
                                            width: { md: "50%", sm: "75%", xs: "100%" },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 100,
                                                        borderRadius: "15px",
                                                        Width: "60%",
                                                        background: "#423FD1",
                                                        scrollbarWidth: "thin",
                                                        scrollbarColor: "#FFF #423FD1 !important",
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={0} disabled>
                                            Select
                                        </MenuItem>
                                        <MenuItem value={1} >
                                            IT
                                        </MenuItem>
                                        <MenuItem value={2} >
                                            Fashion
                                        </MenuItem>

                                    </Select>
                                )}
                                name="email"
                                control={control}
                            />
                        </Grid>

                    </Grid>


                    <Grid
                        sx={{
                            //width: { md: isCustomRange ? "100%" : "66%", sm: "100%", xs: "100%" }
                                width: { md: "75%", sm: "100%", xs: "100%" }, display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }
                        }}
                        className="my-5"
                    >
                            <Grid item md={4} sx={{ width: { md: "50%", sm: "50%", xs: "100%" }, display: "flex", alignItems: "center" }}>
                                <Typography my={{ md: 0, sm: 0, xs: 1 }} sx={{ fontSize: "18px", fontWeight: "400" }}>
                                Company Size:
                            </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ width: {md:"75%",sm:"75%",xs:"100%"} }}>
                            <Controller
                                defaultValue={0}
                                render={({ field }) => (
                                    <Select
                                        select
                                        {...field}
                                        className="calc-form-input"
                                        // error={!!errors.email}
                                        // helperText={errors?.email?.message}
                                        required
                                        sx={{
                                            width: { md: "50%", sm: "75%", xs: "100%" },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 100,
                                                        borderRadius: "15px",
                                                        Width: "60%",
                                                        background: "#423FD1",
                                                        scrollbarWidth: "thin",
                                                        scrollbarColor: "#FFF #423FD1 !important",
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={0} disabled>
                                            Select
                                        </MenuItem>
                                        <MenuItem value={1} >
                                            1-100
                                        </MenuItem>
                                        <MenuItem value={2} >
                                            100-500
                                        </MenuItem>

                                    </Select>
                                )}
                                name="email"
                                control={control}
                            />
                        </Grid>

                    </Grid>

                    <Grid
                        sx={{
                            //width: { md: isCustomRange ? "100%" : "66%", sm: "100%", xs: "100%" }
                                width: { md: "75%", sm: "100%", xs: "100%" }, display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }
                        }}
                        className="my-5"
                    >
                            <Grid item md={4} sx={{ width: { md: "50%", sm: "50%", xs: "100%" }, display: "flex", alignItems: "center" }}>
                                <Typography my={{ md: 0, sm: 0, xs: 1 }} sx={{ fontSize: "18px", fontWeight: "400" }}>
                                Target Market:
                            </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ width: {md:"75%",sm:"75%",xs:"100%" }}}>
                            <Controller
                                defaultValue={0}
                                render={({ field }) => (
                                    <Select
                                        select
                                        {...field}
                                        className="calc-form-input"
                                        // error={!!errors.email}
                                        // helperText={errors?.email?.message}
                                        required
                                        sx={{
                                            width: { md: "50%", sm: "75%", xs: "100%" },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 100,
                                                        borderRadius: "15px",
                                                        Width: "60%",
                                                        background: "#423FD1",
                                                        scrollbarWidth: "thin",
                                                        scrollbarColor: "#FFF #423FD1 !important",
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value={0} disabled>
                                            Select
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            1-100
                                        </MenuItem>
                                        <MenuItem value={2} >
                                            100-500
                                        </MenuItem>

                                    </Select>
                                )}
                                name="email"
                                control={control}
                            />
                        </Grid>

                    </Grid>

                    <Grid
                        sx={{
                            //width: { md: isCustomRange ? "100%" : "66%", sm: "100%", xs: "100%" }
                                width: { md: "75%", sm: "100%", xs: "100%" }, display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }
                        }}
                        className="my-5"
                    >
                            <Grid item md={4} sx={{ width: { md: "50%", sm: "50%", xs: "100%" }, display: "flex", alignItems: "center" }}>
                                <Typography my={{ md: 0, sm: 0, xs: 1 }} sx={{ fontSize: "18px", fontWeight: "400" }}>
                                Number of LinkedIn Accounts:
                            </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ width:{md: "75%",sm:"75%",xs:"100%" }}}>
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="calc-form-input"
                                        placeholder="Select"
                                        // error={!!errors.email}
                                        // helperText={errors?.email?.message}
                                        required
                                        sx={{
                                            width: { md: "100%", sm: "100%", xs: "100%" },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                    />
                                )}
                                name="email"
                                control={control}
                            />
                        </Grid>

                    </Grid>


                    <Grid
                        sx={{
                            //width: { md: isCustomRange ? "100%" : "66%", sm: "100%", xs: "100%" }
                                width: { md: "75%", sm: "100%", xs: "100%" }, display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }
                        }}
                        className="my-5"
                    >
                            <Grid item md={4} sx={{ width: { md: "50%", sm: "50%", xs: "100%" }, display: "flex", alignItems: "center" }}>
                                <Typography my={{ md: 0, sm: 0, xs: 1 }} sx={{ fontSize: "18px", fontWeight: "400" }}>
                                Your Monthly Revenue Goal:
                            </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ width: {md:"75%",sm:"75%",xs:"100%"} }}>
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="calc-form-input"
                                        placeholder="Select"
                                        // error={!!errors.email}
                                        // helperText={errors?.email?.message}
                                        required
                                        sx={{
                                            width: { md: "100%", sm: "100%", xs: "100%" },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                    />
                                )}
                                name="email"
                                control={control}
                            />
                        </Grid>

                    </Grid>

                    <Grid
                        sx={{
                            //width: { md: isCustomRange ? "100%" : "66%", sm: "100%", xs: "100%" }
                                width: { md: "75%", sm: "100%", xs: "100%" }, display: "flex", flexDirection: { md: "row", sm: "row", xs: "column" }
                        }}
                        className="my-5"
                    >
                            <Grid item md={4} sx={{ width: { md: "50%", sm: "50%", xs: "100%" }, display: "flex", alignItems: "center" }}>
                                <Typography my={{ md: 0, sm: 0, xs: 1 }} sx={{ fontSize: "18px", fontWeight: "400" }}>
                                Cost per Sale:
                            </Typography>
                        </Grid>
                        <Grid item md={8} sx={{ width: {md:"75%",sm:"75%",xs:"100%"} }}>
                            <Controller
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="calc-form-input"
                                        placeholder="Select"
                                        // error={!!errors.email}
                                        // helperText={errors?.email?.message}
                                        required
                                        sx={{
                                            width: { md: "100%", sm: "100%", xs: "100%" },
                                            "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                            },
                                        }}
                                    />
                                )}
                                name="email"
                                control={control}
                            />
                        </Grid>

                    </Grid>

                    <Grid sx={{display:"flex",justifyContent: "center",width:{md:"60%",sm:"60%",xs:"100%"},paddingY:"30px"}}>
                        <Button
                            disableRipple
                            variant="contained"
                            onClick={() => navigate("/ai-results")}
                            size="large"
                            sx={{ background: "transparent", minWidth: "0px", padding: 0, boxShadow: "none", "&:hover": { background: "transparent", boxShadow: "none",transform:"scale(1.1)" }}}
                            type="submit"
                        >
                            <img src={proceed} alt=""/>
                        </Button>
                    </Grid>

                    
                </Grid>

            </Grid>

            {/* <Hidden mdDown>
                <div className="w-full lg:w-1/4 px-16">
                    
                    <div className="login-container">
                        <div className="login-text">Welcome back to</div>
                        <div className="login-text2">FacoonLabs</div>
                    </div>
                </div>
            
            </Hidden> */}
                <Hidden mdDown>
            <Grid
                className="w-full lg:w-1/4" sx={{ display: "flex", overflow: "visible", zIndex: "10", alignItems: "center" }}
            >
                {/* <Grid sx={{ width: "100%", display: "flex", alignItems: "center",  }}> */}
                <img src={rocket} alt="" style={{
                        height: "100%", // Make the image larger than the parent container
                        width: "80%",
                        position: "absolute", // Position the image absolutely relative to the container
                        top: "40%",
                        right: 0, }} />
                {/* </Grid> */}

            </Grid>
                </Hidden>
        </div>
        <Footer />
        </div>
    )
}
export default Calculator;