import {
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Icon,
  Button,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSearchProspects,
  setProspectsUserFilter,
  setProspectsUserFilterDetail,
  setSearchUrl,
} from "../../../../store/campaignSlice";
import { useEffect, useState } from "react";
import { success } from "../../../../store/alertSlice";
import { MySwitch } from "../time-zone/myComponents";
import { setSelectedTabValue } from "../../../../store/settingsSlice";
import SearchHeaderDialog from "./SearchHeaderDialog";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import { LoaderProgress } from "../../../LoaderProgress";

export const SearchHeader = ({ handleClickOpenURL }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);
  const [userFilter, setUserFilter] = useState(campaign?.admins?.[0]);
  const [selectedUser, setSelectedUser] = useState(campaign?.admins?.[0]);
  const campaignsLoading = useSelector(
    (state) => state.campaign.campaignsLoading
  );
  const [open, setOpen] = useState(false);

  const searchProspectsMeta = useSelector(
    (state) => state.campaign.searchProspectsMeta
  );
  const linkedin = useSelector(
    (state) => state.settings.linkedInConnectedStatus
  );

  const [inputValue, setInputValue] = useState("");

  const searchProspects = useSelector(
    (state) => state.campaign.searchProspects
  );

  const truncatedString = (str) => {
    if (str?.length > 100) {
      return str.slice(0, 100) + "...";
    }
    return str;
  };

  useEffect(() => {
    setUserFilter(campaign?.admins?.[0]);
  }, [campaign]);

  const { id, name } = useParams();

  const onSubmit = (data) => {
    const updatedData = {
      search_url: data,
      id: id,
      user_uuid: userFilter.uuid,
    };
    dispatch(setSearchUrl(updatedData)).then((res) => {
      if (res.payload?.status === 200) {
        dispatch(
          getSearchProspects({
            campaign: id,
            user_uuid: userFilter.uuid,
          })
        );
        dispatch(success("URL updated successfully"));
        setInputValue("");
      }
    });
  };

  const URL = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className={
        themeMode === "dark"
          ? "p-8 my-4 dashboard-table-layout"
          : "p-8 my-4 dashboard-table-layout-light"
      }
    >
      {campaignsLoading && <LoaderProgress />}

      <div
        className={
          themeMode === "dark"
            ? "dashboard-subtitle pt-2"
            : "dashboard-subtitle-light pt-2"
        }
      >
        Search list are used to help find your ideal target audience. Create a
        search URL on Linkedin Sales Navigator to capture for this campaign.{" "}
      </div>
      <div
        className="cursor-pointer"
        style={{ color: themeMode === "dark" ? "#24ff00" : "#217CF9" }}
        onClick={handleClickOpenURL}
      >
        How Search URL works?
      </div>
      <div className="lg:flex w-full items-center space-x-4">
        <div className="w-full">
          <div className="lg:flex-row flex flex-col space-y-2 lg:space-y-0 items-center py-6 lg:space-x-6">
            <div
              className={
                themeMode === "dark"
                  ? "dashboard-subtitle"
                  : "dashboard-subtitle-light"
              }
            >
              Select team member to view
            </div>
            <Select
              defaultValue=""
              displayEmpty
              className={
                themeMode === "dark"
                  ? "dashboard-field"
                  : "dashboard-field-light"
              }
              value={userFilter}
              onChange={(e) => {
                setUserFilter(e.target.value);

                dispatch(setProspectsUserFilter(e.target.value.uuid));
                dispatch(setProspectsUserFilterDetail(e.target.value));

                dispatch(
                  getSearchProspects({
                    campaign: id,
                    user_uuid: e.target.value.uuid,
                  })
                );
              }}
              size="small"
              sx={{
                minWidth: "259px",
                "& .MuiSelect-icon": {
                  color: themeMode === "dark" ? "white" : "black",
                },
                "& .MuiSelect-select.MuiSelect-select": {
                  color: themeMode === "dark" ? "white" : "black",
                },
                "& .MuiSelect-select": {
                  color: "blue", // Text color of the selected item
                },
                "& .MuiInputLabel-root": {
                  color: "red",
                },
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
            >
              {campaign?.admins?.map((member) => (
                <MenuItem value={member}>
                  {member.first_name + " " + member.last_name}
                </MenuItem>
              ))}

              {campaign?.members?.map((member) => (
                <MenuItem value={member}>
                  {member.first_name + " " + member.last_name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            className={
              themeMode === "dark"
                ? "search-header-title"
                : "search-header-title-light"
            }
          >
            {userFilter?.first_name}’s search URL
          </div>
          <div className="pt-2 pl-4">
            {" "}
            <div
              className={
                themeMode === "dark"
                  ? "dashboard-subtitle"
                  : "dashboard-subtitle-light"
              }
            >
              Current search URL (Last updated{" "}
              {moment(
                userFilter?.search_sequencers
                  ?.filter((n) => n.status_type === "active")
                  ?.map((sequencer) => sequencer.created_at)
              )?.format("DD MMM YYYY")}
              ):
            </div>
            <div
              className={`cursor-pointer ${
                themeMode === "dark"
                  ? "dashboard-subtitle "
                  : "dashboard-subtitle-light "
              }`}
              onClick={URL}
            >
              {userFilter?.search_sequencers?.length === 0 && (
                <span style={{ fontWeight: 700 }}>
                  {" "}
                  Set a search url to begin automation
                </span>
              )}
              <i>
                {truncatedString(
                  `${userFilter?.search_sequencers
                    ?.filter((n) => n.status_type === "active")
                    ?.map((sequencer) => sequencer.search_url)}`
                )}
              </i>
            </div>
            {userFilter?.search_sequencers?.length > 0 && (
              <div
                className={
                  themeMode === "dark"
                    ? "dashboard-subtitle pt-2"
                    : "dashboard-subtitle-light pt-2"
                }
              >
                We’ve collected
                <span style={{ fontWeight: 700 }}>
                  {" "}
                  {searchProspectsMeta?.total_count}
                </span>{" "}
                prospects from this URL.{" "}
              </div>
            )}
            <div className="my-4">
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button lg:p-2 lg:px-8"
                    : "create-button-light lg:p-2 lg:px-8"
                }
                variant="contained"
                startIcon={<Edit />}
                onClick={URL}
              >
                <span className="create-button-text"> Edit / View URL</span>
              </Button>
            </div>
          </div>
          {/* <div
            className={
              themeMode === "dark"
                ? "dashboard-subtitle pt-8 pb-1"
                : "dashboard-subtitle-light pt-8 pb-1"
            }
          >
            Enter search URL{" "}
          </div>
          <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center pb-8 space-x-4">
            <OutlinedInput
              value={inputValue}
              //onChange={(e) => setInputValue(e.target.value)}
              onClick={URL}
              id="searchURL"
              className={
                themeMode === "dark"
                  ? "dashboard-field lg:w-full"
                  : "dashboard-field-light lg:w-full"
              }
              size="small"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "& .MuiInputBase-input": {
                  color: themeMode === "dark" ? "white" : "black",
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M5.2 0C6.57913 0 7.90177 0.547855 8.87696 1.52304C9.85215 2.49823 10.4 3.82087 10.4 5.2C10.4 6.488 9.928 7.672 9.152 8.584L9.368 8.8H10L14 12.8L12.8 14L8.8 10V9.368L8.584 9.152C7.672 9.928 6.488 10.4 5.2 10.4C3.82087 10.4 2.49823 9.85215 1.52304 8.87696C0.547855 7.90177 0 6.57913 0 5.2C0 3.82087 0.547855 2.49823 1.52304 1.52304C2.49823 0.547855 3.82087 0 5.2 0ZM5.2 1.6C3.2 1.6 1.6 3.2 1.6 5.2C1.6 7.2 3.2 8.8 5.2 8.8C7.2 8.8 8.8 7.2 8.8 5.2C8.8 3.2 7.2 1.6 5.2 1.6Z"
                        fill={themeMode === "dark" ? "white" : "#000"}
                      />
                    </svg>{" "}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Search"
            />
            <Button
              className={
                themeMode === "dark"
                  ? "create-button lg:p-2 lg:px-8"
                  : "create-button-light lg:p-2 lg:px-8"
              }
              variant="contained"
              onClick={() =>
                onSubmit(document.getElementById("searchURL").value)
              }
            >
              <span className="create-button-text">Submit</span>
            </Button>
          </div> */}
          <div className="flex items-center space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              // className="lg:ml-6"
            >
              <path
                d="M0.625 13.125L7.5 1.25L14.375 13.125H0.625ZM7.5 11.25C7.67708 11.25 7.82563 11.19 7.94563 11.07C8.06563 10.95 8.12542 10.8017 8.125 10.625C8.12458 10.4483 8.06458 10.3 7.945 10.18C7.82542 10.06 7.67708 10 7.5 10C7.32292 10 7.17458 10.06 7.055 10.18C6.93542 10.3 6.87542 10.4483 6.875 10.625C6.87458 10.8017 6.93458 10.9502 7.055 11.0706C7.17542 11.191 7.32375 11.2508 7.5 11.25ZM6.875 9.375H8.125V6.25H6.875V9.375Z"
                fill={linkedin?.connected ? "#217CF9" : "#FF0000"}
              />
            </svg>
            {linkedin?.connected ? (
              <div
                className="reconnect"
                style={{ color: themeMode === "dark" ? "#24ff00" : "#217CF9" }}
              >
                LinkedIn account connected
              </div>
            ) : (
              <div className="reconnect">Reconnect your LinkedIn account</div>
            )}

            <MySwitch
              disabled={linkedin?.connected}
              onChange={() => {
                dispatch(setSelectedTabValue(2));
                navigate("/dashboard/settings");
              }}
            />
            <Tooltip
              title={
                linkedin?.connected
                  ? "Account already connected, no action required"
                  : "Reconnect your LinkedIn account"
              }
            >
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.41667 10.5H7.58333V9.33333H6.41667V10.5ZM7 3.5C6.38116 3.5 5.78767 3.74583 5.35008 4.18342C4.9125 4.621 4.66667 5.21449 4.66667 5.83333H5.83333C5.83333 5.52391 5.95625 5.22717 6.17504 5.00838C6.39383 4.78958 6.69058 4.66667 7 4.66667C7.30942 4.66667 7.60616 4.78958 7.82496 5.00838C8.04375 5.22717 8.16667 5.52391 8.16667 5.83333C8.16667 7 6.41667 6.85417 6.41667 8.75H7.58333C7.58333 7.4375 9.33333 7.29167 9.33333 5.83333C9.33333 5.21449 9.0875 4.621 8.64992 4.18342C8.21233 3.74583 7.61884 3.5 7 3.5ZM2.91667 1.75H11.0833C11.3928 1.75 11.6895 1.87292 11.9083 2.09171C12.1271 2.3105 12.25 2.60725 12.25 2.91667V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V2.91667C1.75 2.60725 1.87292 2.3105 2.09171 2.09171C2.3105 1.87292 2.60725 1.75 2.91667 1.75Z"
                  fill="#217CF9"
                />
              </svg>
            </Tooltip>
          </div>
        </div>
        <div className="py-8 w-full">
          <div
            className="search-list-info"
            style={{ color: themeMode === "dark" ? "#24ff00" : "#217CF9" }}
          >
            Why is my search list number different than Sales Navigator?
          </div>
          <div className="search-list-info-det" style={{ fontStyle: "italic" }}>
            Sales Navigator and Search List may have different number for these
            reaons
          </div>
          <div
            className="search-list-info-det pt-2"
            style={{ fontWeight: "bold" }}
          >
            Display Cap
          </div>
          <div className="search-list-info-det">
            This page displays a maximum of 2,500 prospects at a time.
          </div>
          <div
            className="search-list-info-det pt-2"
            style={{ fontWeight: "bold" }}
          >
            No Re-Invites{" "}
          </div>
          <div className="search-list-info-det">
            We auto-exclude prospects previously invited to avoid LinkedIn
            restrictions. To check who you’ve sent an invite to, visit this page
            on LinkedIn.{" "}
          </div>
          <div
            className="search-list-info-det pt-2"
            style={{ fontWeight: "bold" }}
          >
            Duplicate Control{" "}
          </div>
          <div className="search-list-info-det">
            If you or your team member have “Dupe prospect” turned on, it
            prevents recollecting the same prospect within a time period across
            campaigns.
          </div>
        </div>
      </div>

      <SearchHeaderDialog
        open={open}
        handleClose={handleClose}
        URL={URL}
        campaign={campaign}
      />
    </div>
  );
};
