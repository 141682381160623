import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Grid
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateTagDialog from "./CreateTagDialog";
import TagsTable from "./TagsTable";
import { getTags } from "../../../store/settingsSlice";
import EditTagDialog from "./EditTagDialog";
import { width } from "@mui/system";

export const Tags = () => {
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.settings.tags);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openEdit, setOpenEdit] = useState(false);

  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    // dispatch(getTags());
  }, [dispatch]);

  return (
    // <div className="settings-content-layout">
    <div>
      <div
        className={`settings-heading ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Manage tags easily within your organization
      </div>
      <div className="py-6">
        <div className="flex items-center">
          <div
            className={`linkedin-secondary-text ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Use tags to track where your leads are in the funnel (e.g. Meeting
            Booked) or add additional qualifiers (e.g. Decision Maker). We
            prepopulate the following tags to your connections that aren’t
            editable, so they will not show up in the table below: <br />
            <br /> {"  "}
            <div className="pl-6">
              <ul>
                <li>
                  Funnels are tagged as “Replied” and “Message 1", “Message 2”,
                  and so forth;
                </li>
                <li>
                  The prospect’s first reply sentiment is tagged as: “Interested
                  First Reply”, “Maybe” or “Not Interested”.{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Grid
        //</div>
        //className="flex w-full justify-between items-center mt-12 mb-4"
        className="mt-12 mb-4"
        sx={{display:"flex",width:"100%",flexDirection:{md:"row",sm:"row",xs:"column"},justifyContent:"space-between",gap:"15px"}}
        >
          <div className="total-tags" style={{color:themeMode==="dark"?"#FFF":"#000"}}>
            Total Tags: {tags.length < 10 ? `0${tags.length}` : tags.length}
          
          <OutlinedInput
            onChange={(e) => setSearchQuery(e.target.value)}
            id="outlined-adornment-password"
            className={
              themeMode === "dark" ? "dashboard-field mx-2" : "dashboard-field-light mx-2"
            }
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "& .MuiInputBase-input": {
                color: themeMode === "dark" ? "white" : "black",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M5.2 0C6.57913 0 7.90177 0.547855 8.87696 1.52304C9.85215 2.49823 10.4 3.82087 10.4 5.2C10.4 6.488 9.928 7.672 9.152 8.584L9.368 8.8H10L14 12.8L12.8 14L8.8 10V9.368L8.584 9.152C7.672 9.928 6.488 10.4 5.2 10.4C3.82087 10.4 2.49823 9.85215 1.52304 8.87696C0.547855 7.90177 0 6.57913 0 5.2C0 3.82087 0.547855 2.49823 1.52304 1.52304C2.49823 0.547855 3.82087 0 5.2 0ZM5.2 1.6C3.2 1.6 1.6 3.2 1.6 5.2C1.6 7.2 3.2 8.8 5.2 8.8C7.2 8.8 8.8 7.2 8.8 5.2C8.8 3.2 7.2 1.6 5.2 1.6Z"
                      fill="white"
                    />
                  </svg>{" "}
                </IconButton>
              </InputAdornment>
            }
            placeholder="Search"
          />
          </div>
          <Button
            className={`p-2 px-4 ${
              themeMode === "dark" ? "create-button" : "create-button-light"
            }`}
            variant="contained"
            onClick={handleClickOpen}
          >
            <span className="create-button-text">Create Tag</span>
          </Button>
        </Grid>
        <div
          className={`px-8 py-2 my-6 ${
            themeMode === "dark"
              ? "dashboard-table-layout"
              : "dashboard-table-layout-light"
          }`}
        >
          <TagsTable handleClickOpen={handleClickOpenEdit} searchQuery={searchQuery} />
        </div>
      </div>
      <CreateTagDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
      <EditTagDialog
        open={openEdit}
        handleClose={handleCloseEdit}
        handleClickOpen={handleClickOpenEdit}
      />
    </div>
  );
};
