import axios from "axios";
const { REACT_APP_API_URL } = process.env;

const api = axios.create({
  baseURL: REACT_APP_API_URL,
  // Other configuration options...
});

api.interceptors.request.use(
  (request) => {
    const client = localStorage.getItem("client");
    const uid = localStorage.getItem("uid");
    const accessToken = localStorage.getItem("access-token");

    const jwt = accessToken;
    if (jwt) {
        // Add the JWT to the Authorization header
        //request.headers.Authorization = `Bearer ${jwt}`;
        request.headers['Access-Token'] = accessToken;
        request.headers['Client'] = client;
        request.headers['Uid'] = uid;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle unauthorized error (e.g., log out user)
    if (error?.response?.status === 401) {
      localStorage.removeItem("client");
      localStorage.removeItem("uid");
      localStorage.removeItem("access-token");
      localStorage.removeItem("user");
      window.location.href = '/'
    }
    return Promise.reject(error);
  }
);

export default api;
