import React, { useState, useEffect } from "react";
import { Grid, Input, Switch, Slider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const MySwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 45,
  height: 26,
  padding: 0.05,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#FF0000",
      "& + .MuiSwitch-track": {
        backgroundColor: "#6A0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#FF0000",
      border: "6px solid red",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#292929",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#292929",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const convertTimeStringToValue = (timeString) => {
  const [time, modifier] = timeString.split(/(am|pm)/);
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "pm" && hours < 12) {
    hours += 12;
  }
  if (modifier === "am" && hours === 12) {
    hours = 0;
  }

  return hours + minutes / 60;
};

const convertValueToTimeString = (value) => {
  const hours = Math.floor(value);
  const minutes = (value % 1) * 60;
  const time = new Date();
  time.setHours(hours);
  time.setMinutes(minutes);
  return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const TimeRangeSlider = ({
  start,
  end,
  onStartChange,
  onEndChange,
  themeMode,
}) => {
  const [value, setValue] = useState([
    convertTimeStringToValue(start),
    convertTimeStringToValue(end),
  ]);

  useEffect(() => {
    setValue([convertTimeStringToValue(start), convertTimeStringToValue(end)]);
  }, [start, end]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onStartChange(convertValueToTimeString(newValue[0]));
    onEndChange(convertValueToTimeString(newValue[1]));
  };

  return (
    <Grid
      sx={{
        width: { md: 600, sm: 400, xs: 170 },
        margin: "0 auto",
        color: themeMode === "dark" ? "white" : "black",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body2">
          {convertValueToTimeString(value[0])}
        </Typography>
        <Typography variant="body2">
          {convertValueToTimeString(value[1])}
        </Typography>
      </div>
      <Slider
        value={value}
        onChange={handleChange}
        min={0}
        max={24}
        step={0.5}
        sx={{
          color: "red",
          height: 4,
          "& .MuiSlider-track": {
            color: "red",
          },
          "& .MuiSlider-rail": {
            color: "grey",
          },
          "& .MuiSlider-thumb": {
            height: 16,
            width: 16,
            backgroundColor: themeMode === "dark" ? "white" : "black",
            border: themeMode === "dark" ? "2px solid #FFF" : "2px solid black",
            "&:hover, &.Mui-focusVisible, &.Mui-active": {
              boxShadow: "inherit",
            },
          },
        }}
      />
    </Grid>
  );
};

export { MySwitch, TimeRangeSlider };
