import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  linkedInConnectedStatus: {},
  tags: [],
  tag: {},
  quickResponses: [],
  quickResponse: {},
  emailNotifications: [],
  organization: {},
  emailNotification: {},
  showCaptcha: false,
  connectLinkedInAccount: {},
  codeCheckpoint: {},
  codeLoading: false,
  connectLinkedInLoading: false,
  selectedTabValue: 1,
};

export const getLinkedInConnectedStatus = createAsyncThunk(
  "settings/getLinkedInConnectedStatus",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/linkedin_connected_accounts/connected_account`
      );

      return res.data;
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const disconnectLinkedInAccount = createAsyncThunk(
  "settings/disconnectLinkedInAccount",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.delete(
        `${REACT_APP_API_URL}/linkedin_connected_accounts/disconnect_account`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data));
      console.error(err);
      return null;
    }
  }
);

export const connectLinkedInAccount = createAsyncThunk(
  "settings/connectLinkedInAccount",
  async (connect_account, { dispatch, getState }) => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/linkedin_connected_accounts/connect_account`,
        { connect_account }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data));
      console.error(err);
      return null;
    }
  }
);

export const solveCodeCheckpoint = createAsyncThunk(
  "settings/solveCodeCheckpoint",
  async (code_checkpoint, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/linkedin_connected_accounts/code_checkpoint`,
        { code_checkpoint }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

// Tags End-points

export const getTags = createAsyncThunk(
  "settings/getTags",
  async ({ tag_query, page = 1, per_page = 10 }, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/app_settings/list_tags`,
        {
          params: {
            query: tag_query ? tag_query : "",
            page: page,
            per_page: per_page,
          },
        }
      );

      return { data: res.data, meta: res.meta, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const deleteTag = createAsyncThunk(
  "settings/deleteTag",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.delete(
        `${REACT_APP_API_URL}/app_settings/delete_tag/${params}`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const createTag = createAsyncThunk(
  "settings/createTag",
  async (app_settings, { dispatch, getState }) => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/app_settings/create_tag`,
        { app_settings }
      );

      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const updateTag = createAsyncThunk(
  "settings/updateTag",
  async (app_settings, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/app_settings/update_tag/${app_settings.uuid}`,
        { app_settings: { title: app_settings.title } }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

// QuickResponses End-points

export const getQuickResponses = createAsyncThunk(
  "settings/getQuickResponses",
  async (
    { quickResponseQuery, page = 1, per_page = 10 },
    { dispatch, getState }
  ) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/app_settings/list_quick_responses`,
        {
          params: {
            query: quickResponseQuery,
            page: page,
            per_page: per_page,
          },
        }
      );

      return { data: res.data, meta: res.meta, status: res.status };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const createQuickResponse = createAsyncThunk(
  "settings/createQuickResponse",
  async (app_settings, { dispatch, getState }) => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/app_settings/create_quick_response`,
        { app_settings }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response?.statusText));
      console.error(err);
      return null;
    }
  }
);

export const updateQuickResponse = createAsyncThunk(
  "settings/updateQuickResponse",
  async (app_settings, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/app_settings/update_quick_response/${app_settings.uuid}`,
        { app_settings }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const deleteQuickResponse = createAsyncThunk(
  "settings/deleteQuickResponse",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.delete(
        `${REACT_APP_API_URL}/app_settings/delete_quick_response/${params}`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

// EmailNotifications End-points

export const getEmailNotifications = createAsyncThunk(
  "settings/getEmailNotifications",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/app_settings/show_email_notification_settings`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const updateEmailNotifications = createAsyncThunk(
  "settings/updateEmailNotifications",
  async (app_settings, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/app_settings/update_email_notification_settings`,
        { app_settings }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

// Organization End-points

export const getOrganization = createAsyncThunk(
  "settings/getOrganization",
  async (params, { dispatch, getState }) => {
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/app_settings/show_organization_settings`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const updateOrganization = createAsyncThunk(
  "settings/updateOrganization",
  async (app_settings, { dispatch, getState }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/app_settings/update_organization_settings`,
        { app_settings }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    },
    setSelectedTabValue: (state, action) => {
      state.selectedTabValue = action.payload;
    },
    setQuickResponse: (state, action) => {
      state.quickResponse = action.payload;
    },
    setEmailNotification: (state, action) => {
      state.emailNotification = action.payload;
    },
    setShowCaptcha: (state, action) => {
      state.showCaptcha = action.payload;
    },
    setLinkedInConnectedStatus: (state, action) => {
      state.linkedInConnectedStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLinkedInConnectedStatus.fulfilled, (state, action) => {
      state.linkedInConnectedStatus = action.payload;
    });
    builder.addCase(connectLinkedInAccount.pending, (state, action) => {
      state.connectLinkedInLoading = true;
    });
    builder.addCase(connectLinkedInAccount.fulfilled, (state, action) => {
      state.connectLinkedInAccount = action.payload?.data;
      state.connectLinkedInLoading = false;
    });
    builder.addCase(connectLinkedInAccount.rejected, (state, action) => {
      state.connectLinkedInLoading = false;
    });
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload?.data;
    });
    builder.addCase(getQuickResponses.fulfilled, (state, action) => {
      state.quickResponses = action.payload?.data;
    });
    builder.addCase(getEmailNotifications.fulfilled, (state, action) => {
      state.emailNotifications = action.payload?.data;
    });
    builder.addCase(getOrganization.fulfilled, (state, action) => {
      state.organization = action.payload?.data;
    });
    builder.addCase(solveCodeCheckpoint.pending, (state, action) => {
      state.codeLoading = true;
    });
    builder.addCase(solveCodeCheckpoint.fulfilled, (state, action) => {
      state.codeLoading = false;
      state.codeCheckpoint = action.payload;
      state.showCaptcha = false;
    });
    builder.addCase(solveCodeCheckpoint.rejected, (state, action) => {
      state.codeLoading = false;
      state.showCaptcha = false;
    });
  },
});

export const {
  setTag,
  setQuickResponse,
  setEmailNotification,
  setShowCaptcha,
  setLinkedInConnectedStatus,
  setSelectedTabValue,
} = settingsSlice.actions;

export default settingsSlice.reducer;
