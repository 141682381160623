import React, { useState } from "react";
import {
  Popover,
  TextField,
  IconButton,
  Box,
  styled,
  Popper,
  Typography
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import {
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    backgroundColor: "red", 
    color: "white", 
  },
});

export default function DateRangePopover({ startDate, endDate, setStartDate, setEndDate}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const themeMode = useSelector((state) => state.themeMode.mode);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton onClick={handleClick}>
        <DateRangeIcon
          style={{ color: themeMode === "dark" ? "white" : "black" }}
        />
      </IconButton>

      <Popover
        PaperProps={{
          sx: {
            padding: "0px",
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF",
            color: themeMode === "dark" ? "white" : "black",
            borderRadius: "10px", 
            border:
              themeMode === "dark" ? "1px solid white" : "1px solid black",
          },
        }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className="flex space-x-8 p-6">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              className={
                themeMode === "dark"
                  ? "custom-datetime-picker"
                  : "custom-datetime-picker-light"
              }
            >
              <Typography
                variant="body1"
                sx={{ color: themeMode === "dark" ? "white" : "black" }}
              >
                Start Date
              </Typography>
              <DatePicker
                placeholder="Start Date"
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                maxDate={endDate ? endDate : null}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    placeholder="Start Date"
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        "& fieldset": {
                          border: "none", 
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          color: themeMode === "dark" ? "white" : "black",
                        },
                      },
                    }}
                    className={
                      themeMode === "dark"
                        ? "meetings-time-field"
                        : "meetings-time-field-light"
                    }
                    {...params}
                  />
                )}
                PopperProps={{
                  component: StyledPopper, 
                }}
              />
            </Box>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              className={
                themeMode === "dark"
                  ? "custom-datetime-picker"
                  : "custom-datetime-picker-light"
              }
            >
              <Typography
                variant="body1"
                sx={{ color: themeMode === "dark" ? "white" : "black" }}
              >
                End Date
              </Typography>
              <DatePicker
                placeholder="End Date"
                value={endDate}
                onChange={(newValue) => {
                  setEndDate(newValue);
                }}
                minDate={startDate ? startDate : null}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    placeholder="End Date"
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        "& fieldset": {
                          border: "none", 
                        },
                      },
                    }}
                    {...params}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </div>
      </Popover>
    </>
  );
}
