import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignDetail,
  updateCampaign,
} from "../../../../store/campaignSlice";
import { error, success } from "../../../../store/alertSlice";
import { useParams } from "react-router-dom";

function ToggleSwitch() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign.campaign);
  const [enabled, setEnabled] = useState(
    campaign?.allow_inmail_to_open_profiles
  );

  return (
    <div
      className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
        enabled ? "bg-red-800" : "bg-gray-600"
      }`}
      onClick={() => {
        dispatch(
          updateCampaign({
            ...campaign,
            allow_inmail_to_open_profiles: !enabled,
          })
        ).then((resp) => {
          if (resp?.payload?.status === 200) {
            dispatch(getCampaignDetail(id));
            dispatch(success("Campaign updated"));
          } else {
            dispatch(error(resp.payload.statusText));
          }
        });
        setEnabled(!enabled);
      }}
    >
      <div
        className={`bg-gray-200 w-4 h-4 rounded-full shadow-md transform ${
          enabled ? "translate-x-6 bg-red" : "translate-x-0"
        }`}
      />
    </div>
  );
}

export default ToggleSwitch;
