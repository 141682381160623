import { Button, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import TeamMemberTable from "./TeamMemberTable";
import AddCampaignTeamMemberDialog from "./AddCampaignTeamMemberDialog";
import ViewCampaignTeamMemberDialog from "./ViewCampaignTeamMemberDialog";
import { useDispatch, useSelector } from "react-redux";
import { getOrganization } from "../../../../store/settingsSlice";

export const TeamMembers = () => {
  const organization = useSelector((state) => state.settings.organization);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openViewDialog, setOpenViewDialog] = useState(false);

  const handleClickOpenViewDialog = () => {
    setOpenViewDialog(true);
  };
  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
  };

  const [value, setValue] = useState("all");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getOrganization());
  }, []);

  return (
    <div>
      <div
        className={
          themeMode === "dark"
            ? "p-4 my-8 dashboard-table-layout"
            : "p-4 my-8 dashboard-table-layout-light"
        }
      >
        <div className="lg:flex justify-between w-full">
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label="secondary tabs example"
            sx={{
              "& .MuiTab-root": {
                color: themeMode === "dark" ? "#FFF" : "black",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textTransform: "none",
              },
              "& .MuiTab-root.Mui-selected": {
                color: themeMode === "dark" ? "#FFF" : "black",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              },
              "& .MuiTabs-indicator": {
                backgroundColor:
                  themeMode === "dark" ? "whitesmoke" : "#0C0404",
              },
            }}
          >
            <Tab disableRipple value="all" label="All-Time" />
            <Tab disableRipple value="14" label="Active in Last 14 Days" />
            <Tab disableRipple value="7" label="Active in Last 7 Days" />
          </Tabs>
          <Button
            className={
              themeMode === "dark"
                ? "create-button p-4 mt-4 lg:mt-0"
                : "create-button-light p-4 mt-4 lg:mt-0"
            }
            variant="contained"
            onClick={handleClickOpen}
          >
            <span className="create-button-text"> Add Team Members</span>
          </Button>
        </div>

        <div className="py-8" style={{ color: "white" }}>
          {value === "all" && (
            <TeamMemberTable handleClickOpen={handleClickOpenViewDialog} />
          )}
          {value === "14" && (
            <TeamMemberTable handleClickOpen={handleClickOpenViewDialog} />
          )}
          {value === "7" && (
            <TeamMemberTable handleClickOpen={handleClickOpenViewDialog} />
          )}
        </div>
      </div>
      <AddCampaignTeamMemberDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        key={organization}
        organization={organization}
      />
      <ViewCampaignTeamMemberDialog
        open={openViewDialog}
        handleClose={handleCloseViewDialog}
        handleClickOpen={handleClickOpenViewDialog}
      />
    </div>
  );
};
