import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  StyledEngineProvider,
  CssBaseline,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import theme from "./Theme/theme.jsx";
import AlertMessage from "./utils/AlertMessage.js";
import { ReactFlowProvider } from 'reactflow';

const theme2 = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.log("Service Worker registration failed:", error);
    });
}
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ThemeProvider theme={theme2}>
            <CssBaseline />
            <AlertMessage />
            <ReactFlowProvider>
              <App />
            </ReactFlowProvider>
          </ThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

reportWebVitals();
