import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
  activateCampaign,
  deactivateCampaign,
  getCampaignDetail,
  getCampaigns,
  updateCampaign,
} from "../../../store/campaignSlice";
import { error, success } from "../../../store/alertSlice";
import { useParams } from "react-router-dom";

const defaultValues = {
  title: "",
  campaign_type: "prospecting",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter title"),
    campaign_type: yup.string().required("You must select campaign type"),
  })
  .required();

export default function ActivationCampaign({ open, handleClose }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  React.useEffect(() => {
    reset({ ...campaign });
  }, [campaign, reset]);

  function onSubmit(data) {
    console.log("🚀 ~ onSubmit ~ data:", data);
    dispatch(updateCampaign(data)).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(getCampaignDetail(id));
        dispatch(success("Campaign updated"));
      } else {
        dispatch(error(resp.payload.statusText));
      }
    });
    handleClose();
    reset(defaultValues);
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-8"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: themeMode === "dark" ? "white" : "#000" }}
          >
            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2"
                  : "create-campaign-dialog-text-light pb-2"
              }
            >
              Deactivating campaign will result in automation being disabled. No
              messages will be sent out. But you will be able to receive any
              alerts from already sent out messages.
            </div>

            <div className="flex w-full justify-end pt-8 space-x-4">
              <Button
                className={`${
                  themeMode === "dark" ? "create-button" : "create-button-light"
                } p-2`}
                variant="contained"
                onClick={() => {
                  if (campaign?.status_type === "activated") {
                    dispatch(deactivateCampaign(id)).then((res) => {
                      if (res?.payload?.status === 200) {
                        dispatch(success("Campaign deactivated"));
                        dispatch(getCampaignDetail(id));
                        handleClose();
                      }
                    });
                  } else {
                    dispatch(activateCampaign(id)).then((res) => {
                      if (res?.payload?.status === 200) {
                        dispatch(success("Campaign activated"));
                        if (
                          campaign?.automation_checks?.activation_confirmed ===
                          false
                        ) {
                          dispatch(
                            updateCampaign({
                              ...campaign,
                              automation_checks: {
                                activation_confirmed: true,
                                inmail_messages_confirmed:
                                  campaign?.automation_checks
                                    ?.inmail_messages_confirmed,
                                search_list_url_confirmed:
                                  campaign?.automation_checks
                                    ?.search_list_url_confirmed,
                                automated_messages_confirmed:
                                  campaign?.automation_checks
                                    ?.automated_messages_confirmed,
                                timezone_and_sending_hours_confirmed:
                                  campaign?.automation_checks
                                    ?.timezone_and_sending_hours_confirmed,
                              },
                            })
                          );
                        }
                        dispatch(getCampaignDetail(id));
                        handleClose();
                      }
                    });
                  }
                }}
              >
                <span className="create-button-text">
                  {campaign?.status_type === "activated"
                    ? "Deactivate"
                    : "Activate"}{" "}
                  Campaign
                </span>
              </Button>
              <Button
                // className={`${
                //   themeMode === "dark" ? "create-button" : "create-button-light"
                // } p-2`}
                variant="text"
                onClick={() => {
                  handleClose();
                }}
              >
                <span
                  className={
                    themeMode === "dark"
                      ? "create-campaign-dialog-text "
                      : "create-campaign-dialog-text-light "
                  }
                >
                  Go Back
                </span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
