import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { capitalizeJustFirstLetter } from "../../../../utils/globalFunctions";
import {
  Backdrop,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { error, success } from "../../../store/alertSlice";
import { getOrganizationMembers, removeMembers } from "../../../store/organizationSlice";

export default function DeleteMemberDialog({ openDialog, handleCloseDialog, members }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);

  function onSubmit() {
    const organization = {
      member_uuids: members?.map(member => member.uuid)
    }
    dispatch(removeMembers(organization)).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(getOrganizationMembers({ page: 1, per_page: 10 }));
        dispatch(success("removed successfully!"));
      }
    });
    handleCloseDialog();
  }

  if(!members)return;

  return (
    <Backdrop>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", 
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-between cursor-pointer"
          onClick={handleCloseDialog}
        >
          <div />
          <div
            className={
              themeMode === "dark"
                ? "create-campaign-dialog-text "
                : "create-campaign-dialog-text-light "
            }
            style={{ fontSize: "18px", color: "red" }}
          >
            Are you sure you want to Permenantly Delete member?
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "black"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div className="w-full flex justify-between">
              <div />
              <div
                className={
                  themeMode === "dark"
                    ? "whitereate-campaign-dialog-text pb-2"
                    : "create-campaign-dialog-text-light pb-2"
                }
                
              >
                Members will be permenantly deleted from your organization. This cannot be undone. All organization data will be removed from deleted members. 
              </div>
              <div />
            </div>
            <TableContainer sx={{ width: "100%", maxHeight: "200px", overflowY: "auto", overflowX: "auto" }}>
              <Table
                sx={{
                  minWidth: 650,
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: themeMode === "dark" ? 'white' : 'black', textAlign: 'center' }}>Names</TableCell>
                    <TableCell
                      sx={{ p: "9px" }}
                      className={` ${
                        themeMode === "dark"
                        ? "text-light sender-text"
                        : "text-dark sender-text-light"
                      }`}
                      align="left"
                    >
                      <span className="flex space-x-2 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="2"
                          height="25"
                          viewBox="0 0 2 25"
                          fill="none"
                          className="mr-2"
                        >
                        <path
                          d="M1 0V40"
                          stroke={themeMode === "dark" ? "#FFF" : "#000"}
                          strokeWidth="1"
                        />
                        </svg>
                        <span
                            style={{
                                fontSize: "14px",
                                fontStyle: "normal",
                            }}
                        >
                        {" "}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: themeMode === "dark" ? 'white' : 'black', textAlign: 'center' }}>Email</TableCell>
                    <TableCell
                      sx={{ p: "9px" }}
                      className={` ${
                        themeMode === "dark"
                        ? "text-light sender-text"
                        : "text-dark sender-text-light"
                      }`}
                      align="left"
                    >
                      <span className="flex space-x-2 items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="2"
                            height="25"
                            viewBox="0 0 2 25"
                            fill="none"
                            className="mr-2"
                          >
                          <path
                            d="M1 0V40"
                            stroke={themeMode === "dark" ? "#FFF" : "#000"}
                            strokeWidth="1"
                          />
                          </svg>
                          <span
                            style={{
                                fontSize: "14px",
                                fontStyle: "normal",
                            }}
                          >
                          {" "}
                          </span>
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: themeMode === "dark" ? 'white' : 'black', textAlign: 'center' }}>Status
                      <HelpOutlineIcon
                        sx={{ color: themeMode === "dark" ? "white" : "black", paddingLeft: '8px' }}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ p: "9px" }}
                      className={` ${
                        themeMode === "dark"
                        ? "text-light sender-text"
                        : "text-dark sender-text-light"
                      }`}
                      align="left"
                    >
                      <span className="flex space-x-2 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="2"
                          height="25"
                          viewBox="0 0 2 25"
                          fill="none"
                          className="mr-2"
                        >
                        <path
                          d="M1 0V40"
                          stroke={themeMode === "dark" ? "#FFF" : "#000"}
                          strokeWidth="1"
                        />
                        </svg>
                        <span
                          style={{
                            fontSize: "14px",
                            fontStyle: "normal",
                          }}
                        >
                        {" "}
                        </span>
                      </span>
                    </TableCell>
                    <TableCell sx={{ color: themeMode === "dark" ? 'white' : 'black', textAlign: 'center' }}>Permission
                      <HelpOutlineIcon
                        sx={{ color: themeMode === "dark" ? "white" : "black", paddingLeft: '8px' }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {members?.length > 0 &&
                    members?.map((row, index) => (
                      <>
                        <TableRow className={themeMode === "dark" ? " listItemClass" : " listItemClassLight"}
                          key={row.uuid}
                        >
                          <TableCell
                            sx={{
                              p: "9px",
                            }}
                          >
                            <span className="flex items-center space-x-4">
                              <Avatar
                                src={row.image_url ? row.image_url : ''}
                                alt="profile picture"
                              ></Avatar>
                              <span
                                className={` ${
                                  themeMode === "dark"
                                  ? "text-light sender-text"
                                  : "text-dark sender-text-light"
                                }`}
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                }}
                              >
                              {row.first_name} {row.last_name}
                              </span>
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{ p: "9px" }}
                            className={` ${
                              themeMode === "dark"
                              ? "text-light sender-text"
                              : "text-dark sender-text-light"
                            }`}
                            align="left"
                          >
                            <span className="flex space-x-2 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2"
                                height="25"
                                viewBox="0 0 2 25"
                                fill="none"
                                className="mr-2"
                              >
                                <path
                                  d="M1 0V40"
                                  stroke={themeMode === "dark" ? "#FFF" : "#000"}
                                  strokeWidth="1"
                                />
                              </svg>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                }}
                              >
                              {" "}
                              </span>
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: "9px",
                              textAlign: 'center'
                            }}
                          >
                            <span
                              className={` ${
                                themeMode === "dark"
                                ? "text-light sender-text"
                                : "text-dark sender-text-light"
                              }`}
                              style={{
                                fontSize: "14px",
                                fontStyle: "normal",
                              }}
                            >
                              {row.email}
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{ p: "9px" }}
                            className={` ${
                              themeMode === "dark"
                              ? "text-light sender-text"
                              : "text-dark sender-text-light"
                            }`}
                            align="left"
                          >
                            <span className="flex space-x-2 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2"
                                height="25"
                                viewBox="0 0 2 25"
                                fill="none"
                                className="mr-2"
                              >
                              <path
                                d="M1 0V40"
                                stroke={themeMode === "dark" ? "#FFF" : "#000"}
                                strokeWidth="1"
                              />
                              </svg>
                              <span
                                style={{
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                }}
                              >
                              {" "}
                              </span>
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: "9px",
                              textAlign: 'center'
                            }}
                          >
                            <span
                              className={` ${
                                themeMode === "dark"
                                ? "text-light sender-text"
                                : "text-dark sender-text-light"
                              }`}
                              style={{
                                fontSize: "14px",
                                fontStyle: "normal",
                              }}
                            >
                              {row.operational_status_type ? capitalizeJustFirstLetter(row.operational_status_type) : ''}
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{ p: "9px" }}
                            className={` ${
                              themeMode === "dark"
                              ? "text-light sender-text"
                              : "text-dark sender-text-light"
                            }`}
                            align="left"
                          >
                            <span className="flex space-x-2 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2"
                                height="25"
                                viewBox="0 0 2 25"
                                fill="none"
                                className="mr-2"
                              >
                              <path
                                d="M1 0V40"
                                stroke={themeMode === "dark" ? "#FFF" : "#000"}
                                strokeWidth="1"
                              />
                              </svg>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                }}
                              >
                              {" "}
                              </span>
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: "9px",
                              textAlign: 'center'
                            }}
                          >
                            <span
                              className={` ${
                                themeMode === "dark"
                                ? "text-light sender-text"
                                : "text-dark sender-text-light"
                              }`}
                              style={{
                                fontSize: "14px",
                                fontStyle: "normal",
                              }}
                            >
                              {row.organization_role_type ? capitalizeJustFirstLetter(row.organization_role_type) : ''}
                              <VisibilityIcon
                                sx={{ color: themeMode === "dark" ? "white" : "black", paddingLeft: '8px' }}
                              />
                            </span>
                          </TableCell>
                        </TableRow>
                        <div className="mt-2"></div>
                      </>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="flex w-full justify-end space-x-4 pt-8">
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-2 px-8"
                    : "create-button-light p-2 px-8"
                }
                style={{ backgroundColor: "transparent" }}
                variant="contained"
                onClick={handleCloseDialog}
              >
                <span
                  className="create-button-text"
                  style={{
                    color: themeMode === "dark" ? "white" : "black",
                  }}
                >
                  Cancel
                </span>
              </Button>
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-2 px-8"
                    : "create-button-light p-2 px-8"
                }
                variant="contained"
                onClick={() => {
                  onSubmit();
                }}
              >
                <span className="create-button-text">Yes, Delete</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
