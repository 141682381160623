import * as React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Typography, Dialog, CircularProgress } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignDetail,
  getSearchProspects,
  setProspectsUserFilterDetail,
  setSearchUrl,
  updateCampaign,
} from "../../../../store/campaignSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { error, success } from "../../../../store/alertSlice";
import { useEffect } from "react";

export default function SearchHeaderDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);

  const searchURLSetting = useSelector(
    (state) => state.campaign.searchURLSetting
  );
  const prospectUserDetail = useSelector(
    (state) => state.campaign.prospectUserDetail
  );
  const [userFilter, setUserFilter] = useState(prospectUserDetail);
  const { id, name } = useParams();

  const [inputValue, setInputValue] = useState(
    `${prospectUserDetail?.search_sequencers
      ?.filter((n) => n.status_type === "active")
      ?.map((sequencer) => sequencer.search_url)}`
  );

  useEffect(() => {
    setInputValue(
      `${prospectUserDetail?.search_sequencers
        ?.filter((n) => n.status_type === "active")
        ?.map((sequencer) => sequencer.search_url)}`
    );
    setUserFilter(prospectUserDetail);
  }, [open]);

  const onSubmit = (data) => {
    const updatedData = {
      search_url: inputValue,
      id: id,
      user_uuid: userFilter.uuid,
    };
    dispatch(setSearchUrl(updatedData)).then((res) => {
      if (res.payload?.status === 200) {
        if (campaign?.automation_checks?.search_list_url_confirmed === false) {
          dispatch(
            updateCampaign({
              ...campaign,
              automation_checks: {
                activation_confirmed:
                  campaign?.automation_checks?.activation_confirmed,
                inmail_messages_confirmed:
                  campaign?.automation_checks?.inmail_messages_confirmed,
                search_list_url_confirmed: true,
                automated_messages_confirmed:
                  campaign?.automation_checks?.automated_messages_confirmed,
                timezone_and_sending_hours_confirmed:
                  campaign?.automation_checks
                    ?.timezone_and_sending_hours_confirmed,
              },
            })
          );
        }
        dispatch(getCampaignDetail(id)).then((resp) => {
          dispatch(
            setProspectsUserFilterDetail(resp?.payload?.data?.admins?.[0])
          );
        });
        dispatch(
          getSearchProspects({
            campaign: id,
            user_uuid: userFilter.uuid,
          })
        );
        dispatch(success("URL updated successfully"));
        setInputValue("");
      }
      handleClose();
    });
  };

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", // Inner background color
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-8"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "black"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <Typography
              className={
                themeMode === "dark" ? "text-light pb-4" : "text-dark pb-4"
              }
              sx={{
                fontFamily: "Poppins",
                fontSize: { md: "33px", sm: "27px", xs: "22px" },
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                height: "fit-content",
              }}
            >
              Current search URL{" "}
            </Typography>
            <div className="flex flex-col  space-y-4   items-center pb-8  ">
              <OutlinedInput
                rows={6}
                multiline
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                id="searchURL"
                className={
                  themeMode === "dark"
                    ? "dashboard-field w-full"
                    : "dashboard-field-light w-full"
                }
                size="small"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "& .MuiInputBase-input": {
                    color: themeMode === "dark" ? "white" : "black",
                  },
                }}
                placeholder="Enter URL"
              />
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button lg:p-2 lg:px-8"
                    : "create-button-light lg:p-2 lg:px-8"
                }
                variant="contained"
                onClick={() =>
                  onSubmit(document.getElementById("searchURL").value)
                }
              >
                <span className="create-button-text flex items-center">
                  {searchURLSetting && (
                    <CircularProgress
                      sx={{ color: "white" }}
                      size={20}
                      className="mr-4"
                    />
                  )}{" "}
                  Submit
                </span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
