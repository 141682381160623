import { Button } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Conversation } from "./Conversation";
import { ScheduledMessages } from "./ScheduledMessages";
import { Notes } from "./Notes";

export const MessageTabs = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const handleChange = (tabValue) => {
    setSelectedTab(tabValue);
  };

  return (
    <div className="py-4">
      <div className=" flex flex-col lg:flex-row space-y-4 lg:space-y-0 space-x-0 lg:space-x-4 px-6">
        <Button
          className={
            selectedTab === 1
              ? themeMode === "dark"
                ? "message-filter-btn-contained p-2 px-4"
                : "message-filter-btn-contained-light p-2 px-4"
              : themeMode === "dark"
              ? "message-filter-btn-outlined p-2 px-4"
              : "message-tab-btn-outlined-light p-2 px-4"
          }
          onClick={() => handleChange(1)}
        >
          <span
            className={
              selectedTab === 1 ? "message-text-selected" : "message-text"
            }
          >
            Conversation
          </span>
        </Button>
        <Button
          className={
            selectedTab === 2
              ? themeMode === "dark"
                ? "message-filter-btn-contained p-2 px-4"
                : "message-filter-btn-contained-light p-2 px-4"
              : themeMode === "dark"
              ? "message-filter-btn-outlined p-2 px-4"
              : "message-tab-btn-outlined-light p-2 px-4"
          }
          onClick={() => handleChange(2)}
        >
          <span
            className={
              selectedTab === 2 ? "message-text-selected" : "message-text"
            }
          >
            Schedule Message{" "}
          </span>
        </Button>
        <Button
          className={
            selectedTab === 3
              ? themeMode === "dark"
                ? "message-filter-btn-contained p-2 px-4"
                : "message-filter-btn-contained-light p-2 px-4"
              : themeMode === "dark"
              ? "message-filter-btn-outlined p-2 px-4"
              : "message-tab-btn-outlined-light p-2 px-4"
          }
          onClick={() => handleChange(3)}
        >
          <span
            className={
              selectedTab === 3 ? "message-text-selected" : "message-text"
            }
          >
            Notes{" "}
          </span>
        </Button>
      </div>
      <div style={{ borderTop: "2px solid white" }} className="mt-4 h-full">
        {selectedTab === 1 && <Conversation />}
        {selectedTab === 2 && <ScheduledMessages />}
        {selectedTab === 3 && <Notes />}
      </div>
    </div>
  );
};
