import { Grid } from "@mui/material";
import NavbarApp from "../../dashboard/navbar/NavbarApp";
import Toolbar from "../../dashboard/toolbar/Toolbar";
import Footer from "../footer/Footer";
import FooterRed from "../footer/FooterRed";
import { useSelector } from "react-redux";
import { Restrictions } from "./Limitations&Restrictions";



const yellowBg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bgYellowLogo.png";
const redBg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bgRedLogo.png"

const LimitationsBestPractice = () => {
    const themeMode = useSelector((state) => state.themeMode.mode);

    return (
        <div
            className={
                themeMode === "dark"
                    ? "dashboard-bg h-full"
                    : "dashboard-bg-light h-full"
            }
        >
            <Grid
                sx={{
                    backgroundImage: themeMode === "dark" ? `url(${ yellowBg })` : `url(${ redBg })`,
                    backgroundSize: { md: "50% 45%", sm: "75% 50%" },
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100% 75%"
                }}
            >
                <NavbarApp />
                
                <Grid
                    item
                    //pl={{ md: 16, sm: 0, xs: 0 }}
                    md={12}
                    sm={12}
                    xs={12}

                >
                    <Restrictions />
                    
                </Grid>
            </Grid>
{
    themeMode==="dark"?
    <Footer />
    :
    <FooterRed />
}


        </div>
    );
};

export default LimitationsBestPractice
