import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  meetings: [],
  meetingMeta: {},
  meetingStartDate: null,
  meetingEndDate: null,
  meetingQuery: "",
};

export const getMeetings = createAsyncThunk(
  "meeting/getMeetings",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(`${REACT_APP_API_URL}/meetings/all_booked`, {
        params: {
          query: searchQuery ? searchQuery.query : "",
          page: searchQuery ? searchQuery.page : 1,
          per_page: searchQuery ? searchQuery.per_page : 25,
          start_date: searchQuery ? searchQuery.start_date : null,
          end_date: searchQuery ? searchQuery.end_date : null,
        },
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    },
    setQuickResponse: (state, action) => {
      state.quickResponse = action.payload;
    },
    setMeetingStartDate: (state, action) => {
      state.meetingStartDate = action.payload;
    },
    setMeetingEndDate: (state, action) => {
      state.meetingEndDate = action.payload;
    },
    setMeetingQuery: (state, action) => {
      state.meetingQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeetings.fulfilled, (state, action) => {
      state.meetings = action.payload?.data?.data;
      state.meetingMeta = action.payload?.data?.meta;
    });
  },
});

export const {
  setTag,
  setQuickResponse,
  setMeetingStartDate,
  setMeetingEndDate,
  setMeetingQuery,
} = meetingSlice.actions;

export default meetingSlice.reducer;
