import { useEffect, useState } from "react";
import { ConnectionInvitationGraph } from "./ChartGraph";
import { CampaignIceberg } from "./CampaignIceberg";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import OverviewTable from "./OverviewTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getOverviewAnalyticalData,
  getOverviewActivities,
} from "../../../app/store/overviewSlice";
import theme from "../../../Theme/theme";
import DateRangePopover from "../../../utils/DateRangePopover";
import dayjs from "dayjs";

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "22px",
  borderRadius: 20,
  backgroundColor: "#333", // Background color of the track (bar background)
  "& .MuiLinearProgress-bar": {
    borderRadius: 20, // Rounded corners for the progress
    backgroundColor: "#f44336", // Color of the progress indicator
  },
}));

const ProgressWithLabel = ({ value, maxValue }) => {
  return (
    <Box position="relative" display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <CustomLinearProgress
          variant="determinate"
          value={(value / maxValue) * 100}
        />
      </Box>
      <Box
        position="absolute"
        display="flex"
        justifyContent="space-between"
        width="100%"
        px={1} // Adjust spacing for inside values
        alignItems="center"
      >
        <Typography variant="body2" color="white" className="w-1/3">
          0
        </Typography>
        <Typography variant="body2" color="white" className="w-full">
          {value}
        </Typography>
        <Typography variant="body2" color="white" className="pr-4">
          {maxValue}
        </Typography>
      </Box>
    </Box>
  );
};

export const OverviewTab = () => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const currentDate = dayjs(); 
  const firstDate = currentDate.subtract(1, 'month'); 
  const lastDate = currentDate; 
  const [startDate, setStartDate] = useState(firstDate);
  const [endDate, setEndDate] = useState(lastDate);
  const diffInDays = endDate.diff(startDate, 'day');
  const isCustomRange = useMediaQuery(
    "(min-width:900px) and (max-width:1050px)"
  );
  const isCustomRange2 = useMediaQuery(
    "(min-width:600px) and (max-width:760px)"
  );
  const overviewAnalyticalData = useSelector(
    (state) => state.overview.overviewAnalyticalData
  );

  const overviewActivities = useSelector(
    (state) => state.overview.overviewActivities
  );

  useEffect(() => {
    dispatch(getOverviewAnalyticalData({ start_date: startDate?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'), end_date: endDate?.format('YYYY-MM-DDTHH:mm:ss.SSSZ') }));
  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(getOverviewActivities());
  }, []);

  return (
    <div>
      <div
        className="py-24 md:px-4 px-8"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          className={`dashboard-title ${
            themeMode === "dark" ? "text-light" : "text-dark"
          }`}
          sx={{
            display: "flex",
            flexDirection: {
              md: isCustomRange?"column":"row",
              sm:"column",
              xs: "column",
            },
            alignItems: { md: "center", sm: "center", xs: "center" },
            justifyContent: {
              md: "flex-start",
              sm: "flex-start",
              xs: "center",
            },
            width: { md: isCustomRange ? "90%" : "80%", sm: "90%", xs: "100%" },
            marginBottom: { md: "10px", sm: "10px", xs: "10px" },
          }}
        >
          <Typography
            sx={{
              fontWeight: "700",
              fontSize: { md: "30px", sm: "30px", xs: "25px" },
              textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" },
            }}
          >
            {overviewAnalyticalData?.title}{" "}
            <span style={{ fontWeight: "400" }}>Dashboard</span>
          </Typography>
          <Grid sx={{display:"flex",gap:"10px"}}>
            <Button
            sx={{
              display: "flex",
              textTransform: "capitalize",
              background: themeMode === "dark" ? "#00000099" : "#F4F4F4",
              border:
                themeMode === "dark" ? "1px solid #FFF" : "1px solid#4F4F4F",
              color: themeMode === "dark" ? "#FFF" : "#4F4F4F",
              borderRadius: "30px",
              width: "fit-content",
              marginLeft: {md:"50px",sm:"50px",xs:"0px"},
              padding: { md: "10px 30px", sm: "10px 30px", xs: "10px" },
              textWrap: "nowrap",
              marginTop: { md: "0px", sm: "0px", xs: "10px" },
            }}
          >
            {diffInDays === 30 ? (
              <Typography>Last 30 Days</Typography>
            ) : (
              <Typography>{startDate.format('MMM D, YYYY')} - {endDate.format('MMM D, YYYY')}</Typography>
            )}
            </Button>
            <DateRangePopover startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
          </Grid>
        </Grid>

        <Grid
          my={{ md: "", sm: 4, xs: 2 }}
          sx={{
            display: "flex",
            // flexDirection: { md: "row", sm: "column", xs: "column" },
            width: {md:"93%",sm:"93%",xs:"100%"},
            [theme.breakpoints.down(1200)]: {
              flexDirection: "column ",
            },
          }}
        >
          <Grid
            px={{ md: 2, sm: 5 }}
            sx={{
              display: "flex",
              width: "50%",
              marginY: "10px",
              [theme.breakpoints.down(1200)]: {
                marginY: "15px",
                width: "100%",
              },
            }}
          >
            <ConnectionInvitationGraph overviewData={overviewAnalyticalData} />
          </Grid>
          <Grid
            px={{ md: 2, sm: 3 }}
            sx={{
              display: "flex",
              width: "50%",
              marginY: "10px",
              [theme.breakpoints.down(1200)]: {
                marginY: "15px",
                width: "100%",
              },
            }}
          >
            <CampaignIceberg
              iceberg_stats={overviewAnalyticalData?.iceberg_stats}
            />
          </Grid>
        </Grid>

        <Grid
          className={`py-8 mb-8 my-1 ${
            themeMode === "dark"
              ? "dashboard-table-layout-overview"
              : "dashboard-table-layout-overview-light"
          }`}
          sx={{
            padding: "30px 20px",
            width: { md: "90%", sm: "90%", xs: "100%" },
          }}
        >
          <div className="my-4">
            <p style={{ fontSize: "20px", fontWeight: "700" }}>Activities </p>
            <p
              style={{
                color: themeMode === "dark" ? "#FFFFFFB2" : "#000000B2",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Automation Plan Today{" "}
            </p>
          </div>
          <div
            className={`pb-1 ${
              themeMode === "dark"
                ? "activities-label"
                : "activities-label-light"
            }`}
          >
            Prospects to be collected
          </div>
          <ProgressWithLabel
            value={overviewActivities?.prospects_to_be_collected?.collected}
            maxValue={
              overviewActivities?.prospects_to_be_collected?.total_count
            }
          />
          <div
            className={`pb-1 mt-4 ${
              themeMode === "dark"
                ? "activities-label"
                : "activities-label-light"
            }`}
          >
            Prospects to be invited{" "}
          </div>
          <ProgressWithLabel
            value={overviewActivities?.prospects_to_be_invited?.invited}
            maxValue={overviewActivities?.prospects_to_be_invited?.total_count}
          />{" "}
          <div
            className={`pb-1 mt-4 ${
              themeMode === "dark"
                ? "activities-label"
                : "activities-label-light"
            }`}
          >
            Prospects to be followed up{" "}
          </div>
          <ProgressWithLabel
            value={overviewActivities?.prospects_to_be_followed?.followed}
            maxValue={overviewActivities?.prospects_to_be_followed?.total_count}
          />{" "}
        </Grid>

        <Grid
          className={`py-8 my-1 ${
            themeMode === "dark"
              ? "dashboard-table-layout-overview"
              : "dashboard-table-layout-overview-light"
          }`}
          sx={{
            padding: "30px 20px",
            width: { md: "90%", sm: "90%", xs: "100%" },
          }}
        >
          <div className="my-4">
            <p style={{ fontSize: "20px", fontWeight: "700" }}>
              Active Team Members
            </p>
            <p
              style={{
                color: themeMode === "dark" ? "#FFFFFFB2" : "#000000B2",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              See how your team has performed and action on issues.
            </p>
          </div>
          <OverviewTable />
        </Grid>
      </div>
    </div>
  );
};
