import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
  getCampaignDetail,
  getCampaigns,
  updateCampaign,
} from "../../../store/campaignSlice";
import { error, success } from "../../../store/alertSlice";
import { useParams } from "react-router-dom";

const defaultValues = {
  title: "",
  campaign_type: "prospecting",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter title"),
    campaign_type: yup.string().required("You must select campaign type"),
  })
  .required();

export default function EditCampaignDialog({ open, handleClose }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  React.useEffect(() => {
    reset({ ...campaign });
  }, [campaign, reset]);

  function onSubmit(data) {
    console.log("🚀 ~ onSubmit ~ data:", data);
    dispatch(updateCampaign(data)).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(getCampaignDetail(id));
        dispatch(success("Campaign updated"));
      } else {
        dispatch(error(resp.payload.statusText));
      }
    });
    handleClose();
    reset(defaultValues);
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D", backdropFilter: "blur(4px)" }}
        PaperProps={{
          sx: {
            backgroundColor: themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-8"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: themeMode === "dark" ? "white" : "#000" }}
          >
            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2"
                  : "create-campaign-dialog-text-light pb-2"
              }
            >
              Name your campaign
            </div>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  required
                  className={`${themeMode==="dark"?"create-campaign-dialog-field":"create-campaign-dialog-field-light"} w-full`}
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "white" : "#000",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "white" : "#000",
                      },
                    },
                  }}
                />
              )}
              name="title"
              control={control}
            />
            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2 pt-4"
                  : "create-campaign-dialog-text-light pb-2 pt-4"
              }
            >
              Type of campaign{" "}
            </div>
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.campaign_type} fullWidth required>
                  <Select
                    defaultValue="prospecting"
                    className={`${themeMode === "dark" ? "create-campaign-dialog-field" : "create-campaign-dialog-field-light"} w-full`}
                    size="small"
                    disabled
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "gray",
                      },
                      minWidth: "259px",
                      "& .MuiSelect-icon": {
                        color: themeMode === "dark" ? "white" : "#000",
                      },
                      "& .MuiSelect-select.MuiSelect-select": {
                        color: themeMode === "dark" ? "white" : "#000",
                        fontWeight: "100px",
                      },
                      "& .MuiSelect-select": {
                        color: themeMode === "dark" ? "white" : "black",
                      },
                      "& .MuiInputLabel-root": {
                        color: themeMode === "dark" ? "white" : "black",
                      },
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                    }}
                    {...field}
                  >
                    <MenuItem value={"prospecting"}>Prospecting</MenuItem>
                    <MenuItem value={"nurture"} disabled>
                      Nurture
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.campaign_type?.message}
                  </FormHelperText>
                </FormControl>
              )}
              name="campaign_type"
              control={control}
            />

            <div className="flex w-full justify-center pt-8">
              <Button
                className={`${themeMode === "dark" ? "create-button" :"create-button-light"} p-2`}
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="create-button-text">Save Campaign</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
