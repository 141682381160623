import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, IconButton, TablePagination } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getChat,
  getInboxMessages,
  getOutboxMessages,
  getSentMessages,
  getTeamInboxMessages,
  markAsRead,
  setDeleteMessageId,
  setOpenedChat,
  setSelectedIndex,
} from "../../store/messagesSlice";
import { LinkedIn } from "@mui/icons-material";
import Delete from "@mui/icons-material/Delete";
import moment from "moment";
import { LoaderProgress } from "../LoaderProgress";

export default function AllMessagesTable({
  messages,
  handleOpenMessage,
  handleClickOpenDelete,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const inboxMeta = useSelector((state) => state.messages.inboxMeta);
  const messageTab = useSelector((state) => state.messages.messageTab);
  const messagesLoading = useSelector(
    (state) => state.messages.messagesLoading
  );

  const [read, setRead] = useState(true);

  const truncatedString = (str) => {
    if (str?.length > 80) {
      return str.slice(0, 80) + "...";
    }
    return str;
  };

  const [page, setPage] = useState(
    inboxMeta?.current_page - 1 ? inboxMeta?.current_page - 1 : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    inboxMeta?.per_page ? inboxMeta.per_page : 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (messageTab === "My Inbox") {
      dispatch(
        getInboxMessages({
          page: newPage + 1,
          per_page: rowsPerPage,
        })
      );
    } else if (messageTab === "Team Inbox") {
      dispatch(
        getTeamInboxMessages({
          page: newPage + 1,
          per_page: rowsPerPage,
        })
      );
    } else if (messageTab === "My Outbox") {
      dispatch(
        getOutboxMessages({
          page: newPage + 1,
          per_page: rowsPerPage,
        })
      );
    } else {
      dispatch(
        getSentMessages({
          page: newPage + 1,
          per_page: rowsPerPage,
        })
      );
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (messageTab === "My Inbox") {
      dispatch(
        getInboxMessages({
          page: 1,
          per_page: parseInt(event.target.value, 10),
        })
      );
    } else if (messageTab === "Team Inbox") {
      dispatch(
        getTeamInboxMessages({
          page: 1,
          per_page: parseInt(event.target.value, 10),
        })
      );
    }
  };

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      {messagesLoading && <LoaderProgress />}
      <Table
        sx={{
          minWidth: 650,
        }}
        aria-label="simple table"
      >
        <TableBody>
          {messages?.length > 0 &&
            messages.map((row, index) => (
              <>
                <TableRow
                  className={
                    themeMode === "dark" &&
                    !row.chat_messages[row.chat_messages.length - 1].read
                      ? " listItemClass"
                      : themeMode === "light" &&
                        !row.chat_messages[row.chat_messages.length - 1].read
                      ? " listItemClassLight"
                      : themeMode === "dark" &&
                        row.chat_messages[row.chat_messages.length - 1].read
                      ? " listItemClassRead"
                      : " listItemClassReadLight"
                  }
                  onClick={() => {
                    dispatch(getChat(row.uuid)).then(() => {
                      dispatch(setSelectedIndex(index));
                      if (
                        !row.chat_messages[row.chat_messages.length - 1].read &&
                        (messageTab === "My Inbox" ||
                          messageTab === "Team Inbox")
                      ) {
                        dispatch(markAsRead({ id: row.uuid })).then((resp) => {
                          if (resp?.payload?.status === 200) {
                            if (messageTab === "My Inbox") {
                              dispatch(
                                getInboxMessages({
                                  page: page,
                                  per_page: rowsPerPage,
                                })
                              );
                            } else if (messageTab === "Team Inbox") {
                              dispatch(
                                getTeamInboxMessages({
                                  page: page,
                                  per_page: rowsPerPage,
                                })
                              );
                            }
                          }
                        });
                      }
                      handleOpenMessage(row);
                    });
                  }}
                >
                  <TableCell
                    sx={{
                      p: "9px",
                    }}
                  >
                    <span className="flex items-center space-x-4">
                      <Avatar
                        src={row.prospect?.profile_picture_url}
                        alt="profile picture"
                      ></Avatar>
                      <span
                        className={` ${
                          themeMode === "dark"
                            ? "text-light sender-text"
                            : "text-dark sender-text-light"
                        }`}
                        style={{
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: !row.chat_messages[
                            row.chat_messages.length - 1
                          ].read
                            ? "700"
                            : "400",
                        }}
                      >
                        {row.prospect?.name}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell
                    sx={{ p: "9px" }}
                    className={` ${
                      themeMode === "dark"
                        ? "text-light sender-text"
                        : "text-dark sender-text-light"
                    }`}
                    align="left"
                  >
                    <span className="flex space-x-2 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2"
                        height="25"
                        viewBox="0 0 2 25"
                        fill="none"
                        className="mr-2"
                      >
                        <path
                          d="M1 0V40"
                          stroke={themeMode === "dark" ? "#FFF" : "#000"}
                          strokeWidth="1"
                        />
                      </svg>
                      <span
                        style={{
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: !row.chat_messages[
                            row.chat_messages.length - 1
                          ].read
                            ? "700"
                            : "400",
                        }}
                      >
                        {" "}
                        {truncatedString(
                          row.chat_messages[row.chat_messages.length - 1]
                            .message
                        )}
                      </span>
                    </span>
                  </TableCell>
                  <TableCell
                    sx={{ p: "9px" }}
                    className={`  ${
                      themeMode === "dark" ? "text-light" : "text-dark"
                    }`}
                    align="left"
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: !row.chat_messages[
                          row.chat_messages.length - 1
                        ].read
                          ? "700"
                          : "400",
                      }}
                    >
                      {" "}
                      {moment(row.updated_at).format("DD MMM hh:mmA")}
                    </span>
                  </TableCell>
                  <TableCell
                    sx={{
                      p: "9px",
                    }}
                    className={` ${
                      themeMode === "dark"
                        ? "table-row-content text-light"
                        : "table-row-content-light text-dark"
                    }`}
                    align="left"
                  >
                    <div className="flex items-center space-x-2">
                      <Link to={row.prospect?.profile_url}>
                        <IconButton
                          target="_blank"
                          onClick={(ev) => ev.stopPropagation()}
                        >
                          <LinkedIn
                            sx={{
                              color: themeMode === "dark" ? "#FFF" : "#000",
                            }}
                          />
                        </IconButton>
                      </Link>
                      <IconButton
                        color="error"
                        onClick={(ev) => {
                          ev.stopPropagation();
                          handleClickOpenDelete();
                          dispatch(setDeleteMessageId(row.uuid));
                        }}
                      >
                        <Delete
                          sx={{ color: themeMode === "dark" ? "" : "#FF0000" }}
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
                <div className="mt-2"></div>
              </>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={inboxMeta?.total_count ? inboxMeta?.total_count : 0}
        page={page}
        key={inboxMeta}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "14px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "14px",
          },
        }}
      />
    </TableContainer>
  );
}
