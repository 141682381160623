import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import {useSelector } from "react-redux";

const CountdownTimer = ({setFetchStatus}) => {
    const initialTime = 10;
    const [timeLeft, setTimeLeft] = useState(initialTime);
    const themeMode = useSelector((state) => state.themeMode.mode);
    const linkedin = useSelector(
        (state) => state.settings.linkedInConnectedStatus
    );
    useEffect(() => {
        if (timeLeft === 0) {
            setFetchStatus(true);
            const resetTimeout = setTimeout(() => {
                setTimeLeft(initialTime); 
                setFetchStatus(false);
            }, 5000); 

            return () => clearTimeout(resetTimeout); 
        }

        const timerId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timerId);
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    };

    return (
        <Grid sx={{display:"flex",color:themeMode==="dark"?"#FFF":"#000"}}>
            <Typography sx={{display:"flex",fontSize:"15px"}}>
                { linkedin?.profile_info_fetched && timeLeft !==0 ?
                    <>Fetching Status in {formatTime(timeLeft)}</>
                    :
                    <>{" "}{formatTime(timeLeft)}</>
                }
            </Typography>
        </Grid>
    );
};

export default CountdownTimer;
