import { Typography, Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStep } from "../../store/resetSlice";
import { Link } from "react-router-dom";

const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png";

export const SuccessfullyChanged = () => {
  const dispatch = useDispatch();
  const chosenMethod = useSelector((state) => state.reset.chosenMethod);

  return (
    <div className="px-10">
      <div className="welcome-content">
        <img src={logo} alt="logo" width={500} height={500} />
        <Typography
          sx={{
            fontSize: { md: "40px", sm: "40px", xs: "30px" },
            fontWeight: "700",
            textWrap: "wrap",
          }}
        >
          Your Password Has Been Successfully Changed{" "}
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "20px", sm: "20px", xs: "14px" },
            fontWeight: "400",
            textWrap: "wrap",
          }}
        >
          Login with your new password{" "}
          <Link
            to="/login"
            style={{ color: "green", textDecoration: "underline" }}
          >
            here
          </Link>
        </Typography>
      </div>
    </div>
  );
};
