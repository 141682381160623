import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteTag, getTags, setTag } from "../../../store/settingsSlice";
import moment from "moment";
import { error, success } from "../../../store/alertSlice";

function createData(serialNo, title, dateCreated) {
  return {
    serialNo,
    title,
    dateCreated,
  };
}

const styles = {
  row: {
    border: "none",
  },
};

export default function TagsTable({ handleClickOpen, searchQuery }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tags = useSelector((state) => state.settings.tags);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [page, setPage] = useState(0); 
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getTags({ tag_query: searchQuery, page: page + 1, per_page: rowsPerPage })); 
  }, [searchQuery, page, rowsPerPage, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); 
  };

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {/* <TableCell
              className={`table-col-title ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              align="left"
              sx={styles.row}
            >
              S. No.{" "}
            </TableCell> */}
            <TableCell
              className={`table-col-title ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              align="left"
              sx={styles.row}
            >
              Tag Name{" "}
            </TableCell>
            <TableCell
              className={`table-col-title ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              align="left"
              sx={styles.row}
            >
              Date Created{" "}
            </TableCell>
            <TableCell
              className={`table-col-title ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
              align="left"
              sx={styles.row}
            >
              {/* Actions */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tags?.data?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              // onClick={() =>
              //   navigate(`/dashboard/campaigns/${row.name}/${index}`)
              // }
              className="cursor-pointer"
            >
              {/* <TableCell
                className={`table-row-content ${
                  themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                sx={styles.row}
              >
                {index + 1}
              </TableCell> */}
              <TableCell
                className={`table-row-content ${
                  themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
              >
                {row.title}
              </TableCell>
              <TableCell
                className={`table-row-content ${
                  themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
              >
                {moment(row.created_at).format("DD MMMM, YYYY")}
              </TableCell>
              <TableCell
                className={`table-row-stats ${
                  themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
              >
                <div className="flex items-center justify-end space-x-6">
                  <span
                    className="cursor-pointer"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      dispatch(setTag(row));
                      handleClickOpen();
                    }}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M14.7563 3.36828C15.0812 3.04333 15.0812 2.50174 14.7563 2.19345L12.8066 0.243716C12.4983 -0.0812387 11.9567 -0.0812387 11.6317 0.243716L10.0986 1.7685L13.2232 4.89307M0 11.8754V15H3.12457L12.34 5.77628L9.21539 2.65171L0 11.8754Z"
                        fill={themeMode === "dark" ? "#FFF" : "#000"}
                      />
                    </svg>
                  </span>
                  <span
                    onClick={() => {
                      dispatch(deleteTag(row.uuid)).then((resp) => {
                        if (resp.payload.status === 204) {
                          dispatch(getTags());
                          dispatch(success("Tag deleted"));
                        } else {
                          dispatch(error(resp.payload.statusText));
                        }
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="16"
                      viewBox="0 0 12 16"
                      fill="none"
                    >
                      <path
                        d="M12 0.888889H9L8.14286 0H3.85714L3 0.888889H0V2.66667H12M0.857143 14.2222C0.857143 14.6937 1.03775 15.1459 1.35925 15.4793C1.68074 15.8127 2.11677 16 2.57143 16H9.42857C9.88323 16 10.3193 15.8127 10.6408 15.4793C10.9622 15.1459 11.1429 14.6937 11.1429 14.2222V3.55556H0.857143V14.2222Z"
                        fill="#FF0000"
                      />
                    </svg>
                  </span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={tags?.meta?.total_count || 0} 
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
        }}
      />
    </TableContainer>
  );
}
