/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";

const bkg = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/background-gradient-lights%201.png"
const ellipse = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Ellipse%2015.png"
const ellipse2 = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Ellipse%2014.png"
const rocket = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/rocketUp.png"
const dotsAnimation = keyframes`
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
`;


const AIMagicLoad = () => {
    const [loading, setLoading] = useState(false);
    return (
        <Grid
            sx={{
                display: "flex",
                width: "100%",
                //height: {md:"100vh",sm:"100vh",xs:"fit-content"},
                height: { md: "100vh", sm: "fit-content", xs: "fit-content" },
                backgroundImage: `url(${bkg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                justifyContent: "center",
                alignItems: "center"
            }}>
            {
                loading ? (

                    <Grid
                        sx={{
                            display: "flex",
                            width: { md: "65%", sm: "65%", xs: "80%" },
                            padding: { md: "100px 50px", sm: "100px 50px", xs: "100px 10px" },
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <Grid sx={{ background: "transparent" }}>
                            <Grid
                                sx={{
                                    background: "transparent",
                                    position: "relative",
                                    "&::before": {
                                        content: "''",
                                        position: "absolute",
                                        inset: 0,
                                        borderRadius: "38px",
                                        padding: "1px",
                                        background: "linear-gradient(145deg,#E01BA5, #55057B)",
                                        WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                        WebkitMaskComposite: "xor",
                                        maskComposite: "exclude",
                                        transform: {
                                            xs: "scale(1.2)",
                                            sm: "scale(1.15)",
                                            md: "scale(1.125)",
                                        },
                                    },
                                }}
                            >
                                <Grid
                                    sx={{
                                        background: "transparent",
                                        position: "relative",
                                        "&::before": {
                                            content: "''",
                                            position: "absolute",
                                            inset: 0,
                                            transform: { md: "scale(1.1)", sm: "scale(1.1)", xs: "scale(1.15)" },
                                            borderRadius: "38px",
                                            padding: "1px",
                                            background: "linear-gradient(145deg,#E01BA5, #55057B)",
                                            WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                            WebkitMaskComposite: "xor",
                                            maskComposite: "exclude",

                                        },
                                    }}

                                >
                                    <Grid
                                        sx={{
                                            background: "linear-gradient(90deg, #741E61 0%, #39005F 100%)",
                                            padding: { md: "15px 150px", sm: "15px 150px", xs: "15px 50px" },
                                            borderRadius: "38px",
                                            position: "relative",
                                            "&::before": {
                                                content: "''",
                                                position: "absolute",
                                                transform: "scale(1.05)",
                                                transform: { md: "scale(1.05)", sm: "scale(1.05)", xs: "scale(1.1)" },
                                                inset: 0,
                                                borderRadius: "38px",
                                                padding: "1px",
                                                background: "linear-gradient(145deg,#E01BA5, #55057B)",
                                                WebkitMask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                                WebkitMaskComposite: "xor",
                                                maskComposite: "exclude",

                                            },

                                        }}

                                    >
                                        <Typography
                                            sx={{
                                                fontSize: { md: "57px", sm: "57px", xs: "35px" },
                                                fontWeight: "400",
                                                whiteSpace: "nowrap", // To prevent wrapping of text
                                                position: "relative",
                                                "&::after": {
                                                    content: '""',
                                                    position: "absolute",
                                                    animation: `${dotsAnimation} 1.5s steps(4, end) infinite`, // Animation of dots
                                                }
                                            }}
                                        >
                                            Please Wait
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
                    :
                    (
                        <Grid
                            sx={{
                                display: "flex", width: { md: "65%", sm: "85%", xs: "100%" }, 
                                padding: {lg:"100px 50px", md: "100px 50px", sm: "100px 50px", xs: "100px 10px" },
                                backgroundImage: `url(${ellipse}),url(${ellipse2})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "100% 10%,20% 90%",
                                backgroundSize: "100px,125px",

                            }}>

                            <Grid
                                sx={{
                                    position: "absolute",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    zIndex: "99",
                                    top: { xl: "15%", lg: "10%", md: "0px", sm: "0px", xs: "-7%" },
                                    overflow: "visible",
                                    left: { xl: "20%", lg: "18%", md: "15%", sm: "10%", xs: "0%" },
                                    transform: { xl: "scale(2) rotate(45deg)", lg: "scale(1.5) rotate(45deg)", md: "scale(1) rotate(45deg)", sm: "scale(1) rotate(45deg)", xs: "scale(0.65) rotate(45deg)" }
                                }}>
                                <img src={rocket} alt="" style={{ width: "75%", height: "75%" }} />
                            </Grid>

                            <Grid
                                sx={{
                                    padding: {md:'50px 35px',sm:"50px 10px",xs:"50px 15px"},
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    position: 'relative',
                                    background: '#000000B2',
                                    boxShadow: '0px 8px 5px 0px #00000040',
                                    color: '#FFFF',
                                    borderRadius: '15px',
                                    overflow: 'hidden',
                                    height: 'fit-content',

                                    '&::before': {
                                        content: "''",
                                        position: 'absolute',
                                        inset: 0,
                                        borderRadius: '15px',
                                        padding: '1px',
                                        background: 'linear-gradient(180deg, white, transparent)',
                                        WebkitMask:
                                            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                        WebkitMaskComposite: 'xor',
                                        maskComposite: 'exclude'
                                    }
                                }}
                            >
                                <Typography sx={{ fontSize: { xl: "4rem", lg: "36px", md: "36px", sm: "36px", xs: "36px" }, fontWeight: "700" }}>
                                    RESULTS
                                </Typography>
                                <Grid py={4} px={{md:"",sm:"",xs:1}} sx={{ display: "flex", flexDirection: "column",width:"100%" }}>
                                    <Grid item md={12} sm={12} xs={12} my={{md:0,sm:0,xs:1}} sx={{ display: "flex",justifyContent:"center",flexDirection:{md:"row",sm:"row",xs:"column"} }}>
                                        <Grid item md={5.8} sm={5.8} xs={12} mx={1} sx={{width:"100%"}}>
                                            <Typography sx={{ fontSize: { xl:"2rem",lg:"18px",md: "18px", sm: "16px", xs: "14px" }, fontWeight: "400", textAlign:{md: "right",sm:"right",xs:"left"}, textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" } }}>
                                                Company Name:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={5.8} mx={1}  sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "18px", xs: "18px" }, fontWeight: "700", textAlign: "left" }}>AMPROTOCOLABS</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12} my={{md:0,sm:0,xs:1}} sx={{ display: "flex", justifyContent: "center", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                                        <Grid item md={5.8} sm={5.8} xs={12} mx={1} sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "16px", xs: "14px" }, fontWeight: "400", textAlign: { md: "right", sm: "right", xs: "left" }, textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" } }}>
                                                Industry:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={5.8} sm={5.8} xs={12} mx={1} sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "18px", xs: "18px" }, fontWeight: "700", textAlign: "left" }}>
                                                xyz
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12} my={{ md: 0, sm: 0, xs: 1 }} sx={{ display: "flex", justifyContent: "center", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                                        <Grid item md={5.8} sm={5.8} xs={12} mx={1} sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "16px", xs: "14px" }, fontWeight: "400", textAlign: { md: "right", sm: "right", xs: "left" }, textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" } }}>
                                                Company Size:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={5.8} sm={5.8} xs={12} mx={1} sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "18px", xs: "18px" }, fontWeight: "700", textAlign: "left" }}>100</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12} my={{ md: 0, sm: 0, xs: 1 }} sx={{ display: "flex", justifyContent: "center", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                                        <Grid item md={5.8} sm={5.8} xs={12} mx={1} sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "16px", xs: "14px" }, fontWeight: "400", textAlign: { md: "right", sm: "right", xs: "left" },textWrap:{md:"nowrap",sm:"nowrap",xs:"wrap"} }}>
                                                No of LinkedIn Accounts:
                                            </Typography>
                                        </Grid>
                                        <Grid item md={5.8} sm={5.8} xs={12} mx={1} sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "18px", xs: "18px" }, fontWeight: "700", textAlign: "left", textWrap:{md:"nowrap",sm:"nowrap",xs:"wrap"} }}>AMPROTOCOLABS</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item md={12} sm={12} xs={12} my={{ md: 0, sm: 0, xs: 1 }} sx={{ display: "flex", justifyContent: "center", flexDirection: { md: "row", sm: "row", xs: "column" } }}>
                                        <Grid item md={5.8} sm={5.8} xs={12} mx={1} sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "16px", xs: "14px" }, fontWeight: "400", textAlign: { md: "right", sm: "right", xs: "left" }, textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" } }}>
                                                Monthly Goal Revenue:</Typography>
                                        </Grid>
                                        <Grid item md={5.8} mx={1} sx={{ width: "100%" }}>
                                            <Typography sx={{ fontSize: { xl: "2rem", lg: "18px", md: "18px", sm: "18px", xs: "18px" }, fontWeight: "700", textAlign: "left", textWrap: { md: "nowrap", sm: "nowrap", xs: "wrap" } }}>AMPROTOCOLABS</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid p={2} sx={{ 
                                    width: {md:"75%",sm:"75%",xs:"100%"}, borderRadius:"12px",
                                    background: "linear-gradient(90deg, rgba(116, 30, 97, 0.28) 0%, rgba(154, 0, 255, 0.28) 100%)", 
                                    boxShadow: "0px 4px 4px 0px #00000040"
                                }}>
                                    <Typography sx={{ fontSize: { xl: "2rem", lg: "16px", md: "16px", sm: "16px", xs: "16px" }, fontWeight: "400" }}>
                                        We’ve crafted targeted messaging and calculated the optimal random outreach times based on industry best practices.
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    )
            }
        </Grid>
    )
}
export default AIMagicLoad;
