import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { resetPassword, setActiveStep } from "../../store/resetSlice";
import { error } from "../../store/alertSlice";
import axios from "axios";

const bkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeFinal.gif";
const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reset_password_token = searchParams.get("token");
  const client = searchParams.get("client");
  const uid = searchParams.get("uid");
  const access_token = searchParams.get("access-token");

  const dispatch = useDispatch();
  const isCustomRange = useMediaQuery(
    "(min-width:900px) and (max-width:1150px)"
  );
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(
        /[*&^%$#@!]/,
        "Password must contain at least one special character (*&^%$#@!)"
      )
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const onSubmit = (data) => {
    axios.defaults.headers["client"] = client;
    axios.defaults.headers["uid"] = uid;
    axios.defaults.headers["access-token"] = access_token;
    dispatch(
      resetPassword({
        ...data,
        token: reset_password_token,
      })
    ).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(setActiveStep(5));
      } else dispatch(error("Could not perform this action"));
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [isPasswordMatched, setIsPasswordMatched] = useState(false);

  useEffect(() => {
    setIsPasswordMatched(watch("password") === watch("password_confirmation"));
  }, [watch("password"), watch("password_confirmation")]);

  const [animationClass, setAnimationClass] = useState("page-enter");

  useEffect(() => {
    setAnimationClass("page-enter");
    return () => {};
  }, []);

  const handlePageLeave = () => {
    setAnimationClass("page-exit");
    setTimeout(() => {
      navigate("/plan-subscription");
    }, 500);
  };

  return (
    <div className="px-8">
      <img src={logo} alt="logo" />
      <Typography
        sx={{
          fontSize: { md: "40px", sm: "40px", xs: "30px" },
          fontWeight: "700",
          textWrap: "nowrap",
        }}
      >
        Reset your password{" "}
      </Typography>
      <Typography
        sx={{
          fontSize: { md: "18px", sm: "18px", xs: "14px" },
          fontWeight: "400",
          textAlign: { md: "left", sm: "center", xs: "center" },
          maxWidth: "500px",
        }}
      >
        Your password must be at least 8 character long with at least one UPPER
        case, one lower case and one special character (*&^%$#@).
      </Typography>

      <div>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: {
              xs: "90%",
              sm: "100%",
              md: "60%",
              lg: "50%",
              xl: "43%",
            },
            margin: "20px 0px",
          }}
        >
          <div sx={{ display: "flex", flexDirection: "column" }}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  FormHelperTextProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  {...field}
                  placeholder="Enter Password*"
                  type={showPassword ? "text" : "password"}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  required
                  InputProps={{
                    style: {
                      color: "#FFF",
                      borderRadius: "15px",
                      backgroundColor: "transparent",
                      border: "1px solid #FFFFFF",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          style={{ color: "#FFF" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#FFF",
                    },
                    shrink: true,
                  }}
                  sx={{
                    marginY: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />

            <Controller
              name="password_confirmation"
              control={control}
              render={({ field }) => (
                <TextField
                  FormHelperTextProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  {...field}
                  placeholder="Confirm Password*"
                  type={showConfirmPassword ? "text" : "password"}
                  error={!!errors.password_confirmation}
                  helperText={errors?.password_confirmation?.message}
                  required
                  InputProps={{
                    style: {
                      color: "#FFF",
                      borderRadius: "15px",
                      backgroundColor: "transparent",
                      border: "1px solid #FFFFFF",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                          style={{ color: "#FFF" }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#FFF",
                    },
                    shrink: true,
                  }}
                  sx={{
                    marginY: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />
          </div>
        </Box>
        <div mb={2} sx={{ display: "flex" }} className="w-full flex">
          {isPasswordMatched &&
          watch("password") &&
          watch("password_confirmation") ? (
            <>
              <CheckCircleIcon
                style={{ marginRight: "10px", color: "green" }}
              />
              <Typography sx={{ color: "green" }}>Passwords Match</Typography>
            </>
          ) : (
            ""
          )}
        </div>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          sx={{
            fontWeight: "700",
            marginY: "10px",
            backgroundColor: "#FFF",
            borderRadius: "30px",
            color: "#B50000",
            textTransform: "capitalize",
            padding: "8px 20px",
            border: "1px solid transparent",
            "&:hover": {
              background: "transparent",
              color: "#FFF",
              border: "1px solid #FFF",
            },
          }}
        >
          Set Password
        </Button>
        {/* <Typography
          onClick={() => dispatch(setActiveStep(3))}
          className="mt-8 mb-4 cursor-pointer"
          sx={{
            fontSize: { md: "16px", sm: "16px", xs: "14px" },
            fontWeight: "500",
            textAlign: { md: "center", sm: "center", xs: "center" },
          }}
        >
          BACK
        </Typography> */}
      </div>
    </div>
  );
};
export default ChangePassword;
