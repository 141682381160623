import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { removeAlert } from "../app/store/alertSlice";

const AlertMessage = () => {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alert.messages); 
  const timeOut = 1500;

  const handleClose = () => {
    dispatch(removeAlert()); 
  };

  useEffect(() => {
    if (alerts.length) {
      const timeout = setTimeout(() => {
        dispatch(removeAlert());
      }, timeOut);

      return () => clearTimeout(timeout);
    }
  }, [alerts, dispatch, timeOut]);

  if (!alerts.length) return null;

  const currentAlert = alerts[0]; 

  return (
    <Snackbar
      open={true}
      autoHideDuration={timeOut}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={currentAlert.type} sx={{ width: "100%" }}>
        {currentAlert.title && <AlertTitle>{currentAlert.title}</AlertTitle>}
        {currentAlert.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;