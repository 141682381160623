import {
    Box,
    Button,
    TextField,
    Typography,
    Grid
} from "@mui/material";
import { useSelector } from "react-redux";

const AdjustFilters = ({ setSelectedTab }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);

    return (
        <>
            <Box
                sx={{

                    padding: '20px',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: 800,
                        padding: '20px',
                        color: '#fff',
                        marginTop: '20px',
                    }}
                >
                    <Typography variant="h5" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black'}}>
                        Retrieve LinkedIn Sales Navigator Filters
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: 2 }}>
                        Go to this URL and adjust filters given below in LinkedIn Sales Navigation
                    </Typography>
                    <TextField
                        fullWidth
                        placeholder="www.linkedin.com/url"
                        variant="outlined"
                        margin="normal"
                        className={
                            themeMode === "dark"
                                ? "create-campaign-dialog-field w-full"
                                : "create-campaign-dialog-field-light w-full"
                        }
                        sx={{
                            "& .MuiInputBase-input": {
                                color: themeMode === "dark" ? "white" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                    color: themeMode === "dark" ? "white" : "black",
                                },
                            },
                        }}
                    />
                    <Typography variant="h6" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: 4,fontWeight:"700",fontSize:"18px" }}>
                        Filters
                    </Typography>
                    <Grid container spacing={2} sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: 4 }}>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Geography:<span style={{fontWeight:"400"}}> California</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Industry: <span style={{ fontWeight: "400" }}> XYZ</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Company:<span style={{ fontWeight: "400" }}> XYZ</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Job Title and Function: <span style={{ fontWeight: "400" }}>XYZ</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Seniority Level: <span style={{ fontWeight: "400" }}>XYZ</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Years of Experience: <span style={{ fontWeight: "400" }}>XYZ</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Groups: <span style={{ fontWeight: "400" }}>California</span></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Content:<span style={{ fontWeight: "400" }}> California</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Company Relation:<span style={{ fontWeight: "400" }}> California</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Education: <span style={{ fontWeight: "400" }}> California</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Job Changes:<span style={{ fontWeight: "400" }}> California </span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Keywords: <span style={{ fontWeight: "400" }}>California</span></Typography>
                            <Typography my={1} variant="body1" sx={{ fontSize: "14px", fontWeight: "600" }}>Connections: <span style={{ fontWeight: "400" }}>California</span></Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: 2 }}>
                        After filter settings, paste a valid LinkedIn Sales Navigator search list URL here*
                    </Typography>
                    <TextField
                        fullWidth
                        placeholder="LinkedIn Sales Navigator search list URL"
                        variant="outlined"
                        margin="normal"
                        className={
                            themeMode === "dark"
                                ? "create-campaign-dialog-field w-full"
                                : "create-campaign-dialog-field-light w-full"
                        }
                        sx={{
                            "& .MuiInputBase-input": {
                                color: themeMode === "dark" ? "white" : "black",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                    color: themeMode === "dark" ? "white" : "black",
                                },
                            },
                        }}
                    />
                    <Box sx={{ marginTop: 3 }}>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: "none",
                            }}
                            className={
                                themeMode === "dark"
                                    ? "create-button px-9"
                                    : "create-button-light px-9"
                            }
                            onClick={() => setSelectedTab(9)}
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
export default AdjustFilters;