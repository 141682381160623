import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  activeStep: 4,
  chosenMethod: "email",
};

export const suggestFeature = createAsyncThunk(
  "helpCenter/suggestFeature",
  async (support_management, { dispatch, getState }) => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/support_management/feature_request`,
        {
          support_management,
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const reportIssue = createAsyncThunk(
  "helpCenter/reportIssue",
  async (support_management, { dispatch, getState }) => {
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/support_management/report_an_issue`,
        {
          support_management,
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const helpCenterPassword = createAsyncThunk(
  "helpCenter/helpCenterPassword",
  async (forgotData, { dispatch, getState }) => {
    const formData = new FormData();

    formData.append("password", forgotData.password);
    formData.append("password_confirmation", forgotData.password_confirmation);
    formData.append("token", forgotData.token);

    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/auth/password`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const helpCenterSlice = createSlice({
  name: "helpCenter",
  initialState,
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setChosenMethod: (state, action) => {
      state.chosenMethod = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setActiveStep, setChosenMethod } = helpCenterSlice.actions;

export default helpCenterSlice.reducer;
