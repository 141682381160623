import { Grid, Typography } from "@mui/material";
import { WhyChooseUsCard } from "./WhyChooseUsCard";

export const WhyChooseUs = ({ imageUrl }) => {
  return (
    <Grid
      sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: {md:"100% 100%",sm:"100% 100%",xs:"100% 100%"},
        backgroundRepeat:"no-repeat",
        display:"flex",justifyContent:"center",flexDirection:"column",
        overflow:"none",
        height:{md:"fit-content",sm:"fit-content",xs:"fit-content"}
      }}
      className="p-14 py-24 text-white"
    >
      <Typography
        sx={{
          typography: {
            md: "h3",
            sm: "h4",
            xs: "h4",
          },
        }}
        style={{ fontWeight: 700 }}
        className="py-10 text-center"
      >
        Why Choose FacoonLabs
      </Typography>

      <div className="py-8 h-full flex justify-center" >
        <Grid container spacing={{md:10,sm:0,xs:2}}>
          
            <WhyChooseUsCard
              title={"Automated Prospecting"}
              subtitle={
                "Connect with ideal prospects effortlessly using our smart automation tools."
              }
            backgroundGif={"	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/rocket.gif"}
            />{" "}
          
            <WhyChooseUsCard
              title={"Custom Strategies"}
              subtitle={
                "Tailored approaches to meet your unique business needs and objectives."
              }
            backgroundGif={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/settings.gif"}
            />{" "}
          
            <WhyChooseUsCard
              title={"24/7 Support"}
              subtitle={"Our team is available around the clock to assist you."}
            backgroundGif={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/support.gif"}
            />{" "}
          
        </Grid>
      </div>
    </Grid>
  );
};
