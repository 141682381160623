import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
  createCampaign,
  getCampaignDetail,
  getCampaigns,
  setCampaignTab,
} from "../../store/campaignSlice";
import { error, success } from "../../store/alertSlice";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  title: "",
  campaign_type: "prospecting",
  campaign_configure: "manual",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter title"),
    campaign_type: yup.string().required("You must select campaign type"),
  })
  .required();

export default function CreateCampaignDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(data) {
    console.log("🚀 ~ onSubmit ~ data:", data);
    const campaignConfigure = data.campaign_configure;
    delete data.campaign_configure;
    dispatch(createCampaign(data)).then((resp) => {
      if (resp?.payload?.status === 201) {
        dispatch(getCampaigns());
        dispatch(success("Campaign Created"));
        dispatch(getCampaignDetail(resp?.payload?.data?.uuid)).then(() => {
          console.log("🚀 ~ onSubmit ~ campaignConfigure:", campaignConfigure);

          if (campaignConfigure === "manual") {
            dispatch(setCampaignTab(1));
            navigate(
              `/dashboard/campaigns/${resp?.payload?.data?.title.replace(
                /#/g,
                ""
              )}/${resp?.payload?.data?.uuid}`
            );
          } else {
            dispatch(setCampaignTab(7));
            navigate(
              `/dashboard/campaigns/${resp?.payload?.data?.title.replace(
                /#/g,
                ""
              )}/${resp?.payload?.data?.uuid}`
            );
          }
        });
      }
    });
    handleClose();
    reset(defaultValues);
  }
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", // Inner background color
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-8"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke="white"
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2"
                  : "create-campaign-dialog-text-light pb-2"
              }
            >
              Name your new campaign
            </div>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  required
                  className={
                    themeMode === "dark"
                      ? "create-campaign-dialog-field w-full"
                      : "create-campaign-dialog-field-light w-full"
                  }
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "white" : "black",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "white" : "black",
                      },
                    },
                  }}
                />
              )}
              name="title"
              control={control}
            />
            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2 pt-4"
                  : "create-campaign-dialog-text-light pb-2 pt-4"
              }
            >
              Type of campaign{" "}
            </div>
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.campaign_type} fullWidth required>
                  <Select
                    defaultValue="prospecting"
                    className={
                      themeMode === "dark"
                        ? "create-campaign-dialog-field w-full"
                        : "create-campaign-dialog-field-light w-full"
                    }
                    size="small"
                    sx={{
                      minWidth: "259px",
                      "& .MuiSelect-icon": {
                        color: themeMode === "dark" ? "white" : "black",
                      },
                      "& .MuiSelect-select.MuiSelect-select": {
                        color: themeMode === "dark" ? "white" : "black",
                        fontWeight: "100px",
                      },
                      "& .MuiSelect-select": {
                        color: "blue",
                      },
                      "& .MuiInputLabel-root": {
                        color: "red",
                      },
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                    }}
                    {...field}
                  >
                    <MenuItem value={"prospecting"}>Prospecting</MenuItem>
                    <MenuItem value={"nurturing"}>Nurture</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.campaign_type?.message}
                  </FormHelperText>
                </FormControl>
              )}
              name="campaign_type"
              control={control}
            />

            <div
              className={
                themeMode === "dark"
                  ? "create-campaign-dialog-text pb-2 pt-4"
                  : "create-campaign-dialog-text-light pb-2 pt-4"
              }
            >
              Configure campaign{" "}
            </div>
            <Controller
              render={({ field }) => (
                <FormControl error={!!errors.campaign_type} fullWidth required>
                  <Select
                    defaultValue="manual"
                    className={
                      themeMode === "dark"
                        ? "create-campaign-dialog-field w-full"
                        : "create-campaign-dialog-field-light w-full"
                    }
                    size="small"
                    sx={{
                      minWidth: "259px",
                      "& .MuiSelect-icon": {
                        color: themeMode === "dark" ? "white" : "black",
                      },
                      "& .MuiSelect-select.MuiSelect-select": {
                        color: themeMode === "dark" ? "white" : "black",
                        fontWeight: "100px",
                      },
                      "& .MuiSelect-select": {
                        color: "blue",
                      },
                      "& .MuiInputLabel-root": {
                        color: "red",
                      },
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                    }}
                    {...field}
                  >
                    <MenuItem value={"manual"}> Manually</MenuItem>
                    <Tooltip
                      title={"To be launched soon"}
                      arrow
                      placement="top-start"
                    >
                      <MenuItem value={"AI"} disabled>
                        <span>With AI Optimization</span>{" "}
                        <span style={{ fontSize: "13px" }}>
                          {" "}
                          &nbsp; (Too be launched soon)
                        </span>
                      </MenuItem>
                    </Tooltip>
                  </Select>
                  <FormHelperText>
                    {errors?.campaign_type?.message}
                  </FormHelperText>
                </FormControl>
              )}
              name="campaign_configure"
              control={control}
            />

            <div className="flex w-full justify-center pt-8">
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-2"
                    : "create-button-light p-2"
                }
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="create-button-text">Create Campaign</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
