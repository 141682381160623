import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { error } from "./alertSlice";
const { REACT_APP_API_URL } = process.env;

const initialState = {
  value: 0,
  notifications: [],
  notificationMeta: {},
  notificationsLoading: false,
};

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async (searchQuery, { dispatch, getState }) => {
    try {
      const res = await axios.get(`${REACT_APP_API_URL}/notifications`, {
        params: {
          page: searchQuery?.page ? searchQuery.page : 1,
          per_page: searchQuery?.per_page ? searchQuery.per_page : 10,
          read_only: true,
          unread_only: true,
        },
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      // dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const notifMarkAllAsRead = createAsyncThunk(
  "notification/markAllAsRead",
  async (_, { dispatch }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/notifications/mark_all_as_read`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const notifMarkOneAsRead = createAsyncThunk(
  "notification/markAllAsRead",
  async (notification, { dispatch }) => {
    try {
      const res = await axios.put(
        `${REACT_APP_API_URL}/notifications/${notification}/mark_as_read`
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setTag: (state, action) => {
      state.tag = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setQuickResponse: (state, action) => {
      state.quickResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state, action) => {
        state.notificationsLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notificationsLoading = false;

        state.notifications = [
          ...state.notifications,
          ...(action.payload?.data?.data || []),
        ];
        state.notificationMeta = action.payload?.data?.meta;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.notificationsLoading = false;
      })
      .addCase(notifMarkAllAsRead.fulfilled, (state, action) => {
        state.notifications = state.notifications.map((notification) => ({
          ...notification,
          read: true,
        }));
      });
  },
});

export const { setTag, setQuickResponse, setNotifications } =
  notificationSlice.actions;

export default notificationSlice.reducer;
