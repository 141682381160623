import {Grid, Button, Typography,useMediaQuery } from "@mui/material";

export const Hero = ({
  pageTitle,
  mainHeading,
  subHeading,
  primarayButton,
  primayButtonLink,
  secondaryButton,
  secondaryButtonLink,
  imageUrl,
}) => {
  const isCustomRange = useMediaQuery('(max-width:764px)');
  return (
    <div className="px-14 pt-14 pb-4 text-white md:pt-24 sm:pt-26">
      <div className="md:flex md:justify-between items-center">
        <div className="md:flex flex-col md:w-1/2 w-full font-style ">
          <Typography mt={isCustomRange?6:0} variant="h6">{pageTitle}</Typography>
          <Typography
            sx={{
              typography: {
                md: "h2",
                sm: "h3",
                xs: "h4",
              },
            }}
            style={{ fontWeight: 700, }}
            className="py-4"
          >
            {mainHeading}
          </Typography>
          <Typography variant="h6">{subHeading}</Typography>
          <Grid className="py-8 flex" sx={{flexDirection:{md:"row",sm:"row",xs:"column"}}}>
            <Button onClick={primayButtonLink} variant="contained" className="primary-button" sx={{ textWrap: "nowrap", fontSize: { md: "16px", sm: "16px", xs: "13px" }, marginRight: { md: "10px", sm: "10px", xs: "0px" } }}>
              {primarayButton}
            </Button>
            {secondaryButton && (
              <Button onClick={secondaryButtonLink} variant="outlined" className="secondary-button" sx={{ textWrap: "nowrap",marginTop:{md:0,sm:0,xs:"10px"},width:{md:"fit-content",sm:"fit-content",xs:"100%"} }}>
                <span className=" text-white">{secondaryButton}</span>
              </Button>
            )}
          </Grid>
        </div>
        <div>
          <img src={imageUrl} alt="background" />
        </div>
      </div>
    </div>
  );
};
