import { useEffect, useState } from "react";
import { TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Checkbox, Avatar, Menu, MenuItem, Box, Typography, ListItemIcon } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LockIcon from '@mui/icons-material/Lock';
import BoltIcon from '@mui/icons-material/Bolt';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoaderProgress } from "../../LoaderProgress";
import { useNavigate } from "react-router-dom";
import { getOrganizationMembers } from "../../../store/organizationSlice";
import { capitalizeJustFirstLetter } from "../../../../utils/globalFunctions";
import { useDispatch, useSelector } from "react-redux";

function createData(serialNo, responseName, campaign, message) {
  return {
    serialNo,
    responseName,
    campaign,
    message,
  };
}

const rows = [
  createData(
    "01",
    "List",
    "Anthony Muharib",
    "Thanks for connecting {firstname}..."
  ),
  createData(
    "02",
    "Second Message",
    "Ben Metzker",
    "Thanks for connecting {firstname}..."
  ),
  createData(
    "03",
    "Third Message",
    "Ben Metzker",
    "Thanks for connecting {firstname}..."
  ),
  createData("04", "No", "Ben Metzker", "Thanks for connecting {firstname}..."),
  createData(
    "05",
    "Follow Up",
    "Ben Metzker",
    "Thanks for connecting {firstname}..."
  ),
];

const styles = {
  row: {
    border: "none",
  },
};

export default function MembersManagementTable({ handleOpenStatusDialog, handleOpenDialog, selectedMember, setSelectedMember, selectedMembers, setSelectedMembers, searchQuery }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const themeMode = useSelector((state) => state.themeMode.mode);
    const members = useSelector((state) => state.organization.organizationMembers);
    const organizationMembersLoading = useSelector((state) => state.organization.organizationMembersLoading);
    const [anchorEl, setAnchorEl] = useState(null);
    const [page, setPage] = useState(0); 
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        dispatch(getOrganizationMembers({ page: page + 1, per_page: rowsPerPage, searchQuery: searchQuery })); 
    }, [page, rowsPerPage, searchQuery, dispatch]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); 
    };

    const handleSelectAllMembers = (event) => {
        if (event.target.checked) {
            setSelectedMembers(members?.data);
          } else {
            setSelectedMembers([]);
        }
    };

    const handleSelectMembers = (row) => {
        const isSelected = selectedMembers?.some((member) => member.uuid === row.uuid);
        if (isSelected) {
            setSelectedMembers((prevSelected) => prevSelected.filter((member) => member.uuid !== row.uuid));
        } else {
            setSelectedMembers((prevSelected) => [...prevSelected, row]);
        }
    };

    return (
        <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
        {organizationMembersLoading && <LoaderProgress />}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                {/* <TableCell
                className={`table-col-title ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
                >
                S. No.{" "}
                </TableCell> */}
                <TableCell
                className={`table-col-title ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
                >
                Names{" "}
                </TableCell>
                <TableCell
                className={`table-col-title ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
                >
                Email{" "}
                </TableCell>
                <TableCell
                className={`table-col-title ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
                >
                Status{" "}
                <HelpOutlineIcon
                    sx={{ color: themeMode === "dark" ? "white" : "black", paddingLeft: '8px' }}
                />
                </TableCell>
                <TableCell
                className={`table-col-title ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
                >
                Permission
                <HelpOutlineIcon
                    sx={{ color: themeMode === "dark" ? "white" : "black", paddingLeft: '8px' }}
                />
                </TableCell>
                <TableCell
                className={`table-col-title ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                }`}
                align="left"
                sx={styles.row}
                >
                    Select All
                    <IconButton
                        onClick={(ev) => ev.stopPropagation()}
                    >
                    <Checkbox
                        sx={{ color: themeMode === "dark" ? "#FFF" : "#000" }}
                        checked={selectedMembers?.length === members?.data?.length && members?.data?.length > 0} 
                        indeterminate={selectedMembers?.length > 0 && selectedMembers?.length < members?.data?.length} 
                        onChange={handleSelectAllMembers} 
                    />
                    </IconButton>
                </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {members?.data?.map((row, index) => (
                <TableRow
                key={row.uuid}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className="cursor-pointer"
                >
                {/* <TableCell
                    className={`table-row-content ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                    }`}
                    sx={styles.row}
                >
                    {index + 1 < 10 ? `0${index + 1}` : index + 1}
                </TableCell> */}
                <TableCell
                    className={`table-row-content ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                    }`}
                    align="left"
                    sx={styles.row}
                >
                    <span className="flex items-center space-x-4">
                        <Avatar
                            src={row.image_url ? row.image_url : ''}
                            alt="profile picture"
                        ></Avatar>
                        <span
                            className={` ${
                                themeMode === "dark"
                                ? "text-light sender-text"
                                : "text-dark sender-text-light"
                            }`}
                            style={{
                                fontSize: "14px",
                                fontStyle: "normal",
                            }}
                        >
                        {row.first_name} {row.last_name}
                        </span>
                    </span>
                </TableCell>
                <TableCell
                    className={`table-row-content ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                    }`}
                    align="left"
                    sx={styles.row}
                >
                    {row.email}
                </TableCell>
                <TableCell
                    className={`table-row-content ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                    }`}
                    align="left"
                    sx={styles.row}
                >
                    {row.operational_status_type ? capitalizeJustFirstLetter(row.operational_status_type) : ''}
                </TableCell>
                <TableCell
                    className={`table-row-content ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                    }`}
                    align="left"
                    sx={styles.row}
                >
                    {row.organization_role_type ? capitalizeJustFirstLetter(row.organization_role_type) : ''}
                </TableCell>
                <TableCell
                    className={`table-row-stats ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                    }`}
                    align="left"
                    sx={styles.row}
                    onClick={() => {
                        setSelectedMember(row);
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <IconButton onClick={(e) => e.stopPropagation()}>
                            <Checkbox 
                                sx={{ color: themeMode === "dark" ? "white" : "black" }}
                                checked={selectedMembers?.some((member) => member.uuid === row.uuid)}
                                onChange={() => handleSelectMembers(row)}
                            />
                        </IconButton>
                        <IconButton
                            onClick={(e) => {
                                setAnchorEl(e.currentTarget); 
                            }}
                        >
                            <MoreVertIcon 
                                sx={{ color: themeMode === "dark" ? "white" : "black" }}
                            />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                            sx={{
                                "& .MuiPaper-root": {
                                    color: themeMode === "dark" ? "white" : "black",
                                    backgroundColor: themeMode === "dark" ? "rgba(0, 0, 0, 0.8)" : "#FFF",
                                    borderRadius: "10px",
                                    padding: '3px',
                                },
                            }}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'down',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={() => setAnchorEl(null)}
                            >
                                <Box sx={{ padding: '3px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', borderBottom: `1px solid ${themeMode === "dark" ? "#FFF" : "#000"}`, 
                                    "&:hover": {
                                        backgroundColor: themeMode === "dark" ? "#FF2828E5" : "#F0F0F0", 
                                    }, 
                                }}>
                                    <Typography sx={{ fontWeight: 600, color: themeMode === "dark" ? "white" : "black" }}>
                                        Change Permission
                                    </Typography>
                                    <ListItemIcon>
                                        <LockIcon sx={{ color: themeMode === "dark" ? "white" : "black", margin: '10px' }} />
                                    </ListItemIcon>
                                </Box>
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={() => {
                                    setAnchorEl(null);
                                    handleOpenStatusDialog(selectedMembers, selectedMember);
                                }}
                            >
                                <Box sx={{ padding: '3px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', borderBottom: `1px solid ${themeMode === "dark" ? "#FFF" : "#000"}`, 
                                    "&:hover": {
                                        backgroundColor: themeMode === "dark" ? "#FF2828E5" : "#F0F0F0", 
                                    }, 
                                }}>
                                    <Typography sx={{ fontWeight: 600, color: themeMode === "dark" ? "white" : "black" }}>
                                        Change Status
                                    </Typography>
                                    <ListItemIcon>
                                        <BoltIcon sx={{ color: themeMode === "dark" ? "white" : "black", margin: '10px' }} />
                                    </ListItemIcon>
                                </Box>
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                onClick={() => {
                                    setAnchorEl(null);
                                    handleOpenDialog(selectedMembers, selectedMember);
                                }}
                            >
                                <Box sx={{ padding: '3px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', borderBottom: `1px solid ${themeMode === "dark" ? "#FFF" : "#000"}`, 
                                    "&:hover": {
                                        backgroundColor: themeMode === "dark" ? "#FF2828E5" : "#F0F0F0", 
                                    }, 
                                }}>
                                    <Typography sx={{ fontWeight: 600 }}>
                                        Delete Member
                                    </Typography>
                                    <ListItemIcon>
                                        <DeleteIcon sx={{ color: "#FF0000", margin: '10px' }} />
                                    </ListItemIcon>
                                </Box>
                            </MenuItem>
                        </Menu>
                    </div>
                </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        <TablePagination
            component="div"
            count={members?.meta?.total_count || 0} 
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
            "& .Mui-disabled": {
                color: themeMode === "dark" ? "darkgray" : "gray",
            },
            "& .MuiSelect-icon": {
                color: themeMode === "dark" ? "white" : "black",
            },
            "& .MuiTablePagination-displayedRows": {
                color: themeMode === "dark" ? "white" : "black",
                fontSize: "13px",
            },
            "& .MuiTablePagination-toolbar": {
                padding: "10px",
            },
            "& .MuiTablePagination-actions": {
                color: themeMode === "dark" ? "white" : "black",
            },
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
                color: themeMode === "dark" ? "white" : "black",
                fontSize: "13px",
            },
            }}
        />
        </TableContainer>
    );
}
