import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { addRemoveToken, getTokens, setFCMToken } from "./app/store/loginSlice";

const firebaseConfig = {
  apiKey: "AIzaSyDo0U4Vu9N3Zty4zdHV5YlpQoBHsGGQIEc",
  authDomain: "facoon-labs.firebaseapp.com",
  projectId: "facoon-labs",
  storageBucket: "facoon-labs.appspot.com",
  messagingSenderId: "126853054104",
  appId: "1:126853054104:web:77c70c1ebc92f2b8305aed",
  measurementId: "G-77HKJ8GZ27",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = (dispatch, allDeviceTokens) => {
  return getToken(messaging, {
    vapidKey:
      "BIws2Yt1MTZWLIKI4iMgrF5IPmC-C5130ZZWNDLe2BBNF4uyd4zPb3SmY9Wjutn4tikwngWaTn8LvCkqKwwgeO4",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("FCM token:", currentToken);
        dispatch(setFCMToken(currentToken));
        dispatch(getTokens()).then((resp) => {
          if (resp?.payload?.data?.fcm_tokens?.enabled.includes(currentToken)) {
            return;
          } else {
            dispatch(
              addRemoveToken({
                enabled: [
                  ...resp?.payload?.data?.fcm_tokens?.enabled,
                  currentToken,
                ],
                disabled: resp?.payload?.data?.fcm_tokens?.disabled,
              })
            ).then(() => {
              dispatch(getTokens());
            });
          }
        });

        // Send the token to your server or use it to send a notification
      } else {
        console.log("No FCM token available.");
      }
    })
    .catch((err) => {
      console.error("Error getting token:", err);
    });
};

// Listener for receiving messages when your web app is in the foreground
export const onMessageListener = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
};
