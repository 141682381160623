export const countriesList =
  [
    {
      name: "Ascension Island",
      code: "AC",
      emoji: "🇦🇨",
      unicode: "U+1F1E6 U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AC.svg",
      dial_code: "+247"
    },
    {
      name: "Andorra",
      code: "AD",
      emoji: "🇦🇩",
      unicode: "U+1F1E6 U+1F1E9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AD.svg",
      dial_code: "+376"
    },
    {
      name: "United Arab Emirates",
      code: "AE",
      emoji: "🇦🇪",
      unicode: "U+1F1E6 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg",
      dial_code: "+971"
    },
    {
      name: "Afghanistan",
      code: "AF",
      emoji: "🇦🇫",
      dial_code: "+93",
      unicode: "U+1F1E6 U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AF.svg"
    },
    {
      name: "Antigua & Barbuda",
      code: "AG",
      emoji: "🇦🇬",
      unicode: "U+1F1E6 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AG.svg",
      dial_code: "+1-268"
    },
    {
      name: "Anguilla",
      code: "AI",
      emoji: "🇦🇮",
      unicode: "U+1F1E6 U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AI.svg",
      dial_code: "+1-264"
    },
    {
      name: "Albania",
      code: "AL",
      emoji: "🇦🇱",
      unicode: "U+1F1E6 U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AL.svg",
      dial_code: "+355"
    },
    {
      name: "Armenia",
      code: "AM",
      emoji: "🇦🇲",
      unicode: "U+1F1E6 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AM.svg",
      dial_code: "+374"
    },
    {
      name: "Angola",
      code: "AO",
      emoji: "🇦🇴",
      unicode: "U+1F1E6 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AO.svg",
      dial_code: "+244"
    },
    {
      name: "Antarctica",
      code: "AQ",
      emoji: "🇦🇶",
      unicode: "U+1F1E6 U+1F1F6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AQ.svg",
      dial_code: "+672"
    },
    {
      name: "Argentina",
      code: "AR",
      emoji: "🇦🇷",
      unicode: "U+1F1E6 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AR.svg",
      dial_code: "+54"
    },
    {
      name: "American Samoa",
      code: "AS",
      emoji: "🇦🇸",
      unicode: "U+1F1E6 U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AS.svg",
      dial_code: "+1-684"
    },
    {
      name: "Austria",
      code: "AT",
      emoji: "🇦🇹",
      unicode: "U+1F1E6 U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AT.svg",
      dial_code: "+43"
    },
    {
      name: "Australia",
      code: "AU",
      emoji: "🇦🇺",
      unicode: "U+1F1E6 U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AU.svg",
      dial_code: "+61"
    },
    {
      name: "Aruba",
      code: "AW",
      emoji: "🇦🇼",
      unicode: "U+1F1E6 U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AW.svg",
      dial_code: "+297"
    },
    {
      name: "Åland Islands",
      code: "AX",
      emoji: "🇦🇽",
      unicode: "U+1F1E6 U+1F1FD",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AX.svg",
      dial_code: "+358"
    },
    {
      name: "Azerbaijan",
      code: "AZ",
      emoji: "🇦🇿",
      unicode: "U+1F1E6 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AZ.svg",
      dial_code: "+994"
    },
    {
      name: "Bosnia & Herzegovina",
      code: "BA",
      emoji: "🇧🇦",
      unicode: "U+1F1E7 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BA.svg",
      dial_code: "+387"
    },
    {
      name: "Barbados",
      code: "BB",
      emoji: "🇧🇧",
      unicode: "U+1F1E7 U+1F1E7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BB.svg",
      dial_code: "+1-246"
    },
    {
      name: "Bangladesh",
      code: "BD",
      emoji: "🇧🇩",
      unicode: "U+1F1E7 U+1F1E9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BD.svg",
      dial_code: "+880"
    },
    {
      name: "Belgium",
      code: "BE",
      emoji: "🇧🇪",
      unicode: "U+1F1E7 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BE.svg",
      dial_code: "+32"
    },
    {
      name: "Burkina Faso",
      code: "BF",
      emoji: "🇧🇫",
      unicode: "U+1F1E7 U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BF.svg",
      dial_code: "+226"
    },
    {
      name: "Bulgaria",
      code: "BG",
      emoji: "🇧🇬",
      unicode: "U+1F1E7 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BG.svg",
      dial_code: "+359"
    },
    {
      name: "Bahrain",
      code: "BH",
      emoji: "🇧🇭",
      unicode: "U+1F1E7 U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg",
      dial_code: "+973"
    },
    {
      name: "Burundi",
      code: "BI",
      emoji: "🇧🇮",
      unicode: "U+1F1E7 U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BI.svg",
      dial_code: "+257"
    },
    {
      name: "Benin",
      code: "BJ",
      emoji: "🇧🇯",
      unicode: "U+1F1E7 U+1F1EF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BJ.svg",
      dial_code: "+229"
    },
    {
      name: "St. Barthélemy",
      code: "BL",
      emoji: "🇧🇱",
      unicode: "U+1F1E7 U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BL.svg",
      dial_code: "+590"
    },
    {
      name: "Bermuda",
      code: "BM",
      emoji: "🇧🇲",
      unicode: "U+1F1E7 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BM.svg",
      dial_code: "+1-441"
    },
    {
      name: "Brunei",
      code: "BN",
      emoji: "🇧🇳",
      unicode: "U+1F1E7 U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BN.svg",
      dial_code: "+673"
    },
    {
      name: "Bolivia",
      code: "BO",
      emoji: "🇧🇴",
      unicode: "U+1F1E7 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BO.svg",
      dial_code: "+591"
    },
    {
      name: "Caribbean Netherlands",
      code: "BQ",
      emoji: "🇧🇶",
      unicode: "U+1F1E7 U+1F1F6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BQ.svg",
      dial_code: "+599"
    },
    {
      name: "Brazil",
      code: "BR",
      emoji: "🇧🇷",
      unicode: "U+1F1E7 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BR.svg",
      dial_code: "+55"
    },
    {
      name: "Bahamas",
      code: "BS",
      emoji: "🇧🇸",
      unicode: "U+1F1E7 U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BS.svg",
      dial_code: "+1-242"
    },
    {
      name: "Bhutan",
      code: "BT",
      emoji: "🇧🇹",
      unicode: "U+1F1E7 U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BT.svg",
      dial_code: "+975"
    },
    {
      name: "Bouvet Island",
      code: "BV",
      emoji: "🇧🇻",
      unicode: "U+1F1E7 U+1F1FB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BV.svg",
      dial_code: "+47"
    },
    {
      name: "Botswana",
      code: "BW",
      emoji: "🇧🇼",
      unicode: "U+1F1E7 U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BW.svg",
      dial_code: "+267"
    },
    {
      name: "Belarus",
      code: "BY",
      emoji: "🇧🇾",
      unicode: "U+1F1E7 U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BY.svg",
      dial_code: "+375"
    },
    {
      name: "Belize",
      code: "BZ",
      emoji: "🇧🇿",
      unicode: "U+1F1E7 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BZ.svg",
      dial_code: "+501"
    },
    {
      name: "Canada",
      code: "CA",
      emoji: "🇨🇦",
      unicode: "U+1F1E8 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CA.svg",
      dial_code: "+1"
    },
    {
      name: "Cocos (Keeling) Islands",
      code: "CC",
      emoji: "🇨🇨",
      unicode: "U+1F1E8 U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CC.svg",
      dial_code: "+61"
    },
    {
      name: "Congo - Kinshasa",
      code: "CD",
      emoji: "🇨🇩",
      unicode: "U+1F1E8 U+1F1E9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CD.svg",
      dial_code: "+243"
    },
    {
      name: "Central African Republic",
      code: "CF",
      emoji: "🇨🇫",
      unicode: "U+1F1E8 U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CF.svg",
      dial_code: "+236"
    },
    {
      name: "Congo - Brazzaville",
      code: "CG",
      emoji: "🇨🇬",
      unicode: "U+1F1E8 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CG.svg",
      dial_code: "+242"
    },
    {
      name: "Switzerland",
      code: "CH",
      emoji: "🇨🇭",
      unicode: "U+1F1E8 U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CH.svg",
      dial_code: "+41"
    },
    {
      name: "Côte d’Ivoire",
      code: "CI",
      emoji: "🇨🇮",
      unicode: "U+1F1E8 U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CI.svg",
      dial_code: "+225"
    },
    {
      name: "Cook Islands",
      code: "CK",
      emoji: "🇨🇰",
      unicode: "U+1F1E8 U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CK.svg",
      dial_code: "+682"
    },
    {
      name: "Chile",
      code: "CL",
      emoji: "🇨🇱",
      unicode: "U+1F1E8 U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CL.svg",
      dial_code: "+56"
    },
    {
      name: "Cameroon",
      code: "CM",
      emoji: "🇨🇲",
      unicode: "U+1F1E8 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CM.svg",
      dial_code: "+237"
    },
    {
      name: "China",
      code: "CN",
      emoji: "🇨🇳",
      unicode: "U+1F1E8 U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CN.svg",
      dial_code: "+86"
    },
    {
      name: "Colombia",
      code: "CO",
      emoji: "🇨🇴",
      unicode: "U+1F1E8 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CO.svg",
      dial_code: "+57"
    },
    {
      name: "Clipperton Island",
      code: "CP",
      emoji: "🇨🇵",
      unicode: "U+1F1E8 U+1F1F5",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CP.svg",
      dial_code: "+689"
    },
    {
      name: "Costa Rica",
      code: "CR",
      emoji: "🇨🇷",
      unicode: "U+1F1E8 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CR.svg",
      dial_code: "+506"
    },
    {
      name: "Cuba",
      code: "CU",
      emoji: "🇨🇺",
      unicode: "U+1F1E8 U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CU.svg",
      dial_code: "+53"
    },
    {
      name: "Cape Verde",
      code: "CV",
      emoji: "🇨🇻",
      unicode: "U+1F1E8 U+1F1FB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CV.svg",
      dial_code: "+238"
    },
    {
      name: "Curaçao",
      code: "CW",
      emoji: "🇨🇼",
      unicode: "U+1F1E8 U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CW.svg",
      dial_code: "+599"
    },
    {
      name: "Christmas Island",
      code: "CX",
      emoji: "🇨🇽",
      unicode: "U+1F1E8 U+1F1FD",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CX.svg",
      dial_code: "+61"
    },
    {
      name: "Cyprus",
      code: "CY",
      emoji: "🇨🇾",
      unicode: "U+1F1E8 U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CY.svg",
      dial_code: "+357"
    },
    {
      name: "Czechia",
      code: "CZ",
      emoji: "🇨🇿",
      unicode: "U+1F1E8 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/CZ.svg",
      dial_code: "+420"
    },
    {
      name: "Germany",
      code: "DE",
      emoji: "🇩🇪",
      unicode: "U+1F1E9 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DE.svg",
      dial_code: "+49"
    },
    {
      name: "Diego Garcia",
      code: "DG",
      emoji: "🇩🇬",
      unicode: "U+1F1E9 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DG.svg",
      dial_code: "+246"
    },
    {
      name: "Djibouti",
      code: "DJ",
      emoji: "🇩🇯",
      unicode: "U+1F1E9 U+1F1EF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DJ.svg",
      dial_code: "+253"
    },
    {
      name: "Denmark",
      code: "DK",
      emoji: "🇩🇰",
      unicode: "U+1F1E9 U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DK.svg",
      dial_code: "+45"
    },
    {
      name: "Dominica",
      code: "DM",
      emoji: "🇩🇲",
      unicode: "U+1F1E9 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DM.svg",
      dial_code: "+1-767"
    },
    {
      name: "Dominican Republic",
      code: "DO",
      emoji: "🇩🇴",
      unicode: "U+1F1E9 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DO.svg",
      dial_code: "+1-809, +1-829, +1-849"
    },
    {
      name: "Algeria",
      code: "DZ",
      emoji: "🇩🇿",
      unicode: "U+1F1E9 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/DZ.svg",
      dial_code: "+213"
    },
    {
      name: "Ceuta & Melilla",
      code: "EA",
      emoji: "🇪🇦",
      unicode: "U+1F1EA U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EA.svg",
      dial_code: "+34"
    },
    {
      name: "Ecuador",
      code: "EC",
      emoji: "🇪🇨",
      unicode: "U+1F1EA U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EC.svg",
      dial_code: "+593"
    },
    {
      name: "Estonia",
      code: "EE",
      emoji: "🇪🇪",
      unicode: "U+1F1EA U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EE.svg",
      dial_code: "+372"
    },
    {
      name: "Egypt",
      code: "EG",
      emoji: "🇪🇬",
      unicode: "U+1F1EA U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg",
      dial_code: "+20"
    },
    {
      name: "Western Sahara",
      code: "EH",
      emoji: "🇪🇭",
      unicode: "U+1F1EA U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EH.svg",
      dial_code: "+212"
    },
    {
      name: "Eritrea",
      code: "ER",
      emoji: "🇪🇷",
      unicode: "U+1F1EA U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ER.svg",
      dial_code: "+291"
    },
    {
      name: "Spain",
      code: "ES",
      emoji: "🇪🇸",
      unicode: "U+1F1EA U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ES.svg",
      dial_code: "+34"
    },
    {
      name: "Ethiopia",
      code: "ET",
      emoji: "🇪🇹",
      unicode: "U+1F1EA U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ET.svg",
      dial_code: "+251"
    },
    {
      name: "European Union",
      code: "EU",
      emoji: "🇪🇺",
      unicode: "U+1F1EA U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EU.svg",
      dial_code: "+388"
    },
    {
      name: "Finland",
      code: "FI",
      emoji: "🇫🇮",
      unicode: "U+1F1EB U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FI.svg",
      dial_code: "+358"
    },
    {
      name: "Fiji",
      code: "FJ",
      emoji: "🇫🇯",
      unicode: "U+1F1EB U+1F1EF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FJ.svg",
      dial_code: "+679"
    },
    {
      name: "Falkland Islands",
      code: "FK",
      emoji: "🇫🇰",
      unicode: "U+1F1EB U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FK.svg",
      dial_code: "+500"
    },
    {
      name: "Micronesia",
      code: "FM",
      emoji: "🇫🇲",
      unicode: "U+1F1EB U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FM.svg",
      dial_code: "+691"
    },
    {
      name: "Faroe Islands",
      code: "FO",
      emoji: "🇫🇴",
      unicode: "U+1F1EB U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FO.svg",
      dial_code: "+298"
    },
    {
      name: "France",
      code: "FR",
      emoji: "🇫🇷",
      unicode: "U+1F1EB U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/FR.svg",
      dial_code: "+33"
    },
    {
      name: "Gabon",
      code: "GA",
      emoji: "🇬🇦",
      unicode: "U+1F1EC U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GA.svg",
      dial_code: "+241"
    },
    {
      name: "United Kingdom",
      code: "GB",
      emoji: "🇬🇧",
      unicode: "U+1F1EC U+1F1E7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
      dial_code: "+44"
    },
    {
      name: "Grenada",
      code: "GD",
      emoji: "🇬🇩",
      unicode: "U+1F1EC U+1F1E9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GD.svg",
      dial_code: "+1-473"
    },
    {
      name: "Georgia",
      code: "GE",
      emoji: "🇬🇪",
      unicode: "U+1F1EC U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GE.svg",
      dial_code: "+995"
    },








    {
      name: "French Guiana",
      code: "GF",
      emoji: "🇬🇫",
      unicode: "U+1F1EC U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GF.svg",
      dial_code: "+594"
    },
    {
      name: "Guernsey",
      code: "GG",
      emoji: "🇬🇬",
      unicode: "U+1F1EC U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GG.svg",
      dial_code: "+44"
    },
    {
      name: "Ghana",
      code: "GH",
      emoji: "🇬🇭",
      unicode: "U+1F1EC U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GH.svg",
      dial_code: "+233"
    },
    {
      name: "Gibraltar",
      code: "GI",
      emoji: "🇬🇮",
      unicode: "U+1F1EC U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GI.svg",
      dial_code: "+350"
    },
    {
      name: "Greenland",
      code: "GL",
      emoji: "🇬🇱",
      unicode: "U+1F1EC U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GL.svg",
      dial_code: "+299"
    },
    {
      name: "Gambia",
      code: "GM",
      emoji: "🇬🇲",
      unicode: "U+1F1EC U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GM.svg",
      dial_code: "+220"
    },
    {
      name: "Guinea",
      code: "GN",
      emoji: "🇬🇳",
      unicode: "U+1F1EC U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GN.svg",
      dial_code: "+224"
    },
    {
      name: "Guadeloupe",
      code: "GP",
      emoji: "🇬🇵",
      unicode: "U+1F1EC U+1F1F5",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GP.svg",
      dial_code: "+590"
    },
    {
      name: "Equatorial Guinea",
      code: "GQ",
      emoji: "🇬🇶",
      unicode: "U+1F1EC U+1F1F6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GQ.svg",
      dial_code: "+240"
    },
    {
      name: "Greece",
      code: "GR",
      emoji: "🇬🇷",
      unicode: "U+1F1EC U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GR.svg",
      dial_code: "+30"
    },
    {
      name: "South Georgia & South Sandwich Islands",
      code: "GS",
      emoji: "🇬🇸",
      unicode: "U+1F1EC U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GS.svg",
      dial_code: "+500"
    },
    {
      name: "Guatemala",
      code: "GT",
      emoji: "🇬🇹",
      unicode: "U+1F1EC U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GT.svg",
      dial_code: "+502"
    },
    {
      name: "Guam",
      code: "GU",
      emoji: "🇬🇺",
      unicode: "U+1F1EC U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GU.svg",
      dial_code: "+1-671"
    },
    {
      name: "Guinea-Bissau",
      code: "GW",
      emoji: "🇬🇼",
      unicode: "U+1F1EC U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GW.svg",
      dial_code: "+245"
    },
    {
      name: "Guyana",
      code: "GY",
      emoji: "🇬🇾",
      unicode: "U+1F1EC U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GY.svg",
      dial_code: "+592"
    },
    {
      name: "Hong Kong SAR China",
      code: "HK",
      emoji: "🇭🇰",
      unicode: "U+1F1ED U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HK.svg",
      dial_code: "+852"
    },
    {
      name: "Heard & McDonald Islands",
      code: "HM",
      emoji: "🇭🇲",
      unicode: "U+1F1ED U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HM.svg",
      dial_code: "+672"
    },
    {
      name: "Honduras",
      code: "HN",
      emoji: "🇭🇳",
      unicode: "U+1F1ED U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HN.svg",
      dial_code: "+504"
    },
    {
      name: "Croatia",
      code: "HR",
      emoji: "🇭🇷",
      unicode: "U+1F1ED U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HR.svg",
      dial_code: "+385"
    },
    {
      name: "Haiti",
      code: "HT",
      emoji: "🇭🇹",
      unicode: "U+1F1ED U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HT.svg",
      dial_code: "+509"
    },
    {
      name: "Hungary",
      code: "HU",
      emoji: "🇭🇺",
      unicode: "U+1F1ED U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/HU.svg",
      dial_code: "+36"
    },
    {
      name: "Canary Islands",
      code: "IC",
      emoji: "🇮🇨",
      unicode: "U+1F1EE U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IC.svg",
      dial_code: "+34"
    },
    {
      name: "Indonesia",
      code: "ID",
      emoji: "🇮🇩",
      unicode: "U+1F1EE U+1F1E9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ID.svg",
      dial_code: "+62"
    },
    {
      name: "Ireland",
      code: "IE",
      emoji: "🇮🇪",
      unicode: "U+1F1EE U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IE.svg",
      dial_code: "+353"
    },
    {
      name: "Israel",
      code: "IL",
      emoji: "🇮🇱",
      unicode: "U+1F1EE U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IL.svg",
      dial_code: "+972"
    },
    {
      name: "Isle of Man",
      code: "IM",
      emoji: "🇮🇲",
      unicode: "U+1F1EE U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IM.svg",
      dial_code: "+44"
    },
    {
      name: "India",
      code: "IN",
      emoji: "🇮🇳",
      unicode: "U+1F1EE U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg",
      dial_code: "+91"
    },
    {
      name: "British Indian Ocean Territory",
      code: "IO",
      emoji: "🇮🇴",
      unicode: "U+1F1EE U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IO.svg",
      dial_code: "+246"
    },
    {
      name: "Iraq",
      code: "IQ",
      emoji: "🇮🇶",
      unicode: "U+1F1EE U+1F1F6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IQ.svg",
      dial_code: "+964"
    },
    {
      name: "Iran",
      code: "IR",
      emoji: "🇮🇷",
      unicode: "U+1F1EE U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IR.svg",
      dial_code: "+98"
    },
    {
      name: "Iceland",
      code: "IS",
      emoji: "🇮🇸",
      unicode: "U+1F1EE U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IS.svg",
      dial_code: "+354"
    },
    {
      name: "Italy",
      code: "IT",
      emoji: "🇮🇹",
      unicode: "U+1F1EE U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IT.svg",
      dial_code: "+39"
    },
    {
      name: "Jersey",
      code: "JE",
      emoji: "🇯🇪",
      unicode: "U+1F1EF U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JE.svg",
      dial_code: "+44"
    },
    {
      name: "Jamaica",
      code: "JM",
      emoji: "🇯🇲",
      unicode: "U+1F1EF U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JM.svg",
      dial_code: "+1-876"
    },
    {
      name: "Jordan",
      code: "JO",
      emoji: "🇯🇴",
      unicode: "U+1F1EF U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JO.svg",
      dial_code: "+962"
    },
    {
      name: "Japan",
      code: "JP",
      emoji: "🇯🇵",
      unicode: "U+1F1EF U+1F1F5",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/JP.svg",
      dial_code: "+81"
    },
    {
      name: "Kenya",
      code: "KE",
      emoji: "🇰🇪",
      unicode: "U+1F1F0 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KE.svg",
      dial_code: "+254"
    },
    {
      name: "Kyrgyzstan",
      code: "KG",
      emoji: "🇰🇬",
      unicode: "U+1F1F0 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KG.svg",
      dial_code: "+996"
    },
    {
      name: "Cambodia",
      code: "KH",
      emoji: "🇰🇭",
      unicode: "U+1F1F0 U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KH.svg",
      dial_code: "+855"
    },
    {
      name: "Kiribati",
      code: "KI",
      emoji: "🇰🇮",
      unicode: "U+1F1F0 U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KI.svg",
      dial_code: "+686"
    },
    {
      name: "Comoros",
      code: "KM",
      emoji: "🇰🇲",
      unicode: "U+1F1F0 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KM.svg",
      dial_code: "+269"
    },
    {
      name: "St. Kitts & Nevis",
      code: "KN",
      emoji: "🇰🇳",
      unicode: "U+1F1F0 U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KN.svg",
      dial_code: "+1-869"
    },
    {
      name: "North Korea",
      code: "KP",
      emoji: "🇰🇵",
      unicode: "U+1F1F0 U+1F1F5",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KP.svg",
      dial_code: "+850"
    },
    {
      name: "South Korea",
      code: "KR",
      emoji: "🇰🇷",
      unicode: "U+1F1F0 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KR.svg",
      dial_code: "+82"
    },
    {
      name: "Kuwait",
      code: "KW",
      emoji: "🇰🇼",
      unicode: "U+1F1F0 U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KW.svg",
      dial_code: "+965"
    },
    {
      name: "Cayman Islands",
      code: "KY",
      emoji: "🇰🇾",
      unicode: "U+1F1F0 U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KY.svg",
      dial_code: "+1-345"
    },
    {
      name: "Kazakhstan",
      code: "KZ",
      emoji: "🇰🇿",
      unicode: "U+1F1F0 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/KZ.svg",
      dial_code: "+7"
    },
    {
      name: "Laos",
      code: "LA",
      emoji: "🇱🇦",
      unicode: "U+1F1F1 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LA.svg",
      dial_code: "+856"
    },
    {
      name: "Lebanon",
      code: "LB",
      emoji: "🇱🇧",
      unicode: "U+1F1F1 U+1F1E7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LB.svg",
      dial_code: "+961"
    },
    {
      name: "St. Lucia",
      code: "LC",
      emoji: "🇱🇨",
      unicode: "U+1F1F1 U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LC.svg",
      dial_code: "+1-758"
    },
    {
      name: "Liechtenstein",
      code: "LI",
      emoji: "🇱🇮",
      unicode: "U+1F1F1 U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LI.svg",
      dial_code: "+423"
    },
    {
      name: "Sri Lanka",
      code: "LK",
      emoji: "🇱🇰",
      unicode: "U+1F1F1 U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LK.svg",
      dial_code: "+94"
    },
    {
      name: "Liberia",
      code: "LR",
      emoji: "🇱🇷",
      unicode: "U+1F1F1 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LR.svg",
      dial_code: "+231"
    },
    {
      name: "Lesotho",
      code: "LS",
      emoji: "🇱🇸",
      unicode: "U+1F1F1 U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LS.svg",
      dial_code: "+266"
    },
    {
      name: "Lithuania",
      code: "LT",
      emoji: "🇱🇹",
      unicode: "U+1F1F1 U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LT.svg",
      dial_code: "+370"
    },
    {
      name: "Luxembourg",
      code: "LU",
      emoji: "🇱🇺",
      unicode: "U+1F1F1 U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LU.svg",
      dial_code: "+352"
    },
































    {
      name: "Latvia",
      code: "LV",
      emoji: "🇱🇻",
      unicode: "U+1F1F1 U+1F1FB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LV.svg",
      dial_code: "+371"
    },
    {
      name: "Libya",
      code: "LY",
      emoji: "🇱🇾",
      unicode: "U+1F1F1 U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/LY.svg",
      dial_code: "+218"
    },
    {
      name: "Morocco",
      code: "MA",
      emoji: "🇲🇦",
      unicode: "U+1F1F2 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg",
      dial_code: "+212"
    },
    {
      name: "Monaco",
      code: "MC",
      emoji: "🇲🇨",
      unicode: "U+1F1F2 U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MC.svg",
      dial_code: "+377"
    },
    {
      name: "Moldova",
      code: "MD",
      emoji: "🇲🇩",
      unicode: "U+1F1F2 U+1F1E9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MD.svg",
      dial_code: "+373"
    },
    {
      name: "Montenegro",
      code: "ME",
      emoji: "🇲🇪",
      unicode: "U+1F1F2 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ME.svg",
      dial_code: "+382"
    },
    {
      name: "St. Martin",
      code: "MF",
      emoji: "🇲🇫",
      unicode: "U+1F1F2 U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MF.svg",
      dial_code: "+590"
    },
    {
      name: "Madagascar",
      code: "MG",
      emoji: "🇲🇬",
      unicode: "U+1F1F2 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MG.svg",
      dial_code: "+261"
    },
    {
      name: "Marshall Islands",
      code: "MH",
      emoji: "🇲🇭",
      unicode: "U+1F1F2 U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MH.svg",
      dial_code: "+692"
    },
    {
      name: "North Macedonia",
      code: "MK",
      emoji: "🇲🇰",
      unicode: "U+1F1F2 U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MK.svg",
      dial_code: "+389"
    },
    {
      name: "Mali",
      code: "ML",
      emoji: "🇲🇱",
      unicode: "U+1F1F2 U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ML.svg",
      dial_code: "+223"
    },
    {
      name: "Myanmar (Burma)",
      code: "MM",
      emoji: "🇲🇲",
      unicode: "U+1F1F2 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MM.svg",
      dial_code: "+95"
    },
    {
      name: "Mongolia",
      code: "MN",
      emoji: "🇲🇳",
      unicode: "U+1F1F2 U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MN.svg",
      dial_code: "+976"
    },
    {
      name: "Macao SAR China",
      code: "MO",
      emoji: "🇲🇴",
      unicode: "U+1F1F2 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MO.svg",
      dial_code: "+853"
    },
    {
      name: "Northern Mariana Islands",
      code: "MP",
      emoji: "🇲🇵",
      unicode: "U+1F1F2 U+1F1F5",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MP.svg",
      dial_code: "+1-670"
    },
    {
      name: "Martinique",
      code: "MQ",
      emoji: "🇲🇶",
      unicode: "U+1F1F2 U+1F1F6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MQ.svg",
      dial_code: "+596"
    },






    {
      name: "Mauritania",
      code: "MR",
      emoji: "🇲🇷",
      unicode: "U+1F1F2 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MR.svg",
      dial_code: "+222"
    },
    {
      name: "Montserrat",
      code: "MS",
      emoji: "🇲🇸",
      unicode: "U+1F1F2 U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MS.svg",
      dial_code: "+1-664"
    },
    {
      name: "Malta",
      code: "MT",
      emoji: "🇲🇹",
      unicode: "U+1F1F2 U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MT.svg",
      dial_code: "+356"
    },
    {
      name: "Mauritius",
      code: "MU",
      emoji: "🇲🇺",
      unicode: "U+1F1F2 U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MU.svg",
      dial_code: "+230"
    },
    {
      name: "Maldives",
      code: "MV",
      emoji: "🇲🇻",
      unicode: "U+1F1F2 U+1F1FB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MV.svg",
      dial_code: "+960"
    },
    {
      name: "Malawi",
      code: "MW",
      emoji: "🇲🇼",
      unicode: "U+1F1F2 U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MW.svg",
      dial_code: "+265"
    },
    {
      name: "Mexico",
      code: "MX",
      emoji: "🇲🇽",
      unicode: "U+1F1F2 U+1F1FD",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MX.svg",
      dial_code: "+52"
    },
    {
      name: "Malaysia",
      code: "MY",
      emoji: "🇲🇾",
      unicode: "U+1F1F2 U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MY.svg",
      dial_code: "+60"
    },
    {
      name: "Mozambique",
      code: "MZ",
      emoji: "🇲🇿",
      unicode: "U+1F1F2 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MZ.svg",
      dial_code: "+258"
    },
    {
      name: "Namibia",
      code: "NA",
      emoji: "🇳🇦",
      unicode: "U+1F1F3 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NA.svg",
      dial_code: "+264"
    },
    {
      name: "New Caledonia",
      code: "NC",
      emoji: "🇳🇨",
      unicode: "U+1F1F3 U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NC.svg",
      dial_code: "+687"
    },
    {
      name: "Niger",
      code: "NE",
      emoji: "🇳🇪",
      unicode: "U+1F1F3 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NE.svg",
      dial_code: "+227"
    },
    {
      name: "Norfolk Island",
      code: "NF",
      emoji: "🇳🇫",
      unicode: "U+1F1F3 U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NF.svg",
      dial_code: "+672"
    },
    {
      name: "Nigeria",
      code: "NG",
      emoji: "🇳🇬",
      unicode: "U+1F1F3 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NG.svg",
      dial_code: "+234"
    },
    {
      name: "Nicaragua",
      code: "NI",
      emoji: "🇳🇮",
      unicode: "U+1F1F3 U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NI.svg",
      dial_code: "+505"
    },
    {
      name: "Netherlands",
      code: "NL",
      emoji: "🇳🇱",
      unicode: "U+1F1F3 U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NL.svg",
      dial_code: "+31"
    },
    {
      name: "Norway",
      code: "NO",
      emoji: "🇳🇴",
      unicode: "U+1F1F3 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NO.svg",
      dial_code: "+47"
    },
    {
      name: "Nepal",
      code: "NP",
      emoji: "🇳🇵",
      unicode: "U+1F1F3 U+1F1F5",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NP.svg",
      dial_code: "+977"
    },
    {
      name: "Nauru",
      code: "NR",
      emoji: "🇳🇷",
      unicode: "U+1F1F3 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NR.svg",
      dial_code: "+674"
    },
    {
      name: "Niue",
      code: "NU",
      emoji: "🇳🇺",
      unicode: "U+1F1F3 U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NU.svg",
      dial_code: "+683"
    },
    {
      name: "New Zealand",
      code: "NZ",
      emoji: "🇳🇿",
      unicode: "U+1F1F3 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/NZ.svg",
      dial_code: "+64"
    },
    {
      name: "Oman",
      code: "OM",
      emoji: "🇴🇲",
      unicode: "U+1F1F4 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/OM.svg",
      dial_code: "+968"
    },
    {
      name: "Panama",
      code: "PA",
      emoji: "🇵🇦",
      unicode: "U+1F1F5 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PA.svg",
      dial_code: "+507"
    },
    {
      name: "Peru",
      code: "PE",
      emoji: "🇵🇪",
      unicode: "U+1F1F5 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PE.svg",
      dial_code: "+51"
    },
    {
      name: "French Polynesia",
      code: "PF",
      emoji: "🇵🇫",
      unicode: "U+1F1F5 U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PF.svg",
      dial_code: "+689"
    },
    {
      name: "Papua New Guinea",
      code: "PG",
      emoji: "🇵🇬",
      unicode: "U+1F1F5 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PG.svg",
      dial_code: "+675"
    },
    {
      name: "Philippines",
      code: "PH",
      emoji: "🇵🇭",
      unicode: "U+1F1F5 U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PH.svg",
      dial_code: "+63"
    },
    {
      name: "Pakistan",
      code: "PK",
      emoji: "🇵🇰",
      unicode: "U+1F1F5 U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PK.svg",
      dial_code: "+92"
    },
    {
      name: "Poland",
      code: "PL",
      emoji: "🇵🇱",
      unicode: "U+1F1F5 U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PL.svg",
      dial_code: "+48"
    },
    {
      name: "St. Pierre & Miquelon",
      code: "PM",
      emoji: "🇵🇲",
      unicode: "U+1F1F5 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PM.svg",
      dial_code: "+508"
    },
    {
      name: "Pitcairn Islands",
      code: "PN",
      emoji: "🇵🇳",
      unicode: "U+1F1F5 U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PN.svg",
      dial_code: "+64"
    },
























    {
      name: "Puerto Rico",
      code: "PR",
      emoji: "🇵🇷",
      unicode: "U+1F1F5 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PR.svg",
      dial_code: "+1-787"
    },
    {
      name: "Palestinian Territories",
      code: "PS",
      emoji: "🇵🇸",
      unicode: "U+1F1F5 U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PS.svg",
      dial_code: "+970"
    },
    {
      name: "Portugal",
      code: "PT",
      emoji: "🇵🇹",
      unicode: "U+1F1F5 U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PT.svg",
      dial_code: "+351"
    },
    {
      name: "Palau",
      code: "PW",
      emoji: "🇵🇼",
      unicode: "U+1F1F5 U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PW.svg",
      dial_code: "+680"
    },
    {
      name: "Paraguay",
      code: "PY",
      emoji: "🇵🇾",
      unicode: "U+1F1F5 U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/PY.svg",
      dial_code: "+595"
    },
    {
      name: "Qatar",
      code: "QA",
      emoji: "🇶🇦",
      unicode: "U+1F1F6 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/QA.svg",
      dial_code: "+974"
    },
    {
      name: "Réunion",
      code: "RE",
      emoji: "🇷🇪",
      unicode: "U+1F1F7 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RE.svg",
      dial_code: "+262"
    },
    {
      name: "Romania",
      code: "RO",
      emoji: "🇷🇴",
      unicode: "U+1F1F7 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RO.svg",
      dial_code: "+40"
    },
    {
      name: "Serbia",
      code: "RS",
      emoji: "🇷🇸",
      unicode: "U+1F1F7 U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RS.svg",
      dial_code: "+381"
    },
    {
      name: "Russia",
      code: "RU",
      emoji: "🇷🇺",
      unicode: "U+1F1F7 U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RU.svg",
      dial_code: "+7"
    },
    {
      name: "Rwanda",
      code: "RW",
      emoji: "🇷🇼",
      unicode: "U+1F1F7 U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/RW.svg",
      dial_code: "+250"
    },
    {
      name: "Saudi Arabia",
      code: "SA",
      emoji: "🇸🇦",
      unicode: "U+1F1F8 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg",
      dial_code: "+966"
    },
    {
      name: "Solomon Islands",
      code: "SB",
      emoji: "🇸🇧",
      unicode: "U+1F1F8 U+1F1E7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SB.svg",
      dial_code: "+677"
    },
    {
      name: "Seychelles",
      code: "SC",
      emoji: "🇸🇨",
      unicode: "U+1F1F8 U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SC.svg",
      dial_code: "+248"
    },
    {
      name: "Sudan",
      code: "SD",
      emoji: "🇸🇩",
      unicode: "U+1F1F8 U+1F1E9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SD.svg",
      dial_code: "+249"
    },
    {
      name: "Sweden",
      code: "SE",
      emoji: "🇸🇪",
      unicode: "U+1F1F8 U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SE.svg",
      dial_code: "+46"
    },
    {
      name: "Singapore",
      code: "SG",
      emoji: "🇸🇬",
      unicode: "U+1F1F8 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SG.svg",
      dial_code: "+65"
    },
    {
      name: "St. Helena",
      code: "SH",
      emoji: "🇸🇭",
      unicode: "U+1F1F8 U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SH.svg",
      dial_code: "+290"
    },
    {
      name: "Slovenia",
      code: "SI",
      emoji: "🇸🇮",
      unicode: "U+1F1F8 U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SI.svg",
      dial_code: "+386"
    },
    {
      name: "Svalbard & Jan Mayen",
      code: "SJ",
      emoji: "🇸🇯",
      unicode: "U+1F1F8 U+1F1EF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SJ.svg",
      dial_code: "+47"
    },
    {
      name: "Slovakia",
      code: "SK",
      emoji: "🇸🇰",
      unicode: "U+1F1F8 U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SK.svg",
      dial_code: "+421"
    },
    {
      name: "Sierra Leone",
      code: "SL",
      emoji: "🇸🇱",
      unicode: "U+1F1F8 U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SL.svg",
      dial_code: "+232"
    },
    {
      name: "San Marino",
      code: "SM",
      emoji: "🇸🇲",
      unicode: "U+1F1F8 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SM.svg",
      dial_code: "+378"
    },
    {
      name: "Senegal",
      code: "SN",
      emoji: "🇸🇳",
      unicode: "U+1F1F8 U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SN.svg",
      dial_code: "+221"
    },
    {
      name: "Somalia",
      code: "SO",
      emoji: "🇸🇴",
      unicode: "U+1F1F8 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SO.svg",
      dial_code: "+252"
    },
    {
      name: "Suriname",
      code: "SR",
      emoji: "🇸🇷",
      unicode: "U+1F1F8 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SR.svg",
      dial_code: "+597"
    },
    {
      name: "South Sudan",
      code: "SS",
      emoji: "🇸🇸",
      unicode: "U+1F1F8 U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SS.svg",
      dial_code: "+211"
    },
    {
      name: "São Tomé & Príncipe",
      code: "ST",
      emoji: "🇸🇹",
      unicode: "U+1F1F8 U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ST.svg",
      dial_code: "+239"
    },
    {
      name: "El Salvador",
      code: "SV",
      emoji: "🇸🇻",
      unicode: "U+1F1F8 U+1F1FB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SV.svg",
      dial_code: "+503"
    },
    {
      name: "Sint Maarten",
      code: "SX",
      emoji: "🇸🇽",
      unicode: "U+1F1F8 U+1F1FD",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SX.svg",
      dial_code: "+1-721"
    },
    {
      name: "Syria",
      code: "SY",
      emoji: "🇸🇾",
      unicode: "U+1F1F8 U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SY.svg",
      dial_code: "+963"
    },
    {
      name: "Eswatini",
      code: "SZ",
      emoji: "🇸🇿",
      unicode: "U+1F1F8 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SZ.svg",
      dial_code: "+268"
    },
    {
      name: "Tristan da Cunha",
      code: "TA",
      emoji: "🇹🇦",
      unicode: "U+1F1F9 U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TA.svg",
      dial_code: "+290"
    },
    {
      name: "Turks & Caicos Islands",
      code: "TC",
      emoji: "🇹🇨",
      unicode: "U+1F1F9 U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TC.svg",
      dial_code: "+1-649"
    },
    {
      name: "Chad",
      code: "TD",
      emoji: "🇹🇩",
      unicode: "U+1F1F9 U+1F1E9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TD.svg",
      dial_code: "+235"
    },
    {
      name: "French Southern Territories",
      code: "TF",
      emoji: "🇹🇫",
      unicode: "U+1F1F9 U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TF.svg",
      dial_code: "+262"
    },
    {
      name: "Togo",
      code: "TG",
      emoji: "🇹🇬",
      unicode: "U+1F1F9 U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TG.svg",
      dial_code: "+228"
    },
    {
      name: "Thailand",
      code: "TH",
      emoji: "🇹🇭",
      unicode: "U+1F1F9 U+1F1ED",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TH.svg",
      dial_code: "+66"
    },
    {
      name: "Tajikistan",
      code: "TJ",
      emoji: "🇹🇯",
      unicode: "U+1F1F9 U+1F1EF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TJ.svg",
      dial_code: "+992"
    },

















    {
      name: "Tokelau",
      code: "TK",
      emoji: "🇹🇰",
      unicode: "U+1F1F9 U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TK.svg",
      dial_code: "+690"
    },
    {
      name: "Timor-Leste",
      code: "TL",
      emoji: "🇹🇱",
      unicode: "U+1F1F9 U+1F1F1",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TL.svg",
      dial_code: "+670"
    },
    {
      name: "Turkmenistan",
      code: "TM",
      emoji: "🇹🇲",
      unicode: "U+1F1F9 U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TM.svg",
      dial_code: "+993"
    },
    {
      name: "Tunisia",
      code: "TN",
      emoji: "🇹🇳",
      unicode: "U+1F1F9 U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg",
      dial_code: "+216"
    },
    {
      name: "Tonga",
      code: "TO",
      emoji: "🇹🇴",
      unicode: "U+1F1F9 U+1F1F4",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TO.svg",
      dial_code: "+676"
    },
    {
      name: "Turkey",
      code: "TR",
      emoji: "🇹🇷",
      unicode: "U+1F1F9 U+1F1F7",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TR.svg",
      dial_code: "+90"
    },
    {
      name: "Trinidad & Tobago",
      code: "TT",
      emoji: "🇹🇹",
      unicode: "U+1F1F9 U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TT.svg",
      dial_code: "+1-868"
    },
    {
      name: "Tuvalu",
      code: "TV",
      emoji: "🇹🇻",
      unicode: "U+1F1F9 U+1F1FB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TV.svg",
      dial_code: "+688"
    },
    {
      name: "Taiwan",
      code: "TW",
      emoji: "🇹🇼",
      unicode: "U+1F1F9 U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TW.svg",
      dial_code: "+886"
    },
    {
      name: "Tanzania",
      code: "TZ",
      emoji: "🇹🇿",
      unicode: "U+1F1F9 U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TZ.svg",
      dial_code: "+255"
    },
    {
      name: "Ukraine",
      code: "UA",
      emoji: "🇺🇦",
      unicode: "U+1F1FA U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UA.svg",
      dial_code: "+380"
    },
    {
      name: "Uganda",
      code: "UG",
      emoji: "🇺🇬",
      unicode: "U+1F1FA U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UG.svg",
      dial_code: "+256"
    },
    {
      name: "U.S. Outlying Islands",
      code: "UM",
      emoji: "🇺🇲",
      unicode: "U+1F1FA U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UM.svg",
      dial_code: "+1"
    },
    {
      name: "United Nations",
      code: "UN",
      emoji: "🇺🇳",
      unicode: "U+1F1FA U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UN.svg",
      dial_code: "N/A"
    },
    {
      name: "United States",
      code: "US",
      emoji: "🇺🇸",
      unicode: "U+1F1FA U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
      dial_code: "+1"
    },
    {
      name: "Uruguay",
      code: "UY",
      emoji: "🇺🇾",
      unicode: "U+1F1FA U+1F1FE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UY.svg",
      dial_code: "+598"
    },
    {
      name: "Uzbekistan",
      code: "UZ",
      emoji: "🇺🇿",
      unicode: "U+1F1FA U+1F1FF",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/UZ.svg",
      dial_code: "+998"
    },
    {
      name: "Vatican City",
      code: "VA",
      emoji: "🇻🇦",
      unicode: "U+1F1FB U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VA.svg",
      dial_code: "+379"
    },
    {
      name: "St. Vincent & Grenadines",
      code: "VC",
      emoji: "🇻🇨",
      unicode: "U+1F1FB U+1F1E8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VC.svg",
      dial_code: "+1-784"
    },
    {
      name: "Venezuela",
      code: "VE",
      emoji: "🇻🇪",
      unicode: "U+1F1FB U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VE.svg",
      dial_code: "+58"
    },
    {
      name: "British Virgin Islands",
      code: "VG",
      emoji: "🇻🇬",
      unicode: "U+1F1FB U+1F1EC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VG.svg",
      dial_code: "+1-284"
    },
    {
      name: "U.S. Virgin Islands",
      code: "VI",
      emoji: "🇻🇮",
      unicode: "U+1F1FB U+1F1EE",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VI.svg",
      dial_code: "+1-340"
    },
    {
      name: "Vietnam",
      code: "VN",
      emoji: "🇻🇳",
      unicode: "U+1F1FB U+1F1F3",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VN.svg",
      dial_code: "+84"
    },
    {
      name: "Vanuatu",
      code: "VU",
      emoji: "🇻🇺",
      unicode: "U+1F1FB U+1F1FA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/VU.svg",
      dial_code: "+678"
    },
    {
      name: "Wallis & Futuna",
      code: "WF",
      emoji: "🇼🇫",
      unicode: "U+1F1FC U+1F1EB",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WF.svg",
      dial_code: "+681"
    },
    {
      name: "Samoa",
      code: "WS",
      emoji: "🇼🇸",
      unicode: "U+1F1FC U+1F1F8",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WS.svg",
      dial_code: "+685"
    },
    {
      name: "Kosovo",
      code: "XK",
      emoji: "🇽🇰",
      unicode: "U+1F1FD U+1F1F0",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/XK.svg",
      dial_code: "+383"
    },
    {
      name: "Yemen",
      code: "YE",
      emoji: "🇾🇪",
      unicode: "U+1F1FE U+1F1EA",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YE.svg",
      dial_code: "+967"
    },
    {
      name: "Mayotte",
      code: "YT",
      emoji: "🇾🇹",
      unicode: "U+1F1FE U+1F1F9",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/YT.svg",
      dial_code: "+262"
    },
    {
      name: "South Africa",
      code: "ZA",
      emoji: "🇿🇦",
      unicode: "U+1F1FF U+1F1E6",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZA.svg",
      dial_code: "+27"
    },
    {
      name: "Zambia",
      code: "ZM",
      emoji: "🇿🇲",
      unicode: "U+1F1FF U+1F1F2",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZM.svg",
      dial_code: "+260"
    },
    {
      name: "Zimbabwe",
      code: "ZW",
      emoji: "🇿🇼",
      unicode: "U+1F1FF U+1F1FC",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ZW.svg",
      dial_code: "+263"
    },
    {
      name: "England",
      code: "ENGLAND",
      emoji: "🏴",
      unicode: "U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/ENGLAND.svg",
      dial_code: "+44"
    },
    {
      name: "Scotland",
      code: "SCOTLAND",
      emoji: "🏴",
      unicode: "U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SCOTLAND.svg",
      dial_code: "+44"
    },
    {
      name: "Wales",
      code: "WALES",
      emoji: "🏴",
      unicode: "U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F",
      img: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/WALES.svg",
      dial_code: "+44"
    }

  ]
//];
