import { Grid,Typography } from "@mui/material";

export const Testimonials = () => {
  return (
    <div className="text-white pt-16 flex justify-center md:px-16 px-8 py-16 mt-8">
      <div className="flex md:flex-row flex-col justify-center items-center md:items-start md:justify-between w-full space-y-10 md:space-y-0">


        <Grid sx={{ display: { md: "none", sm: "none", xs: "flex" }, flexDirection: "column", alignItems: "center" }}>
          <Typography
            sx={{
              typography: {
                md: "h5",
                sm: "h6",
                xs: "h6",
              },
            }}
            style={{ fontWeight: 700 }}
            className="py-8 text-center "
          >
            Testimonials{" "}
          </Typography>
          <Typography
            sx={{
              typography: {
                md: "h3",
                sm: "h4",
                xs: "h4",
              },
              fontSize: { md: "60px", sm: "40px", xs: "40px" },
              fontWeight: { md: "700px", sm: "700px", xs: "700px" },
              width: { md: "75%", sm: "100%", xs: "100%" }
            }}
            style={{ fontWeight: 700 }}
            className="text-center pb-8"
          >
            What Our Clients Say
          </Typography>
          <img
            src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/star.png"
            alt="stars"
          />
        </Grid>

        <Grid className=" testimonial-card-1 py-8 mt-8 md:mt-0 px-2" 
        sx={{
          left:{md:"60px",sm:"",xs:"0px"}
          }}>
          <div className="testimonial-card-1-text px-8 h-full">
            <div className="testimonial-card-content">
              FacoonLabs helped me triple my meeting bookings within two months.
              Highly recommended!
            </div>
            <div className="testimonial-card-quotes">“</div>
            <div className="flex items-center space-x-4">
              <div className="testimonial-card-img">
                <img src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/user.png" alt=""/>
              </div>
              <div>
                <div className="testimonial-card-name ">Ujang Karlos.</div>
                <div className="testimonial-card-designation ">
                  Sales Manager
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid sx={{display:{md:"flex",sm:"flex",xs:"none"},flexDirection:"column",alignItems:"center"}}>
          <Typography
            sx={{
              typography: {
                md: "h5",
                sm: "h6",
                xs: "h6",
              },
            }}
            style={{ fontWeight: 700 }}
            className="py-8 text-center "
          >
            Testimonials{" "}
          </Typography>
          <Typography
            sx={{
              typography: {
                md: "h3",
                sm: "h4",
                xs: "h4",
              },
              fontSize: { md: "60px", sm: "40px", xs: "40px" },
              fontWeight: { md: "700px", sm: "700px", xs: "700px" },
              width: { md: "75%", sm: "100%", xs: "100%" }
            }}
            style={{ fontWeight: 700 }}
            className="text-center pb-8"
          >
            What Our Clients Say
          </Typography>
          <img
            src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/star.png"
            alt="stars"
          />
        </Grid>
        <Grid className="testimonial-card-2 py-8 px-2 h-full"
          sx={{
            right:{md:"60px",sm:"",xs:"0px"}
          }}>
          <div className="testimonial-card-1-text px-8">
            <div className="testimonial-card-content">
              Their automated system is a game-changer for my business
              development efforts.
            </div>
            <div className="testimonial-card-quotes">“</div>
            <div className="flex items-center space-x-4">
              <div className="testimonial-card-img">
                <img src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/user.png" alt="" />
              </div>
              <div>
                <div className="testimonial-card-name ">Sarah Benito</div>
                <div className="testimonial-card-designation ">
                  Business Owner{" "}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};
