export const notifications = [
    {
        id: 0,
        text: "Carolyn Chandler replied to your message.",
        read: false
    },
    {
        id: 1,
        text: "Ministry of Magic sent a message.",
        read: true
    },
    {
        id: 2,
        text: "Herwi Pottah reacted on your message.",
        read: true
    },
    {
        id: 3,
        text: "You have 3 unread messages from Cuban Hermes",
        read: true
    },
    {
        id: 4,
        text: "Ministry of Magic sent a message",
        read: false
    },

]

const helpGrey = 
<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.884 11.7357C16.2165 10.8932 16.397 9.99119 16.397 9C16.397 8.28634 16.2925 7.60242 16.112 6.96806C15.4945 7.11674 14.8485 7.19604 14.174 7.19604C11.267 7.19604 8.6925 5.69934 7.125 3.38987C6.2795 5.53084 4.6645 7.27533 2.6315 8.22687C2.5935 8.47467 2.5935 8.74229 2.5935 9C2.5935 10.9111 3.32115 12.744 4.61637 14.0954C5.91159 15.4468 7.66828 16.2059 9.5 16.2059C10.4975 16.2059 11.457 15.978 12.3215 15.5716C12.863 16.652 13.11 17.1872 13.091 17.1872C11.533 17.7324 10.3265 18 9.5 18C7.201 18 5.0065 17.0584 3.3915 15.3634C2.4035 14.3326 1.672 13.0738 1.2635 11.7059H0V7.19604H1.0355C1.8335 3.09251 5.32 0 9.5 0C11.78 0 13.9745 0.94163 15.599 2.63656C16.8055 3.88546 17.613 5.48128 17.9455 7.19604H19V11.6465V11.6663V11.7059H18.943L15.561 14.9471L10.526 14.3524V12.6971H15.1145L15.884 11.7357ZM6.9065 8.77203C7.1915 8.77203 7.467 8.89097 7.6665 9.10903C7.866 9.31718 7.98 9.60463 7.98 9.90198C7.98 10.1993 7.866 10.4868 7.6665 10.6949C7.467 10.9031 7.1915 11.022 6.9065 11.022C6.308 11.022 5.8235 10.5264 5.8235 9.90198C5.8235 9.27753 6.308 8.77203 6.9065 8.77203ZM12.084 8.77203C12.6825 8.77203 13.1575 9.27753 13.1575 9.90198C13.1575 10.5264 12.6825 11.022 12.084 11.022C11.4855 11.022 11.001 10.5264 11.001 9.90198C11.001 9.6023 11.1151 9.31489 11.3182 9.10298C11.5213 8.89108 11.7968 8.77203 12.084 8.77203Z" fill="#9F9F9F" />
</svg>

const helpWhite = 
<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.884 11.7357C16.2165 10.8932 16.397 9.99119 16.397 9C16.397 8.28634 16.2925 7.60242 16.112 6.96806C15.4945 7.11674 14.8485 7.19604 14.174 7.19604C11.267 7.19604 8.6925 5.69934 7.125 3.38987C6.2795 5.53084 4.6645 7.27533 2.6315 8.22687C2.5935 8.47467 2.5935 8.74229 2.5935 9C2.5935 10.9111 3.32115 12.744 4.61637 14.0954C5.91159 15.4468 7.66828 16.2059 9.5 16.2059C10.4975 16.2059 11.457 15.978 12.3215 15.5716C12.863 16.652 13.11 17.1872 13.091 17.1872C11.533 17.7324 10.3265 18 9.5 18C7.201 18 5.0065 17.0584 3.3915 15.3634C2.4035 14.3326 1.672 13.0738 1.2635 11.7059H0V7.19604H1.0355C1.8335 3.09251 5.32 0 9.5 0C11.78 0 13.9745 0.94163 15.599 2.63656C16.8055 3.88546 17.613 5.48128 17.9455 7.19604H19V11.6465V11.6663V11.7059H18.943L15.561 14.9471L10.526 14.3524V12.6971H15.1145L15.884 11.7357ZM6.9065 8.77203C7.1915 8.77203 7.467 8.89097 7.6665 9.10903C7.866 9.31718 7.98 9.60463 7.98 9.90198C7.98 10.1993 7.866 10.4868 7.6665 10.6949C7.467 10.9031 7.1915 11.022 6.9065 11.022C6.308 11.022 5.8235 10.5264 5.8235 9.90198C5.8235 9.27753 6.308 8.77203 6.9065 8.77203ZM12.084 8.77203C12.6825 8.77203 13.1575 9.27753 13.1575 9.90198C13.1575 10.5264 12.6825 11.022 12.084 11.022C11.4855 11.022 11.001 10.5264 11.001 9.90198C11.001 9.6023 11.1151 9.31489 11.3182 9.10298C11.5213 8.89108 11.7968 8.77203 12.084 8.77203Z" fill="white" />
</svg>

const helpBlack = 
<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.884 11.7357C16.2165 10.8932 16.397 9.99119 16.397 9C16.397 8.28634 16.2925 7.60242 16.112 6.96806C15.4945 7.11674 14.8485 7.19604 14.174 7.19604C11.267 7.19604 8.6925 5.69934 7.125 3.38987C6.2795 5.53084 4.6645 7.27533 2.6315 8.22687C2.5935 8.47467 2.5935 8.74229 2.5935 9C2.5935 10.9111 3.32115 12.744 4.61637 14.0954C5.91159 15.4468 7.66828 16.2059 9.5 16.2059C10.4975 16.2059 11.457 15.978 12.3215 15.5716C12.863 16.652 13.11 17.1872 13.091 17.1872C11.533 17.7324 10.3265 18 9.5 18C7.201 18 5.0065 17.0584 3.3915 15.3634C2.4035 14.3326 1.672 13.0738 1.2635 11.7059H0V7.19604H1.0355C1.8335 3.09251 5.32 0 9.5 0C11.78 0 13.9745 0.94163 15.599 2.63656C16.8055 3.88546 17.613 5.48128 17.9455 7.19604H19V11.6465V11.6663V11.7059H18.943L15.561 14.9471L10.526 14.3524V12.6971H15.1145L15.884 11.7357ZM6.9065 8.77203C7.1915 8.77203 7.467 8.89097 7.6665 9.10903C7.866 9.31718 7.98 9.60463 7.98 9.90198C7.98 10.1993 7.866 10.4868 7.6665 10.6949C7.467 10.9031 7.1915 11.022 6.9065 11.022C6.308 11.022 5.8235 10.5264 5.8235 9.90198C5.8235 9.27753 6.308 8.77203 6.9065 8.77203ZM12.084 8.77203C12.6825 8.77203 13.1575 9.27753 13.1575 9.90198C13.1575 10.5264 12.6825 11.022 12.084 11.022C11.4855 11.022 11.001 10.5264 11.001 9.90198C11.001 9.6023 11.1151 9.31489 11.3182 9.10298C11.5213 8.89108 11.7968 8.77203 12.084 8.77203Z" fill="black" />
</svg>



const nightModeGrey=
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7425 3.09L13.2125 5.03L14.1225 8.09L11.4925 6.28L8.8625 8.09L9.7725 5.03L7.2425 3.09L10.4325 3L11.4925 0L12.5525 3L15.7425 3.09ZM19.2425 10L17.6025 11.25L18.1925 13.23L16.4925 12.06L14.7925 13.23L15.3825 11.25L13.7425 10L15.8025 9.95L16.4925 8L17.1825 9.95L19.2425 10ZM16.9625 14.95C17.7925 14.87 18.6825 16.05 18.1525 16.8C17.8325 17.25 17.4925 17.67 17.0725 18.07C13.1625 22 6.8325 22 2.9325 18.07C-0.9775 14.17 -0.9775 7.83 2.9325 3.93C3.3325 3.53 3.7525 3.17 4.2025 2.85C4.9525 2.32 6.1325 3.21 6.0525 4.04C5.7825 6.9 6.7425 9.87 8.9425 12.06C11.1325 14.26 14.0925 15.22 16.9625 14.95ZM15.3225 16.97C12.4925 16.81 9.6925 15.64 7.5225 13.5C5.3525 11.31 4.1925 8.5 4.0325 5.68C1.2225 8.82 1.3325 13.64 4.3425 16.66C7.3625 19.67 12.1825 19.78 15.3225 16.97Z" fill="#9F9F9F" />
    </svg>
const nightModeBlack=
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7425 3.09L13.2125 5.03L14.1225 8.09L11.4925 6.28L8.8625 8.09L9.7725 5.03L7.2425 3.09L10.4325 3L11.4925 0L12.5525 3L15.7425 3.09ZM19.2425 10L17.6025 11.25L18.1925 13.23L16.4925 12.06L14.7925 13.23L15.3825 11.25L13.7425 10L15.8025 9.95L16.4925 8L17.1825 9.95L19.2425 10ZM16.9625 14.95C17.7925 14.87 18.6825 16.05 18.1525 16.8C17.8325 17.25 17.4925 17.67 17.0725 18.07C13.1625 22 6.8325 22 2.9325 18.07C-0.9775 14.17 -0.9775 7.83 2.9325 3.93C3.3325 3.53 3.7525 3.17 4.2025 2.85C4.9525 2.32 6.1325 3.21 6.0525 4.04C5.7825 6.9 6.7425 9.87 8.9425 12.06C11.1325 14.26 14.0925 15.22 16.9625 14.95ZM15.3225 16.97C12.4925 16.81 9.6925 15.64 7.5225 13.5C5.3525 11.31 4.1925 8.5 4.0325 5.68C1.2225 8.82 1.3325 13.64 4.3425 16.66C7.3625 19.67 12.1825 19.78 15.3225 16.97Z" fill="black" />
    </svg>
const nightModeWhite =
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7425 3.09L13.2125 5.03L14.1225 8.09L11.4925 6.28L8.8625 8.09L9.7725 5.03L7.2425 3.09L10.4325 3L11.4925 0L12.5525 3L15.7425 3.09ZM19.2425 10L17.6025 11.25L18.1925 13.23L16.4925 12.06L14.7925 13.23L15.3825 11.25L13.7425 10L15.8025 9.95L16.4925 8L17.1825 9.95L19.2425 10ZM16.9625 14.95C17.7925 14.87 18.6825 16.05 18.1525 16.8C17.8325 17.25 17.4925 17.67 17.0725 18.07C13.1625 22 6.8325 22 2.9325 18.07C-0.9775 14.17 -0.9775 7.83 2.9325 3.93C3.3325 3.53 3.7525 3.17 4.2025 2.85C4.9525 2.32 6.1325 3.21 6.0525 4.04C5.7825 6.9 6.7425 9.87 8.9425 12.06C11.1325 14.26 14.0925 15.22 16.9625 14.95ZM15.3225 16.97C12.4925 16.81 9.6925 15.64 7.5225 13.5C5.3525 11.31 4.1925 8.5 4.0325 5.68C1.2225 8.82 1.3325 13.64 4.3425 16.66C7.3625 19.67 12.1825 19.78 15.3225 16.97Z" fill="white" />
    </svg>

const notifGrey = 
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 19H11C11 20.1 10.1 21 9 21C7.9 21 7 20.1 7 19ZM18 17V18H0V17L2 15V9C2 5.9 4 3.2 7 2.3V2C7 0.9 7.9 0 9 0C10.1 0 11 0.9 11 2V2.3C14 3.2 16 5.9 16 9V15L18 17ZM14 9C14 6.2 11.8 4 9 4C6.2 4 4 6.2 4 9V16H14V9Z" fill="#9F9F9F" />
    </svg>
const notifBlack =
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 19H11C11 20.1 10.1 21 9 21C7.9 21 7 20.1 7 19ZM18 17V18H0V17L2 15V9C2 5.9 4 3.2 7 2.3V2C7 0.9 7.9 0 9 0C10.1 0 11 0.9 11 2V2.3C14 3.2 16 5.9 16 9V15L18 17ZM14 9C14 6.2 11.8 4 9 4C6.2 4 4 6.2 4 9V16H14V9Z" fill="black" />
    </svg>

const notifWhite =
    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 19H11C11 20.1 10.1 21 9 21C7.9 21 7 20.1 7 19ZM18 17V18H0V17L2 15V9C2 5.9 4 3.2 7 2.3V2C7 0.9 7.9 0 9 0C10.1 0 11 0.9 11 2V2.3C14 3.2 16 5.9 16 9V15L18 17ZM14 9C14 6.2 11.8 4 9 4C6.2 4 4 6.2 4 9V16H14V9Z" fill="white" />
    </svg>



export {helpBlack,helpWhite,helpGrey,nightModeGrey,nightModeBlack,nightModeWhite,notifWhite,notifGrey,notifBlack}