import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export const footLinks = [
  {
    id: 0,
    name: "Twitter",
    link: "",
    img: TwitterIcon,
  },
  {
    id: 1,
    name: "Facebook",
    link: "",
    img: FacebookRoundedIcon,
  },
  {
    id: 2,
    name: "LinkedIn",
    link: "",
    img: LinkedInIcon,
  },
];

export const product = [
  {
    id: 0,
    name: "Pricing",
    link: "/pricing",
  },
  // {
  //   id: 1,
  //   name: "Services",
  //   link: "/services",
  // },
];

export const company = [
  {
    id: 0,
    name: "About Us",
    link: "/about",
  },
  {
    id: 1,
    name: "Terms & Conditions",
    link: "/terms-condition",
  },
  {
    id: 2,
    name: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    id: 3,
    name: "FAQ",
    link: "/faqs",
  },
];
