import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./styles.css";
import SuggestFeatureDialog from "./SuggestFeatureDialog";
const linkedinYellow =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/linkedInYellow.png";
const msgYellow =
  "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/msgYellow.png";
const faqYellow =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/faqYellow.png";
const supportYellow =
  "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/supportYellow.png";
const yellowBg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bgYellowLogo.png";
const ideaYellow =
  "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ideaYellow.png";

const faqRed =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/faqRed.png";
const msgRed =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/msgRed.png";
const linkedInRed =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/linkedInRed.png";
const supportRed =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/supportRed.png";
const redBg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bgRedLogo.png";
const ideaRed =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ideaRed.png";

const HelpCenter = () => {
  const navigate = useNavigate();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const isCustomRange = useMediaQuery(
    "(min-width:900px) and (max-width:1100px)"
  );

  const [animationClass, setAnimationClass] = useState("enlarge");
  const [open, setOpen] = useState(false);
  const [state, setState] = useState("");

  const handleClickOpen = (type) => {
    setOpen(true);
    setState(type);
  };

  const handleClose = () => {
    setOpen(false);
    setState(null);
  };

  const handlePageChange = () => {
    setTimeout(() => {
      setAnimationClass("enlarge");
    }, 500);
  };

  return (
    <div
      style={{
        backgroundImage:
          themeMode === "dark" ? `url(${yellowBg})` : `url(${redBg})`,
        backgroundSize: { md: "50% 50%", sm: "75% 75%" },
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right bottom",
      }}
    >
      <div className="py-24 md:px-16 px-8 text-center">
        <Typography
          className={themeMode === "dark" ? "text-light" : "text-dark"}
          sx={{ fontSize: "40px", fontWeight: "600" }}
        >
          Help Center
        </Typography>
        <Typography
          className={themeMode === "dark" ? "text-light" : "text-dark"}
        >
          In this page, you can find solution to your problems or answers to
          your queries.
        </Typography>
      </div>
      <Grid
        container
        className={animationClass}
        sx={{
          display: "flex",
          justifyContent: {
            md: isCustomRange ? "center" : "space-between",
            sm: "space-evenly",
          },
          background: "transparent",
          flexDirection: { md: "row", sm: "row", xs: "column" },
          flexWrap: {
            md: isCustomRange ? "wrap" : "nowrap",
            sm: "wrap",
            xs: "",
          },
        }}
      >
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 0,
            minWidth: "0px",
          }}
        >
          <Button
            onClick={() => navigate("/limitations-and-best-practices")}
            sx={{
              width: "234px",
              height: "234px",
              borderRadius: "50%",
              background: themeMode === "dark" ? "#02000080" : "#DEDEDE80",
              border:
                themeMode === "dark"
                  ? "5px solid #F9E74D"
                  : "5px solid #BE0000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={themeMode === "dark" ? linkedinYellow : linkedInRed}
              alt=""
            />
          </Button>
          <Typography
            className={themeMode === "dark" ? "text-light" : "text-dark"}
            my={2}
            sx={{
              width: "60%",
              fontSize: "22px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Limitations & Best Practices
          </Typography>
        </Grid>

        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleClickOpen("contactUs")}
            sx={{
              width: "234px",
              height: "234px",
              borderRadius: "50%",
              background: themeMode === "dark" ? "#02000080" : "#DEDEDE80",
              border:
                themeMode === "dark"
                  ? "5px solid #F9E74D"
                  : "5px solid #BE0000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={themeMode === "dark" ? msgYellow : msgRed} alt="" />
          </Button>
          <Typography
            className={themeMode === "dark" ? "text-light" : "text-dark"}
            my={2}
            sx={{
              width: "60%",
              fontSize: "22px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Report an Issue
          </Typography>
        </Grid>

        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleClickOpen("liveSupport")}
            sx={{
              width: "234px",
              height: "234px",
              borderRadius: "50%",
              background: themeMode === "dark" ? "#02000080" : "#DEDEDE80",
              border:
                themeMode === "dark"
                  ? "5px solid #F9E74D"
                  : "5px solid #BE0000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={themeMode === "dark" ? supportYellow : supportRed}
              alt=""
            />
          </Button>
          <Typography
            className={themeMode === "dark" ? "text-light" : "text-dark"}
            my={2}
            sx={{
              width: "60%",
              fontSize: "22px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Live Support Chat
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => navigate("/faqs")}
            sx={{
              width: "234px",
              height: "234px",
              borderRadius: "50%",
              background: themeMode === "dark" ? "#02000080" : "#DEDEDE80",
              border:
                themeMode === "dark"
                  ? "5px solid #F9E74D"
                  : "5px solid #BE0000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={themeMode === "dark" ? faqYellow : faqRed} alt="" />
          </Button>
          <Typography
            className={themeMode === "dark" ? "text-light" : "text-dark"}
            my={2}
            sx={{
              width: "60%",
              fontSize: "22px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Frequently Ask Questions
          </Typography>
        </Grid>
      </Grid>

      <Grid
        className="py-6 pb-24"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          className={themeMode === "dark" ? "text-light" : "text-dark"}
        >
          Feeling creative?
        </Typography>
        <Button
          onClick={() => handleClickOpen("suggest")}
          disableRipple
          sx={{
            padding: "0px",
            minWidth: "0px",
            textTransform: "none",
            "&:hover": {
              background: "transparent",
            },
          }}
        >
          <Typography
            sx={{
              color: themeMode === "dark" ? "#FFE50B" : "#BE0000",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={themeMode === "dark" ? ideaYellow : ideaRed}
              alt=""
              style={{ marginRight: "10px" }}
            />
            Suggest a Feature
          </Typography>
        </Button>
      </Grid>

      <SuggestFeatureDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        state={state}
      />
    </div>
  );
};
export default HelpCenter;
