import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  Typography,
  Box,
  StepContent,
  stepConnectorClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useDispatch, useSelector } from "react-redux";
import { BorderAllRounded } from "@mui/icons-material";
import { getChat, markMeetingBooked } from "../../../store/messagesSlice";
import { success } from "../../../store/alertSlice";
import { useEffect } from "react";

const steps = [
  "Invitation Scheduled",
  "Invitation Sent",
  "Invitation Accepted",
  "Prospect has replied",
  "Meeting booked",
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#29CD00",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#29CD00",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#250000",
    borderLeftWidth: 4,
    padding: 0,
    margin: 0,
    marginLeft: -2,
  },
}));

export default function StepperStages() {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [activeStep, setActiveStep] = React.useState(0);
  const openedChat = useSelector((state) => state.messages.openedChat);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (openedChat?.prospect?.progression_status === "queued_for_automation") {
      setActiveStep(0);
    }
    if (openedChat?.prospect?.progression_status === "invitation_scheduled") {
      setActiveStep(1);
    }
    if (openedChat?.prospect?.progression_status === "invitation_sent") {
      setActiveStep(2);
    }
    if (openedChat?.prospect?.progression_status === "invitation_accepted") {
      setActiveStep(3);
    }
    if (openedChat?.prospect?.progression_status === "prospect_replied") {
      setActiveStep(4);
    }
    if (openedChat?.prospect?.progression_status === "meeting_booked") {
      setActiveStep(5);
    }
    // if (openedChat.prospect?.meeting_booked) {
    //   setActiveStep(4);
    // }
  }, [openedChat]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      connector={<QontoConnector />}
      className="py-6"
    >
      {steps.map((step, index) => (
        <Step key={step.label}>
          <StepLabel
            sx={{
              padding: 0,
              "& .MuiStepIcon-root.Mui-completed": {
                color: themeMode === "dark" ? "#00FF19" : "#03660D",
              },
              "& .MuiStepIcon-root.Mui-active": {
                color: themeMode === "dark" ? "#00FF19" : "#03660D",
              },
              "& .MuiStepIcon-text": {
                display: "none",
              },
            }}
          >
            <span className="step-label"> {step}</span>
          </StepLabel>
        </Step>
      ))}
      <Button
        className="step-label ml-2"
        style={{ color: "#BDFF00", textTransform: "none", fontStyle: "italic" }}
        disabled={openedChat?.prospect?.meeting_booked}
        onClick={() =>
          dispatch(markMeetingBooked(openedChat.uuid)).then((resp) => {
            if (resp?.payload?.status === 200) {
              dispatch(getChat(openedChat.uuid));
              dispatch(success("Meeting Booked"));
            }
          })
        }
      >
        {openedChat?.prospect?.meeting_booked
          ? "Meeeting booked"
          : "Mark as Booked"}
      </Button>
    </Stepper>
  );
}
