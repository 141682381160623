import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";

import { ArkoseCaptchaIntegration } from "./captcha-integration/ArkoseCaptchaIntegration";
import { error, success } from "../store/alertSlice";
import { getLinkedInConnectedStatus } from "../store/settingsSlice";
import { solveCodeCheckpoint } from "../store/onboardingSlice";
import { useNavigate } from "react-router-dom";

const defaultValues = {
  username: "",
  password: "",
};

const schema = yup.object().shape({}).required();

export default function OTPDialog({ open, handleClose }) {
  const navigate = useNavigate();
  const connectAccount = useSelector(
    (state) => state.settings.connectLinkedInAccount
  );
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [value, setValue] = React.useState("one");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(data) {
    dispatch(
      solveCodeCheckpoint({
        code: data.code,
      })
    ).then((res) => {
      if (res.payload?.status === 201) {
        dispatch(getLinkedInConnectedStatus());
        dispatch(success(res.payload?.data?.message));

        handleClose();
        navigate("/onboarding-complete");
      } else {
        dispatch(error(res.payload?.data?.message));
        dispatch(getLinkedInConnectedStatus());
        handleClose();
      }
    });
  }

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFF", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-4"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke="white"
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
            className="flex flex-col  "
          >
            <div
              className={`create-campaign-dialog text-center pb-4 ${
                themeMode === "dark" ? "text-light" : "text-dark"
              }`}
            >
              Enter your OTP
            </div>
            <div>
              <div
                className={`create-campaign-dialog pb-2 ${
                  themeMode === "dark" ? "text-light" : "text-dark"
                }`}
              >
                Please check your email for OTP
              </div>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.username}
                    helperText={errors?.username?.message}
                    type="email"
                    required
                    className={`w-full ${
                      themeMode === "dark"
                        ? "create-campaign-dialog-field"
                        : "create-campaign-dialog-field-light"
                    }`}
                    size="small"
                    sx={{
                      "& .MuiInputBase-input": {
                        color: themeMode === "dark" ? "#FFF" : "#000",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          color: themeMode === "dark" ? "#FFF" : "#000",
                        },
                      },
                    }}
                  />
                )}
                name="code"
                control={control}
              />

              <div className="flex w-full justify-center pt-8">
                <Button
                  className="create-button p-2 px-4"
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                >
                  <span className="create-button-text">Connect</span>
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
