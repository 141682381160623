import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "./alertSlice";
import api from "../services/api";

const initialState = {
  organization: {},
  organizationDataLoading: false,
  organizationMembers: {},
  organizationMembersLoading: false,
};

export const getOrganization = createAsyncThunk(
    "organization/getOrganization",
    async (params, { dispatch, getState }) => {
      try {
        const res = await api.get(
          `/app_settings/show_organization_settings`
        );
        return { data: res.data, status: res.status };
      } catch (err) {
        dispatch(error(err.message));
        console.error(err);
        return null;
      }
    }
);

export const updateOrganization = createAsyncThunk(
    "organization/updateOrganization",
    async (app_settings, { dispatch, getState }) => {
      try {
        const res = await api.put(
          `/app_settings/update_organization_settings`,
          { app_settings }
        );
  
        return { data: res.data, status: res.status };
      } catch (err) {
        dispatch(error(err.message));
        console.error(err);
        return null;
      }
    }
);

export const getOrganizationMembers = createAsyncThunk(
  "organization/getOrganizationMembers",
  async ({ page = 1, per_page = 10, searchQuery }, { dispatch }) => {
    try {
      const res = await api.get(
        `/overview/all_members`, {
          params: {
            query: searchQuery ? searchQuery : "",
            page: page,
            per_page: per_page,
          },
        }
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const reactivateMembers = createAsyncThunk(
  "organization/reactivateMembers",
  async (organization, { dispatch, getState }) => {
    try {
      const res = await api.put(
        `/organizations/reactivate_members`,
        { organization }  
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err?.response?.data?.message));
      console.error(err);
      return null;
    }
  }
);

export const deactivateMembers = createAsyncThunk(
    "organization/deactivateMembers",
    async (organization, { dispatch, getState }) => {
      try {
        const res = await api.put(
          `/organizations/deactivate_members`,
          { organization }
        );
  
        return { data: res.data, status: res.status };
      } catch (err) {
        dispatch(error(err?.response?.data?.message));
        console.error(err);
        return null;
      }
    }
);

export const addMembers = createAsyncThunk(
    "organization/addMembers",
    async (organization, { dispatch, getState }) => {
      try {
        const res = await api.post(
          `/organizations/add_members_by_invite`,
          { organization }
        );
  
        return { data: res.data, status: res.status };
      } catch (err) {
        dispatch(error(err?.response?.data?.message));
        console.error(err);
        return null;
      }
    }
);


export const removeMembers = createAsyncThunk(
    "organization/removeMembers",
    async (organization, { dispatch, getState }) => {
      try {
        const res = await api.delete(
          `/organizations/remove_members_as_app_users`,
          { organization }
        );
  
        return { data: res.data, status: res.status };
      } catch (err) {
        dispatch(error(err?.response?.data?.message));
        console.error(err);
        return null;
      }
    }
);


export const organizationSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder.addCase(getOrganization.pending, (state, action) => {
        state.organizationDataLoading = true;
      });
      builder.addCase(getOrganization.fulfilled, (state, action) => {
        state.organization = action.payload?.data;
        state.organizationDataLoading = false;
      });
      builder.addCase(getOrganization.rejected, (state, action) => {
        state.organizationDataLoading = false;
      });
      builder.addCase(getOrganizationMembers.pending, (state, action) => {
        state.organizationMembersLoading = true;
      });
      builder.addCase(getOrganizationMembers.fulfilled, (state, action) => {
        state.organizationMembers = action.payload;
        state.organizationMembersLoading = false;
      })
      builder.addCase(getOrganizationMembers.rejected, (state, action) => {
        state.organizationMembersLoading = false;
      });
    },
  });
  
export const {} = organizationSlice.actions;

export default organizationSlice.reducer;