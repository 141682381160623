import { Grid,useMediaQuery } from "@mui/material";
export const DistributedTeam = ({ imageUrl }) => {
  const isCustomRange = useMediaQuery('(min-width:900px) and (max-width:1200px)');
  return (
    <Grid
      sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: { md: "100% 100%", sm: "100% 100%", xs: "100% 100%" },
        backgroundRepeat: "no-repeat",
        display: "flex", justifyContent: "center", flexDirection: "column",
        overflow: "none",
        height: { md: "fit-content", sm: "fit-content", xs: "fit-content" }
      }}
      className="p-14 lg:py-24 md:py-10 py-14 text-white md:px-24 sm:px-4"
    >
      <Grid sx={{display:"flex",flexDirection:{md:isCustomRange?"column":"row",sm:"column",xs:"column"}}}
      className="py-16 md:py-2 sm:pt-0"
    >
      {/* //className="lg:flex justify-between py-16 md:py-2 sm:pt-0"> */}
        <Grid className="dist-team-heading mb-6" sx={{ display: { md: isCustomRange ? "flex" : "none", sm: "flex", xs: "flex" }, textAlign: { md: "right", sm: "center", xs: "center" }, justifyContent: { md: isCustomRange ? "center":"right", sm: "center", xs: "center" }, }}>
          We’re a distributed team
        </Grid>
        <img src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/map.png" alt="map" />
        <div>
          <Grid className="dist-team-heading" sx={{ display: { md: isCustomRange ? "none" : "flex", sm: "none", xs: "none" }, textAlign: { md: "right", sm: "center", xs: "center" }, justifyContent: { md: isCustomRange ? "center" : "right", sm: "center", xs: "center" }, }}>
            We’re a distributed team
          </Grid>
          <Grid className="dist-team-content" sx={{ fontSize: { md: "60px", sm: "60px", xs: "30px" }, textAlign: { md: isCustomRange ? "center" : "right",sm:"center",xs:"center"},lineHeight:{md:"90px",sm:"90px",xs:"45px"}}}>
            We have the team all around the{" "}
            <span className="dist-team-content1 ">world</span>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
