import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getCampaignDetail,
  updateTeamMembers,
} from "../../../../store/campaignSlice";
import { error, success } from "../../../../store/alertSlice";

export default function AddCampaignTeamMemberDialog({
  open,
  handleClose,
  organization,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const campaign = useSelector((state) => state.campaign.campaign);
  const themeMode = useSelector((state) => state.themeMode.mode);

  function filterArray(array1, array2) {
    const uuidsInArray2 = new Set(array2?.map((item) => item.uuid));

    const filteredArray1 = array1?.filter(
      (item) => !uuidsInArray2.has(item.uuid)
    );

    return filteredArray1;
  }

  const uuidsInCampaignMembers = campaign?.members?.map((item) => item.uuid);
  const [currentMembers, setCurrentMembers] = React.useState(campaign?.members);
  const [organizationMembers, setOrganizationMembers] = React.useState(
    filterArray(organization?.members, campaign?.members)
  );

  React.useEffect(() => {
    if (open) {
      setCurrentMembers(campaign?.members);
      setOrganizationMembers(
        filterArray(organization?.members, campaign?.members)
      );
    }
  }, [open]);

  React.useEffect(() => {
    setOrganizationMembers(
      filterArray(organization?.members, campaign?.members)
    );
  }, [organization]);

  const addMemeber = (uuid) => {
    setOrganizationMembers((prevOrganizationMembers) => {
      const [itemToMove] = prevOrganizationMembers.filter(
        (item) => item.uuid === uuid
      );
      if (!itemToMove) return prevOrganizationMembers; // No matching item found

      const updatedOrganizationMembers = prevOrganizationMembers.filter(
        (item) => item.uuid !== uuid
      );
      setCurrentMembers((prevCurrentMembers) => [
        ...prevCurrentMembers,
        itemToMove,
      ]);
      return updatedOrganizationMembers;
    });
  };

  const removeMemeber = (uuid) => {
    setCurrentMembers((prevCurrentMembers) => {
      const [itemToMove] = prevCurrentMembers.filter(
        (item) => item.uuid === uuid
      );
      if (!itemToMove) return prevCurrentMembers; // No matching item found

      const updatedCurrentMembers = prevCurrentMembers.filter(
        (item) => item.uuid !== uuid
      );
      setOrganizationMembers((prevOrganizationMembers) => [
        ...prevOrganizationMembers,
        itemToMove,
      ]);
      return updatedCurrentMembers;
    });
  };

  const onSubmit = () => {
    const updatedData = {
      id: id,
      add_member_uuids: currentMembers.map((item) => item.uuid),
      remove_member_uuids: organizationMembers
        .filter(
          (member) => !campaign?.admins.some((o) => o.uuid === member.uuid)
        )
        .map((item) => item.uuid),
    };
    console.log("🚀 ~ onSubmit ~ updatedData:", updatedData);
    dispatch(updateTeamMembers(updatedData)).then((resp) => {
      if (resp.payload.status === 200) {
        dispatch(getCampaignDetail(id));
        dispatch(success("Memebers updated"));
      } else {
        dispatch(error(resp.payload.statusText));
      }
    });
    handleClose();
  };

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background:
            themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-2 pt-6"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: themeMode === "dark" ? "white" : "#000" }}
          >
            <div
              className=" pb-2 add-team-member-heading"
              style={{ color: themeMode === "dark" ? "white" : "#000" }}
            >
              Add a Team Member{" "}
            </div>
            <div
              className="create-campaign-dialog pb-2 pt-4"
              style={{ fontWeight: "700" }}
            >
              Current Campaign Members
            </div>

            {currentMembers?.map((member, index) => (
              <div className="flex space-x-24 pt-6 items-center" key={index}>
                <div
                  className={`member-name w-1/3 ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                  }`}
                >
                  {member.first_name + " " + member.last_name}
                </div>
                <div
                  className="flex space-x-2 items-center cursor-pointer"
                  onClick={() => removeMemeber(member.uuid)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                  >
                    <path
                      d="M12 0.888889H9L8.14286 0H3.85714L3 0.888889H0V2.66667H12M0.857143 14.2222C0.857143 14.6937 1.03775 15.1459 1.35925 15.4793C1.68074 15.8127 2.11677 16 2.57143 16H9.42857C9.88323 16 10.3193 15.8127 10.6408 15.4793C10.9622 15.1459 11.1429 14.6937 11.1429 14.2222V3.55556H0.857143V14.2222Z"
                      fill="#FF0000"
                    />
                  </svg>
                  <div className="member-name" style={{ color: "#FF0000" }}>
                    Remove
                  </div>
                </div>
              </div>
            ))}

            <div
              className="create-campaign-dialog pb-2 pt-8"
              style={{ fontWeight: "700" }}
            >
              Organization Members{" "}
            </div>
            {organizationMembers
              ?.filter(
                (member) =>
                  !campaign?.admins.some((o) => o.uuid === member.uuid)
              )
              ?.map((member, index) => (
                <div className="flex space-x-24 pt-2 items-center" key={index}>
                  <div
                    className="member-name w-1/3"
                    style={{ color: themeMode === "dark" ? "white" : "#000" }}
                  >
                    {member.first_name + " " + member.last_name}
                  </div>
                  <div className="flex space-x-2 items-center cursor-pointer">
                    <span className="team-add-plus ">+</span>
                    <div
                      className="member-name"
                      style={{
                        color: themeMode === "dark" ? "#00FF19" : "#000",
                      }}
                      onClick={() => addMemeber(member.uuid)}
                    >
                      Add as a team member{" "}
                    </div>
                  </div>
                </div>
              ))}

            <div className="flex w-full justify-center pt-8">
              <Button
                className="create-button p-2"
                variant="contained"
                onClick={() => onSubmit()}
              >
                <span className="create-button-text text-xl">Save</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
