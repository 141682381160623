import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Button, styled, TextField } from "@mui/material";
import FileUploadButton from "./FileUploadButton";
import { CheckCircleOutline, DeleteOutline } from "@mui/icons-material";
import { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { changePassword, requestCode } from "../../../store/userProfileSlice";
import { success } from "../../../store/alertSlice";

const defaultValues = {
  current_password: "",
  password: "",
  password_confirmation: "",
};

const schema = yup
  .object()
  .shape({
    current_password: yup.string().required("You must enter required data"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(
        /[*&^%$#@!]/,
        "Password must contain at least one special character (*&^%$#@!)"
      )
      .required("Password is required"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  })
  .required();

export default function ChangePasswordDialog({ open, handleClose, message }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [codeMode, setCodeMode] = useState(false);
  const [isPasswordMatched, setIsPasswordMatched] = useState(false);

  const deleteMessageId = useSelector(
    (state) => state.messages.deleteMessageId
  );
  const { handleSubmit, register, reset, setValue, control, watch, formState } =
    useForm({
      defaultValues,
      mode: "all",
      resolver: yupResolver(schema),
    });

  const { errors } = formState;

  useEffect(() => {
    setIsPasswordMatched(watch("password") === watch("password_confirmation"));
  }, [watch("password"), watch("password_confirmation")]);

  function onSubmit(data) {
    let updatedData = {};
    if (codeMode) {
      updatedData = {
        verification_code: data.current_password,
        password: data.password,
        password_confirmation: data.password_confirmation,
      };
    } else {
      updatedData = {
        current_password: data.current_password,
        password: data.password,
        password_confirmation: data.password_confirmation,
      };
    }

    console.log("🚀 ~ onSubmit ~ data:", updatedData);

    dispatch(changePassword(updatedData)).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(success("Password changed sucessfully"));
        handleClose();
        reset(defaultValues);
        setCodeMode(false);
      }
    });
  }

  const CustomTextField = styled(TextField)({
    "& .MuiInput-underline:before": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInputBase-input": {
      color: themeMode === "dark" ? "white" : "#000",
      fontSize: "13px",
    },
    //   minWidth: "239px",
  });

  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", // Inner background color
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-between cursor-pointer pb-8"
          onClick={handleClose}
        >
          <div />
          <div
            className={
              themeMode === "dark"
                ? "create-campaign-dialog-text "
                : "create-campaign-dialog-text-light "
            }
            style={{ fontSize: "18px", color: "red" }}
          >
            Change Password
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "black"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div className="py-6">
              <div className="sm:flex text-white sm:space-x-14 items-center mb-10">
                <div
                  className={`profile-field-title ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                  }`}
                >
                  {codeMode ? " Verification Code" : "Current Password"}:
                </div>
                <Controller
                  render={({ field }) => (
                    <CustomTextField
                      className="md:w-1/2 lg:w-1/2"
                      {...field}
                      error={!!errors.current_password}
                      helperText={errors?.current_password?.message}
                      required
                      variant="standard"
                    />
                  )}
                  name="current_password"
                  control={control}
                />

                <div
                  className={`profile-field-title cursor-pointer pt-0 sm:pt-4 ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                  }`}
                  style={{ color: "green", fontSize: "13px" }}
                  onClick={() => {
                    if (!codeMode) {
                      dispatch(requestCode()).then((resp) => {
                        if (resp.payload.status === 200) {
                          setValue("current_password", "");
                          setCodeMode(true);
                        }
                      });
                    }
                  }}
                >
                  {!codeMode
                    ? "Dont Remember? Request Code"
                    : "Enter Code sent on your email"}
                </div>
              </div>
              <div className="flex text-white space-x-12 items-center mb-10">
                <div
                  className={`profile-field-title w-1/9 ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                  }`}
                >
                  New Password:
                </div>
                <Controller
                  render={({ field }) => (
                    <CustomTextField
                      className="md:w-1/2 lg:w-1/2"
                      {...field}
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      required
                      variant="standard"
                    />
                  )}
                  name="password"
                  control={control}
                />{" "}
              </div>
              <div className="flex text-white space-x-6 items-center mb-10">
                <div
                  className={`profile-field-title ${
                    themeMode === "dark" ? "text-light" : "text-dark"
                  }`}
                >
                  Confirm Password:
                </div>
                <Controller
                  render={({ field }) => (
                    <CustomTextField
                      className="md:w-1/2 lg:w-1/2"
                      {...field}
                      error={!!errors.password_confirmation}
                      helperText={errors?.password_confirmation?.message}
                      required
                      variant="standard"
                    />
                  )}
                  name="password_confirmation"
                  control={control}
                />
              </div>

              <div className="w-full flex mb-2">
                {isPasswordMatched &&
                watch("password") &&
                watch("password_confirmation") ? (
                  <>
                    <CheckCircleOutline
                      style={{ marginRight: "10px", color: "green" }}
                    />
                    <Typography sx={{ color: "green" }}>
                      Passwords Match
                    </Typography>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="flex w-full justify-center">
                <Button
                  className="create-button p-2 px-4 "
                  variant="contained"
                  onClick={handleSubmit(onSubmit)}
                  sx={{ minWidth: "150px" }}
                >
                  <span className="create-button-text">Save</span>
                </Button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
