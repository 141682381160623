/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { Typography } from "@mui/material";
import { BeginYourJourney } from "../homepage/BeginYourJourney";
import { ContactUsForm } from "./ContactUsForm";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 0%;
    background-image: linear-gradient(180deg,#380103 10%, #8c0106 24%,#d10008 42%, #f00009 61%, #030000 93%);
  }
  
  25%{
    background-position: 50% 50%;
    background-image: linear-gradient(180deg, #700f12 10%, #4a0104 24%,#700206 42%, #700f12 61%, #bf0202 93%);
  }

  50% {
    background-position: 100% 100%;
    background-image: linear-gradient(0deg,#030000 10%, #9c0207 24%,#d10000 42%, #8c0106 61%, #380103 93%);
    background-image: linear-gradient(180deg, rgba(6,6,6,1) 0%, rgba(199,0,0,1) 100%);
  }
  
  75% {
    background-position: 50% 50%;
    background-image: linear-gradient(180deg, #700f12 10%, #4a0104 24%,#700206 42%, #700f12 61%, #bf0202 93%);
  }
  
  100% {
    background-position: 0% 0%;
    background-image: linear-gradient(180deg,#380103 10%, #8c0106 24%,#d10008 42%, #f00009 61%, #030000 93%);
  }
`;


export const ContactUs = () => {
  return (
    <>
      <Navbar />
      <div
       className="flex flex-col lg:flex md:pt-24 sm:pt-10 xs:pt-8"
        // style={{
        //   //backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/contact-shader.gif)`,
        //   backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/contactUsGif.gif)`,
        //   backgroundRepeat: "no-repeat",
        //   backgroundSize: "cover",
        // }}
        //   className="p-24"
        css={css`

          background-size: 240% 240%;
          animation: ${gradientAnimation} 20s ease infinite;
      `}
      >
        <div className="text-center text-white p-24 flex flex-col justify-center items-center">
          <Typography variant="h6">Contact Us</Typography>
          <Typography
            sx={{
              typography: {
                md: "h3",
                sm: "h4",
                xs: "h4",
              },
            }}
            style={{ fontWeight: 700 }}
            className="py-4 flex md:w-2/3"
          >
            We’ve got an entire team dedicated to supporting you and your
            business
          </Typography>
        </div>
        <div className="text-center text-white pb-2">Get in Touch</div>

        <div
          style={{
            backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/Vector3.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          }}
          className="text-white px-16 pt-24 pb-16"
        >
          <div className="lets-talk">Let’s talk!</div>
          <div className="lets-talk2">
            Go ahead and send us a brief about you. We will try to respond
            within 24 hours.
          </div>
          <div className="md:flex justify-between pt-16 space-y-16 md:space-y-0">
            <div className="">
              <ContactUsForm />
            </div>
            <div>
              <img src={"https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ContactLogo.png"} alt="here"></img>
            </div>
          </div>
        </div>
      </div>
      <BeginYourJourney />
      <Footer />
    </>
  );
};
