import * as React from "react";
import Button from "@mui/material/Button";
import { ButtonGroup, Grid, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import __ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setIntervalFrequency,
  setIntervalType,
  updateCampaign,
} from "../../../../store/campaignSlice";
import { error, success } from "../../../../store/alertSlice";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const _ = __.runInContext();

_.mixin({
  setIn: (state, name, value) => {
    return _.setWith(_.clone(state), name, value, _.clone);
  },
});

export default function FollowUpSendTime({ open, handleClose, followUp }) {
  const dispatch = useDispatch();
  const { name, id } = useParams();
  const navigate = useNavigate();
  const automatedMessage = useSelector(
    (state) => state.campaign.automatedMessage
  );

  const [selected, setSelected] = React.useState("hours");
  const [interval, setInterval] = React.useState(
    automatedMessage?.follow_up_interval_frequency
  );

  const themeMode = useSelector((state) => state.themeMode.mode);
  const campaign = useSelector((state) => state.campaign.campaign);

  const buttons = [
    <Button
      key="Minutes"
      onClick={() => {
        setSelected("minutes");
        dispatch(setIntervalType("minutes"));
      }}
      className={
        selected === "minutes"
          ? themeMode === "dark"
            ? "follow-up-time-btn-selected px-6"
            : "follow-up-time-btn-selected-light px-6"
          : themeMode === "dark"
            ? "follow-up-time-btn-outline px-6"
            : "follow-up-time-btn-outline-light px-6"
      }
      sx={{
        borderRadius: "10px 0px 0px 10px",
        fontSize: {
          md: "13px !important",
          sm: "13px !important",
          xs: "10px !important",
        },
      }}
    >
      <span
        className={
          selected === "minutes"
            ? themeMode === "dark"
              ? "follow-up-btn-text-light"
              : "follow-up-btn-text"
            : themeMode === "dark"
              ? "follow-up-btn-text"
              : "follow-up-btn-text-light"
        }
      >
        Minutes
      </span>
    </Button>,
    <Button
      key="Hours"
      onClick={() => {
        setSelected("hours");
        dispatch(setIntervalType("hours"));
      }}
      className={
        selected === "hours"
          ? themeMode === "dark"
            ? "follow-up-time-btn-selected px-6"
            : "follow-up-time-btn-selected-light px-6"
          : themeMode === "dark"
            ? "follow-up-time-btn-outline px-6"
            : "follow-up-time-btn-outline-light px-6"
      }
      sx={{
        fontSize: {
          md: "13px !important",
          sm: "13px !important",
          xs: "10px !important",
        },
      }}
    >
      <span
        className={
          selected === "hours"
            ? themeMode === "dark"
              ? "follow-up-btn-text-light"
              : "follow-up-btn-text"
            : themeMode === "dark"
              ? "follow-up-btn-text"
              : "follow-up-btn-text-light"
        }
      >
        Hours
      </span>
    </Button>,
    <Button
      key="Days"
      onClick={() => {
        setSelected("days");
        dispatch(setIntervalType("days"));
      }}
      className={
        selected === "days"
          ? themeMode === "dark"
            ? "follow-up-time-btn-selected px-6"
            : "follow-up-time-btn-selected-light px-6"
          : themeMode === "dark"
            ? "follow-up-time-btn-outline px-6"
            : "follow-up-time-btn-outline-light px-6"
      }
      sx={{
        fontSize: {
          md: "13px !important",
          sm: "13px !important",
          xs: "10px !important",
        },
      }}
    >
      <span
        className={
          selected === "days"
            ? themeMode === "dark"
              ? "follow-up-btn-text-light"
              : "follow-up-btn-text"
            : themeMode === "dark"
              ? "follow-up-btn-text"
              : "follow-up-btn-text-light"
        }
      >
        Days
      </span>
    </Button>,
    <Button
      key="Weeks"
      onClick={() => {
        setSelected("weeks");
        dispatch(setIntervalType("weeks"));
      }}
      className={
        selected === "weeks"
          ? themeMode === "dark"
            ? "follow-up-time-btn-selected px-6"
            : "follow-up-time-btn-selected-light px-6"
          : themeMode === "dark"
            ? "follow-up-time-btn-outline px-6"
            : "follow-up-time-btn-outline-light px-6"
      }
      sx={{
        borderRadius: "0px 10px 10px 0px",
        fontSize: {
          md: "13px !important",
          sm: "13px !important",
          xs: "10px !important",
        },
      }}
    >
      <span
        className={
          selected === "weeks"
            ? themeMode === "dark"
              ? "follow-up-btn-text-light"
              : "follow-up-btn-text"
            : themeMode === "dark"
              ? "follow-up-btn-text"
              : "follow-up-btn-text-light"
        }
      >
        Weeks
      </span>
    </Button>,
  ];

  const { control, watch, reset, handleSubmit, formState, getValues } = useForm(
    {
      mode: "onChange",
      // resolver: yupResolver(schema),
    }
  );

  useEffect(() => {
    reset(campaign);
    setSelected(automatedMessage?.follow_up_interval_type);
    setInterval(automatedMessage?.follow_up_interval_frequency);
  }, [reset, campaign]);

  const { isValid, dirtyFields, errors } = formState;

  const formData = watch();

  function onSubmit(data) {
    dispatch(
      updateCampaign({
        ...campaign,
        follow_up_interval_type: selected,
        follow_up_interval_frequency: data.follow_up_interval_frequency,
      })
    ).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(success("Campaign updated"));
      } else {
        dispatch(error(resp.payload.statusText));
      }
    });
  }

  useEffect(() => {
    handleSubmit(onSubmit);
  }, [followUp]);

  return (
    <div>
      <div>
        <div id="alert-dialog-description" style={{ color: "white" }}>
          <Grid
            my={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { md: "row", sm: "row", xs: "column" },
            }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  className={
                    themeMode === "dark"
                      ? "create-campaign-dialog-field w-full lg:w-1/5 sm:1/5 bRadius10"
                      : "create-campaign-dialog-field-light w-full lg:w-1/5 sm:1/5 bRadius10"
                  }
                  size="small"
                  type="number"
                  value={interval}
                  onChange={(ev) => {
                    setInterval(ev.target.value);
                    dispatch(setIntervalFrequency(ev.target.value));
                  }}
                  sx={{
                    marginY: { md: "0px", sm: "0px", xs: "15px" },
                    maxWidth: "100px",
                    borderRadius: "10px",
                    textAlign: "right",
                    "& .MuiInputBase-input": {
                      color: themeMode === "dark" ? "white" : "black", // Text color
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        color: themeMode === "dark" ? "white" : "black",
                      },
                    },
                  }}
                />
              )}
              name="automated_messages[0].follow_up_interval_frequency"
              control={control}
            />
            <ButtonGroup size="large" aria-label="Large button group">
              {buttons}
            </ButtonGroup>
          </Grid>
          {selected === "minutes" &&
            formData.follow_up_interval_frequency < 60 && (
              <div
                className={
                  themeMode === "dark"
                    ? "dashboard-subtitle py-2"
                    : "dashboard-subtitle-light py-2"
                }
                style={{ color: "red", fontSize: "13px" }}
              >
                Must be atleast 60 when interval type is set to minutes
              </div>
            )}
          {/* <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              className="create-button p-2 px-8 mt-4"
              variant="contained"
              disabled={
                selected === "minutes" &&
                formData.follow_up_interval_frequency < 60
              }
              onClick={handleSubmit(onSubmit)}
              style={{ backgroundColor: "white" }}
            >
              <span className="create-button-text" style={{ color: "red" }}>
                Save Follow up
              </span>
            </Button>
            <Button
              className="create-button p-2 px-8 mt-4"
              variant="contained"
              onClick={() => {
                if (campaign.campaign_type === "prospecting") {
                  navigate(`message-flow`);
                } else {
                  navigate(`message-flow-nurture`);
                }
              }}
              style={{ backgroundColor: "#730101" }}
            >
              <span className="create-button-text" style={{ color: "#FFF" }}>
                See how these messages work!
              </span>
            </Button>
          </Grid> */}
        </div>
      </div>
    </div>
  );
}