import {
  Button,
  Backdrop,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import {
  connectLinkedInAccount,
  getLinkedInConnectedStatus,
  setShowCaptcha,
  solveCodeCheckpoint,
} from "../../../store/settingsSlice";
import { ArkoseCaptchaIntegration } from "./captcha-integration/ArkoseCaptchaIntegration";
import { success } from "../../../store/alertSlice";

const defaultValues = {
  username: "",
  password: "",
};

const schema = yup
  .object()
  .shape({
    username: yup.string().required("You must enter email"),
    password: yup.string().required("You must enter password"),
  })
  .required();

export const CookieConnect = ({ handleClickOpenCookie }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const { handleSubmit, register, reset, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  function onSubmit(data) {
    console.log("🚀 ~ CookieConnect ~ data:", data);
  }
  return (
    <div>
      <div className={`create-campaign-dialog py-2 pt-4 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
        Copy your LinkedIn cookies.{" "}
        <span
          className="underline cursor-pointer"
          onClick={handleClickOpenCookie}
        >
          How to find them?
        </span>
      </div>
      <div className={`create-campaign-dialog py-2 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
        Your cookies need to be collected in the same browser as this page.
      </div>

      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.li_at}
            helperText={errors?.li_at?.message}
            placeholder="Enter your li_at value"
            required
            className={`w-full ${themeMode === "dark" ? "create-campaign-dialog-field" : "create-campaign-dialog-field-light"}`}
            size="small"
            sx={{
              "& .MuiInputBase-input": {
                color: themeMode === "dark" ? "#FFF" : "#000",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                  color: themeMode === "dark" ? "#FFF" : "#000",
                },
              },
            }}
          />
        )}
        name="li_at"
        control={control}
      />

      <div className={`create-campaign-dialog py-2 pt-4 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
        If your account has Recruiter or Sales Navigator subscription, copy li_a
        too.
      </div>
      <Controller
        render={({ field }) => (
          <TextField
            {...field}
            error={!!errors.li_a}
            helperText={errors?.li_a?.message}
            required
            placeholder="Enter your li_a value (optional)"
            className={`w-full ${themeMode === "dark" ? "create-campaign-dialog-field" : "create-campaign-dialog-field-light"}`}
            size="small"
            sx={{
              "& .MuiInputBase-input": {
                color: themeMode === "dark" ? "#FFF" : "#000",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "none",
                  color: themeMode === "dark" ? "#FFF" : "#000",
                },
              },
            }}
          />
        )}
        name="li_a"
        control={control}
      />

      <div className="flex w-full justify-center pt-8">
        <Button
          className="create-button p-2 px-4"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          <span className="create-button-text">Connect</span>
        </Button>
      </div>
    </div>
  );
};
