import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const BeginYourJourney = () => {
  
  const navigate = useNavigate();
  const user = useSelector((state) => state.userProfile.userProfile);

  const handleNavigateDashboard = () => {
    navigate("/dashboard");
  };
  const GoToSignUp = () => {
    navigate("register");
  };


  return (
    <div
      style={{
        backgroundImage: `url(	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/journey.gif)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="text-white p-8"
    >
      <div className="begin-journey-heading text-center">
        {" "}
        Get B2B Sales Leads in no Time
      </div>
      <div className="flex flex-col md:flex-row pt-6 justify-center items-center">
        <Button onClick={user ? handleNavigateDashboard : GoToSignUp} variant="contained" className="primary-button">
          {user ? "Go To Dashboard" : "Begin your journey"}
        </Button>
        <div className="text-center mt-4 md:mt-0">
          <span className="begin-journey-content ml-4 ">
            or Learn More About
          </span>
          <span className="begin-journey-subcontent ml-2 ">Our Services </span>
        </div>
      </div>
    </div>
  );
};
