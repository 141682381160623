import * as React from "react";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Backdrop, TextField } from "@mui/material";
//import ToggleSwitch from "../search-list/ToggleSwitch";
import { MySwitch } from "../time-zone/myComponents";

export default function ViewCampaignTeamMemberDialog({ open, handleClose }) {
  const themeMode = useSelector((state) => state.themeMode.mode);
  const [currentMembers, setCurrentMembers] = React.useState([
    "Ben Metzker",
    "Anthony Martinez",
  ]);
  const [organizationMembers, setOrganizationMembers] = React.useState([
    "Anab Imam",
    "Syed M. Hamza",
  ]);
  return (
    <Backdrop>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "#0000004D", backdropFilter: "blur(4px)" }}
        PaperProps={{
          sx: {
            backgroundColor: themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "#FFFFFFBB", // Inner background color
            borderRadius: "10px",
            border: themeMode === "dark" ? "1px solid white" : "1px solid #000",
            color: themeMode === "dark" ? "white" : "#000",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-end cursor-pointer pb-2 pt-6"
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode==="dark"?"white":"#000"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div className={`pb-4 add-team-member-heading ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
              Anthony Martinez{" "}
            </div>
            <div className={`view-team-content pb-8 pt-4 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
              Following guidelines of LinkedIn, the maximum amount of daily
              invites per user should be less than 25{" "}
            </div>

            <div className="flex space-x-8">
              <div className="w-1/3">
                <span className={`no-of-invites ${themeMode === "dark" ? "text-light" : "text-dark"} `}>
                  {" "}
                  Number of invites a day:{" "}
                </span>
                <div className={`no-of-invites-2 ${themeMode === "dark" ? "text-light" : "text-dark"}`}>
                  (10 invites left for allocation)
                </div>
              </div>
              <TextField
                className={themeMode === "dark" ? "create-campaign-dialog-field" : "create-campaign-dialog-field-light"}
                size="small"
                value={0}
                sx={{
                  "& .MuiInputBase-input": {
                    color: themeMode === "dark" ? "#FFF" : "#000"
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      color: themeMode==="dark"?"white":"#000",
                    },
                  },
                }}
              />{" "}
            </div>
            <div className="flex space-x-8 pt-12">
              <span className={`no-of-invites w-1/3 ${themeMode === "dark" ? "text-light" : "text-dark"} `}>
                {" "}
                Team Member Status:{" "}
              </span>
              <div className="flex space-x-4 items-center">
                {/* <ToggleSwitch /> */}
                <MySwitch />
                <div className="toggle-label ">Inactive</div>
              </div>
            </div>

            <div className="flex w-full justify-center pt-8">
              <Button className="create-button p-2" variant="contained">
                <span className="create-button-text text-xl">Save</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
