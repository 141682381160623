import React from 'react';
import { Typography, Button, TextField } from '@mui/material';
import { useSelector } from "react-redux";

const InMailMessages = ({ setSelectedSubTab }) => {
    const themeMode = useSelector((state) => state.themeMode.mode);

    return (
        <>
            <Typography variant="h5" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: '20px'}}>
                Edit your In-Mail Messages
            </Typography>
            <Typography variant="h6" sx={{ color: themeMode === "dark" ? 'white': 'black', marginBottom: '10px' }}>
                First InMail Message (1st Message)
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: 2 }}>
                InMail Subject Line*
            </Typography>
            <TextField
                fullWidth
                label=""
                variant="outlined"
                margin="normal"
                defaultValue="Subject line generated by AI"
                className={
                    themeMode === "dark"
                        ? "create-campaign-dialog-field w-full"
                        : "create-campaign-dialog-field-light w-full"
                }
                sx={{
                    "& .MuiInputBase-input": {
                        color: themeMode === "dark" ? "white" : "black",
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            border: "none",
                            color: themeMode === "dark" ? "white" : "black",
                        },
                    },
                }}
            />
            <Typography variant="subtitle1" gutterBottom sx={{ color: themeMode === "dark" ? 'white': 'black', marginTop: 2 }}>
                InMail Message*
            </Typography>
            <TextField
                fullWidth
                label=""
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                defaultValue="A long/short message generated by AI"
                className={
                    themeMode === "dark"
                        ? "create-campaign-dialog-field w-full"
                        : "create-campaign-dialog-field-light w-full"
                }
                sx={{
                    "& .MuiInputBase-input": {
                        color: themeMode === "dark" ? "white" : "black",
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            border: "none",
                            color: themeMode === "dark" ? "white" : "black",
                        },
                    },
                }}
            />
            <Button
                variant="contained"
                sx={{
                    textTransform: "none",
                    marginY: "30px"
                }}
                className={
                    themeMode === "dark"
                        ? "create-button px-9"
                        : "create-button-light px-9"
                }
                onClick={() => setSelectedSubTab(3)}
            >
                Next
            </Button>
        </>
    )
}
export default InMailMessages;