import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, styled } from '@mui/material';
import {
  Backdrop,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { error, success } from "../../../store/alertSlice";
import { addMembers } from "../../../store/organizationSlice";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const defaultValues = {
  invitee_details: [{ first_name: "", last_name: "", email: "" }]
};

const schema = yup.object().shape({
  invitee_details: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required("You must enter first name"),
      last_name: yup.string().required("You must enter last name"),
      email: yup.string().email("Invalid email").required("You must enter email"),
    })
  ),
});


export default function AddMemberDialog({ openDialog, handleCloseAddMemberDialog }) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);

  const { handleSubmit, control, reset, formState: { errors } } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });
  
  const { fields, append, remove } = useFieldArray({
    control,
    name: "invitee_details",
  });

  function onSubmit(data) {
    const organization = {
      invitee_details: data.invitee_details
    }
    dispatch(addMembers(organization)).then((resp) => {
      if (resp?.payload?.status === 200) {
        dispatch(success("invitation request has been sent"));
      } 
    });
    handleCloseDialog();
  }

  const handleCloseDialog = () => {
    reset(defaultValues); 
    handleCloseAddMemberDialog();
  };

  const CustomTextField = styled(TextField)({
    "& .MuiInput-underline:before": {
    borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:hover:before": {
    borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:after": {
    borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInputBase-input": {
    color: themeMode === "dark" ? "white" : "#000",
    fontSize: "13px",
    },
    "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "gray",
    },
  });

  return (
    <Backdrop>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          background: themeMode === "dark" ? "rgba(0, 0, 0, 0.30)" : "FFFFFF",
          backdropFilter: "blur(4px)",
        }}
        PaperProps={{
          sx: {
            backgroundColor:
              themeMode === "dark" ? "rgba(0, 0, 0, 0.60)" : "FFFFFF", 
            borderRadius: "10px",
            border: "1px solid white",
            color: themeMode === "dark" ? "white" : "black",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
        }}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="w-full flex justify-between cursor-pointer"
          onClick={handleCloseDialog}
        >
          <div />
          <div
            className={
              themeMode === "dark"
                ? "create-campaign-dialog-text "
                : "create-campaign-dialog-text-light "
            }
            style={{ fontSize: "18px", color: themeMode === "dark" ? "white" : "black" }}
          >
            Add members to your organization{" "}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
          >
            <path
              d="M1.5 1L20.5 16.5M20.5 1L1.5 16.5"
              stroke={themeMode === "dark" ? "white" : "black"}
              stroke-width="2"
            />
          </svg>{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "white" }}
          >
            <div
               style={{
                maxHeight: "200px", 
                overflowY: "auto",  
              }}
            >
              {fields.map((field, index) => (
                <div key={field.id} className="py-6 lg:flex lg:space-x-4" >
                  <div className="flex justify-center sm:justify-start text-white space-x-2 items-center mb-8">
                    <div
                      className={`profile-field-title  ${
                      themeMode === "dark" ? "text-light" : "text-dark"
                      }`}
                    >
                      First Name:
                    </div>
                    <Controller
                      render={({ field }) => (
                      <CustomTextField
                        className=" "
                        {...field}
                        error={!!errors.invitee_details?.[index]?.first_name}
                        helperText={errors.invitee_details?.[index]?.first_name?.message}
                        required
                        variant="standard"
                      />
                      )}
                      name={`invitee_details[${index}].first_name`}
                      control={control}
                    />
                  </div>
                  <div className="flex justify-center sm:justify-start text-white space-x-2 items-center mb-8">
                    <div
                      className={`profile-field-title  ${
                      themeMode === "dark" ? "text-light" : "text-dark"
                      }`}
                    >
                      Last Name:
                    </div>
                    <Controller
                      render={({ field }) => (
                      <CustomTextField
                        className=" "
                        {...field}
                        error={!!errors.invitee_details?.[index]?.last_name}
                        helperText={errors.invitee_details?.[index]?.last_name?.message}
                        required
                        variant="standard"
                      />
                      )}
                      name={`invitee_details[${index}].last_name`}
                      control={control}
                    />
                  </div>
                  <div className="flex justify-center sm:justify-start text-white space-x-2 items-center mb-8">
                    <div
                      className={`profile-field-title  ${
                      themeMode === "dark" ? "text-light" : "text-dark"
                      }`}
                    >
                      Email:{" "}
                    </div>
                    <Controller
                      render={({ field }) => (
                      <CustomTextField
                        className=" "
                        {...field}
                        error={!!errors.invitee_details?.[index]?.email}
                        helperText={errors.invitee_details?.[index]?.email?.message}
                        required
                        variant="standard"
                      />
                      )}
                      name={`invitee_details[${index}].email`}
                      control={control}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="mb-8">
              <div
                className={`cursor-pointer text-center py-4 ${
                  themeMode === "dark"
                    ? "add-member-dashed"
                    : "add-member-dashed-light"
                }`}
                onClick={() => append({ first_name: "", last_name: "", email: "" })}
              >
                <span
                  className={
                    themeMode === "dark"
                      ? "add-member-title "
                      : "add-member-title-light "
                  }
                >
                  + Add More Members{" "}
                </span>
              </div>
            </div>
            <div className="flex w-full justify-end space-x-4 pt-8">
              <Button
                className={
                  themeMode === "dark"
                    ? "create-button p-2 px-8"
                    : "create-button-light p-2 px-8"
                }
                style={{ backgroundColor: "transparent" }}
                variant="contained"
                onClick={handleCloseDialog}
              >
                <span
                  className="create-button-text"
                  style={{
                    color: themeMode === "dark" ? "white" : "black",
                  }}
                >
                  Cancel
                </span>
              </Button>
              <Button
                sx={{
                  borderRadius: '30px',
                  border: '1px solid #000',
                }}
                variant="contained"
                color="success"
                onClick={handleSubmit(onSubmit)}
              >
                <span className="create-button-text">Send Invitation</span>
              </Button>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
}
