import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OnboardingNav from "./OnboardingNav";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { setPassword } from "../store/onboardingSlice";
import { error, success } from "../store/alertSlice";
import { useDispatch } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import OnboardingErrorDialog from "./OnboardingErrorDialog";

const bkg =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/welcomeFinal.gif";
// "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/onboarding2Gif.gif"
// "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/setPassBkg.png";
const logo =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoWhiteGlow.png	";

const SetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isCustomRange = useMediaQuery(
    "(min-width:900px) and (max-width:1150px)"
  );
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
    }
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(
        /[*&^%$#@!]/,
        "Password must contain at least one special character (*&^%$#@!)"
      )
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
    dispatch(setPassword(data)).then((resp) => {
      if (resp.payload?.status === 200) {
        dispatch(success(`Details updated!`));
        // navigate("/plan-subscription");
        handlePageLeave();
      } else {
        handleClickOpen();
        dispatch(error("Error occured"));
      }
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [isPasswordMatched, setIsPasswordMatched] = useState(false);

  useEffect(() => {
    setIsPasswordMatched(watch("password") === watch("password_confirmation"));
  }, [watch("password"), watch("password_confirmation")]);

  const [animationClass, setAnimationClass] = useState("page-enter");

  useEffect(() => {
    setAnimationClass("page-enter");
    return () => {};
  }, []);

  const handlePageLeave = () => {
    setAnimationClass("page-exit");
    setTimeout(() => {
      navigate("/plan-subscription");
    }, 500);
  };

  return (
    <Grid
      className="welcome"
      sx={{
        // backgroundImage: `url(${bkg})`,
        backgroundImage:
          "linear-gradient(to right, #b03302 0%, #FF3001 20%, #FF3001 80%, #b03302 100%)",
        height: {
          md: isCustomRange ? "120vh !important" : "100vh !important",
          sm: "120vh !important",
          xs: "110vh !important",
        },
      }}
    >
      <OnboardingNav selected={3} />
      <Grid
        mt={{ md: 16, sm: 15, xs: 18 }}
        mb={{ md: "", sm: 8, xs: 10 }}
        className={animationClass}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { md: "flex-start", sm: "center", xs: "center" },
          justifyContent: "center",
          width: { md: "53%", sm: "53%", xs: "90%" },
          height: "fit-content",
          transform: { md: "scale(1)", sm: "scale(0.8)", xs: "scale(0.9)" },
        }}
      >
        <img src={logo} alt="Logo" />
        <Typography
          sx={{
            fontSize: { md: "40px", sm: "40px", xs: "30px" },
            fontWeight: "700",
            textWrap: "nowrap",
          }}
        >
          Set your password
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "18px", sm: "18px", xs: "14px" },
            fontWeight: "400",
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          Your password must be at least 8 character long with at least one
          UPPER case, one lower case and one special character (*&^%$#@).
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: {
              xs: "90%",
              sm: "100%",
              md: "60%",
              lg: "50%",
              xl: "43%",
            },
            margin: "20px 0px",
          }}
        >
          <Grid sx={{ display: "flex", flexDirection: "column" }}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  FormHelperTextProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  {...field}
                  placeholder="Enter Password*"
                  type={showPassword ? "text" : "password"}
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  required
                  InputProps={{
                    style: {
                      color: "#FFF",
                      borderRadius: "15px",
                      backgroundColor: "transparent",
                      border: "1px solid #FFFFFF",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          style={{ color: "#FFF" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#FFF",
                    },
                    shrink: true,
                  }}
                  sx={{
                    marginY: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />

            <Controller
              name="password_confirmation"
              control={control}
              render={({ field }) => (
                <TextField
                  FormHelperTextProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  {...field}
                  placeholder="Confirm Password*"
                  type={showConfirmPassword ? "text" : "password"}
                  error={!!errors.password_confirmation}
                  helperText={errors?.password_confirmation?.message}
                  required
                  InputProps={{
                    style: {
                      color: "#FFF",
                      borderRadius: "15px",
                      backgroundColor: "transparent",
                      border: "1px solid #FFFFFF",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                          style={{ color: "#FFF" }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#FFF",
                    },
                    shrink: true,
                  }}
                  sx={{
                    marginY: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Box>
        <Grid mb={2} sx={{ display: "flex" }}>
          {isPasswordMatched &&
          watch("password") &&
          watch("password_confirmation") ? (
            <>
              <CheckCircleIcon
                style={{ marginRight: "10px", color: "green" }}
              />
              <Typography sx={{ color: "green" }}>Passwords Match</Typography>
            </>
          ) : (
            ""
          )}
        </Grid>
        <Button
          type="submit"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          sx={{
            fontWeight: "700",
            marginY: "10px",
            backgroundColor: "#FFF",
            borderRadius: "30px",
            color: "#B50000",
            textTransform: "capitalize",
            padding: "8px 20px",
            border: "1px solid transparent",
            "&:hover": {
              background: "transparent",
              color: "#FFF",
              border: "1px solid #FFF",
            },
          }}
        >
          Set Password
        </Button>
      </Grid>
      <OnboardingErrorDialog
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
      />
    </Grid>
  );
};
export default SetPassword;
