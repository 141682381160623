import { Avatar, Box, Button, styled, TextField } from "@mui/material";
import FileUploadButton from "./FileUploadButton";
import { DeleteOutline } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getOrganization,
  updateOrganization,
} from "../../../store/settingsSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import __ from "lodash";
import { success } from "../../../store/alertSlice";

const userDp =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ben.png";

const CustomTextField = styled(TextField)({
  "& .MuiInput-underline:before": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiInputBase-input": {
    color: "white",
    fontSize: "13px",
  },

  //   minWidth: "239px",
});

const defaultValues = {
  title: "",
  address: "",
  website: "",
  logo_data_uri: "",
  logo_url: "",
};

const schema = yup
  .object()
  .shape({
    title: yup.string().required("You must enter title"),
    address: yup.string().required("You must enter address"),
    website: yup.string().required("You must enter website"),
    logo_data_uri: yup.mixed().notRequired(),
    logo_url: yup.string().notRequired(),
  })
  .required();

export const Organization = () => {
  const organization = useSelector((state) => state.settings.organization);
  const user = useSelector((state) => state.userProfile.userProfile);
  const emailExists = organization?.admins?.some(
    (member) => member.email === user.email
  );

  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const { handleSubmit, register, reset, setValue, control, watch, formState } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  useEffect(() => {
    reset({ ...organization });
  }, [organization, reset]);

  useEffect(() => {
    // dispatch(getOrganization());
  }, []);

  function onSubmit(data) {
    dispatch(updateOrganization(data)).then((resp) => {
      if (resp.payload.status === 200) {
        dispatch(success("Organization updated"));
        dispatch(getOrganization());
      }
    });
  }

  const CustomTextField = styled(TextField)({
    "& .MuiInput-underline:before": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: themeMode === "dark" ? "white" : "#000",
    },
    "& .MuiInputBase-input": {
      color: themeMode === "dark" ? "white" : "#000",
      fontSize: "13px",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "gray", // Change the text color in disabled state
    },
    //   minWidth: "239px",
  });

  return (
    <div>
      <div
        className={`settings-heading ${
          themeMode === "dark" ? "text-light" : "text-dark"
        }`}
      >
        Organization Settings
      </div>
      <div className="py-6">
        <div className="flex justify-center sm:justify-start text-white space-x-4 sm:space-x-16 items-center mb-10">
          <div
            className={`profile-field-title w-1/7 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Name:<span style={{ color: "transparent" }}>aa</span>
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                disabled={!emailExists}
                className="md:w-1/2 lg:w-1/4"
                {...field}
                error={!!errors.title}
                helperText={errors?.title?.message}
                required
                variant="standard"
                sx={{
                  width: { md: "239px", sm: "239px", xs: "150px" },
                  marginLeft: { md: "10px" },
                }}
              />
            )}
            name="title"
            control={control}
          />
        </div>

        <div className="flex justify-center sm:justify-start text-white space-x-4 sm:space-x-16 items-center mb-10">
          <div
            className={`profile-field-title w-1/7 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Address:
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                disabled={!emailExists}
                className="md:w-1/2 lg:w-1/4"
                {...field}
                error={!!errors.address}
                helperText={errors?.address?.message}
                required
                variant="standard"
                sx={{ width: { md: "239px", sm: "239px", xs: "150px" } }}
              />
            )}
            name="address"
            control={control}
          />{" "}
        </div>
        <div className="flex justify-center sm:justify-start text-white space-x-4 sm:space-x-16 items-center mb-10">
          <div
            className={`profile-field-title w-1/7 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Website:
          </div>
          <Controller
            render={({ field }) => (
              <CustomTextField
                disabled={!emailExists}
                className="md:w-1/2 lg:w-1/4"
                {...field}
                error={!!errors.website}
                helperText={errors?.website?.message}
                required
                variant="standard"
                sx={{ width: { md: "239px", sm: "239px", xs: "150px" } }}
              />
            )}
            name="website"
            control={control}
          />{" "}
        </div>

        <div className="flex flex-col sm:flex-row justify-center sm:justify-start text-white space-y-4 sm:space-y-0 sm:space-x-16 items-center mb-10">
          <div
            className={`profile-field-title w-1/7 ${
              themeMode === "dark" ? "text-light" : "text-dark"
            }`}
          >
            Profile Picture:
          </div>
          <Avatar
            alt="Remy Sharp"
            src={watch("logo_data_uri") || watch("logo_url")}
            sx={{ width: 100, height: 100 }}
          />{" "}
          <div>
            {" "}
            <FileUploadButton setValue={setValue} />
          </div>
          <div>
            <Button
              component="label"
              variant="outlined"
              tabIndex={-1}
              className="py-2 px-4"
              startIcon={<DeleteOutline />}
              style={{
                borderRadius: "30px",
                border:
                  themeMode === "dark"
                    ? "1px solid white"
                    : "1px solid #00000080",
                color:
                  themeMode === "dark"
                    ? "rgba(255, 255, 255, 0.4)"
                    : "#00000080",
                textTransform: "none",
              }}
              onClick={() => {
                setValue("remove_logo", true);
                setValue("logo_data_uri", "");
                setValue("logo_url", "");
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
      <Button
        className={`p-2 px-4 ${
          themeMode === "dark" ? "create-button" : "create-button-light"
        }`}
        variant="contained"
        onClick={handleSubmit(onSubmit)}
      >
        <span className="create-button-text">Save</span>
      </Button>
    </div>
  );
};
