export const MissionCard = ({ imageSrc, title, content }) => {
  return (
    <div className=" ">
      <div className="mission-container w-full text-white">
        <img src={imageSrc} className="mission-layout" width={90} alt="icon" />
        <div className="mission-card text-center">
          <div className="mission-card-heading pt-16">{title}</div>
          <div className="mission-card-content py-16 px-10">{content}</div>
        </div>
      </div>
    </div>
  );
};
