import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Chip, TablePagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SaveAlt } from "@mui/icons-material";
import { getChat } from "../../store/messagesSlice";
import {
  getConnections,
  getWaitingConnections,
} from "../../store/connectionSlice";
import { useState } from "react";

export default function PendingConnectionsTable({
  openMessageShow,
  inputvalue,
  selectedFilter,
  tag_uuids,
}) {
  const dispatch = useDispatch();
  const themeMode = useSelector((state) => state.themeMode.mode);
  const connections = useSelector(
    (state) => state.connection.waitingConnections
  );
  const connectionMeta = useSelector(
    (state) => state.connection.waitingConnectionMeta
  );

  const [page, setPage] = useState(
    connectionMeta?.current_page ? connectionMeta?.current_page - 1 : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    connectionMeta?.current_page ? connectionMeta?.per_page : 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      getWaitingConnections({
        page: newPage + 1,
        per_page: rowsPerPage,
        query: inputvalue,
        has_email: selectedFilter.includes("email"),
        has_phone_number: selectedFilter.includes("phone"),
        tag_uuids: tag_uuids.length > 0 ? tag_uuids.map((tag) => tag.uuid) : [],
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      getWaitingConnections({
        page: 1,
        per_page: parseInt(event.target.value, 10),
        query: inputvalue,
        has_email: selectedFilter.includes("email"),
        has_phone_number: selectedFilter.includes("phone"),
        tag_uuids: tag_uuids.length > 0 ? tag_uuids.map((tag) => tag.uuid) : [],
      })
    );
  };

  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Name{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Meeting{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Tags{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Title{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Company{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Email{" "}
            </TableCell>
            <TableCell
              className={` ${
                themeMode === "dark"
                  ? "table-col-title text-light"
                  : "table-col-title-light text-dark"
              }`}
              align="left"
            >
              Phone Number{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {connections?.length > 0 &&
            connections?.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                className="cursor-pointer"
                onClick={() => {
                  dispatch(getChat(row.chat_uuid)).then(() => {
                    openMessageShow(row);
                  });
                }}
              >
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                >
                  {row.full_name}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.meeting_booked === true ? "Booked" : ""}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.tags.map((tag, index) => (
                    <div>
                      <Chip
                        label={tag}
                        variant="outlined"
                        className={
                          themeMode === "dark"
                            ? "table-row-tags mb-1"
                            : "table-row-tags-light mb-1"
                        }
                        sx={{
                          color: themeMode === "dark" ? "white" : "black",
                          fontSize: "10px",
                        }}
                      />
                    </div>
                  ))}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.current_job_title}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.current_company}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.email ? row.email : "Not available"}
                </TableCell>
                <TableCell
                  className={` ${
                    themeMode === "dark"
                      ? "table-row-content text-light"
                      : "table-row-content-light text-dark"
                  }`}
                  align="left"
                >
                  {row.phone ? row.phone : "Not Available"}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={connectionMeta?.total_count ? connectionMeta?.total_count : 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .Mui-disabled": {
            color: themeMode === "dark" ? "darkgray" : "gray",
          },
          "& .MuiSelect-icon": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-displayedRows": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
          "& .MuiTablePagination-toolbar": {
            padding: "10px",
          },
          "& .MuiTablePagination-actions": {
            color: themeMode === "dark" ? "white" : "black",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-input": {
            color: themeMode === "dark" ? "white" : "black",
            fontSize: "13px",
          },
        }}
      />
    </TableContainer>
  );
}
