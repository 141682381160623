import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error } from "./alertSlice";
import api from "../services/api";

const initialState = {
  inboxMessages: [],
  inboxMeta: {},
  teamInboxMessages: {},
  outboxMessages: {},
  sentMessages: {},
  deleteMessageId: null,
  openedChat: {},
  messageTab: "My Inbox",
  show_reminder: false,
  messagesLoading: false,
  selectedIndex: 0,
  openChatBox: false,
  prospectInfo: {},
  chatNotes: [],
};

export const getProspectInfo = createAsyncThunk(
  "messages/getProspectInfo",
  async (prospect, { dispatch }) => {
    try {
      const res = await api.get(`/prospects/${prospect}/profile_info`);
      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getChat = createAsyncThunk(
  "messages/getChat",
  async (searchQuery, { dispatch }) => {
    try {
      const res = await api.get(`/chats/${searchQuery}`);
      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getInboxMessages = createAsyncThunk(
  "messages/getInboxMessages",
  async (searchQuery, { dispatch }) => {
    try {
      const res = await api.get(`/chats/my_inbox`, {
        params: {
          query: searchQuery ? searchQuery.query : "",
          page: searchQuery ? searchQuery.page : 1,
          per_page: searchQuery ? searchQuery.per_page : 10,
          unread: searchQuery?.unread ? searchQuery.unread : false,
          tag_uuids: searchQuery?.tag_uuids ? searchQuery.tag_uuids : [],
        },
      });
      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const deleteChat = createAsyncThunk(
  "messages/deleteChat",
  async (params, { dispatch, getState }) => {
    try {
      const res = await api.delete(`/chats/${params}/delete_chat`);

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const setChatTags = createAsyncThunk(
  "messages/setChatTags",
  async (chat, { dispatch, getState }) => {
    try {
      const res = await api.put(`/chats/${chat.id}/set_tags_for_chat`, {
        chat,
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const markMeetingBooked = createAsyncThunk(
  "messages/markMeetingBooked",
  async (chat, { dispatch, getState }) => {
    try {
      const res = await api.put(`/chats/${chat}/mark_meeting_as_booked`);

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const setReminder = createAsyncThunk(
  "messages/setReminder",
  async (chat, { dispatch, getState }) => {
    try {
      const res = await api.put(`/chats/${chat.id}/set_reminder_to_chat`, {
        chat,
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const sendChatMessage = createAsyncThunk(
  "messages/sendChatMessage",
  async (chat, { dispatch, getState }) => {
    try {
      const res = await api.put(`/chats/${chat.id}/send_new_message_in_chat`, {
        chat,
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const markAsRead = createAsyncThunk(
  "messages/markAsRead",
  async (chat, { dispatch, getState }) => {
    try {
      const res = await api.put(`/chats/${chat.id}/mark_all_messages_as_read`);

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const receiveReply = createAsyncThunk(
  "messages/receiveReply",
  async (chat, { dispatch, getState }) => {
    try {
      const res = await api.post(`/chats/receive_a_reply`);

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const sendScheduledMessage = createAsyncThunk(
  "messages/sendScheduledMessage",
  async (chat, { dispatch, getState }) => {
    try {
      const res = await api.put(
        `/chats/${chat.id}/send_new_scheduled_message_in_chat`,
        {
          chat,
        }
      );

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const setNotes = createAsyncThunk(
  "messages/setNotes",
  async (note, { dispatch, getState }) => {
    try {
      const res = await api.post(`/chats/${note.id}/notes`, {
        note,
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const deleteNote = createAsyncThunk(
  "messages/deleteNote",
  async (note, { dispatch, getState }) => {
    try {
      const res = await api.delete(`/chats/${note.id}/notes/${note.noteId}`, {
        note,
      });

      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const getNotes = createAsyncThunk(
  "messages/getNotes",
  async (chat, { dispatch, getState }) => {
    try {
      const res = await api.get(`/chats/${chat}/notes`);
      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.response.data.error));
      console.error(err);
      return null;
    }
  }
);

export const getTeamInboxMessages = createAsyncThunk(
  "messages/getTeamInboxMessages",
  async (searchQuery, { dispatch }) => {
    try {
      const res = await api.get(`/chats/team_inbox`, {
        params: {
          query: searchQuery ? searchQuery.query : "",
          page: searchQuery ? searchQuery.page : 1,
          per_page: searchQuery ? searchQuery.per_page : 10,
          unread: searchQuery?.unread ? searchQuery.unread : false,
          tag_uuids: searchQuery?.tag_uuids ? searchQuery.tag_uuids : [],
        },
      });
      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);
export const getOutboxMessages = createAsyncThunk(
  "messages/getOutboxMessages",
  async (searchQuery, { dispatch }) => {
    try {
      const res = await api.get(`/chats/my_outbox`, {
        params: {
          query: searchQuery ? searchQuery.query : "",
          page: searchQuery ? searchQuery.page : 1,
          per_page: searchQuery ? searchQuery.per_page : 10,
          unread: searchQuery?.unread ? searchQuery.unread : false,
          tag_uuids: searchQuery?.tag_uuids ? searchQuery.tag_uuids : [],
        },
      });
      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const getSentMessages = createAsyncThunk(
  "messages/getSentMessages",
  async (searchQuery, { dispatch }) => {
    try {
      const res = await api.get(`/chats/sent_messages`, {
        params: {
          query: searchQuery ? searchQuery.query : "",
          page: searchQuery ? searchQuery.page : 1,
          per_page: searchQuery ? searchQuery.per_page : 10,
          unread: searchQuery?.unread ? searchQuery.unread : false,
          tag_uuids: searchQuery?.tag_uuids ? searchQuery.tag_uuids : [],
        },
      });
      return { data: res.data, status: res.status };
    } catch (err) {
      dispatch(error(err.message));
      console.error(err);
      return null;
    }
  }
);

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setDeleteMessageId: (state, action) => {
      state.deleteMessageId = action.payload;
    },
    setOpenedChat: (state, action) => {
      state.openedChat = action.payload;
    },
    setMessageTab: (state, action) => {
      state.messageTab = action.payload;
    },
    setShowMessageReminder: (state, action) => {
      state.show_reminder = action.payload;
    },
    setSelectedIndex: (state, action) => {
      state.selectedIndex = action.payload;
    },
    setOpenChatBox: (state, action) => {
      state.openChatBox = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInboxMessages.pending, (state, action) => {
        state.messagesLoading = true;
      })
      .addCase(getInboxMessages.fulfilled, (state, action) => {
        state.inboxMessages = action.payload?.data?.data;
        state.inboxMeta = action.payload?.data?.meta;
        state.messagesLoading = false;
      })
      .addCase(getInboxMessages.rejected, (state, action) => {
        state.messagesLoading = false;
      })
      .addCase(getTeamInboxMessages.pending, (state, action) => {
        state.messagesLoading = true;
      })
      .addCase(getTeamInboxMessages.fulfilled, (state, action) => {
        state.inboxMessages = action.payload?.data?.data;
        state.inboxMeta = action.payload?.data?.meta;
        state.messagesLoading = false;
      })
      .addCase(getTeamInboxMessages.rejected, (state, action) => {
        state.messagesLoading = true;
      })
      .addCase(getOutboxMessages.pending, (state, action) => {
        state.messagesLoading = true;
      })
      .addCase(getOutboxMessages.fulfilled, (state, action) => {
        state.inboxMessages = action.payload?.data?.data;
        state.inboxMeta = action.payload?.data?.meta;
        state.messagesLoading = false;
      })
      .addCase(getOutboxMessages.rejected, (state, action) => {
        state.messagesLoading = false;
      })

      .addCase(getSentMessages.pending, (state, action) => {
        state.messagesLoading = true;
      })

      .addCase(getSentMessages.fulfilled, (state, action) => {
        state.inboxMessages = action.payload?.data?.data;
        state.inboxMeta = action.payload?.data?.meta;
        state.messagesLoading = false;
      })
      .addCase(getSentMessages.rejected, (state, action) => {
        state.messagesLoading = false;
      })
      .addCase(getChat.pending, (state, action) => {
        state.messagesLoading = true;
      })
      .addCase(getChat.fulfilled, (state, action) => {
        state.openedChat = action.payload?.data;
        state.messagesLoading = false;
      })
      .addCase(getChat.rejected, (state, action) => {
        state.messagesLoading = false;
      })
      .addCase(getProspectInfo.fulfilled, (state, action) => {
        state.prospectInfo = action.payload?.data?.deeper_profile_info;
      })
      .addCase(getNotes.fulfilled, (state, action) => {
        state.chatNotes = action.payload?.data;
      });
  },
});

export const {
  setDeleteMessageId,
  setOpenedChat,
  setMessageTab,
  setShowMessageReminder,
  setSelectedIndex,
  setOpenChatBox,
} = messagesSlice.actions;

export default messagesSlice.reducer;
