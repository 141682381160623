const tango ="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/tango.png"
const tend = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/tend.png";
const ribbon = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ribbon.png"
const mind = "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/mind.png";
const ladder = "	https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/ladder.png"

export const Partners = () => {
  return (
    <div className="partners py-4">
      <div className="flex md:flex-row flex-col flex-wrap md:space-x-16 space-y-8 md:space-y-0 items-center w-full justify-center">
        <img src={tango} alt="tango" className=" pt-3" />
        <img src={tend} alt="tend" />
        <img src={ribbon} alt="ribbon" />
        <img src={mind} alt="mind" />
        <img src={ladder} alt="ladder" />
      </div>
    </div>
  );
};
