import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignDetail,
  updateTeamMembers,
} from "../../../../store/campaignSlice";
import { success } from "../../../../store/alertSlice";

export default function TeamMemberTable({ handleClickOpen }) {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign.campaign);
  const themeMode = useSelector((state) => state.themeMode.mode);
  const { id } = useParams();

  const navigate = useNavigate();
  return (
    <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Name{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Email{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Status{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Daily Invites{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              New Connections{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Replies{" "}
            </TableCell>
            <TableCell
              className={
                themeMode === "dark"
                  ? "table-col-title"
                  : "table-col-title-light"
              }
              align="left"
            >
              Actions{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaign?.admins?.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className="cursor-pointer"
            >
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-content"
                    : "table-row-content-light"
                }
                onClick={handleClickOpen}
              >
                {row.first_name + " " + row.last_name} <small>(Admin)</small>
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-content"
                    : "table-row-content-light"
                }
                align="left"
              >
                {row.email}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-content"
                    : "table-row-content-light"
                }
                align="left"
              >
                {row.status}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-stats"
                    : "table-row-stats-light"
                }
                align="left"
              >
                <span> {row.dailyInvites}</span>
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-stats"
                    : "table-row-stats-light"
                }
                align="left"
              >
                {row.newConnections}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-stats"
                    : "table-row-stats-light"
                }
                align="left"
              >
                {row.replies}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-stats"
                    : "table-row-stats-light"
                }
                align="left"
              >
                <div className="flex space-x-2">
                  <span className="remove-team-member">N/A</span>
                </div>
              </TableCell>
            </TableRow>
          ))}
          {campaign?.members?.map((row, index) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className="cursor-pointer"
            >
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-content"
                    : "table-row-content-light"
                }
                onClick={handleClickOpen}
              >
                {row.first_name + " " + row.last_name}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-content"
                    : "table-row-content-light"
                }
                align="left"
              >
                {row.email}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-content"
                    : "table-row-content-light"
                }
                align="left"
              >
                {row.status}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-stats"
                    : "table-row-stats-light"
                }
                align="left"
              >
                <span> {row.dailyInvites}</span>
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-stats"
                    : "table-row-stats-light"
                }
                align="left"
              >
                {row.newConnections}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-stats"
                    : "table-row-stats-light"
                }
                align="left"
              >
                {row.replies}
              </TableCell>
              <TableCell
                className={
                  themeMode === "dark"
                    ? "table-row-stats"
                    : "table-row-stats-light"
                }
                align="left"
                onClick={() =>
                  dispatch(
                    updateTeamMembers({
                      id: id,
                      add_member_uuids: [],
                      remove_member_uuids: [row.uuid],
                    })
                  ).then((resp) => {
                    if (resp.payload.status === 200) {
                      dispatch(getCampaignDetail(id));
                      dispatch(success("Memebers updated"));
                    }
                  })
                }
              >
                <div className="flex space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="16"
                    viewBox="0 0 12 16"
                    fill="none"
                    // className="mt-2"
                  >
                    <path
                      d="M12 0.888889H9L8.14286 0H3.85714L3 0.888889H0V2.66667H12M0.857143 14.2222C0.857143 14.6937 1.03775 15.1459 1.35925 15.4793C1.68074 15.8127 2.11677 16 2.57143 16H9.42857C9.88323 16 10.3193 15.8127 10.6408 15.4793C10.9622 15.1459 11.1429 14.6937 11.1429 14.2222V3.55556H0.857143V14.2222Z"
                      fill="#FF0000"
                    />
                  </svg>{" "}
                  <span className="remove-team-member">Remove</span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
