import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
  AppBar,
  Badge,
  Toolbar,
  Typography,
  Grid,
  Box,
  Button,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  Paper,
  Divider,
  Avatar,
} from "@mui/material";
import { ToolbarList } from "../toolbar/toolbarList";
import NotificationMenu from "./NotificationMenu";
import { useDispatch, useSelector } from "react-redux";
import { setThemeMode } from "../../store/themeModeSlice";
import { logoutUser } from "../../store/userProfileSlice";
import { CheckOutlined, Home } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import MenuIcon from "@mui/icons-material/Menu";
import MessageDialog from "../messages/messageShow/MessageDialog";
import { setSelectedTabValue } from "../../store/settingsSlice";
import { getNotifications } from "../../store/notificationSlice";
import { addRemoveToken } from "../../store/loginSlice";

import {
  helpBlack,
  helpGrey,
  helpWhite,
  nightModeBlack,
  nightModeGrey,
  nightModeWhite,
  notifBlack,
  notifWhite,
  notifGrey,
} from "./data";

const logout =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logout.png";

const bellBlack =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bellBlack.png";

const bell =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bellGrey.png";

const bellWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bellWhite.png";

const logoYellow =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoFace.png";
const logoRed =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/logoRed.png";

const linkedInRounded =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/linkedInRounded.png";
const linkedInRoundedWhite =
  "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/linkedInroundedWhite.png";

const NavbarApp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const storedTheme = localStorage.getItem("theme");
  const [isTheme, setIsTheme] = useState(storedTheme);
  const linkedin = useSelector(
    (state) => state.settings.linkedInConnectedStatus
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openMessageShow, setOpenMessageShow] = useState(false);
  const [messageId, setMessageId] = useState(null);

  const handleOpenMessage = (message) => {
    setMessageId(message.notificaitonable_uuid);
    setOpenMessageShow(true);
  };

  const handleCloseMessage = () => {
    setMessageId(null);
    setOpenMessageShow(false);
  };

  const [hovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [optionHovered, setOptionHovered] = useState(null);

  const user = useSelector((state) => state.userProfile.userProfile);
  const fcmToken = useSelector((state) => state.login.fcmToken);
  const fcmTokens = useSelector((state) => state.login.fcmTokens);

  const themeMode = useSelector((state) => state.themeMode.mode);

  const toggleTheme = () => {
    const newTheme = isTheme === "dark" ? "light" : "dark";
    setIsTheme(newTheme);
    dispatch(setThemeMode(newTheme));
    localStorage.setItem("theme", newTheme);
    return newTheme;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const open = Boolean(anchorEl);

  const [notifBoxVisible, setNotifBoxVisible] = useState(false);

  const openNotif = (event) => {
    setNotifBoxVisible(event.currentTarget);
  };

  const closeNotif = () => {
    setNotifBoxVisible(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelpCenter = () => {
    if (location.pathname.startsWith("/dashboard/help-center")) {
      const lastVisitedPage = localStorage.getItem("lastVisitedPage");
      if (lastVisitedPage) {
        navigate(lastVisitedPage);
      }
    } else {
      localStorage.setItem("lastVisitedPage", location.pathname);
      navigate("/dashboard/help-center");
    }
  };

  const handleNavigateHome = () => {
    navigate("/");
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const updatedFCMTokensArray = fcmTokens?.enabled?.filter(
      (item) => item !== fcmToken
    );
    dispatch(
      addRemoveToken({
        enabled: updatedFCMTokensArray,
        disabled: [...fcmTokens?.disabled, fcmToken],
      })
    );
    dispatch(logoutUser());
    setAnchorEl(null);
    navigate("/");
  };

  const path = location.pathname;

  const nameLength = user?.last_name?.length + user?.first_name?.length;

  const countUnread = useSelector(
    (state) =>
      state.notification?.notificationMeta?.total_unread_notifications_count
  );

  return (
    <>
      <Box>
        <AppBar
          position="fixed"
          className={`appBar`}
          sx={{
            paddingRight: { md: "60px", sm: "10px", xs: "0px" },
            paddingLeft: { md: "15px", sm: "10px", xs: "0px" },
            background:
              themeMode === "dark"
                ? "radial-gradient(50% 50% at 50% 50%, #2d0d0d 0%, #130000 100%)"
                : "radial-gradient(50% 50% at 50% 50%, #fafafa 0%, #ececec 100%)",
          }}
        >
          <Toolbar
            className="spaceBtwn"
            sx={{
              paddingY: "1px",
              paddingRight: { md: "0px", sm: "0px", xs: "0px" },
              paddingLeft: { md: "0px", sm: "30px", xs: "15px" },
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{
                "&:focus": {
                  outline: "none",
                },
                display: {
                  md: "none",
                  sm: "block",
                },
              }}
            >
              <Typography
                sx={{
                  color: themeMode === "dark" ? "#FFF" : "#030303",
                  fontSize: "25px",
                }}
              >
                <MenuIcon sx={{ height: "45px", width: "45px" }} />
              </Typography>
            </IconButton>

            <Grid
              sx={{
                display: {
                  md: "block",
                  sm: "none",
                  xs: "none",
                },
              }}
            >
              <Button
                disableRipple
                onClick={handleNavigateHome}
                sx={{
                  background: themeMode === "dark" ? "#150101" : "#ECECEC",
                  //padding: "13px",
                  width: "75px",
                  height: "75px",
                  marginLeft: "5px",
                  //transform:"scale(1.5)",
                  "&:hover": {
                    background: themeMode === "dark" ? "#150101" : "#ECECEC",
                  },
                }}
              >
                <img
                  src={themeMode === "dark" ? logoYellow : logoRed}
                  alt=""
                  style={{ transform: "scale(1.4)" }}
                />
              </Button>
            </Grid>

            <Toolbar
              className="toolBar"
              sx={{
                display: { md: "none", sm: "none", xs: "none" },
                // width: { md: "fit-content", sm: "50%", xs: "40%" },
                overflowX: { md: "hidden", sm: "auto" },
              }}
            >
              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                sx={{ background: "transparent" }}
              >
                <Grid
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className={
                    themeMode === "dark" ? "presentation" : "presentation-light"
                  }
                >
                  <Grid
                    className="w100p20"
                    sx={{
                      display: {
                        md: "none",
                        sm: "block",
                        xs: "block",
                      },
                    }}
                  >
                    <Button disableRipple component={Link} to="/dashboard">
                      <img
                        src={themeMode === "dark" ? logoYellow : logoRed}
                        alt=""
                      />
                    </Button>
                  </Grid>
                  {ToolbarList.map((item, index) => (
                    <Button
                      disableRipple
                      key={item.name}
                      className="btn"
                      onClick={() => navigate(item.link)}
                    >
                      <Grid
                        item
                        md={12}
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                        className="ball"
                        sx={{
                          // boxShadow:
                          //   themeMode === "dark" &&
                          //   location.pathname.startsWith(item.link)
                          //     ? "0px 0px 6px 3px #F9E74D"
                          //     : themeMode === "dark" &&
                          //       !location.pathname.startsWith(item.link)
                          //     ? "0px 4px 4px 0px #00000040"
                          //     : themeMode === "light" &&
                          //       location.pathname.startsWith(item.link)
                          //     ? "0px 0px 6px 3px #BE0000"
                          //     : "0px 4px 4px 0px #00000040",
                          boxShadow:
                            themeMode === "dark"
                              ? location.pathname.startsWith(item.link)
                                ? "0px 0px 6px 3px #F9E74D"
                                : "0px 4px 4px 0px #00000040"
                              : location.pathname.startsWith(item.link)
                              ? "0px 0px 6px 3px #BE0000"
                              : "0px 4px 4px 0px #00000040",
                          // backgroundColor:
                          //   themeMode === "dark" &&
                          //   location.pathname.startsWith(item.link)
                          //     ? "#F9E74D"
                          //     : themeMode === "light" &&
                          //       location.pathname.startsWith(item.link)
                          //     ? "#BE0000"
                          //     : themeMode === "dark"
                          //     ? "#52000099"
                          //     : themeMode === "light"
                          //     ? "#D4D4D4"
                          //     : "",
                          backgroundColor:
                            themeMode === "dark"
                              ? location.pathname.startsWith(item.link)
                                ? "#F9E74D"
                                : "#52000099"
                              : location.pathname.startsWith(item.link)
                              ? "#BE0000"
                              : "#D4D4D4",
                          "&:hover": {
                            border:
                              themeMode === "dark" ? "0.5px solid #f7e334" : "",
                            background:
                              themeMode === "dark" ? "#f9e74d" : "#BE0000",
                          },
                        }}
                      >
                        <Grid item md={12} mr={1} className="toolImg">
                          {/* <img
                            src={
                              themeMode === "dark"
                                ? hovered === index ||
                                  location.pathname.startsWith(item.link)
                                  ? item.imgD
                                  : item.img
                                : themeMode === "light"
                                ? hovered === index ||
                                  location.pathname.startsWith(item.link)
                                  ? item.imgW
                                  : item.imgD
                                : ""
                            }
                            alt={item.name}
                            className="w300"
                          /> */}
                          <div className="w300" loading="lazy">
                            {themeMode === "dark"
                              ? hovered === index ||
                                location.pathname.startsWith(item.link)
                                ? item.imgD
                                : item.img
                              : hovered === index ||
                                location.pathname.startsWith(item.link)
                              ? item.imgW
                              : item.imgD}
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item md={12} className="alignCenter">
                        <Typography
                          className="name"
                          sx={{
                            color:
                              themeMode === "dark"
                                ? hovered === index ||
                                  location.pathname.startsWith(item.link)
                                  ? "#FFF"
                                  : "#FFFFFF80"
                                : hovered === index ||
                                  location.pathname.startsWith(item.link)
                                ? "#BE0100"
                                : "#4D4D4D",
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Grid>
                    </Button>
                  ))}
                </Grid>
              </Drawer>
            </Toolbar>

            <Grid
              //className={`flex w-full justify-end lg:ml-16 pt-4`}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid
                className="alignCenter"
                sx={{ marginX: { md: "50px", sm: "50px", xs: "0px" } }}
              >
                <Button
                  onMouseEnter={() => setOptionHovered(4)}
                  onMouseLeave={() => setOptionHovered(null)}
                  className="ball2"
                  onClick={() => {
                    dispatch(setSelectedTabValue(2));
                    navigate("/dashboard/settings");
                  }}
                  sx={{
                    padding: "0px",
                    marginX: "5px",
                    backgroundColor: linkedin.connected ? "#58f55c" : "#fa3737",
                    width: { md: "51px", sm: "51px", xs: "35px" },
                    height: { md: "51px", sm: "51px", xs: "35px" },
                    position: "relative",
                  }}
                >
                  <Grid
                    sx={{
                      position: "absolute",
                      top: { md: "-5px", sm: "-5px", xs: "-10px" },
                      right: { md: "-5px", sm: "-5px", xs: "-10px" },
                    }}
                  >
                    {!linkedin?.connected ? (
                      <div
                        style={{
                          background: "#FFF",
                          borderRadius: "50%",
                          transform: "scale(0.8)",
                        }}
                      >
                        <ErrorIcon
                          sx={{ color: "red", transform: "scale(1.2)" }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          background: "#3cfa41",
                          borderRadius: "50%",
                          width: "15px",
                          height: "15px",
                          position: "relative",
                          top: "5px",
                          right: "5px",
                        }}
                      />
                    )}
                  </Grid>

                  <img
                    src={linkedInRoundedWhite}
                    alt=""
                    style={{ width: "100%" }}
                  />
                  <Grid
                    sx={{
                      display: optionHovered === 4 ? "flex" : "none",
                      position: "absolute",
                      background: !linkedin?.connected ? "#773636" : "#3b7736",
                      top: { md: "50px", sm: "50px", xs: "35px" },
                      padding: "5px 8px",
                      border: "1px solid #FFF",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        textWrap: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      {!linkedin?.connected
                        ? "Account: Disconnected"
                        : "Account: Connected"}
                    </Typography>
                  </Grid>
                </Button>

                <Button
                  onMouseEnter={() => setOptionHovered(1)}
                  onMouseLeave={() => setOptionHovered(null)}
                  className="ball2"
                  onClick={toggleTheme}
                  sx={{
                    marginX: "5px",
                    backgroundColor:
                      themeMode === "dark" ? "#52000099" : "#FFF",
                    width: { md: "51px", sm: "51px", xs: "35px" },
                    height: { md: "51px", sm: "51px", xs: "35px" },
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#ff000099" : "#BE0000",
                    },
                  }}
                >
                  <div style={{ transform: "scale(1.1)" }}>
                    {optionHovered === 1
                      ? nightModeWhite
                      : themeMode === "dark"
                      ? nightModeGrey
                      : nightModeBlack}
                  </div>

                  <Grid
                    sx={{
                      display: optionHovered === 1 ? "flex" : "none",
                      position: "absolute",
                      background: "#773636",
                      top: { md: "50px", sm: "50px", xs: "35px" },
                      padding: "5px 8px",
                      border: "1px solid #FFF",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        textWrap: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      {themeMode === "dark" ? "Light" : "Dark"} Mode
                    </Typography>
                  </Grid>
                </Button>

                <Button
                  onMouseEnter={() => setOptionHovered(2)}
                  onMouseLeave={() => setOptionHovered(null)}
                  onClick={handleHelpCenter}
                  className="ball2"
                  sx={{
                    marginX: "5px",
                    backgroundColor: location.pathname.startsWith(
                      "/dashboard/help-center"
                    )
                      ? "#BE0000"
                      : themeMode === "dark"
                      ? "#52000099"
                      : "#FFF",
                    width: { md: "51px", sm: "51px", xs: "35px" },
                    height: { md: "51px", sm: "51px", xs: "35px" },
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#ff000099" : "#BE0000",
                    },
                  }}
                >
                  <div style={{ transform: "scale(1.2)" }}>
                    {location.pathname.startsWith("/dashboard/help-center") ||
                    optionHovered === 2
                      ? helpWhite
                      : themeMode === "dark"
                      ? helpGrey
                      : helpBlack}
                  </div>
                  <Grid
                    sx={{
                      display: optionHovered === 2 ? "flex" : "none",
                      position: "absolute",
                      background: "#773636",
                      top: { md: "50px", sm: "50px", xs: "35px" },
                      padding: "5px 8px",
                      border: "1px solid #FFF",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        textWrap: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      Help Center
                    </Typography>
                  </Grid>
                </Button>

                <Button
                  onMouseEnter={() => setOptionHovered(3)}
                  onMouseLeave={() => setOptionHovered(null)}
                  onClick={openNotif}
                  className="ball2"
                  sx={{
                    backgroundColor:
                      notifBoxVisible && themeMode === "dark"
                        ? "#FF000099"
                        : !notifBoxVisible && themeMode === "dark"
                        ? "#52000099"
                        : notifBoxVisible && themeMode === "light"
                        ? "#BE0000"
                        : "#FFF",

                    marginX: "5px",
                    width: { md: "51px", sm: "51px", xs: "35px" },
                    height: { md: "51px", sm: "51px", xs: "35px" },
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#ff000099" : "#BE0000",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      position: "absolute",
                      top: { md: "-5px", sm: "-5px", xs: "-10px" },
                      right: { md: "-5px", sm: "-5px", xs: "-10px" },
                    }}
                  >
                    {countUnread ? (
                      <div
                        style={{
                          background: "#FFF",
                          borderRadius: "50%",
                        }}
                      >
                        <Grid
                          sx={{
                            background: "red",
                            color: "#FFF",
                            padding: "5px",
                            borderRadius: "50%",
                            height: "24px",
                            width: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {countUnread}
                        </Grid>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <div style={{ transform: "scale(1.1)" }}>
                    {optionHovered === 3 || notifBoxVisible
                      ? notifWhite
                      : themeMode === "dark"
                      ? notifGrey
                      : notifBlack}
                  </div>
                  <Grid
                    sx={{
                      display: optionHovered === 3 ? "flex" : "none",
                      position: "absolute",
                      background: "#773636",
                      top: { md: "50px", sm: "50px", xs: "35px" },
                      padding: "5px 8px",
                      border: "1px solid #FFF",
                      borderRadius: "30px",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "none",
                        color: "#FFF",
                        textWrap: "nowrap",
                        fontSize: "12px",
                      }}
                    >
                      Notifications
                    </Typography>
                  </Grid>
                </Button>
              </Grid>

              <NotificationMenu
                notifBoxVisible={notifBoxVisible}
                closeNotif={closeNotif}
                handleOpenMessage={handleOpenMessage}
              />

              <Button
                disableRipple
                className="profile"
                onClick={handleClick}
                sx={{
                  backgroundColor: {
                    md: themeMode === "dark" ? "#3434344D" : "#FFFFFF",
                    sm: themeMode === "dark" ? "#3434344D" : "#FFFFFF",
                    xs: "transparent",
                  },
                  padding: {
                    md: "0px 15px 0px 0px",
                    sm: "0px 15px 0px 0px",
                    xs: "0px",
                  },
                  fontSize: { md: "16px", sm: "16px", xs: "10px" },
                  marginX: "5px",
                  color: themeMode === "dark" ? "#FFF" : "#000",
                  "&:hover": {
                    backgroundColor: {
                      md: themeMode === "dark" ? "#3434344D" : "#FFFFFF",
                      sm: themeMode === "dark" ? "#3434344D" : "#FFFFFF",
                      xs: "transparent",
                    },
                  },
                }}
              >
                <Typography
                  sx={{
                    display: { md: "flex", sm: "flex", xs: "none" },
                    alignItems: "center",
                  }}
                >
                  {/* <img src={user?.image_url} alt="" className="userDp" /> */}
                  <Avatar
                    alt="Remy Sharp"
                    src={user?.image_url || user?.linkedin_profile_picture_url}
                    sx={{
                      width: 100,
                      height: 100,
                      marginRight: { md: "20px", sm: "20px", xs: "0px" },
                    }}
                    className="userDp"
                  />{" "}
                  {user?.first_name > 8
                    ? `${user?.first_name?.substring(0, 8)}...`
                    : user?.first_name}
                  <span
                    className="nameBold"
                    style={{ display: { md: "flex", sm: "none", xs: "none" } }}
                  >
                    {" "}
                    {nameLength > 8
                      ? `${user?.last_name?.substring(0, 2)}...`
                      : user?.last_name}
                  </span>
                </Typography>
                <Typography
                  sx={{ display: { md: "none", sm: "none", xs: "flex" } }}
                >
                  {/* <img src={user?.image_url} alt="" className="userDp" /> */}
                  <Avatar
                    alt="Remy Sharp"
                    src={user?.image_url}
                    sx={{
                      width: 100,
                      height: 100,
                      marginRight: { md: "20px", sm: "20px", xs: "0px" },
                    }}
                    className="userDp"
                  />{" "}
                </Typography>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onMouseLeave={handleClose}
                PaperProps={{
                  sx: {
                    backgroundColor: "#FF0000AA",
                    color: "white",
                    borderRadius: "30px",
                    padding: "5px 15px 5px 15px",
                    width: "195px",
                  },
                }}
              >
                <MenuItem
                  onClick={handleNavigateHome}
                  sx={{
                    borderRadius: "15px",
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#52000099" : "#EC9080",
                    },
                  }}
                >
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <Home
                      style={{
                        marginRight: "8px",
                        padding: "0px",
                      }}
                    />
                    Go to Home
                  </Typography>
                </MenuItem>
                <Divider sx={{ backgroundColor: "#fff" }} />
                <MenuItem
                  onClick={handleLogout}
                  sx={{
                    borderRadius: "15px",
                    "&:hover": {
                      background:
                        themeMode === "dark" ? "#52000099" : "#EC9080",
                    },
                  }}
                >
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={logout}
                      alt="Logout"
                      style={{ marginRight: "10px", marginLeft: "4px" }}
                    />
                    Logout
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      <MessageDialog
        openMessageShow={openMessageShow}
        closeMessageShow={handleCloseMessage}
        messageId={messageId}
      />
    </>
  );
};
export default NavbarApp;
