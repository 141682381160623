import { Grid, Typography } from "@mui/material";
import { HowItWorksCard } from "./HowItWorksCard";
const idealAudience = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/searchEngine.png"
const emailCapture = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/emailCapture.png"
const conversionRate = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/conversionRate.png"
const handleResponse = "https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/bro.png"

export const HowItWorks = () => {
  return (
    <div className="pt-16 text-white">
      <Typography
        sx={{
          typography: {
            md: "h3",
            sm: "h4",
            xs: "h4",
          },
        }}
        style={{ fontWeight: 700 }}
        className="py-10 text-center"
      >
        How It Works
      </Typography>
      <div className="flex justify-center">
        <img src="https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/lines.png" alt="lines" className="px-8" />
      </div>
      <div
        className="py-8 h-full flex justify-center md:px-24 px-8"
        style={{
          backgroundImage: `url(https://bucketeer-02e39b98-2de8-40e4-b373-c6008e170e62.s3.amazonaws.com/public/worksBg.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <Grid container spacing={1.5}>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <HowItWorksCard
              image = {idealAudience}
              title={"Step 1:"}
              subtitle={"Identify Your Ideal Audience"}
              heading={"Connect with right individuals"}
              content={
                "Focus on meaningful connections, not random contacts. Leverage industry insights and past data to reach top decision-makers."
              }
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <HowItWorksCard
              image={emailCapture}
              title={"Step 2:"}
              subtitle={"Craft Effective Messages"}
              heading={"Personalize Your Approach"}
              content={
                "Avoid generic messages. Use advanced tools to quickly create tailored responses that resonate with your prospects."
              }
            />
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <HowItWorksCard
              image={handleResponse}
              title={"Step 3:"}
              subtitle={"Handle Responses Efficiently"}
              heading={"Automate Follow-Ups"}
              content={
                "Let our team manage the initial contact and replies. Set up sequences for follow-ups and reminders, ensuring your calendar fills with meetings."
              }
            />{" "}
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <HowItWorksCard
              image={conversionRate}
              title={"Step 4:"}
              subtitle={"Optimize Strategy"}
              heading={"Review & Improve Campaigns"}
              content={
                "Regular strategy sessions to analyze results, refine approaches, and streamline your prospecting process for better efficiency and success."
              }
            />{" "}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
